import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as LeadsActions from '../../actions/leads/leadsAction';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalResponse from '../layout/modals/modalResponse';
import Modal from '../layout/modals/detail/index';
import './style.scss';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';

import DatePicker from '../tools/datePickerControl';
import { StatusCodes } from 'http-status-codes';

const columns = [
	{
		name: 'Acción',
		selector: 'type',
		sortable: true
	},
	{
		name: 'Fecha',
		selector: 'date',
		sortable: true
	},
	{
		name: 'Estado',
		selector: 'async',
		sortable: true
	},
	{
		name: 'Nombre de campaña',
		selector: 'data.nombre_de_campana',
		selector2: 'data.campania',
		sortable: true
	},

	{
		name: 'Correo electrónico',
		selector: 'data.correo_contacto',
		selector2: 'data.correoContacto',
		sortable: true,
		filterDDL: true
	},
	{
		name: 'Nombre de contacto',
		selector: 'data.nombre_contacto',
		selector2: 'data.nombreContacto',
		sortable: true,
		filterDDL: true
	},
	{
		name: 'Numero de contacto',
		selector: 'data.numero_contacto',
		selector2: 'data.numeroContacto',
		sortable: true,
		filterDDL: true
	},
	{
		name: 'Medio virtual',
		selector: 'data.medio_virtual',
		selector2: 'data.medioVirtual',
		sortable: true,
		filterDDL: true
	},
];


let downloadEnable = false;
class LeadsList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			show: false,
			detailLeads: [],
			commentLeads: [],
			logSynergy: '',
			fromDate: '',
			toDate: ''
		};

		this.datePickerRef = React.createRef();
	}

	showModal = () => {
		this.setState({ show: !this.state.show });
	};

	getLeads = () => {

		let dates = { from: new Date(), toDate: new Date() };
		this.setTimeDate(dates.from, dates.toDate);

		const params = {
			$and: [
				{ $or: [{ type: 'createLead' }, { type: 'comment' }] },
				{
					$or: [{
						date: {
							'$gte': dates.from,
							'$lt': dates.toDate
						}
					}]
				}
			]
		};
		this.props.actions.getLeads({ types: params });
	};

	componentDidMount() {
		this.getLeads();
	}

	filtrarLeads = e => {
		e.preventDefault();
		const action = e.target.action.value;
		const campana = e.target.campana.value;
		const contacto = e.target.contacto.value;
		const slAsync = e.target.slAsync.value;

		const data = [
			{ key: { action: action }, value: action },
			{ key: { campana: campana }, value: campana },
			{ key: { contacto: contacto }, value: contacto },
			{ key: { from: this.state.fromDate }, value: this.state?.fromDate?.toString() },
			{ key: { to: this.state.toDate }, value: this.state?.toDate?.toString() },
			{ key: { async: slAsync }, value: slAsync }
		];
		let params = {};
		let or = [];
		let areDatesRangeValid = this.validateRange(this.datePickerRef.current.datePickerRef.current.value, this.datePickerRef.current.datePickerRef.current.value2);
		if (!areDatesRangeValid) {
			this.setState({ fromDate: '', toDate: '' });
			if (this.datePickerRef?.current) this.datePickerRef.current.handleCleanImput()
			ModalResponse({
				title: 'Leads',
				text: 'Rango de fechas inválido',
				res: 404
			});
		}else if (action != '' || campana != '' || contacto != '' || slAsync != '' || (this.state.fromDate != "" && this.state.toDate != "")) {
			if(!this.props.checkAuth()) return;
			data.map((val, id) => {
				if (val.value != '') {
					Object.assign(params, val.key);
				}
			});
			this.props.actions.getLeads({ types: params });
		} else {
			ModalResponse({
				title: 'Leads',
				text: 'Debe ingresar al menos un filtro',
				res: 404
			});
		}
	};

	validateRange(strDateFrom, strDateTo) {
		let areValid = true;
		if (strDateFrom !== undefined && strDateTo !== undefined) {
			if (strDateFrom !== null && strDateTo !== null) {
				let fromDate = this.createDate(strDateFrom);
				let toDate = this.createDate(strDateTo);
				if (toDate < fromDate) {
					areValid = false;
				}
			}
		}
		return areValid;
	}

	createDate(strDate) {
		let day = parseInt(strDate.substring(0, 2));
		let mon = parseInt(strDate.substring(3, 5));
		let year = parseInt(strDate.substring(6, 10));
		let resDate = new Date(year, mon - 1, day);
		return resDate;
	}

	cleanFilter = e => {
		e.preventDefault();
		document.getElementById('action').value = '';
		document.getElementById('campana').value = '';
		document.getElementById('contacto').value = '';
		document.getElementById('slAsync').value = '';
		this.setState({ fromDate: '', toDate: '' });

		if(this.datePickerRef?.current) this.datePickerRef.current.handleCleanImput()
		if(!this.props.checkAuth()) return;

		this.getLeads();

	};

	showDetail = data => {
		let datos = [];
		let comment = [];
		let logSynergy = [];
		const dataSynergy = Array.isArray(data.logSynergy) ? data.logSynergy[0] : data.logSynergy;
		Object.keys(data.data).map(function (key) {
			datos.push({ key: key, value: data.data[key] });
		});
		if (typeof data.comments === 'object') {
			Object.keys(data.comments).map(function (key) {
				comment.push({ key: key, value: JSON.stringify(data.comments[key]) });
			});
		} else {
			comment = data.comments;
		}
		if (typeof dataSynergy === 'object') {
			Object.keys(dataSynergy).map(function (key) {
				logSynergy.push({ key: key, value: JSON.stringify(dataSynergy[key]) });
			});
		} else {
			logSynergy = data.logSynergy;
		}


		this.setState({
			detailLeads: datos,
			commentLeads: comment,
			show: true,
			logSynergy
		});
	};

	handleDate = (data) => {
		let fromDate = data.from != undefined ? new Date(data.from) : undefined;
		let toDate = data.to != undefined ? new Date(data.to) : undefined;
		this.setState({ fromDate: !isNaN(fromDate.valueOf()) ? fromDate : '' });
		this.setState({ toDate: !isNaN(toDate.valueOf()) ? toDate : '' });
	}

	handleInvalidRange = () => {
		this.setState({ fromDate: '', toDate: '' });
		if (this.datePickerRef?.current) this.datePickerRef.current.handleCleanImput()
		ModalResponse({
			title: 'Leads',
			text: 'Rango de fechas inválido',
			res: 404
		});
	}

	setTimeDate = (from, to) => {
		if (from) {
			from.setHours(0);
			from.setMinutes(0);
			from.setSeconds(0);
			from.setMilliseconds(0);
		}

		to.setHours(23);
		to.setMinutes(59);
		to.setSeconds(59);
		to.setMilliseconds(999);
	}

	onHandleDownload = (datos) => {
		if (datos.length > 0) {
			if(!this.props.checkAuth()) return;
			downloadEnable = true;
			let copyColumns = [...columns];
			copyColumns.push({
				name: 'Tarea',
				selector: 'task',
			});

			let columnsReq = copyColumns.map(function (column) {
				let columnAux = [];
				columnAux.title = column.name;
				columnAux.id = column.selector;
				return JSON.stringify(Object.assign({}, columnAux));
			}).join();

			let datosString = datos.map(function (dataItem) {
				let data = {}
				if (dataItem.type == "comment") {
					data = {
						"type": dataItem.type,
						"date": dataItem.date,
						"async": dataItem.async == "true" ? "Sincronizado" : "No Sincronizado",
						"data.nombre_de_campana": dataItem.data.campania,
						"data.correo_contacto": dataItem.data.correoContacto,
						"data.nombre_contacto": dataItem.data.nombreContacto,
						"data.numero_contacto": dataItem.data.numeroContacto,
						"data.medio_virtual": dataItem.data.medioVirtual,
						"task": dataItem.comments.HID != undefined ? dataItem.comments.HID : "0"
					}

				} else {
					data = {
						"type": dataItem.type,
						"date": dataItem.date,
						"async": dataItem.async == "true" ? "Sincronizado" : "No Sincronizado",
						"data.nombre_de_campana": dataItem.data.nombre_de_campana,
						"data.correo_contacto": dataItem.data.correo_contacto,
						"data.nombre_contacto": dataItem.data.nombre_contacto,
						"data.numero_contacto": dataItem.data.numero_contacto,
						"data.medio_virtual": dataItem.data.medio_virtual,
						"task": dataItem.comments
					}


				}
				return JSON.stringify(Object.assign({}, data));
			}).join();

			let reqExcel = {
				"columns": JSON.parse(`[${columnsReq}]`),
				"data": JSON.parse(`[${datosString}]`),
				"typeFile": "csv"
			};

			this.props.actions.sendExcelObject(reqExcel);

		} else {
			ModalResponse({
				title: 'Descargar excel',
				text: 'No hay datos para descargar',
				res: 404,
			});
		}
	};


	downloadExcel = (data) => {

		var blob = new Blob([atob(data)], { encoding: "UTF-8", type: 'text/plain;charset=UTF-8' });
		if (window.navigator.msSaveBlob) {
			window.navigator.msSaveOrOpenBlob(blob, 'exportData' + new Date().toDateString() + '.csv');
		}
		else {
			var a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(blob, { type: "text/plain;charset=UTF-8" });
			a.download = "exportData" + new Date().toDateString() + ".csv";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}

	render() {
		let { error, isLoading, leads, excelData } = this.props;
		const btnActions = {
			btnShowDetail: this.showDetail,
			chMassiveActions: false
		};
		let datos = [];
		let status = 0;
		let text = '';
		let data = {};

		if (leads) {
			/*if (leads.data.error === 'Forbidden') {
			  return <div>No tiene permiso para este componente</div>;
			}*/
			if (leads.data != null) {
				datos = leads.data;
			}

		}

		if (excelData) {
			if (excelData.status === StatusCodes.OK) {
				if (downloadEnable) {
					this.downloadExcel(excelData.data);
					downloadEnable = false;
				}
			}
		}

		return (
			<div>
				<div>
					<h3>Leads</h3>
					<form onSubmit={this.filtrarLeads}>
						<div className="row divFilter">
							<div className="column4">
								<DatePicker id="datePickerControl"
									ref={this.datePickerRef}
									handleDate={this.handleDate}
									handleInvalidRange={this.handleInvalidRange}
									from={this.state.fromDate}
									to={this.state.toDate} />
							</div>
							<div className="column4">
								<select className="slFilter" id="slAsync" name="slAsync">
									<option value="">Seleccione el estado</option>
									<option value="true">Sincronizado</option>
									<option value="false">No sincronizado</option>
								</select>
							</div>
							<div className="column4"></div>
						</div>
						<div className="row divFilter">
							<div className="column4">
								<select className="slFilter" id="action" name="action">
									<option value="">Seleccione la acción</option>
									<option value="createLead">Crear Leads</option>
									<option value="comment">Comentario</option>
								</select>
							</div>
							<div className="column4">
								<input
									className="inputFilter"
									id="campana"
									name="campana"
									type="text"
									placeholder="Campaña"
								/>
							</div>
							<div className="column4">
								<input
									className="inputFilter"
									id="contacto"
									name="contacto"
									type="text"
									placeholder="Correo electrónico"
								/>
							</div>
							<div className="column4">
								<div className="row">
									<div className="column2">
										<button className="green-fill btnBuscar" type="submit">
											<div className="row">
												<div className="column2">
													<span className="siicon icon-search searchMarket"></span>
												</div>
												<div className="column2">
													<span>Buscar</span>
												</div>
											</div>
										</button>
									</div>
									<div className="column2">
										<button
											onClick={this.cleanFilter}
											className="orange-fill"
											type="button"
										>
											Limpiar
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
				{/*error && <h4>Error: {error.response.data}</h4>*/}
				{isLoading ? (
					<Loader />
				) : (
					<div>
						<div className="divTable">
							<TableReport
								columns={columns} // Data columns to table
								tabs={false} //Enable tabs
								tabsData={[]} //Load data to tabs check option on tabsData const
								headerCounterTab={true} //Counter tab option
								btnActions={btnActions}
								actionButtons={true} //Enable buttons (edit, delete, copy, open)
								pageSize={10} // Enable page size
								pagination={true} //Enable pagination (true, false)
								paginationNav={'arrows'} //Nav options (arrow, dot)
								paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
								maxSizePagination={6} //Size pagination
								defaultPaginationNumber={1} //Current pagination when start app
								paginationGrouped={10} //Grouped by page
								detailRow={false} //Open column detail with left arrow
								data={datos} //Data for table
							/>
						</div>
						<Modal
							onClose={this.showModal}
							show={this.state.show}
							title="Detalles del Lead"
						>
							{this.state.detailLeads.map((val, id) => {
								return (
									<div className="row rowModal">
										<div className="column2">{val.key}</div>
										<div className="column2">{val.value}</div>
									</div>
								);
							})}
							<hr />
							<h3 className="h3Modal">Comentarios</h3>
							{typeof this.state.commentLeads === 'object' ? (
								this.state.commentLeads.map((val, id) => {
									return (
										<div className="row rowModal">
											<div className="column2">{val.key}</div>
											<div className="column2">{val.value}</div>
										</div>
									);
								})
							) : (
								<div>{this.state.commentLeads}</div>
							)}
							<hr />
							<h3 className="h3Modal">Log Synergy</h3>
							{typeof this.state.logSynergy === 'object' ? (
								this.state.logSynergy.map((val, id) => {
									return (
										<div className="row rowModal">
											<div className="column2">{val.key}</div>
											<div className="column2">{val.value}</div>
										</div>
									);
								})
							) : (
								<div>{this.state.logSynergy}</div>
							)}

						</Modal>
						<FontAwesomeIcon className='icon-question' icon={faQuestion} />
						<p className='text-info-data'>Si requieres un reporte de más de 1.000 registros puedes comunicarte con el equipo de BI o la tribu STARK por medio de un Halp.</p>
						<br />
						{getAccess(this.props.parentPath, ACCESS.Download_Excel) ? (
							<button className="button green-fill btnDownload" onClick={e => this.onHandleDownload(datos)}>
								{' '}
								Descargar excel
							</button>
						) : null}
					</div>
				)}
			</div>
		);
	}
}

LeadsList.propTypes = {
	actions: PropTypes.object.isRequired,
	leads: PropTypes.object,
	error: PropTypes.object,
	excelData: PropTypes.object
};

const mapStateToProps = state => ({
	leads: state.leads.leads,
	error: state.leads.err,
	isLoading: state.leads.isLoading,
	excelData: state.synergy.excelData
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(LeadsActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(LeadsList);
