import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import SiigersRoute from "./siigersRoute";
import LoginPage from "../containers/auth/login";
import RegisterPage from "../containers/auth/register";
import PassReset from "../containers/auth/passReset";
import Dashboard from "../containers/dashboard";
import Plans from "../containers/plans";
import Dependences from "../containers/dependences";
import Components from "../containers/components";
import Groups from "../containers/groups";
import DependenceCreate from "../containers/dependences/create";
import GroupCreate from "../containers/groups/create";
import ComponentCreate from "../containers/components/create";
import DependenceEdit from "../containers/dependences/edit";
import ComponentEdit from "../containers/components/edit";
import GroupEdit from "../containers/groups/edit";
import UserEdit from "../containers/users/edit";
import Perfil from "../containers/users/perfil";
import Users from "../containers/users";
import Company from "../containers/market/company";
import SearchEmail from "../containers/market/searchEmail";
import CompanyLogs from "../containers/market/companyLogs";
import UsersManagement from "../containers/market/usersPageManagement";
import Market from "../containers/market";
import ProcessNSN from "../containers/market/processNSN";
import Settings from "../containers/settings";
import Logs from "../containers/logs";
import Leads from "../containers/leads";
import Surveys from "../containers/surveys";
import Notification from "../containers/notificationAS";
import Campaigns from "../containers/campaigns";
import NotificationCreate from "../containers/notificationAS/create";
import NotificationEdit from "../containers/notificationAS/edit";
import AccountStatement from "../containers/accountStatement";
import Accountant from "../containers/accountant";
import AuroraSubscription from "../containers/auroraSubscription";
import AuroraTokens from '../containers/auroraTokens';
import RecurrentPaymetReport from "../containers/recurrentPaymentReport";
import StatePaymetReport from "../containers/statePaymentReport";
import LinkAurora from "../containers/linkAurora";
import RefundAurora from "../containers/refundAurora";
import CloudLog from "../containers/cloudLog";
import Invoices from "../containers/contracts";
import AuroraConfigProviderContainer from "../containers/auroraConfigProvider";
import CreateConfigProviderContainer from "../containers/auroraConfigProvider/createProvider";
import EditConfigProviderContainer from "../containers/auroraConfigProvider/editProvider";
import AuroraConigOriginsContainer from "../containers/auroraConfigOrigins";
import CreateConfigOriginContainer from "../containers/auroraConfigOrigins/createOrigin";
import EditConfigOriginContainer from "../containers/auroraConfigOrigins/editOrigin";
import CamaignSettingContainer from "../containers/five9/w2cCountry";
import CamaignSettingsRulesContainer from "../containers/five9/w2cRules";
import FormUsersPage from "../containers/market/formUsersPage";
import ProductListSynergy from "../containers/productListSynergy";
import BlackListEmailContainer from "../containers/blackListEmail";
import GeneralReportContainer from '../containers/generalReport'
import CampaignContainer from "../containers/shop/campaign/index";
import MenuAdminContainer from "../containers/menuadmin";
import MarketwebAccountantContainer from "../containers/marketwebAccountantPortal/index";
import InternalRequestsContainer from "../containers/internalRequests";

import DataSource from "../containers/datasource";
import DataReport from "../containers/datareport";
import EditDataSourceContainer from "../containers/datasource/edit";
import CreateDataSourceContainer from "../containers/datasource/create";
import ActivationManagementContainer from "../containers/activationManagement";

/* Siigers */
import Sviajes from '../components/siigers/viajes/sviajes';
import Sperfil from '../components/siigers/perfil/Sperfil'
import Sexplorar from '../components/siigers/explorar/sexplorar';
import Smensajes from '../components/siigers/msg/smensajes';
import Sperfilanfitrion from "../components/siigers/perfil/Sperfilanfitrion";
import SperfilAlojamiento from "../components/siigers/perfil/SperfilAlojamiento";
import SviajesReservas from "../components/siigers/viajes/sviajesreservas";
import SviajesAprobacion from "../components/siigers/viajes/sviajesAprobacion";
import { AuthProvider } from "../context/auth-context";



class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div>
          <AuthProvider>
          <Switch>
            <Route path="/" exact={true} component={LoginPage} />
            <Route path="/login/:token/:cloudToken" component={LoginPage} />
            <Route path="/register" component={RegisterPage} />
            <Route path="/reset-password" component={PassReset} />
            <PrivateRoute path={"/dashboard"} component={Dashboard} />
            <PrivateRoute path={"/dependences"} component={Dependences} />
            <PrivateRoute path={"/components"} component={Components} />
            <PrivateRoute path={"/groups"} component={Groups} />
            <PrivateRoute path={"/datareport"} component={DataReport} />
            <Route path={"/datasource/create"} component={DataSource} />
            <Route path={"/datasource/create-form"} component={CreateDataSourceContainer} />
            <Route path={"/datasources/edit/:id"} component={EditDataSourceContainer} />

            <PrivateRoute
              path={"/configproviders"}
              component={AuroraConfigProviderContainer}
            />
            <PrivateRoute
              path={"/configprovider/create"}
              component={CreateConfigProviderContainer}
            />
            <Route
              path={"/configprovider/edit/:id"}
              component={EditConfigProviderContainer}
            />
            <PrivateRoute
              path={"/configorigins"}
              component={AuroraConigOriginsContainer}
            />
            <PrivateRoute
              path={"/configorigin/create"}
              component={CreateConfigOriginContainer}
            />
            <Route
              path={"/configorigin/edit/:id"}
              component={EditConfigOriginContainer}
            />
            <Route
              path={"/generalReport/:id"}
              component={GeneralReportContainer}
            />
            <PrivateRoute path={"/market"} component={Market} />
            <PrivateRoute path={"/nsn"} component={ProcessNSN} />
            <PrivateRoute
              path={"/dependence/create"}
              component={DependenceCreate}
            />
            <PrivateRoute
              path={"/component/create"}
              component={ComponentCreate}
            />
            <PrivateRoute path={"/group/create"} component={GroupCreate} />
            <Route path={"/dependence/edit/:id"} component={DependenceEdit} />
            <Route path={"/component/edit/:id"} component={ComponentEdit} />
            <Route path={"/group/edit/:id"} component={GroupEdit} />
            <Route path={"/user/edit/:id"} component={UserEdit} />
            <Route path={"/perfil/:id"} component={Perfil} />
            <Route exact path={"/company/:id"} component={Company} />
            <Route path={"/company/:id/logs"} component={CompanyLogs} />
            <Route path={"/users_management/:id"} component={UsersManagement} />
            <Route
              path={"/company/:id/user/:action"}
              component={FormUsersPage}
            />

            <PrivateRoute path={"/plans"} component={Plans} />
            <PrivateRoute path={"/plans/new"} component={Settings} />
            <PrivateRoute path={"/users"} component={Users} />
            <PrivateRoute path={"/settings"} component={Settings} />
            <PrivateRoute path={"/logs"} component={Logs} />
            <PrivateRoute path={"/invoices"} component={Invoices} />
            <PrivateRoute path={"/leads"} component={Leads} />
            <PrivateRoute path={"/surveys"} component={Surveys} />
            <PrivateRoute path={"/notificationas"} component={Notification} />
            <PrivateRoute path={"/campaigns"} component={Campaigns} />
            <PrivateRoute path={"/linkAurora"} component={LinkAurora} />
            <PrivateRoute path={"/refundAuroraPayments"} component={RefundAurora} />
            <PrivateRoute path={"/cloudLog"} component={CloudLog} />
            <PrivateRoute
              path={"/aurorasubscription"}
              component={AuroraSubscription}
            />
            <PrivateRoute
              path={"/paymetreport"}
              component={RecurrentPaymetReport}
            />
            <PrivateRoute path={'/statepaymetreport'} component={StatePaymetReport} />
            <PrivateRoute path={'/group/create'} component={GroupCreate} />
            <Route path={'/dependence/edit/:id'} component={DependenceEdit} />
            <Route path={'/component/edit/:id'} component={ComponentEdit} />
            <Route path={'/group/edit/:id'} component={GroupEdit} />
            <Route path={'/user/edit/:id'} component={UserEdit} />
            <Route path={'/perfil/:id'} component={Perfil} />
            <Route path={'/company/:id'} component={Company} />
            <PrivateRoute path={'/plans'} component={Plans} />
            <PrivateRoute path={'/plans/new'} component={Settings} />
            <PrivateRoute path={'/users'} component={Users} />
            <PrivateRoute path={'/settings'} component={Settings} />
            <PrivateRoute path={'/logs'} component={Logs} />
            <PrivateRoute path={'/invoices'} component={Invoices} />
            <PrivateRoute path={'/leads'} component={Leads} />
            <PrivateRoute path={'/surveys'} component={Surveys} />
            <PrivateRoute path={'/notificationas'} component={Notification} />
            <PrivateRoute path={'/linkAurora'} component={LinkAurora} />
            <PrivateRoute path={"/refundAuroraPayments"} component={RefundAurora} />
            <PrivateRoute path={'/cloudLog'} component={CloudLog} />
            <PrivateRoute path={'/aurorasubscription'} component={AuroraSubscription} />
            <PrivateRoute path={'/auroraTokens'} component={AuroraTokens} />
            <PrivateRoute path={'/paymetreport'} component={RecurrentPaymetReport} />
            <PrivateRoute path={"/productListSynergy"} component={ProductListSynergy} />
            <PrivateRoute
              path={"/accountStatements"}
              component={AccountStatement}
            />
            <PrivateRoute path={"/accountant"} component={Accountant} />
            <PrivateRoute
              path={"/campaignSetting"}
              component={CamaignSettingContainer}
            />
            <PrivateRoute
              path={"/campaignSettingsRules"}
              component={CamaignSettingsRulesContainer}
            />
            <PrivateRoute
              path={"/notificationa/create"}
              component={NotificationCreate}
            />
            <Route
              path={"/notificationa/edit/:id"}
              component={NotificationEdit}
            />
            <PrivateRoute path={"/blackListEmail"} component={BlackListEmailContainer} />
            <PrivateRoute path={"/shop/campaign"} component={CampaignContainer} />

            <PrivateRoute path={"/activationManagement"} component={ActivationManagementContainer} />
            <PrivateRoute path={"/menu-admin"} component={MenuAdminContainer} />

            <PrivateRoute path={"/accountantPortal"} component={MarketwebAccountantContainer} />
            <PrivateRoute path={"/internalRequests"} component={InternalRequestsContainer} />
            <PrivateRoute path={"/searchEmail"} component={SearchEmail} />

            <SiigersRoute path={"/siigers/viajes/reservas"} component={SviajesReservas} />
            <SiigersRoute path={"/siigers/viajes/aprobar"} component={SviajesAprobacion} />
            <SiigersRoute path={"/siigers/viajes"} component={Sviajes} />
            <SiigersRoute path={"/siigers/perfil/alojamiento"} component={SperfilAlojamiento} />
            <SiigersRoute path={"/siigers/mensajes"} component={Smensajes} />
            <SiigersRoute path={"/siigers/explorar"} component={Sexplorar} />
            <SiigersRoute path={"/siigers/perfil/anfitrion"} component={Sperfilanfitrion} />
            <SiigersRoute path={"/siigers/perfil"} component={Sperfil} />
            <SiigersRoute path={"/siigers"} component={Sexplorar} />
            <SiigersRoute path={"/siiger"} component={Sexplorar} />

          </Switch>
          </AuthProvider>
        </div >
      </BrowserRouter >
    );
  }
}

export default App;
