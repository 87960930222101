import { jwtDecode } from "jwt-decode";

export function setCookie(cname, cvalue, hours) {
  let d = new Date();
  d.setTime(d.getTime() + hours * 60 * 60 * 1000); // (exdays * 24 * 60 * 60 * 1000));
  let expires = 'expires=' + d.toUTCString();
  if(cvalue && cvalue.length > 4000){
    let nvalue=cvalue.slice(4000);
    cvalue=cvalue.slice(0, 4000);
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    setCookie(cname+"2", nvalue, 1);
  }
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname) {
  let name = cname + '=';
  let ca = document.cookie.split(';');

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
}

export function checkCookie() {
  let user = getCookie('token');
  if (user !== '' && checkToken(user)) {
    return user;
  } else {
    return null;
  }
}

export function isLoggedIn() {
  try {
    const cookie = getCookie('token');
    if (!cookie) return false;
    return checkToken(cookie);
  } catch (error) {
    return false;
  }
}

export function checkToken(cookie) {
  try {
    const decoded = jwtDecode(cookie);
    const currentTime = Math.floor(Date.now() / 1000);

    return decoded.exp && decoded.exp > currentTime;
  } catch (error) {
    return false;
  }
}

export function deleteCookie(names) {
  for (let i = 0; i < names.length; i++) {
    document.cookie = names[i] + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }
}
