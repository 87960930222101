import React, { Component } from 'react';
import { Link } from 'react-router-dom'

import { SiigoSwitchAtom } from '@siigo/siigo-switch-atom-react'
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react'

import Smenu from '../slayout/smenu/smenu';
import Anfitrionperfil from '../sshared/anfitrionperfil/anfitrionperfil';

import { deleteCookie } from '../../../utils/cookies'  
import config from '../../../config';

import './Sperfil.scss'
import SquareIconData from '../sshared/SquareIconData/SquareIconData';
import { UpdateSiiger, UpdateSiigerIsHost } from '../helpers/SiigerHelper';
import SperfilAlojamiento from './SperfilAlojamiento';

class Sperfil extends Component {   
   
    constructor(props) {
	    super(props);
        let _siigerLs = localStorage.getItem('siiger');
        if ((_siigerLs == undefined)||(_siigerLs == 'undefined')){ //:TODO esto se tiene que hacer con objetos no con el string ¬¬
            this.logout();
        }

        let siigerO = JSON.parse(_siigerLs);

        this.state = { 
            siiger : siigerO
        };            
    } 

    logout = () => {
        localStorage.removeItem('siiger');
        localStorage.removeItem('siigertravelsearch');
        deleteCookie(['isSuccess', 'token', 'email', 'sub']);
        this.setState({ isSuccess: false });
        window.open(config.url + 'auth/azureadoauth2/logout', '_self');
    };

    onChangeIsHost(e){       
        this.setState({ siiger: { ...this.state.siiger, 
                        isHost: !this.state.siiger.isHost } });
        localStorage.setItem("siiger", JSON.stringify(this.state.siiger));
        UpdateSiigerIsHost(this.state.siiger, this.state.siiger.isHost);
    }

    EsHostPorDefecto(s){
        return (s.host.name == '')&&(s.host.country == 0)&&(s.host.state == 0);
    }

    render(){
        return(
            <>
                <Smenu />  
                <div className='container siiger'>                    
                    {/* Perfil */}
                    <div className='scard'>
                        <div className='row borderbottom-sutil'>
                            <Anfitrionperfil {...this.props}/>                    
                        </div>

                        <div className='row align-center'>
                            <h4>¿Deseas alojar a un Siiger?</h4>
                        </div>
                        <div className='row align-center borderbottom-sutil'>
                            <SiigoSwitchAtom
                                checked= { this.state.siiger.isHost }
                                textOn ="Habilitado para visitas"
                                textOff="No habilitado"
                                onChanged= {e => { this.onChangeIsHost(e) }}
                            />
                        </div>                

                        <div className='row align-center row-cerrar-sesion'>
                            <SiigoButtonAtom
                                id='searchBuscarViajesButton'
                                color="secondatry"
                                size="l"
                                type="button"
                                text="Cerrar sesión"
                                icon="fas"     
                                onClick={this.logout}                               
                            />                                    
                        </div>                            
                    </div>
                    
                    {/* Si es host , y tiene perfil...  */}
                    { this.state.siiger.isHost ? ( this.EsHostPorDefecto(this.state.siiger) ? <DivSinPerfil {...this.state.siiger} /> : <DivConPerfil {...this.state.siiger} /> ) : null }                  
                </div>
            </>
        );
    }

}

function DivSinPerfil(props){
    return(
        <div id="DivSinPerfil" className='scard'>
            <div className='align-center'>
                <i className="fa-solid fa-house-chimney-user"></i>                            
            </div>
            <div className='text-center'>
                <label>Aún no tienes perfil de anfitrión , debes crear uno</label>
            </div>

            <div className=''>
                <div className='col width-100'>
                    <Link to="/siigers/perfil/anfitrion">
                        <SiigoButtonAtom
                            id='searchBuscarViajesButton'
                            color="primary-green"
                            size="l"
                            type="button"
                            text="Crear perfil de anfitrión"
                            icon="fas"                                    
                        />                                    
                    </Link>
                </div>                            
            </div>                        
        </div>
    )
}

function DivConPerfil(props){
    
    return(
        <SperfilAlojamiento {...props} />
    )
}

export default Sperfil;