import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/productListSynergy';

const FormData = require('form-data');

export function* sendExcelObjectSynergy(action) {
	const api = API.create();
	api.removeHeader('Content-Type');
	api.setHeader('Content-Type','multipart/form-data');
	const { params } = action;	
	const formdata = new FormData();
	formdata.append('file', params);
	try {	
		const response = yield call(api.productListSynergy.postProductListSynergy, formdata);	
		
		yield put({ type: types.SEND_EXCEL_OBJECT_RECEIVED_SYNERGY, updateData: response }); 
	} catch (err) {
		yield put({ type: types.SEND_EXCEL_OBJECT_FAILED_SYNERGY, err });
	}
}

export function* downloadFileSynergy(action) {		
	const api = API.create();
	try {
		let excel = yield call(api.productListSynergy.getProductListSynergy, '');	
		yield put({ type: types.DOWNLOAD_FILE_SYNERGY_RECEIVED, data: excel });

	} catch (err) {
		yield put({ type: types.DOWNLOAD_FILE_SYNERGY_FAILED, err });
	}
}