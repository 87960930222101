import * as types from '../index';

export const getDependences = () => {
  return { type: types.GET_DEPENDENCES };
};

export const getDependence = msisdn => {
  return { type: types.GET_DEPENDENCE, msisdn };
};

export const createDependence = dep => {
  return { type: types.CREATE_DEPENDENCE, dep };
};

export const updateDependence = (dep, msisdn) => {
  return { type: types.UPDATE_DEPENDENCE, msisdn, dep };
};

export const deleteDependence = msisdn => {
  return { type: types.DELETE_DEPENDENCE, msisdn };
};
