import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as ContractsActions from '../../actions/contracts/contractsAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalResponse from '../layout/modals/modalResponse';
import './style.scss';
import DatePicker from '../tools/datePickerControl';
import { getAccess } from './../../utils/access';
import { ACCESS } from './access';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import ModalResendEmail from './modalResendEmail';
import { StatusCodes } from 'http-status-codes';

const columns = [
	{
		name: 'Factura',
		selector: 'invoiceId',
		sortable: true,
	},
	{
		name: 'Fecha',
		selector: 'date',
		sortable: true,
	},
	{
		name: 'Estado',
		selector: 'statusSendEmail',
		sortable: true,
	},
	{
		name: 'Correo electrónico',
		selector: 'email',
		sortable: true,
		filterDDL: true,
	},
	{
		name: 'Nombre',
		selector: 'name',
		sortable: true,
		filterDDL: true,
	},
	{
		name: 'Cotización',
		selector: 'quoteId',
		sortable: true,
		filterDDL: true,
	},
];
let deleteDep = false;
let resendInv = false;
let downloadEnable = false;
class InvoiceList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			detailLeads: [],
			commentLeads: [],
			logSynergy: '',
			fromDate: '',
			toDate: '',
			showResendEmailModal: false,
			resendEmailData: {},
		};
		this.datePickerRef = React.createRef();
	}


	showModal = () => {
		this.setState({ show: !this.state.show });
	};

	showResendEmailModal = () => {
		this.setState({ showResendEmailModal: !this.state.showResendEmailModal });
	};

	closeResendEmailModal = () => {
		this.setState({ showResendEmailModal: !this.state.showResendEmailModal });
	};

	resendEmails = (data) => {
		if (!this.props.checkAuth()) return;
		this.props.actions.resendInvoiceEmail(data);
		this.closeResendEmailModal();
		resendInv = true;
	}

	getInvoices = () => {
		let date = new Date();
		let date2 = new Date();
		let country = this.getCountry(localStorage.getItem('country'))
		date.setHours(-24);
		date2.setHours(24);

		const params = {
			from: date.toISOString(),
			to: date2.toISOString(),
			country: country,
		};
		if (!this.props.checkAuth()) return;
		this.props.actions.getInvoices({ types: params });
	};

	copyUrl = (link) => {
		var aux = document.createElement('input');
		aux.setAttribute('value', link);
		document.body.appendChild(aux);
		aux.select();
		document.execCommand('copy');
		document.body.removeChild(aux);
		//document.getElementById('spanLink' + id).innerText = 'Enlace copiado!';
		ModalResponse({
			title: 'Copiar Url',
			text: 'La URL de la factura se copió correctamente',
			res: 200,
		});
	};

	resendInvoice = (data) => {
		this.setState({
			resendEmailData: {
				email: data.email,
				invoiceId: data.invoiceId,
				invoiceUrl: data.invoiceUrl,
				name: data.name
			}
		}, () => {
			this.showResendEmailModal();
		});
	};

	componentDidMount() {
		this.getInvoices();
	}

	releadInvoice = () => {
		const invoice = document.getElementById('invoice').value;
		const email = document.getElementById('email').value;
		const status = document.getElementById('slStatus').value;
		if (
			invoice != '' ||
			email != '' ||
			status != '' ||
			(this.state.fromDate != '' && this.state.toDate != '')
		) {
			this.filtrarInvoices();
		} else {
			this.getInvoices();
		}
	};

	getCountry(country) {
		switch (country) {
			case 'Colombia':
				return 'SiigoCol';
			case 'Ecuador':
				return 'Contifico'
			case 'Perú':
				return 'SiigoPer'
			default:
				return 'SiigoCol'
		}
	}

	filtrarInvoices = () => {
		const invoice = document.getElementById('invoice').value;
		const email = document.getElementById('email').value;
		const status = document.getElementById('slStatus').value;
		let country = this.getCountry(localStorage.getItem('country'))

		const data = [
			{ key: { invoice: invoice, country }, value: invoice },
			{ key: { email: email, country }, value: email },
			{ key: { status: status, country }, value: status, },
			{ key: { from: this.state?.fromDate, country }, value: this.state?.fromDate != "" && this.state.fromDate?.toISOString() },
			{ key: { to: this.state?.toDate, country }, value: this.state?.toDate != "" && this.state.toDate?.toISOString() },
		];
		let params = {};
		if (
			invoice != '' ||
			email != '' ||
			status != '' ||
			(this.state.fromDate != '' && this.state.toDate != '')
		) {
			if (!this.props.checkAuth()) return;
			data.forEach((val) => {
				if (val.value != '')
					Object.assign(params, val.key);
			});
			this.props.actions.getInvoices({ types: params });
		} else {
			ModalResponse({
				title: 'Facturas',
				text: 'Debe ingresar al menos un filtro',
				res: 404,
			});
		}
	};

	cleanFilter = (e) => {
		e.preventDefault();
		if (this.datePickerRef?.current) {
			this.datePickerRef.current.handleCleanImput();
		}
		document.getElementById('invoice').value = '';
		document.getElementById('email').value = '';
		document.getElementById('slStatus').value = '';
		this.setState({ fromDate: undefined, toDate: undefined });
		if (!this.props.checkAuth()) return;
		this.getInvoices();
	};

	handleDate = (data) => {
		try {
			this.setState({ fromDate: data.from, toDate: data.to });
		} catch (error) {
			ModalResponse({
				title: 'Inválido',
				text: 'Selecciona fechas validas.',
				res: 404
			});
		}

	};

	onHandleDownload = (datos) => {
		let dataDownload = null;
		let itemString = '';
		if (datos.length > 0) {
			if (!this.props.checkAuth()) return;
			downloadEnable = true;

			let cpColumns = [...columns]

			let columnsReq = cpColumns.map(function (column) {
				let columnAux = [];
				columnAux.title = column.name;
				columnAux.id = column.selector;
				return JSON.stringify(Object.assign({}, columnAux));
			}).join();

			let cpDatos = JSON.parse(JSON.stringify(datos));
			let datosString = cpDatos.map(function (dataItem) {

				let cpData = dataItem;
				cpData.statusSendEmail == "true" ? cpData.statusSendEmail = "Enviado" : cpData.statusSendEmail = "No Enviado";

				return JSON.stringify(Object.assign({}, cpData));
			}).join();

			let reqExcel = {
				"columns": JSON.parse(`[${columnsReq}]`),
				"data": JSON.parse(`[${datosString}]`),
				"typeFile": "csv"
			};

			this.props.actions.sendExcelObject(reqExcel);

		} else {
			ModalResponse({
				title: 'Descargar excel',
				text: 'No hay datos para descargar',
				res: 404,
			});
		}
	};


	downloadExcel = (data) => {
		var blob = new Blob([atob(data)], { type: 'text/plain; charset=utf-8' });
		if (window.navigator.msSaveBlob) {
			window.navigator.msSaveOrOpenBlob(blob, 'exportData' + new Date().toDateString() + '.csv');
		}
		else {
			var a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(blob, { type: "text/plain; charset=utf-8" });
			a.download = "exportData" + new Date().toDateString() + ".csv";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}

	render() {
		let {
			error,
			isLoading,
			invoices,
			dataResendInvoice,
			isLoadingResendEmail,
			excelData,
			t
		} = this.props;
		const btnActions = {
			btnCopyUrl: getAccess(this.props.parentPath, ACCESS.Copy_Url) ? this.copyUrl : null,
			btnResendInvoice: getAccess(this.props.parentPath, ACCESS.Resend) ? this.resendInvoice : null,
		};
		let datos = [];
		let status = 0;
		let text = '';
		let data = {};

		if (invoices) {
			if (invoices.data != null) {
				datos = invoices.data;
			}
		}

		if (excelData) {
			if (excelData.status === StatusCodes.OK) {
				if (downloadEnable) {
					this.downloadExcel(excelData.data);
					downloadEnable = false;
				}
			}
		}

		if (dataResendInvoice && resendInv && !isLoadingResendEmail) {
			if (dataResendInvoice.code == 10) {
				ModalResponse({
					title: 'Reenviar Factura',
					text: 'La factura se reenvió exitosamente !!!',
					res: 200,
				});
				this.releadInvoice();
			} else {
				ModalResponse({
					title: 'Reenviar Factura',
					text:
						'No fue posible reenviar la factura, por favor comuníquese con el administrador',
					res: 404,
				});
			}
			resendInv = false;
		}

		return (
			<div>
				<div>
					<h3>Facturas</h3>
					<form>
						<div className="row divFilter">
							<div className="column4">
								<DatePicker id="datePickerControl"
									ref={this.datePickerRef}
									handleDate={this.handleDate}
								/>
							</div>
							<div className="column4">
								<select className="slFilter" id="slStatus" name="slStatus">
									<option value="">Seleccione el estado</option>
									<option value="true">Enviada</option>
									<option value="false">No enviada</option>
								</select>
							</div>
							<div className="column4"></div>
						</div>
						<div className="row divFilter">
							<div className="column4">
								<input
									className="inputFilter"
									id="email"
									name="email"
									type="text"
									placeholder="Correo electrónico"
								/>
							</div>
							<div className="column4">
								<input
									className="inputFilter"
									id="invoice"
									name="invoice"
									type="text"
									placeholder="Factura"
								/>
							</div>
							<div className="column4">
								<div className="row">
									<div className="column2">
										<button
											className="green-fill btnBuscar"
											type="button"
											onClick={this.filtrarInvoices}
										>
											<div className="row">
												<div className="column2">
													<span className="siicon icon-search searchMarket"></span>
												</div>
												<div className="column2">
													<span>Buscar</span>
												</div>
											</div>
										</button>
									</div>
									<div className="column2">
										<button
											id="btnFilter"
											onClick={this.cleanFilter}
											className="orange-fill"
											type="button"
										>
											Limpiar
										</button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
				{isLoading ? (
					<Loader />
				) : (
					<div>
						<div className="divTable">
							<TableReport
								columns={columns} // Data columns to table
								tabs={false} //Enable tabs
								tabsData={[]} //Load data to tabs check option on tabsData const
								headerCounterTab={true} //Counter tab option
								btnActions={btnActions}
								actionButtons={true} //Enable buttons (edit, delete, copy, open)
								pageSize={10} // Enable page size
								pagination={true} //Enable pagination (true, false)
								paginationNav={'arrows'} //Nav options (arrow, dot)
								paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
								maxSizePagination={6} //Size pagination
								defaultPaginationNumber={1} //Current pagination when start app
								paginationGrouped={10} //Grouped by page
								detailRow={false} //Open column detail with left arrow
								data={datos} //Data for table
							/>
							<FontAwesomeIcon className='icon-question' icon={faQuestion} />
							<p className='text-info-data'>{t("invoice.report.max-records-message")}</p>
							<br />
							{
								getAccess(this.props.parentPath, ACCESS.Download_Excel) ? (
									<button className="button green-fill btnDownload" onClick={e => this.onHandleDownload(datos)}>
										{' '}
										Descargar excel
									</button>
								) : null
							}

							<ModalResendEmail showModal={this.state.showResendEmailModal} handleCloseModal={this.closeResendEmailModal} emailDataChange={this.state.resendEmailData} resendEmails={this.resendEmails} t={t} />
						</div>
					</div>
				)}
			</div>
		);
	}
}

InvoiceList.propTypes = {
	actions: PropTypes.object.isRequired,
	invoices: PropTypes.object,
	error: PropTypes.object,
	excelData: PropTypes.object
};

const mapStateToProps = (state) => ({
	invoices: state.contracts.invoices,
	error: state.contracts.err,
	isLoading: state.contracts.isLoading,
	isLoadingResendEmail: state.contracts.isLoadingResendEmail,
	dataResendInvoice: state.contracts.dataResendInvoice,
	excelData: state.synergy.excelData
});

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(ContractsActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InvoiceList));


