import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/indexHubspot';
import APIGeneral from '../services/api/indexGeneral';
import config from '../config.js';

export function* getLeads(action) {
	const api = API.create();
	try {
		const { params } = action;
		let leads = yield call(api.leads.getLeads, {
			types: JSON.stringify(params.types)
		});
		yield put({ type: types.LEADS_RECEIVED, data: leads });
	} catch (err) {
		yield put({ type: types.LEADS_REQUEST_FAILED, err });
	}
}


export function* sendExcelObject(action) {
	const api = APIGeneral.create(config.urlExcelApi);
	try {
		const { params } = action;
		let auth = yield call(api.accountant.authExcel, { username: 'admin', password: 'admin' });
		api.setHeader('authorization', auth.data.token);
		let excel = yield call(api.accountant.sendExcelObject, params);
		yield put({ type: types.SEND_EXCEL_OBJECT_RECEIVED, data: excel });
	} catch (err) {
		yield put({ type: types.SEND_EXCEL_OBJECT_FAILED, err });
	}
}