import React from 'react';
import Swal from 'sweetalert2';

const ModalDelete = ({ actionDelete, id, dataText= {}}) => {
  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: 'button red',
      cancelButton: 'button orange'
    },
    buttonsStyling: false
  });

  swalWithBootstrapButtons
    .fire({
      title: dataText.title ? dataText.title : '¿Estás seguro?',
      text: dataText.text ? dataText.text : '¡No podrás revertir esto!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: dataText ? "Sí" : 'Sí, eliminar!',
      cancelButtonText: dataText ? "No" : 'No, cancelar!',
      reverseButtons: true
    })
    .then(result => {
      if (result.value) {
        actionDelete(id);
      } else if (Object.keys(dataText).length === 0 && result.dismiss === Swal.DismissReason.cancel) {
        
        return swalWithBootstrapButtons.fire(
          'Cancelado',
          'Tu registro esta seguro :)',
          'error'
        );
      }
    });
    return <div></div>;
};

export default ModalDelete;
