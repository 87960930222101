import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as ComponentActions from '../../actions/components/componentActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalDelete from '../layout/modals/modalDelete';
import ModalResponse from '../layout/modals/modalResponse';
import svgs from '../layout/icons';
import { StatusCodes } from 'http-status-codes';

const columns = [
  {
    name: 'Nombre del Componente',
    selector: 'name',
    sortable: true
  },
  {
    name: 'Descripcion',
    selector: 'description',
    sortable: true,
    filterDDL: true
  }
];
let deleteDep = false;
class ComponentList extends Component {
  componentDidMount() {
    this.props.actions.getComponents();
  }

  checkDelete = id => {
    ModalDelete({
      actionDelete: this.props.actions.deleteComponent,
      id: id
    });
    deleteDep = true;
  };

  render() {
    let {
      error,
      isLoading,
      isLoadingDel,
      componentList,
      resDelete
    } = this.props;
    const btnActions = {
      btnEdit: '/component/edit/',
      btnDelete: this.checkDelete
    };
    let datos = [];
    let status = 0;
    let text = '';
    if (componentList) {
      if (componentList.data.error === 'Forbidden') {
        return <div>No tiene permiso para este componente</div>;
      }
      datos = componentList.data;
    }
    if (resDelete && deleteDep && !isLoadingDel) {
      if (resDelete.status === StatusCodes.OK) {
        text = 'El componente ha sido eliminado';
      } else if (resDelete.status === StatusCodes.NOT_FOUND) {
        text =
          'No fue posible eliminar el componente, es posible que sea padre de otro componente o este asignado a un grupo';
      }
      deleteDep = false;
      status = resDelete.status;
    }
    return (
      <div>
        {!isLoadingDel && status > 0 && text !== '' ? (
          <ModalResponse title={'Eliminar!'} text={text} res={status} />
        ) : null}
        {error && <h4>Error: {error.response.data}</h4>}
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <h3>Componentes</h3>
            <a href="/component/create" className="a-button green-fill">
              <img className="icon-img icon-mas" src={svgs['mas']} alt="" />
              Crear
            </a>
            <TableReport
              columns={columns} // Data columns to table
              tabs={false} //Enable tabs
              tabsData={[]} //Load data to tabs check option on tabsData const
              headerCounterTab={true} //Counter tab option
              btnActions={btnActions}
              actionButtons={true} //Enable buttons (edit, delete, copy, open)
              pageSize={10} // Enable page size
              pagination={true} //Enable pagination (true, false)
              paginationNav={'arrows'} //Nav options (arrow, dot)
              paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
              maxSizePagination={6} //Size pagination
              defaultPaginationNumber={1} //Current pagination when start app
              paginationGrouped={10} //Grouped by page
              detailRow={false} //Open column detail with left arrow
              data={datos} //Data for table
            />
          </div>
        )}
      </div>
    );
  }
}

ComponentList.propTypes = {
  actions: PropTypes.object.isRequired,
  componentList: PropTypes.object,
  error: PropTypes.object
};

const mapStateToProps = state => ({
  componentList: state.components.components,
  resDelete: state.components.component,
  error: state.components.err,
  isLoading: state.components.isLoading,
  isLoadingDel: state.components.isLoadingDel
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ComponentActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ComponentList);
