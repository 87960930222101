import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import { SiigoInputAtom } from '@siigo/siigo-input-atom-react';
import { SiigoTextAreaAtom } from '@siigo/siigo-text-area-atom-react';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { ChangeStateBooking, GetSiigerByEmail, SendEmail } from '../helpers/SiigerHelper';
import Smenu from '../slayout/smenu/smenu';
import SiigerAlertMessages from '../sshared/SiigerMessages/siigerAlertMessages';

class SviajesAprobacion extends Component {

    constructor(props) {
	    super(props);

        let _bookingIndex = this.props.location.state.bookingIndex;
        let _booking = this.props.location.state.booking[_bookingIndex];
        let _originSiiger = this.props.location.state;

        this.state = {
            goTomensajes: false,
            data: this.props.location.state,
            booking: _booking,
            bookingIndex: _bookingIndex,
            guest: undefined,
            guestName: "",
            originSiiger: _originSiiger,
            msgHuesped: ""
        }
    }
    
    componentDidMount() {
        //Voy a obtener la info completa del huesped, para que la vea el host.
        GetSiigerByEmail(this.state.booking.guestEmail).then((response) => {
            this.setState(
                {
                    ...this.state, 
                    guest : response.data.document,
                    guestName : response.data.document.name
                })
        },[])
    }

    AprobarHuesped(b)
    {
        let statusAprobado = 2;

        let _place_id = this.state.booking.placeId;
        let _booking_id = this.state.booking.id;
        let _booking_index = this.props.bookingIndex;

        //Voy a cambiar el estado a aprobado
        ChangeStateBooking(this.state.originSiiger.contact.email, _place_id, _booking_id, _booking_index,  statusAprobado).then((response)  => {          
            if (response.data){
                let resp = response.data;
                //Si pude actualizar el estado a Aprobado
                if (resp.modifiedCount >= 1)
                {
                    //me voy a la vista mensaje pero , envio el email. 
                    this.setState({
                        ...this.state,
                        goTomensajes: true
                    }, () => {
                        //data para el email
                        let data = {
                            telefono: this.state.originSiiger.contact.phone,
                            direccion: this.state.originSiiger.contact.direccion,
                            msg: this.state.msgHuesped,
                            guest: this.state.guest,
                            host: this.state.originSiiger,
                            subject: 'Alojamiento aprobado',
                            emailFrom: this.state.originSiiger.contact.email,
                            emailTo: this.state.guest.contact.email,
                            booking: this.state.booking,
                            status: statusAprobado
                        }      
                        
                    })        
                }
            }
          }, []);    
    }    

    //Me guardo la direccion, luego la envio por email pero no la guardo en la base
    onChangeDireccion(e){ 
        this.setState({ 
            ...this.state, 
            originSiiger: { ...this.state.originSiiger,
                contact: { ...this.state.originSiiger.contact,
                    direccion: e.target.value
                }
            }
        });                                  
    }

    //Me guardo el telefono, luego la envio por email pero no la guardo en la base
    onChangeTelefono(e){
        this.setState({ 
            ...this.state, 
            originSiiger: { ...this.state.originSiiger,
                contact: { ...this.state.originSiiger.contact,
                    phone: e.target.value
                }
            }
        });         
    }    

    //Me guardo el mensaje, luego la envio por email pero no la guardo en la base
    onChangeMsgHuesped(e) {
        this.setState({ 
                ...this.state, 
                msgHuesped: e.target.value
            });                  
    }

    render() {

        if (this.state.goTomensajes) {
            //si termine por aqui, me voy pa atras
            return (
                <Redirect to={{ pathname: "/siigers/mensajes" }} />
            )
        }

        return(
                <>
                    <Smenu />  
                    <div className='container siiger'>
                        <div className='scard'>  
                            <div className='row'>
                                <h4>Aprobación de huesped { this.state.originSiiger.host.name }</h4>
                            </div>                            

                            <div className='row'>
                                <div className='col-1'>

                                    {{
                                        1 : <i className="fa-solid fa-hotel fa-2xl alojamiento-icon"></i>,
                                        2 : <i className="fa-solid fa-house-chimney-user fa-2xl alojamiento-icon"></i>                                                 
                                    }[this.state.originSiiger.host.type]}
                                </div>

                                <div className='col'>                                
                                    <div className='alojamiento-descripcion'>
                                        {{
                                            1: <label>Habitacion individual</label>,
                                            2: <label>Habitacion compartida</label>,                                                                                                      
                                        }[this.state.originSiiger.host.place[0].placetype]}                                  
                                                                                
                                        <label>{this.state.originSiiger.host.place[0].peoplecount} camas</label>
                                        <label><b>{this.state.originSiiger.host.name}</b></label>
                                    </div>
                                </div>  
                            </div>

                            <hr className='siiger'></hr>

                            <div className='row'>
                                <div className='col-1'>
                                    <i className="fa-solid fa-person-circle-question fa-2xl alojamiento-icon"></i>
                                </div>

                                <div className='col'>                                
                                    <div className='alojamiento-descripcion'>
                                        <label><b>Huesped</b></label>
                                        <label>{ this.state.guestName }</label>
                                        <label>{ this.state.booking.guestEmail }</label>
                                        <label>{ this.state.booking.dateFrom } - { this.state.booking.dateUntil }</label>
                                    </div>
                                </div>  
                            </div>                                           

                            <SiigerAlertMessages title="Aprobación" msg="Se le enviará un email con tus datos de dirección, teléfono y correo al huésped."/>

                            <div className='row'>
                                <SiigoInputAtom
                                    align="left"
                                    errorMessage="Por favor completa tu dirección."
                                    id="inputDireccion"
                                    label="Dirección de tu morada"
                                    maxLength={400}
                                    minLength={0}
                                    name="inputDireccion"
                                    requiredMessage="Este campo es requerido."
                                    type="text"
                                    onchange={ (e) => {this.onChangeDireccion(e)} }
                                    value={ this.state.originSiiger.contact.direccion }
                                />
                            </div>          

                            <div className='row'>
                                <SiigoInputAtom
                                    align="left"
                                    errorMessage="Por favor completa tu teléfono."
                                    id="inputTelefono"
                                    label="Teléfono"
                                    maxLength={400}
                                    minLength={0}
                                    name="inputTelefono"
                                    requiredMessage="Este campo es requerido."
                                    type="text"
                                    onchange={ (e) => {this.onChangeTelefono(e)} }
                                    value={ this.state.originSiiger.contact.phone }
                                />
                            </div>           
                        
                            <div>
                                <SiigoTextAreaAtom
                                        cols="45"
                                        errorMessage="Por favor envíe un mensaje al huésped."
                                        label="Escribe tu mensaje para el huésped aquí..."                               
                                        maxLength={400}
                                        minLength={0}
                                        id="textMensajeHuesped"
                                        name="textMensajeHuesped"
                                        requiredMessage="Por favor deje un mensaje."
                                        showRenderCount="false"
                                        value= { this.state.msgHuesped }
                                        onchange={ (e) => { this.onChangeMsgHuesped(e) } }
                                >
                                </SiigoTextAreaAtom>
                            </div>



                            <div className='row'>
                                <SiigoButtonAtom
                                    id='btnAprobarHuesped'
                                    color="primary-green"
                                    size="l"
                                    type="button"
                                    text="Aprobar huesped"
                                    icon="fas"     
                                    onClick = {() => this.AprobarHuesped(this.props)}                               
                                /> 
                            </div>                        
                        </div>
                    </div>
                </>
        )
    }
}

export default SviajesAprobacion;