import axios from 'axios';
import MailActions from './services/MailActions';

const create = () => {
  const api = axios.create({
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    },
    timeout: 30000
  });

  const mailActions = new MailActions(api);

  return {
    setHeader: (key, value) => { api.defaults.headers.common[key] = value; },
    removeHeader: (key) => { delete api.defaults.headers.common[key]; },
    mailActions
  };
};

export default {
  create
};
