import React, { Component } from 'react';
import BlackListEmailWrapper from '../../components/blackListEmail/wrapper';

class BlackListEmailContainer extends Component {
  render() {
    return (
      <div>
        <BlackListEmailWrapper parentPath={this.props.path}/>
      </div>
    );
  }
}

export default BlackListEmailContainer;
