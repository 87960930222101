import axios from 'axios';
import Market from './services/market';
import PayrollPackage from './services/PayrollPackage';

const create = () => {
  const api = axios.create({
    headers: {
      'Cache-Control': 'no-cache',
      'content-type': 'application/json'
    },
    timeout: 240000
  });

  const market = new Market(api);
  const payrollPackage = new PayrollPackage(api);

  return {
    setHeader: (key, value) => { api.defaults.headers.common[key] = value; },
    removeHeader: (key) => { delete api.defaults.headers.common[key]; },
    market,
    payrollPackage
  };
};

export default {
  create
};
