import React, { Component } from "react";
import Layout from "../layout";
import { checkCookie } from "../../utils/cookies";
import { Redirect } from "react-router-dom";
import FormUsers from "../../components/market/formUsers";

class FormUsersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const id = this.props.match.params.id;
    const action = this.props.match;
    return (
      <div>
        {checkCookie() !== null ? (
          <div>
            <Layout route={"/market"} />
            <section className={"container"}>
              <div className="section">
                <FormUsers parentPath={"/market"} id={id} action={action} />
              </div>
            </section>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: this.props.location },
            }}
          />
        )}
      </div>
    );
  }
}

export default FormUsersPage;
