import React, { useContext, useEffect, useState } from 'react';
import Loader from '../tools/loader';
import Modal from './modalTarea/modalPlan';
import ModalResponse from '../layout/modals/modalResponse';
import { COUNTRYS } from './utils';
import ModalChangePlan from '../layout/modals/modalChangePlan';
import ModalAddPackage from './modalPackage/ModalAddPackage';
import ModalPackageDetail from './modalPackage/ModalPackageDetail';
import { AllowedInvoices, COMPLEMENTS_TYPES_ENUM, Complementos, LICENCE_TYPE, invoicesPlan, planSubType, planes, planesConversionMx } from './plandata';
import CountryContext from '../../context/countries-context';
import { Trans, useTranslation } from 'react-i18next';
import * as MarketActions from '../../actions/market/marketAction';
import { ACCESS } from './access';
import { getAccess } from '../../utils/access';
import { useHistory, useLocation } from 'react-router-dom';
import { PAYROLL_COMPANY_STATUS, PAYROLL_UPDATE_TYPE } from './state-company';
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import { useDispatch, useSelector } from 'react-redux';
import Complements from './complemets';
import FormCompanyDetail from './formCompanyDetail';
import mailProvider from '../../providers/mailProvider/mailProvider';
import config from '../../config';
import CardDocuments from './cardDocuments';
import { ConvertDateFormat } from '../../utils/revertFormatDate';
import moment from 'moment';
import { uuidV4Generator } from "../../utils/randomizer";
import { COUNTRYS as COUNTRIES } from "./utils/index";

import { getCookie } from "../../utils/cookies";
import { convert2AlphaCodeTo3AlphaCodeCountry } from "../../sagas/marketSaga"
import ModalCompanyChanges from './modalTarea/modalCompanyChanges';
import Swal from 'sweetalert2';
import { StatusCodes } from 'http-status-codes';
import { useAuth } from '../../context/auth-context';


function companyMap(currentState) {
    let company = JSON.parse(localStorage.getItem('Company'));
    if (!company) { return }

    if (company.PrepaidPlanType == null) {
        company.PrepaidPlanType = parseInt(company.PrepaidPlanType)
        company.PrepaidPlanType = 0
    }
    
    if (company?.PayrollUsers?.includes("PayrollUsers")) {
        company.PayrollUsers = company?.PayrollUsers?.split("|PayrollUsers:")[1];
    } else if (company?.XMLServicePlan?.includes("PayrollUsers")) {
        company.PayrollUsers = company?.XMLServicePlan?.split("|PayrollUsers:")[1];
    } else {
        company.PayrollUsers = "";
    }

    let inactivationDate = company?.InactivationDate;
    if(inactivationDate != null && inactivationDate != undefined){
        if(inactivationDate.includes("-") && inactivationDate.includes("T")){
            inactivationDate = moment.utc(company?.InactivationDate).format('DD/MM/YYYY');
        }else{
            inactivationDate = null;
        }
    }

    return {
        plan: Object.values(LICENCE_TYPE).find((license) => license.value == company.PlanType),
        name: company.CompanyFullName,
        nit: company.Nit,
        companyKey: company.CloudTenantCompanyKey,
        serial: company.Serial,
        state: company.State,
        active: (PAYROLL_COMPANY_STATUS?.ACTIVE == company.State || PAYROLL_COMPANY_STATUS?.READONLY == company.State),
        actualState: currentState == null ? company.State : currentState,
        PrepaidPlanType: company.PrepaidPlanType,
        users: company.AllowedUsers,
        pos: company.POSTerminal,
        spaceAvailable: company.SpaceAvailable,
        facturas: company.AllowedInvoices,
        date: company.CreateByDate,
        userFullName: company.UserFullName,
        userEmail: company.UserEmail,
        marketVersion: company.MenuVersionDB,
        XMLServicePlan: company?.XMLServicePlan,
        payrollUsers: company.PayrollUsers,
        quoteNumber: company.QuoteNumber,
        CloudMultiTenantID: Number(company?.CloudMultiTenantID),
        CloudTenantID: company?.CloudTenantID,
        welcomeEmail: company?.PasswordMailSent,
        workflowInstanceCode: company?.WorkflowInstanceCode,
        cloudTenantConnectionUser: company?.CloudTenantConnectionUser,
        inactivationDate: inactivationDate,
    }
}

function CompanyDetail(props) {
    const countryContext = useContext(CountryContext);
    const history = useHistory();
    const location = useLocation();
    const { state } = useLocation();
    const { t } = useTranslation();
    const actionsMarket = useDispatch();
    const marketState = useSelector((_state) => _state.market);
    const [isResendMail, setIsResendMail] = useState();
    const [pack, setPack] = useState({ add: false, delete: false });
    const [modalPlan, setModalplan] = useState({
        show: false,
        plan: ""
    });
    const [companyPrincipal, setCompanyPrincipal] = useState();
    const [complementsNE, setComplementsNE] = useState();
    const [packages, setPackages] = useState({
        packages: [],
        packageDetail: [],
        showModalPackageDetail: false,
        showModalPackage: false,
        acronym: "FE"
    });
    const [modifiedData, setModifiedData] = useState({});
    const [hasCompanyChanges, setHasCompanyChanges] = useState(false);
    const [modalCompChanges, setModalCompChanges] = useState({ show: false })
    const { checkAuth } = useAuth();

    useEffect(() => {
        if (countryContext?.isCountryChanged()) {
            countryContext.setCountryChanged(false);
            window.location.href = "/market";
        }
    }, [countryContext]);

    useEffect(() => {
        if (companyPrincipal !== undefined && companyPrincipal.companyKey !== undefined) {
            updateInvoices();
            localStorage.setItem('interCompany', true);
            let tenantId = companyPrincipal?.cloudTenantConnectionUser.split("SIIGOCloudTenant_")[1];
            if (!props.complement?.length && countryContext.country == COUNTRIES.COLOMBIA.CODE) {
                actionsMarket(MarketActions.getAddOnsNE(tenantId));
            }
            if (!props.complement?.length && countryContext.country == COUNTRIES.COLOMBIA.CODE) {
                actionsMarket(MarketActions.verifyNitEdition({
                    data: {
                        tenantId: companyPrincipal?.cloudTenantConnectionUser?.split("SIIGOCloudTenant_")[1],
                        cloudTenantCompanyKey: companyPrincipal.companyKey
                    },
                    country: countryContext.country
                }));
            }
        }
    }, [companyPrincipal]);

    useEffect(() => {
        marketState.packages?.length &&
            setPackages({ ...packages, packages: marketState.packages });
    }, [marketState.packages]);

    useEffect(() => {
        marketState.packageDetail?.length &&
            setPackages({ ...packages, packageDetail: marketState.packageDetail });
    }, [marketState.packageDetail]);

    useEffect(() => {
        if (state?.company) {
            localStorage.setItem('Company', JSON.stringify(state.company));
            setCompanyPrincipal(companyMap());
        }
    }, [state?.company]);

    useEffect(() => {
        if (marketState.modify >= 400) {
            ModalResponse({
                title: t('market.companyDetail.modal.updatedCompany.error.title'),
                text: t('market.companyDetail.modal.updatedCompany.error.text'),
                res: 500,
            });
        }
        if (marketState.modify === StatusCodes.OK) {
            ModalResponse({
                title: t('market.companyDetail.modal.updatedCompany.successful.title'),
                text: t('market.companyDetail.modal.updatedCompany.successful.text'),
                res: 200,
            });

            const normalizeData = normalizeKeys(modifiedData);

            const mergeChangedProperties = Object.assign({}, state.company, normalizeData);
            localStorage.setItem("Company", JSON.stringify(mergeChangedProperties));
            setHasCompanyChanges(false);
        }
        setHasCompanyChanges(false);
        delete marketState?.modify;
    }, [marketState?.modify]);

    useEffect(() => {
        if (marketState?.plan) {
            ModalResponse({
                title: t('market.companyDetail.modal.plan.title'),
                text: t('market.companyDetail.modal.plan.successful'),
                res: 200,
            });
            delete marketState?.plan;
        } else if (marketState?.plan === false) {//Se favalida directamente contra false en caso de salir algo mal en la petición
            ModalResponse({
                title: t('market.companyDetail.modal.plan.title'),
                text: t('market.companyDetail.modal.plan.error'),
                res: 500,
            });
            delete marketState?.plan;
        }
    }, [marketState?.plan]);

    useEffect(() => {
        if ((marketState.pack || marketState.packPayroll) && pack.add) {
            if (typeof marketState.pack == 'object' || typeof marketState.packPayroll == 'object') {
                ModalResponse({
                    title: t("market.companyDetail.modal.addPackage.modalResponse.title"),
                    text: t("market.companyDetail.modal.addPackage.modalResponse.successful"),
                    res: 200,
                });
            } else {
                ModalResponse({
                    title: t("market.companyDetail.modal.addPackage.modalResponse.title"),
                    text: t("market.companyDetail.modal.addPackage.modalResponse.error"),
                    res: 404,
                });
            }
            updateInvoices();
            setPack({ ...pack, add: false });
            setPackages({ ...packages, showModalPackage: false });
        }
    }, [marketState]);

    useEffect(() => {
        if (pack.delete && !marketState.isLoadingPackDelete) {
            if (marketState.deleteBalance) {
                ModalResponse({
                    title: t("market.companyDetail.modal.deletePackage.modalResponse.title"),
                    text: t("market.companyDetail.modal.deletePackage.modalResponse.successful"),
                    res: 200,
                });
            } else {
                ModalResponse({
                    title: t("market.companyDetail.modal.deletePackage.modalResponse.title"),
                    text: t("market.companyDetail.modal.deletePackage.modalResponse.error"),
                    res: 500,
                });
            }
            updateInvoices();
            setPack({ ...pack, delete: false });
            setPackages({ ...packages, showModalPackageDetail: false });
        }
    }, [marketState]);

    useEffect(() => {
        if (marketState.complement && Array.isArray(marketState.complement) && marketState.complement.some(_complement => _complement.type === COMPLEMENTS_TYPES_ENUM.NOMINA)) {
            setComplementsNE(marketState.complement)
        }
    }, [marketState.complement]);

    const updateInvoices = () => {
        actionsMarket(MarketActions.getPackage());
        actionsMarket(MarketActions.getBalancePack({
            companyKey: companyPrincipal?.companyKey,
        }));
        actionsMarket(MarketActions.getPackageDetail({
            CompanyKey: companyPrincipal?.companyKey,
        }));
        if (countryContext.country != COUNTRIES.COLOMBIA.CODE) return;
        actionsMarket(MarketActions.getPayrollPackBalance(companyPrincipal?.companyKey));
        actionsMarket(MarketActions.getPayrollDetail(companyPrincipal?.companyKey));
    }

    const getPlanType = () => {
        const plan =
            companyPrincipal?.plan?.value == LICENCE_TYPE.SOLO_FACTURACION.value
                ? invoicesPlan.hasOwnProperty(companyPrincipal?.facturas) ?
                    invoicesPlan[companyPrincipal?.facturas] : '7'
                : companyPrincipal?.plan?.value;


        if (countryContext.country == COUNTRYS.MEXICO.CODE)
            return planesConversionMx.hasOwnProperty(plan) ? planesConversionMx[plan] : [];

        return planes.hasOwnProperty(plan) ? planes[plan] : [];
    };

    const pascalToCamel = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    const normalizeKeys = (obj) => {
        const normalizedObj = {};
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                normalizedObj[pascalToCamel(key)] = obj[key];
            }
        }
        return normalizedObj;
    };

    const setPrePlanType = (planActual, planChange) => {
        const planType = {
            'null': 3,
            'Mensual': 2,
            'Anual': 3
        };

        const planActualValue = parseInt(planActual);
        const planChangeValue = parseInt(planChange);

        const plansForNull = [
            LICENCE_TYPE.CONTADOR.value,
            LICENCE_TYPE.SIIGO_NUBE.value,
            LICENCE_TYPE.SIIGO_NUBE_INDEPENDIENTE.value,
            LICENCE_TYPE.SIIGO_NUBE_EMPRENDEDOR.value,
            LICENCE_TYPE.SIIGO_NUBE_PREMIUM.value,
            LICENCE_TYPE.SIIGO_FE_PRO.value,
            LICENCE_TYPE.SIIGO_NUBE_FACTURACION.value,
            LICENCE_TYPE.SIIGO_NUBE_FACTURACION_DUO.value,
            LICENCE_TYPE.SIIGO_NUBE_GESTION_INICIO.value,
            LICENCE_TYPE.SIIGO_NUBE_GESTION_INICIO_AVANZADO.value,
            LICENCE_TYPE.SIIGO_NUBE_GESTION_PREMIUM.value
        ];

        const plansForMensualOrAnual = [
            LICENCE_TYPE.SIIGO_FE_PRO.value,
            LICENCE_TYPE.SOLO_FACTURACION.value,
            LICENCE_TYPE.SOLO_NOMINA.value,
            LICENCE_TYPE.SIIGO_POS.value
        ];

        if (plansForNull.includes(planActualValue)) {
            return planType.null;
        }

        if (plansForMensualOrAnual.includes(planActualValue)) {
            return planChangeValue === LICENCE_TYPE.FE_GRATIS.value ? planType.Mensual : planType.Anual;
        }

        return planSubType[companyPrincipal?.PrepaidPlanType];
    }

    const actionChange = (companyUpgrade) => {
        const params = {
            companyUpgrade,
            meta: {
                country: countryContext.country,
                requestId: uuidV4Generator(),
            }
        }
        actionsMarket(MarketActions.setPlan(params));
        setModalplan({ show: false });
        let company = JSON.parse(localStorage.getItem('Company'));
        company.PrepaidPlanType = params?.companyUpgrade?.prepaidPlanType;
        localStorage.setItem('Company', JSON.stringify(company));
        setCompanyPrincipal(companyMap());
    };

    const changePlan = (e, planActual, planChange, country) => {
        e.preventDefault();
        if(!checkAuth()) return;
        const changeTo = planChange
            ? parseInt(planChange)
            : String(planActual);

        if (companyPrincipal?.marketVersion?.substring(0, 2) == '05') {
            ModalResponse({
                title: t("market.companyDetail.modal.plan.title"),
                text: t("market.companyDetail.modal.plan.errorVersionFive"),
                res: 404,
            });
        } else if (planActual == '14' || planActual == '17') {
            ModalResponse({
                title: t("market.companyDetail.modal.plan.title"),
                text: t("market.companyDetail.modal.plan.errorBestPlan"),
                res: 202,
            });
        } else if (planActual == '' || planChange == '') {
            setModalplan({ show: true });
        } else {
            const planActualName = Object.keys(LICENCE_TYPE).map(function (licence) {
                return LICENCE_TYPE[licence]?.value === planActual ? licence : "";
            }).join("");
            const changeToName = Object.keys(LICENCE_TYPE).map(function (licence) {
                return LICENCE_TYPE[licence]?.value === changeTo ? licence : "";
            }).join("");
            const data = {
                actual: LICENCE_TYPE[planActualName].name,
                changeTo: LICENCE_TYPE[changeToName].name,
            };

            const preplanType = setPrePlanType(planActual, planChange);

            const params = {
                companyKey: companyPrincipal?.companyKey,
                license: changeTo,
                prepaidPlanType: preplanType,
            };
            localStorage.setItem('planActual', changeTo);
            localStorage.setItem(
                'AllowedInvoices',
                AllowedInvoices.hasOwnProperty(changeTo)
                    ? AllowedInvoices[changeTo]
                    : ''
            );

            ModalChangePlan({
                actionChange,
                data,
                params,
            });
        }
    };

    const deletePackage = (e, deleteData) => {
        e.preventDefault();
        if(!checkAuth()) return;
        actionsMarket(MarketActions.deleteBalance(deleteData));
        setPack({ ...pack, delete: true });
    };

    const deletePackageNE = (e, deleteData) => {
        e.preventDefault();
        if(!checkAuth()) return;
        let deleteDataNE = Object.keys(deleteData).reduce((acc, key) => {
            acc[key[0].toLowerCase() + key.slice(1)] = deleteData[key];
            return acc;
        }, {});
        actionsMarket(MarketActions.deletePayrollPackage(deleteDataNE));
        setPack({ ...pack, delete: true });
    }

    const addPackage = (e) => {
        if (e.target.package.value != '-9' && e.target.observations.value != '') {
            if(!checkAuth()) return;
            let data;
            let _pack = packages.packages.filter((element) => element._id == e.target.package.value)[0];
            if (packages.acronym === 'FE') {
                data = {
                    CompanyKey: companyPrincipal?.companyKey,
                    PackageID: _pack.Code,
                    Value: _pack.Price,
                    PurchaseDate: new Date().toISOString(),
                    PaymentType: 2,
                    DocumentsQuantity: _pack.Quantity,
                    ReferenceCode: _pack.Code,
                    State: 1,
                    Observations: e.target.observations.value,
                    PurchaseType: _pack.PackageType,
                };
                actionsMarket(MarketActions.addPackage(data));
            } else {
                data = {
                    companyKey: companyPrincipal?.companyKey,
                    packageID: _pack._id,
                    purchaseDate: new Date().toISOString(),
                    value: "1000",
                    paymentType: 1,
                    documentsQuantity: _pack.Quantity,
                    referenceCode: "1111",
                    state: 1,
                    observations: e.target.observations.value,
                    purcharseType: 0
                }
                actionsMarket(MarketActions.addPayrollPackage(data));
            }
            setPack({ ...pack, add: true });;
        } else {
            if (e.target.observations.value == '')
                document.getElementById('observations').style.border = '1px solid red'
            ModalResponse({
                title: t("market.companyDetail.modal.addPackage.modalResponse.title"),
                text: t("market.companyDetail.modal.addPackage.modalResponse.text"),
                res: 404,
            });
        }
    };

    const resendWelcomeMail = () => {
        if (!isResendMail) {
            if(!checkAuth()) return;
            setIsResendMail(true);

            let userEmail = companyPrincipal?.userEmail?.trim();
            let userFullName = companyPrincipal?.userFullName?.trim();
            let tenantID = companyPrincipal?.cloudTenantConnectionUser?.split("SIIGOCloudTenant_")[1];
            let bodyResendMail = {
                userEmail,
                sendWelcomeMail: true,
                userFullName,
                tenantID
            };
            const params = {
                bodyResendMail,
                meta: {
                    country: countryContext.country,
                    requestId: uuidV4Generator(),
                }
            }
            const options = {
                method: 'post',
                url: `${config.url}bridge?companyKey=${companyPrincipal?.companyKey}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getCookie('token')}`,
                    'backoffice-service-country': convert2AlphaCodeTo3AlphaCodeCountry(params.meta.country),
                    'backoffice-service-name': 'resend-email',
                    'backoffice-request-id': params.meta.requestId
                },
                data: params.bodyResendMail
            }

            mailProvider.resetPassword(
                companyPrincipal?.userFullName?.trim(),
                options
            )
                .then(pass => {
                    setCompanyPrincipal({ ...companyPrincipal, welcomeEmail: (pass.data.newPassword) });
                    setIsResendMail(false);
                })
                .catch(_error => {
                    setCompanyPrincipal({ ...companyPrincipal, welcomeEmail: false });
                    setIsResendMail(false);
                });
        }
    }

    const validateManualCompanyStateChange = (newState) => {
        if (countryContext.country == COUNTRYS.COLOMBIA.CODE && companyPrincipal.state !== newState)
            actionsMarket(MarketActions.changeCompanyAddonState({
                tenantId: companyPrincipal?.cloudTenantConnectionUser?.split("SIIGOCloudTenant_")[1],
                companyPreviuosState: companyPrincipal.state,
                companyNewState: newState,
                updateType: PAYROLL_UPDATE_TYPE.MANUAL
            }));
    }

    const modifyData = (formData) => {
        if(!checkAuth()) return;
        const allowedPayrolUsers = [
            LICENCE_TYPE.SIIGO_NUBE.value,
            LICENCE_TYPE.SOLO_NOMINA.value,
            LICENCE_TYPE.SIIGO_NUBE_INDEPENDIENTE.value,
            LICENCE_TYPE.SIIGO_NUBE_EMPRENDEDOR.value,
            LICENCE_TYPE.SIIGO_NUBE_PREMIUM.value
        ];
        let prepaidPlanType = parseInt(formData.PrepaidPlanType)
        let modifyData = {
            companyKey: formData.companyKey,
            companyFullName: formData.name.replace(/[^a-zA-Z 0-9.]+/g, ' '),
            active: Number(formData.active) > 0,
            allowedUsers: Number(formData.users),
            POSTerminal: formData.pos,
            serial: formData.serial,
            prepaidPlanType: !isNaN(prepaidPlanType) ? prepaidPlanType : 3,
            workflowInstanceCode: formData.workflowInstanceCode,
            payrollUsers: allowedPayrolUsers?.includes(companyPrincipal?.plan?.value) && formData.payrollUsers ? (companyPrincipal?.XMLServicePlan.split("|PayrollUsers:")[0] + "|PayrollUsers:" + formData.payrollUsers) : (companyPrincipal?.XMLServicePlan),
            state: formData.state,
            inactivationDate: formData.inactivationDate,
            quoteNumber: formData.quoteNumber,
            nit: formData.nit
        };

        setModifiedData(modifyData);

        const params = {
            modifyData,
            meta: {
                country: countryContext.country,
                requestId: uuidV4Generator(),
            }
        }

        if (modifyData.posTerminal != '' && modifyData.allowedUsers != '' && modifyData.companyFullName != '' && modifyData.serial != '' && modifyData.workFlowInstanceCode != '', modifyData.nit != '') {
            if (allowedPayrolUsers.includes(companyPrincipal?.plan?.value)) {
                if (formData.payrollUsers == "" || parseInt(formData.payrollUsers) >= 0 && parseInt(formData.payrollUsers) <= 500 && formData.payrollUsers != "") {
                    actionsMarket(MarketActions.modifyCompany(params));
                } else {
                    ModalResponse({
                        title: 'Validar campos',
                        text: 'Validar "Empleados de Nomina", Valor Limite 500',
                        res: 404,
                    });
                }
            } else {
                actionsMarket(MarketActions.modifyCompany(params));
            }
        } else {
            ModalResponse({
                title: 'Validar campos',
                text: 'Todos los campos son requeridos',
                res: 404,
            });
        }
        validateManualCompanyStateChange(Number(formData.state));
    };

    const getPackageDetail = (e) => {
        e.preventDefault();
        if (Array.isArray(marketState.packages) && marketState.packages?.length && marketState.packageDetail?.length) {
            setPackages({
                ...packages,
                packages: marketState.packages,
                packageDetail: marketState.packageDetail,
                acronym: 'FE',
                showModalPackageDetail: true
            });
        } else {
            ModalResponse({
                title: 'Ver detalle',
                text: 'No ha adicionado paquetes todavia',
                res: 404,
            });
        }
    };

    const getPackage = (e) => {
        e.preventDefault();
        if (Array.isArray(marketState.packages) && marketState.packages?.length > 0) {
            setPackages({
                ...packages,
                acronym: 'FE',
                showModalPackage: true
            });
        }
    };

    const setPacksNE = () => {
        let packs = []
        Complementos[0].planesComplemento.forEach((item) => {
            if (item.documentBase > 5) {
                packs.push({
                    "_id": item.code,
                    "Name": item.name,
                    "Quantity": item.documentBase,
                    "Price": 1000000,
                    "PackageType": 0,
                    "PercentVAT": 19,
                    "Code": item.code
                })
            }
        });
        return packs;
    }

    const getPackageDetailNE = (e) => {
        e.preventDefault();
        let setPacks = setPacksNE();
        if (marketState.payrollDetail?.length > 0) {
            let packagesNE = marketState.payrollDetail?.map(item => {
                item.PurchaseDate = ConvertDateFormat(item.PurchaseDate.slice(0, 10));
                return item;
            });

            setPackages({
                ...packages,
                packages: setPacks,
                packageDetail: packagesNE,
                acronym: 'NE',
                showModalPackageDetail: true
            });
        } else {
            ModalResponse({
                title: 'Ver detalle',
                text: 'No ha adicionado paquetes todavia',
                res: 404,
            });
        }
    };

    const getPackageNE = (e) => {
        e.preventDefault();
        const packagesNE = setPacksNE();
        if (packagesNE) {
            setPackages({
                ...packages,
                packages: packagesNE,
                acronym: 'NE',
                showModalPackage: true
            });
        }
    }

    const getPackageUpdate = (e = null) => {
        if (e === null)
            return;
        e.preventDefault();
    };

    const handleBackClick = () => {
        
        if (hasCompanyChanges) {
            showSwalModalCompChanges();
        } else {
            let urlFrom = location.state?.from;
            let email = location.state?.email;
            history.push({
                pathname: urlFrom,
                state: { company: companyPrincipal, email: email }
            });
        }
    };

    const handleBackFromModal = () => {
        let urlFrom = location.state?.from;
        let email = location.state?.email;
        history.push({
            pathname: urlFrom,
            state: { company: companyPrincipal, email: email }
        });
    }

    const showSwalModalCompChanges = () => {

        Swal.fire({
            customClass: { confirmButton: 'button green', cancelButton: 'button red' },
            title: t("market.companyDetail.modal.companyChanges.title"),
            text: t("market.companyDetail.modal.companyChanges.text"),
            type: 'warning',
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sí',
            cancelButtonText: 'No',
            buttonsStyling: false,
            reverseButtons: true,
            html: '',
        }).then( (result) => {
            if(result.isConfirmed)
                handleBackFromModal();
        });
    }


    return (
        <div>
            <Modal
                onClose={() => setModalplan({ ...modalPlan, show: false })}
                changePlan={changePlan}
                title={t("market.companyDetail.modal.plan.title")}
                show={modalPlan.show}
                plan={companyPrincipal?.plan?.value}
                country={countryContext?.country}
            >
                <p>
                    <Trans i18nKey="market.companyDetail.modal.plan.current" >
                        <b>{{ name: companyPrincipal?.plan?.name }}</b>
                    </Trans>
                </p>
                <div className="form-change-plan input-container1">
                    <label>{t("market.companyDetail.modal.plan.form.title")}</label>
                    <select name="planModal" id="planModal">
                        <option value="-9">{t("market.companyDetail.modal.plan.form.labelSelectPlan")}</option>
                        {getPlanType().map((val, id) => {
                            return <option key={id} value={val.id}>{val.name}</option>;
                        })}
                    </select>
                </div>
            </Modal>
            <ModalAddPackage
                title={packages.acronym === 'FE' ? t("market.companyDetail.modal.addPackage.titleFE") : t("market.companyDetail.modal.addPackage.titleNE")}
                showModal={packages.showModalPackage}
                packages={packages.packages}
                hideModalPackage={() => setPackages({ ...packages, showModalPackage: false })}
                addPackage={addPackage}
                isLoadingPack={marketState.isLoadingPack}
            />
            <ModalPackageDetail
                showModal={packages.showModalPackageDetail}
                packages={packages.packageDetail}
                packs={packages.packages}
                getPackageUpdate={getPackageUpdate}
                acronym={packages.acronym}
                hideModalPackage={() => setPackages({ ...packages, showModalPackageDetail: false })}
                deletePackage={packages.acronym === 'FE' ? deletePackage : deletePackageNE}
                isLoadingPack={marketState.isLoadingPack}
                isLoadingPackDelete={marketState.isLoadingPackDelete}
            />

            <ModalCompanyChanges
                onClose={() => setModalCompChanges({ ...modalCompChanges, show: false })}
                setModalCompChanges={setModalCompChanges}
                setHasCompanyChanges={setHasCompanyChanges}
                handleBackFromModal={handleBackFromModal}
                title={t("market.companyDetail.modal.companyChanges.title")}
                show={modalCompChanges.show}
            >
                <label>{t("market.companyDetail.modal.companyChanges.text")}</label>
            </ModalCompanyChanges>

            <div className='header-company-detail'>
                <div>
                    <a className="link-back" onClick={handleBackClick}>
                        <i className="siicon icon-atras"></i>
                        <span>{t("market.companyDetail.buttonBack")}</span>
                    </a>
                    <h3 className="div-back">{t("market.companyDetail.contractData")}</h3>
                </div>
                <div>
                    {getAccess(props.parentPath, ACCESS.Users_Management) && countryContext.countries?.company?.usersAcces?.visible && (
                        <SiigoButtonAtom
                            className='right-buttons'
                            color="secondary"
                            text={t("market.companyDetail.manageUsersButton")}
                            onClick={() => history.push({
                                pathname: `/users_management/${props.id}`,
                                state: { from: location.state?.from, detail: history.location.pathname, company: companyPrincipal }
                            })}
                        />
                    )}
                    {countryContext.countries?.company?.btnPlanConverter?.visible && getAccess(props.parentPath, ACCESS.Convert_Companies) ? (
                        <SiigoButtonAtom
                            className='right-buttons'
                            color="secondary"
                            text={t("market.companyDetail.convertPlanButton")}
                            onClick={(e) => changePlan(e, companyPrincipal?.plan?.value, '')}
                        />
                    ) : null}
                    <SiigoButtonAtom
                        className='right-buttons'
                        color="secondary"
                        text={t("market.companyDetail.logs")}
                        onClick={() => history.push({
                            pathname: `${history.location.pathname}/logs`,
                            state: { from: location.state?.from, detail: history.location.pathname }
                        })}
                    />
                    {countryContext.countries?.company?.btnComplements?.visible && companyPrincipal?.plan?.name !== "Contador" && companyPrincipal?.plan?.name !== "Mundo anterior" && getAccess(props.parentPath, ACCESS.Show_complement_NE) &&
                        <Complements />
                    }
                </div>
            </div>
            <div className="company-detail">
                {!(marketState.isLoading || marketState.isLoadingModify)
                    && companyPrincipal?.companyKey ? (
                    <FormCompanyDetail
                        company={companyPrincipal}
                        country={countryContext}
                        t={t}
                        parentPath={props.parentPath}
                        resendWelcomeMail={resendWelcomeMail}
                        onSubmit={modifyData}
                        isResendMail={isResendMail}
                        isValidToEditNit={
                            marketState?.verifyNitEdition ?
                                marketState?.verifyNitEdition?.IsValidToEdit
                                : true
                        }
                        setHasCompanyChanges={setHasCompanyChanges}
                    />
                ) :
                    <Loader />
                }
            </div>
            {countryContext?.countries?.company?.cardDocFE?.visible && (config.planesPack.planes.includes(companyPrincipal?.plan.value) || config.planesPackERP.planes.includes(companyPrincipal?.plan.value)) &&
                <CardDocuments
                    title={t('market.companyDetail.billingDocuments.title')}
                    acronym={packages.acronym}
                    documentsbase={{
                        title: t('market.companyDetail.billingDocuments.documentsBase.title'),
                        documentsQuantity: config.planesPackERP.planes.includes(companyPrincipal?.plan.value) ? t('market.companyDetail.billingDocuments.documentsBase.unlimited') : companyPrincipal?.facturas && companyPrincipal?.facturas
                    }}
                    documentsAditionals={!config.planesPackERP.planes.includes(companyPrincipal?.plan.value) && {
                        title: t('market.companyDetail.billingDocuments.documentsAditionals.title'),
                        tooltip: t('market.companyDetail.billingDocuments.documentsAditionals.tooltip'),
                        documentsQuantity: marketState.balance,
                        viewDetails: getPackageDetail,
                        addDocuments: getPackage
                    }}
                />
            }
            {countryContext?.countries?.company?.cardDocNE?.visible && complementsNE?.length &&
                <CardDocuments
                    title={t('market.companyDetail.payrollDocuments.title')}
                    acronym={'NE'}
                    documentsbase={{
                        title: t('market.companyDetail.payrollDocuments.documentsBase.title'),
                        documentsQuantity: (complementsNE[0]?.documentBase > 0) && (complementsNE[0]?.module === 0) ? complementsNE[0].documentBase : t('market.companyDetail.payrollDocuments.documentsBase.unlimited')
                    }}
                    documentsAditionals={(complementsNE[0]?.documentBase > 0) && (complementsNE[0]?.module === 0) && {
                        title: t('market.companyDetail.payrollDocuments.documentsAditionals.title'),
                        tooltip: t('market.companyDetail.payrollDocuments.documentsAditionals.tooltip'),
                        documentsQuantity: marketState.balanceNE,
                        viewDetails: getPackageDetailNE,
                        addDocuments: getPackageNE
                    }}
                />
            }
        </div>
    )
}

export default CompanyDetail;
