import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { SiigoInputAtom } from "@siigo/siigo-input-atom-react";
import { SiigoSelectAtom } from "@siigo/siigo-select-atom-react";
import { SiigoButtonAtom } from "@siigo/siigo-button-atom-react";
import { SiigoCheckboxAtom } from "@siigo/siigo-checkbox-atom-react";
import { SiigoPanelModalMolecule } from "@siigo/siigo-panel-modal-molecule-react";
import { SiigoManagementTableMolecule } from "@siigo/siigo-management-table-molecule-react";
import { SiigoNotificationsInlineMolecule } from "@siigo/siigo-notifications-inline-molecule-react";

import Swal from 'sweetalert2';

import Loader from '../tools/loader';
import SiigoDualListBox from "./siigoDualListBox";
import { EditLabelsColumns } from './editLabelsColumns';

import svgs from "../layout/icons";
import { getCookie } from "../../utils/cookies";

import {
    DATAOPTIONS,
    CONSTVALUES,
    TYPES_AUTH,
    APPLY_FILTER_PAGINATION,
    APPLY_POST_FILTER_PAGINATION
} from "./data";

// Validations 
import { validateDataSearch, validateDataForm, resetForm } from "./validations";
import { SiigoTextAreaAtom } from "@siigo/siigo-text-area-atom-react";
import FormDataAuth from "./formDataAuth";


const FormDataSource = ({ propsFather, isEdit = false, dataDetail = null }) => {
    // Props
    const {
        error,
        groupId,
        dataProperties,
        dataCollection,
        isLoadingProperties,
        isLoadingCollection,
        isLoadingCreatedData,
        successSave,
        editSuccess,
        t,
        actions
    } = propsFather;

    //Actions
    const { getProperties, getCollections, saveData, editDataSource } = actions;
    const [errorFormAuth, setErrorFormAuth] = useState(false);

    useEffect(() => {
        if (error) {
            setOpenModalCollections(false);
            setOptionListKeySelected("");
        }
    }, [error]);

    useEffect(() => {
        if (isEdit && dataDetail !== null && dataCollection.length === 0) {
            const { typeConnection, dataSource, typeAuthenticate, bodyRequest, parentGroupId, viewCollection, nameKey } = dataDetail;

            const data = {
                typeConnection,
                dataSource,
                typeAuthenticate,
                nameCollection: viewCollection
            }

            if (typeConnection === CONSTVALUES.MICROSERVICE) {
                data.nameKey = nameKey
                data.jsonData = JSON.parse(bodyRequest)
                data.groupId = parentGroupId;

                if (typeAuthenticate === TYPES_AUTH.NEW_AUTH) {
                    data.configAuth = authData;
                }
            }

            getCollections(data);
        }
    }, []);

    useEffect(() => {
        if (dataProperties?.length > 0) {
            setOpenModalCollections(true);
            setOptionsCollection(dataProperties.map(item => (
                {
                    label: item,
                    value: item
                }
            )));
        }
    }, [dataProperties])

    useEffect(() => {
        if (dataCollection?.length > 0) {
            setOpenModalCollections(false);
            setOptionListKeySelected("");
            setDataColumns(dataCollection.map(item => (
                {
                    label: item,
                    value: item
                }
            )));
        }
    }, [dataCollection]);

    useEffect(() => {
        if (successSave) {
            Swal.fire({
                title: 'Exito',
                text: t('dataSourceReport.successMessages.successCreate'),
                icon: 'success',
                type: "success"
            });

            setTimeout(() => {
                setRedirectSuccess(true);
            }, 3000);
        }


    }, [successSave])

    useEffect(() => {
        if (editSuccess) {
            Swal.fire({
                title: 'Exito',
                text: t('dataSourceReport.successMessages.successEdit'),
                icon: 'success',
                type: "success"
            });

            setTimeout(() => {
                setRedirectSuccess(true);
            }, 3000);
        }


    }, [editSuccess])

    // States for forms
    const [formDatasource, setFormDatasource] = useState({
        typeConnection: dataDetail?.typeConnection || null,
        dataSource: dataDetail?.dataSource || "",
        method: dataDetail?.method || "",
        nameReport: dataDetail?.nameReport || "",
        parentGroupId: dataDetail?.parentGroupId || groupId,
        token: dataDetail?.token || "",
        bodyRequest: dataDetail?.bodyRequest || "",
        typeAuthenticate: dataDetail?.typeAuthenticate || "",
        viewCollection: dataDetail?.viewCollection || "",
        nameKey: dataDetail?.nameKey || "",
        userModified: {
            name: getCookie('name'),
            email: getCookie('email'),
            _id: getCookie('token')
        },
        fieldFilter: dataDetail?.fieldFilter || [],
        typeDataFilters: dataDetail?.typeDataFilters || "",
        jsonColumns: dataDetail?.jsonColumns || [],
        exportsType: {
            excel: false,
            pdf: false
        },
        configPagination: {},
        configAuth: dataDetail?.configAuth || {}
    });

    const [authData, setAuthData] = useState({
        method: dataDetail?.configAuth?.method || "",
        consumerKey: dataDetail?.configAuth?.consumerKey || "",
        consumerSecret: dataDetail?.configAuth?.consumerSecret || "",
        accessToken: dataDetail?.configAuth?.accessToken || "",
        tokenSecret: dataDetail?.configAuth?.tokenSecret || "",
        realm: dataDetail?.configAuth?.realm || ""
    });

    const [filterData, setFilterData] = useState({
        type: "",
        value: "",
        optionalInit: "",
        optionalFinish: "",
        applyFilter: "",
        postOption: "",
        keyPers: ""
    });

    const [paginationData, setPaginationData] = useState({
        methodApplication: dataDetail?.configPagination?.methodApplication || "",
        keyName: dataDetail?.configPagination?.keyName || "",
    });

    const [exportExcel, setExportExcel] = useState({
        checked: dataDetail?.exportsType?.excel || false,
        indeterminate: false,
        label: 'Excel',
        name: 'Op1'
    });

    const [exportPDF, setExportPDF] = useState({
        checked: dataDetail?.exportsType?.pdf || false,
        indeterminate: false,
        label: 'PDF',
        name: 'Op2'
    });

    const getJsonColumnsStrValue = (jsonColumns) => {
        return jsonColumns?.map(element => element.value || element) || [];
    }

    const [optionsValueFilter, setOptionsValueFilter] = useState(getJsonColumnsStrValue(dataDetail?.jsonColumns));
    const [openModalCollections, setOpenModalCollections] = useState(false);
    const [optionsCollection, setOptionsCollection] = useState([]);
    const [optionListKeySelected, setOptionListKeySelected] = useState("");
    const [dataColumns, setDataColumns] = useState([]);

    // States for Errors
    const [errorFilter, setErrorFilter] = useState(false);
    const [errorValidSearch, setErrorValidSearch] = useState(false);
    const [errorForm, setErrorForm] = useState(false);

    //Redirect
    const [redirectSuccess, setRedirectSuccess] = useState(false);


    //Reset Complete Form
    const resetCompleteForm = () => {
        resetForm(
            formDatasource, setFormDatasource, setFilterData,
            setExportExcel, setExportPDF, setOptionsValueFilter,
            setOptionsCollection, setOptionListKeySelected, setDataColumns,
            setErrorFilter, setErrorValidSearch, setErrorForm
        );
    }



    //Functions
    const handleChange = e => {
        setFormDatasource({
            ...formDatasource,
            [e.target.name]: e.target.value
        });

        if (e.target.name === "typeConnection" && formDatasource.typeConnection !== null) {
            resetCompleteForm();
        }
    }

    const handleChangeExport = e => {
        const [element] = e.detail;
        setFormDatasource({
            ...formDatasource,
            exportsType: {
                ...formDatasource.exportsType,
                [e.target.name]: element !== undefined ? true : false
            }
        });

        if (e.target.name === "excel") {
            setExportExcel({
                ...exportExcel,
                checked: element !== undefined ? true : false
            });
        } else {
            setExportPDF({
                ...exportPDF,
                checked: element !== undefined ? true : false
            });
        }
    }

    const handleChangeFilters = e => {
        setFilterData({
            ...filterData,
            [e.target.name]: e.target.value
        });
    }

    const onSelectedColumns = value => {

        const newJsonCols = value.map(item => {
            const existsElement = formDatasource.jsonColumns?.find(e => e === item || e.value === item);

            if (["string", "undefined"].includes(typeof existsElement)) return {
                label: item,
                value: item
            }

            return existsElement;
        })

        setFormDatasource({
            ...formDatasource,
            jsonColumns: newJsonCols
        });

        setOptionsValueFilter(value);
    }

    const addFilter = () => {
        const { type, value } = filterData;
        if (type.trim() === "" || value.trim() === "") {
            setErrorFilter(true);
            return;
        }

        setErrorFilter(false);

        const filtersDataSource = formDatasource.fieldFilter;

        setFormDatasource({
            ...formDatasource,
            fieldFilter: [
                ...filtersDataSource,
                filterData
            ]
        });

        setOptionsValueFilter(optionsValueFilter.filter(option => option !== value));

        setFilterData({
            type: "",
            value: ""
        });

    }
    
    // Remover un filtro de la tabla
    const removeFilter = itemTable => {

        const filters = formDatasource.fieldFilter;
        setFormDatasource({
            ...formDatasource,
            fieldFilter: filters.filter(item => item.value !== itemTable.value)
        });

        // Se agrega de nuevo el elemento eliminado al Select de value en filtros
        setOptionsValueFilter([
            ...optionsValueFilter,
            itemTable.value
        ]);
    }

    const getDataTable = () => {
        const objectActions = {
            type: "standard"
        }

        const filters = formDatasource.fieldFilter;
        const data = filters.map(filter => (
            {
                type: filter.type,
                value: filter.value,
                actions: "X",
                preferencesActions: objectActions
            }
        ));

        return data;
    }

    const searchDataReport = () => {

        setDataColumns([]);
        setFormDatasource({
            ...formDatasource,
            jsonColumns: [],
            fieldFilter: []
        });
        setOptionsValueFilter([]);

        const { typeConnection, method, dataSource, typeAuthenticate, token, bodyRequest, parentGroupId } = formDatasource;

        let dataValidate = formDatasource;
        dataValidate.configAuth = authData;

        const isValid = validateDataSearch(dataValidate);
        if (!isValid) {
            setErrorValidSearch(true);
            return;
        }

        setErrorValidSearch(false);

        const data = {
            typeConnection,
            method,
            dataSource,
            typeAuthenticate,
            token,
            groupId: parentGroupId
        }

        if (typeConnection === CONSTVALUES.MICROSERVICE) {
            data.jsonData = JSON.parse(bodyRequest);

            if (typeAuthenticate === TYPES_AUTH.NEW_AUTH) {
                data.configAuth = authData;
            }
        }
        // Http Request
        getProperties(data);
    }

    const getDataCollection = () => {
        const { typeConnection, dataSource, typeAuthenticate, bodyRequest, parentGroupId } = formDatasource;
        const data = {
            typeConnection,
            dataSource,
            typeAuthenticate,
            nameCollection: optionListKeySelected
        }

        if (typeConnection === CONSTVALUES.MICROSERVICE) {
            data.nameKey = optionListKeySelected
            data.jsonData = JSON.parse(bodyRequest)
            data.groupId = parentGroupId

            if (typeAuthenticate === TYPES_AUTH.NEW_AUTH) {
                data.configAuth = authData;
            }
        }

        // Http Request 
        getCollections(data);
    }

    const handleSubmit = e => {
        e.preventDefault();

        const form = formDatasource;
        form.configPagination = paginationData;
        form.configAuth = authData;

        const isValid = validateDataForm(form);

        if (!isValid) {
            setErrorForm(true);
            return;
        }

        setErrorForm(false);

        // Http Request
        isEdit ? editDataSource(dataDetail?._id, form) : saveData(form);

    }

    const handlerChangeLabel = (e, item, index) => {
        e.preventDefault();

        const newArr = [...formDatasource.jsonColumns];
        newArr[index] = { label: e.target.value, value: (item.value || item) };
        setFormDatasource({
            ...formDatasource,
            jsonColumns: newArr
        });
    }

    const handleChangePagination = e => {
        setPaginationData({
            ...paginationData,
            [e.target.name]: e.target.value
        });
    }


    return (
        <>
            {redirectSuccess ? (
                <Redirect
                    to={{
                        pathname: '/datareport'
                    }}
                />
            ) : null}
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="column2">
                        <SiigoInputAtom
                            style={{ width: '100%' }}
                            align="left"
                            errorMessage="Error."
                            id="nameReport"
                            label="Nombre reporte"
                            name="nameReport"
                            onchange={handleChange}
                            requiredMessage={t('dataSourceReport.errorValidation.requiredMessage')}
                            type="text"
                            value={formDatasource.nameReport}
                            required
                        />
                    </div>
                    <div className="column2">
                        <SiigoSelectAtom
                            style={{ width: '100%' }}
                            options={DATAOPTIONS.TYPECONNECTION}
                            onChangeData={handleChange}
                            name="typeConnection"
                            value={formDatasource.typeConnection}
                            errorMessage="Error."
                            id="typeConnection"
                            label="Tipo de Conexión"
                            requiredMessage={t('dataSourceReport.errorValidation.requiredMessage')}
                            required
                        />
                    </div>
                </div>
                {formDatasource.typeConnection === CONSTVALUES.MICROSERVICE ? (
                    <div className="row mt-3">
                        <SiigoNotificationsInlineMolecule
                            style={{ width: "100%" }}
                            eventTimeout={900000}
                            textBody={{
                                text: t('dataSourceReport.infoMessages.microParameters')
                            }}
                            type="info"
                        />
                    </div>
                ) : null}
                <div className="row mt-3">
                    <div className="column2">
                        <SiigoInputAtom
                            style={{ width: '100%' }}
                            align="left"
                            errorMessage="Error."
                            id="dataSource"
                            label="Ruta origen"
                            name="dataSource"
                            onchange={handleChange}
                            onPressCleanButton={function noRefCheck() { }}
                            requiredMessage={t('dataSourceReport.errorValidation.requiredMessage')}
                            type={isEdit ? "password" : "text"}
                            value={formDatasource.dataSource}
                            required
                        />
                    </div>
                </div>


                {formDatasource.typeConnection === CONSTVALUES.MICROSERVICE ? (
                    <div>
                        <div className="row mt-3" style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                            <div className="column">
                                <SiigoSelectAtom
                                    style={{ width: '100%' }}
                                    options={DATAOPTIONS.METHOD}
                                    onChangeData={handleChange}
                                    name="method"
                                    value={formDatasource.method}
                                    id="method"
                                    label="Método de Conexión"
                                    requiredMessage={t('dataSourceReport.errorValidation.requiredMessage')}
                                    required
                                />
                            </div>
                            <div className="column">
                                <SiigoSelectAtom
                                    style={{ width: '100%' }}
                                    options={formDatasource.method === 'POST' ? DATAOPTIONS.TYPEAUTHPOST : DATAOPTIONS.TYPEAUTH}
                                    onChangeData={handleChange}
                                    name="typeAuthenticate"
                                    value={formDatasource.typeAuthenticate}
                                    errorMessage="Error."
                                    id="typeAuthenticate"
                                    label="Tipo de Autenticación"
                                />
                            </div>

                            <div className="column">
                                <SiigoInputAtom
                                    style={{ width: '100%' }}
                                    align="left"
                                    errorMessage="Error."
                                    id="token"
                                    label="Token"
                                    name="token"
                                    onchange={handleChange}
                                    type="text"
                                    value={formDatasource.token}
                                />
                            </div>
                        </div>

                        {formDatasource.typeAuthenticate === DATAOPTIONS.TYPEAUTHPOST[1].value && formDatasource.method === DATAOPTIONS.METHOD[1].value ? (
                            <div className="column">
                                <FormDataAuth
                                    authData={authData}
                                    setAuthData={setAuthData}
                                    t={t}
                                    isEdit={isEdit}
                                />
                            </div>
                        ) : null}


                        {formDatasource.method === "POST" && (
                            <div className="column">
                                <SiigoTextAreaAtom
                                    style={{ width: '50%', height: '50%' }}
                                    align="left"
                                    errorMessage="Error."
                                    id="bodyRequest"
                                    label="Json de la petición"
                                    name="bodyRequest"
                                    onchange={handleChange}
                                    pressCleanButton={function noRefCheck() { }}
                                    requiredMessage={t('dataSourceReport.errorValidation.requiredMessage')}
                                    type="text"
                                    value={formDatasource.bodyRequest}
                                    required
                                />
                            </div>
                        )}

                    </div>
                ) : null}

                {errorValidSearch ? (
                    <div className="row mt-3">
                        <SiigoNotificationsInlineMolecule
                            style={{ width: "100%" }}
                            eventTimeout={90000}
                            textBody={{
                                text: t('dataSourceReport.errorValidation.validateSearch')
                            }}
                            type="warning"
                        />
                    </div>
                ) : null}

                <div className="row mt-3">
                    <div className="column2">
                        <SiigoButtonAtom
                            ariaLabel="click here"
                            color="primary-blue"
                            displayText=""
                            download=""
                            form=""
                            formaction=""
                            href=""
                            icon="fas fa-solid fa-search"
                            iconPosition="start"
                            name=""
                            onClick={searchDataReport}
                            size="m"
                            text="Buscar"
                            type="button"
                            loading={isLoadingProperties}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="column2">
                        <div className="row">
                            <div className="column4">
                                <SiigoCheckboxAtom
                                    direction="row"
                                    name="excel"
                                    onchange={handleChangeExport}
                                    options={[exportExcel]}
                                />
                            </div>
                            <div className="column2">
                                <SiigoCheckboxAtom
                                    direction="row"
                                    name="pdf"
                                    onchange={handleChangeExport}
                                    options={[exportPDF]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {isEdit && isLoadingCollection ? (
                    <Loader />
                ) : (
                    <div className="row mt-3">
                        <div className="column2">
                            <label style={{ color: "#737373", fontWeight: "bold" }}>Columnas</label>
                            <SiigoDualListBox
                                options={dataColumns}
                                selected={getJsonColumnsStrValue(formDatasource.jsonColumns)}
                                onSelected={onSelectedColumns}
                                t={t}
                            />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="column2" style={{ paddingRight: "2%", marginTop: "4%" }}>
                        <EditLabelsColumns
                            handlerChangeLabel={handlerChangeLabel}
                            jsonColumns={formDatasource.jsonColumns}
                            t={t}
                        />

                    </div>
                    <div className="column2 mt-4">
                        <label style={{ color: "#737373", fontWeight: "bold" }}>Configuración de filtros</label>
                        <div className="mt-3">
                            {formDatasource.typeConnection === CONSTVALUES.MICROSERVICE ? (
                                <div className="row">
                                    <div className="column4">
                                        <SiigoSelectAtom
                                            style={{ width: '100%' }}
                                            options={DATAOPTIONS.OPTION_APPLY_FILTER_PAGINATION}
                                            onChangeData={handleChangeFilters}
                                            name="applyFilter"
                                            value={filterData.applyFilter}
                                            errorMessage="Error."
                                            id="type"
                                            label="Método de aplicación"
                                        />
                                    </div>
                                    {filterData.applyFilter === APPLY_FILTER_PAGINATION.BODY ? (
                                        <div className="column4">
                                            <SiigoSelectAtom
                                                style={{ width: '100%' }}
                                                options={DATAOPTIONS.OPTIONS_APPLY_POST_FILTER_PAGINATION}
                                                onChangeData={handleChangeFilters}
                                                name="postOption"
                                                value={filterData.postOption}
                                                errorMessage="Error."
                                                id="type"
                                                label="Sitio de aplicación"
                                            />
                                        </div>
                                    ) : null}
                                    {filterData.postOption === APPLY_POST_FILTER_PAGINATION.KEY ? (
                                        <div className="column4">
                                            <SiigoInputAtom
                                                style={{ width: '100%' }}
                                                align="left"
                                                errorMessage="Error."
                                                id="token"
                                                label="Key personalizada"
                                                name="keyPers"
                                                onchange={e => setFilterData({ ...filterData, [e.target.name]: e.target.value })}
                                                type="text"
                                                value={filterData.keyPers}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            ) : null}

                            {formDatasource.typeConnection === CONSTVALUES.MICROSERVICE && filterData.applyFilter === APPLY_FILTER_PAGINATION.BODY ? (
                                <div className="row mt-2">
                                    <div className="column2">
                                        <SiigoSelectAtom
                                            style={{ width: '100%' }}
                                            options={DATAOPTIONS.OPTION_TYPE_DATA_FILTER}
                                            onChangeData={handleChange}
                                            name="typeDataFilters"
                                            value={formDatasource.typeDataFilters}
                                            errorMessage="Error."
                                            id="typeDataFilters"
                                            label="Tipo de dato para filtros"
                                        />
                                    </div>
                                </div>
                            ) : null}
                            <div className="row mt-2">
                                <div className="column4">
                                    <SiigoSelectAtom
                                        style={{ width: '100%' }}
                                        options={DATAOPTIONS.TYPEDATA}
                                        onChangeData={handleChangeFilters}
                                        name="type"
                                        value={filterData.type}
                                        errorMessage="Error."
                                        id="type"
                                        label="Tipo de dato"
                                    />
                                </div>
                                <div className="column4">
                                    <SiigoSelectAtom
                                        style={{ width: '100%' }}
                                        options={
                                            formDatasource.typeConnection == CONSTVALUES.MONGODB ?
                                                optionsValueFilter.map(option => ({ label: option, value: option })) :
                                                dataColumns.map(option => ({ label: option.label, value: option.value }))
                                        }
                                        onChangeData={handleChangeFilters}
                                        name="value"
                                        value={filterData.value}
                                        errorMessage="Error."
                                        id="value"
                                        label="Valor"
                                    />
                                </div>
                                <div className="column4">
                                    <SiigoButtonAtom
                                        ariaLabel="click here"
                                        color="primary-green"
                                        displayText=""
                                        download=""
                                        form=""
                                        formaction=""
                                        href=""
                                        icon="fas fa-solid fa-plus-circle"
                                        iconPosition="start"
                                        name=""
                                        onClick={addFilter}
                                        size="m"
                                        type="button"
                                    />
                                </div>
                            </div>
                            {formDatasource?.typeConnection === CONSTVALUES.MICROSERVICE &&
                                filterData.type === "date" &&
                                filterData.value !== "" ? (
                                <>
                                    <div className="row mt-2">
                                        <div className="column2">
                                            <SiigoInputAtom
                                                style={{ width: '100%' }}
                                                align="left"
                                                errorMessage="Error."
                                                id="optionalInit"
                                                label={`start_${filterData.value}`}
                                                name="optionalInit"
                                                onchange={handleChangeFilters}
                                                onPressCleanButton={function noRefCheck() { }}
                                                type="text"
                                                value={filterData.optionalInit}
                                            />
                                        </div>
                                        <div className="column2">
                                            <SiigoInputAtom
                                                style={{ width: '100%' }}
                                                align="left"
                                                errorMessage="Error."
                                                id="optionalFinish"
                                                label={`end_${filterData.value}`}
                                                name="optionalFinish"
                                                onchange={handleChangeFilters}
                                                type="text"
                                                value={filterData.optionalFinish}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="column6">
                                            <p>Puedes agregar los valores de consulta para las fechas aquí. Si no seleccionas nada por default será: fechaini y fechafin</p>
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {errorFilter ? (
                                <div className="row mt-3">
                                    <SiigoNotificationsInlineMolecule
                                        style={{ width: "95%" }}
                                        eventTimeout={90000}
                                        textBody={{
                                            text: t('dataSourceReport.errorValidation.validateFilter')
                                        }}
                                        type="error"
                                    />
                                </div>
                            ) : null}
                        </div>
                        <div className="mt-4">
                            <SiigoManagementTableMolecule
                                containerWidthScrollTable="400px"
                                value={getDataTable()}
                                headers={DATAOPTIONS.HEADERSLISTTABLE}
                                hiddenCurrentActions
                                onClickButtonAction={e => removeFilter(e.detail.item)}
                                reorder
                                resizeColumn
                                rowNumber
                                selectRows
                                sortable
                                sticky
                                stopAutoCloseEditMode
                            />
                        </div>
                    </div>
                </div>

                {formDatasource.typeConnection === CONSTVALUES.MICROSERVICE ? (
                    <div className="row mb-5">
                        <div className="column2 mt-4">
                            <label style={{ color: "#737373", fontWeight: "bold" }}>Configuración de paginación</label>
                            <div className="mt-3">
                                {formDatasource.typeConnection === CONSTVALUES.MICROSERVICE ? (
                                    <div className="row">
                                        <div className="column2">
                                            <SiigoSelectAtom
                                                style={{ width: '100%' }}
                                                options={DATAOPTIONS.OPTION_APPLY_FILTER_PAGINATION}
                                                onChangeData={handleChangePagination}
                                                name="methodApplication"
                                                value={paginationData.methodApplication}
                                                errorMessage="Error."
                                                id="type"
                                                label="Método de aplicación"
                                            />
                                        </div>
                                        <div className="column2">
                                            <SiigoInputAtom
                                                style={{ width: '100%' }}
                                                align="left"
                                                errorMessage="Error."
                                                id="token"
                                                label="Key"
                                                name="keyName"
                                                onchange={handleChangePagination}
                                                type="text"
                                                value={paginationData.keyName}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}

                {errorForm ? (
                    <div className="row mt-4">
                        <div className="column2">
                            <SiigoNotificationsInlineMolecule
                                style={{ width: "100%" }}
                                eventTimeout={90000}
                                textBody={{
                                    text: t('dataSourceReport.errorValidation.validateCreatedEdit')
                                }}
                                type="error"
                            />
                        </div>
                    </div>
                ) : null}
                <div className="row mt-4">
                    {isLoadingCreatedData ? (
                        <Loader />
                    ) : (
                        <div className="column2">
                            <button
                                type="submit"
                                className="button green-fill"
                            >
                                <img className="icon-img icon-mas" src={svgs[isEdit ? "editar" : "mas"]} alt="" />
                                {isEdit ? "Editar" : "Guardar"}
                            </button>
                        </div>
                    )}
                </div>
            </form>
            <SiigoPanelModalMolecule
                openModal={openModalCollections}
                title={
                    formDatasource.typeConnection === CONSTVALUES.MONGODB ? "Colecciones Mongo" : "Keys del Microservicio"
                }
                width="600px"
                onClosePanelModal={() => {
                    setOpenModalCollections(!openModalCollections);
                    setOptionListKeySelected("");
                }}
            >
                <div className="row mt-4">
                    <div className="column2">
                        <SiigoSelectAtom
                            style={{ width: '100%' }}
                            options={optionsCollection}
                            name="collection"
                            errorMessage="Error."
                            id="collection"
                            label="Colección"
                            value={optionListKeySelected}
                            onChangeData={e => {
                                setOptionListKeySelected(e.target.value);
                                if (formDatasource.typeConnection === CONSTVALUES.MICROSERVICE) {
                                    setFormDatasource({
                                        ...formDatasource,
                                        nameKey: e.target.value
                                    });
                                } else {
                                    setFormDatasource({
                                        ...formDatasource,
                                        viewCollection: e.target.value
                                    });
                                }

                            }}
                        />
                    </div>
                </div>
                <div className="row mt-4" style={{ diplay: "flex", justifyContent: "flex-end" }}>
                    <SiigoButtonAtom
                        ariaLabel="click here"
                        color="primary-blue"
                        iconPosition="start"
                        name=""
                        onClick={getDataCollection}
                        size="m"
                        text="Seleccionar"
                        type="button"
                        disabled={optionListKeySelected.trim() === ""}
                        loading={isLoadingCollection}
                    />
                </div>
            </SiigoPanelModalMolecule>
        </>

    );
}

export default FormDataSource;