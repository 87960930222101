import React, { useEffect, useRef, useState } from 'react';
import { SiigoSpinnerAtom } from "@siigo/siigo-spinner-atom-react";

export const LoaderContext = React.createContext();

export const LoaderProvider = ({ children }) => {
    const [showLoading, setShowLoading] = useState(false);
    const [text, setText] = useState("");
    const spinnerRef = useRef(null);

    const showLoader = (visible, loadingText = "") => {
        setShowLoading(visible);
        setText(loadingText);
    };

    return (
        <LoaderContext.Provider value={{
            showLoader,
        }}>
            {children}
            <SiigoSpinnerAtom
                ref={spinnerRef}
                text={text}
                showSpinner={showLoading}
                disableOverlayCloseEvent={true}
                hiddenOverlay={false}
            />
        </LoaderContext.Provider>
    );
};

export const LoaderConsumer = LoaderContext.Consumer;

export default LoaderContext;