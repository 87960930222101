
export default function (api) {
  return {
    dataSourceResponse: () => api.get('groupsDatasource'),
    getReportResponse: () => api.get('groupsDatasource/getListDataSource'),
    addDataSourceResponse: (body) => api.post('groupsDatasource', body),
    propertiesResponse: (body) => api.post('dataSource/getProperties', body),
    collectionResponse: (body) => api.post('dataSource/getCollectionNameKey', body),
    saveDataResponse: (body) => api.post('dataSource', body),
    getDetailDataSource: id => api.get(`dataSource/${id}`),
    editDataSource: (id, body) => api.put(`dataSource/${id}`, body)
  };
}
