import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalResponse from '../layout/modals/modalResponse';
import Modal from '../layout/modals/auroraModal/index';
import { createLog, createConfigOrigin } from '../../actions/auroraConfigOrigins/auroraConfigOriginsActions';
import OriginsList from './originsList';
import { getCookie } from '../../utils/cookies';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';
import './style.scss';
import { StatusCodes } from 'http-status-codes';

let access = true;
class CreateConfigOrigin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newOrigin: {},
            showCreate: false,
            openCreate: false,
            showSuccess: false,
            openSuccess: false,
            showAccErr: false,
            openAccErr: false,
            disableBtnCreate: false,
            origins: [{ origin: "", state: "Active" }],
        };
    }

    componentDidMount() {
        document.title = "Crear Configuración de Origen";
        access = getAccess(this.props.parentPath, ACCESS.create_origin);

        if (!access) {
            this.showModalAccErr();
        }
    }

    showModalAccErr = () => {
        this.setState({ showAccErr: !this.state.showAccErr, openAccErr: !this.state.openAccErr });
    }

    focusInput = (e) => {
        e.preventDefault();
        e.currentTarget.classList.remove("inputError");
        e.currentTarget.classList.remove("inputOk");
        e.currentTarget.classList.add('inputFocus');
        if (e.currentTarget.previousSibling) {
            e.currentTarget.previousSibling.classList.remove('inputError');
            e.currentTarget.previousSibling.classList.remove('inputOk');
            e.currentTarget.previousSibling.classList.add('labelModal');
        }
    };

    blurInput = (e, optional = true) => {
        e.preventDefault();
        if (e.currentTarget.value === '' && optional) {
            e.currentTarget.classList.remove("inputOk");
            e.currentTarget.classList.add('inputError');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelOk');
                e.currentTarget.previousSibling.classList.add('labelError');
            }
        } else {
            e.currentTarget.classList.remove("inputError");
            e.currentTarget.classList.add('inputOk');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelError');
                e.currentTarget.previousSibling.classList.add('labelOk');
            }
        }
    };

    redirectPage() {
        window.location.assign(encodeURI("/configorigins"));
    }

    renderModalX(text) {
        return (
            <div>
                <div className="uxDetailDataFilterTitle" style={{ textAlign: "center" }}>
                    <h4 >{text}</h4>
                </div>
                <div className="uxDetailContainer" style={{ textAlign: "center" }}>
                    <button
                        style={{ margin: "20px" }}
                        className="orange-fill"
                        type="button"
                        onClick={(e) => { this.redirectPage() }}
                    >Aceptar
                    </button>

                </div>
            </div >
        );
    }

    createOrigin = () => {
        this.setState({ disableBtnCreate: true });
        this.props.dispatch(createConfigOrigin(this.state.newOrigin));
    }

    processResponseCreateOrigin() {
        this.setState({ disableBtnCreate: false });
        this.showModalCreateOrigin();
        const resp = this.state.createOrigin;
        if (resp && (resp.status === StatusCodes.OK || resp.status === StatusCodes.CREATED)) {
            this.createLogActivity(resp.data);
            this.showModaSuccess();
        } else {
            this.modalAlert('¡Error!', 'Ha ocurrido un problema, inténtelo más tarde.', 404);
        }
    }

    componentDidUpdate() {
        if (this.props.errorCreate !== this.state.errorCreate) {
            this.setState({ errorCreate: this.props.errorCreate }, () => {
                if (this.state.errorCreate) {
                    this.modalAlert('¡Error!', 'Ha ocurrido un problema, inténtelo más tarde.', 404);
                }
            });
        }
        if (this.props.createOrigin !== this.state.createOrigin) {
            this.setState({ createOrigin: this.props.createOrigin }, () => {
                if (this.state.createOrigin) this.processResponseCreateOrigin();
            });
        }
        if (this.props.isLoadingCreate !== this.state.isLoadingCreate) {
            this.setState({ isLoadingCreate: this.props.isLoadingCreate });
        }
    }

    renderModalConfirm() {
        return (
            <div>
                <div className="uxDetailDataFilterTitle" style={{ textAlign: "center" }}>
                    <h4 >Crear Configuración de Origen</h4>
                </div>
                <div className="uxDetailContainer" style={{ textAlign: "center" }}>
                    <div >
                        <span class="labelUxDetail">¿Esta seguro que desea crear el nuevo Origen?</span>
                    </div>
                    <br />
                    <button
                        style={{ margin: "20px", opacity: this.state.disableBtnCreate ? "0.5" : "1.0" }}
                        disabled={this.state.disableBtnCreate}
                        className="orange-fill"
                        type="button"
                        onClick={this.createOrigin}
                    >Aceptar
                    </button>
                    <button
                        style={{ margin: "20px", opacity: this.state.disableBtnCreate ? "0.5" : "1.0" }}
                        className="red-fill"
                        disabled={this.state.disableBtnCreate}
                        type="button"
                        onClick={this.showModalCreateOrigin}
                    >Cancelar
                    </button>
                </div>
            </div >
        );
    }

    showModalCreateOrigin = (e) => {
        this.setState({ showCreate: !this.state.showCreate });
    }

    showModaSuccess = () => {
        this.setState({ showSuccess: !this.state.showSuccess, openSuccess: !this.state.openSuccess });
    }

    validValue(value) {
        return value === "" || value === undefined || value === null;
    }

    validarOriginList() {
        if (this.state.origins.length < 1) return false;
        for (let i = 0; i < this.state.origins.length; i++) {
            var origins = this.state.origins[i];
            if (this.validValue(origins.state) || this.validValue(origins.origin)) {
                return false;
            }
        }
        return true;
    }

    modalAlert(title, text, res) {
        ModalResponse({ title, text, res, });
    }

    handleChange = e => {
        let origins = [...this.state.origins];
        origins[e.target.dataset.id][e.target.name] = e.target.value;
        this.setState({ origins });
    };

    createLogActivity(object) {
        let aditionalData = { created_object: object }

        let params = {
            nameComponent: "Crear Origen",
            nameUser: getCookie('name'),
            action: "Create Origin",
            date: (new Date()).toISOString(),
            aditionalData: JSON.stringify(aditionalData),
        }

        this.props.dispatch(createLog(params));
    }

    addOriginItem = e => {
        e.preventDefault();
        this.setState(prevState => ({
            origins: [...prevState.origins, { state: "Active", origin: "" }]
        }));
    };

    removeOriginItem = e => {
        e.preventDefault();
        let origins = [...this.state.origins];
        origins.splice(e.target.dataset.id, 1);
        this.setState({ origins });
    };

    btnCreateOrigin = (e) => {
        e.preventDefault();
        const type = e.target.originType.value;
        const descrip = e.target.description.value;
        const state = e.target.originState.value;
        if (type && descrip && state && this.validarOriginList()) {
            var origin = {
                description: descrip,
                state,
                type,
                origins: this.state.origins
            }
            this.setState({ newOrigin: origin, showCreate: true, openCreate: true });
        }
        else {
            this.modalAlert('¡Hey!', 'Te hacen falta campos por ingresar', 404);
        }
    }

    render() {

        return (
            <div style={{ paddingBottom: "5%" }}>
                <h3>Crear Configuración de Origenes y Notificaciones</h3>
                <br />
                <br />
                <form onSubmit={this.btnCreateOrigin}>

                    <div className="input-container-accountant">
                        <label>Tipo de Notificación</label>
                        <input
                            type="text"
                            name="originType"
                            id="originType"
                            placeholder=""
                            className="inputFilter customEnterText"
                            onFocus={(e) => this.focusInput(e)}
                            onBlur={(e) => this.blurInput(e)}
                            disabled={!access}
                        />
                    </div>
                    <div className="input-container-accountant">
                        <label>Descripción</label>
                        <input
                            type="text"
                            name="description"
                            id="description"
                            placeholder=""
                            className="inputFilter customEnterText"
                            onFocus={(e) => this.focusInput(e)}
                            onBlur={(e) => this.blurInput(e)}
                            disabled={!access}
                        />
                    </div>

                    <div className="input-container-accountant">
                        <label >Seleccione el Estado</label>
                        <select className="slFilter customEnterText" id="originState" name="originState"
                            onFocus={(e) => this.focusInput(e)}
                            onBlur={(e) => this.blurInput(e)}
                            disabled={!access}
                        >
                            <option value="">Ninguno</option>
                            <option className="successState" value="Active">Activo</option>
                            <option className="errorState" value="Inactive">Inactivo</option>
                        </select>
                    </div>

                    <h3>Origenes</h3>
                    <hr className="sep" />
                    <OriginsList
                        access={!access}
                        origins={this.state.origins}
                        change={this.handleChange}
                        remove={this.removeOriginItem}
                        focusInput={this.focusInput}
                        blurInput={this.blurInput}
                    />
                    <br />
                    <br />
                    <div className="center">
                        <button
                            type="button"
                            className="btn btn-outline-primary margin-bottom-btn"
                            onClick={this.addOriginItem}
                            disabled={!access}
                        >
                            <i>+</i> Agregar Origen
                        </button>
                    </div>
                    <br />
                    <div className="p-top-20 center">
                        <button className="button green-fill" disabled={!access}>Crear Origen</button>
                    </div>
                </form>

                <Modal
                    onClose={this.showModalCreateOrigin}
                    show={this.state.showCreate}
                    open={this.state.openCreate}>
                    {this.renderModalConfirm()}
                </Modal>

                <Modal
                    onClose={(e) => { this.props.history.push('/configorigins') }}
                    show={this.state.showSuccess}
                    title="Origen Creado"
                    open={this.state.openSuccess}>
                    {this.renderModalX("¡El origen fue creado exitosamente!")}
                </Modal>
                <Modal
                    onClose={(e) => { this.props.history.push('/configorigins') }}
                    show={this.state.showAccErr}
                    title="Crear Origen"
                    open={this.state.openAccErr}>
                    {this.renderModalX("¡No tienes permiso para crear origenes! Comunicate con el Administrador.")}
                </Modal>

            </div>
        );
    }

}

CreateConfigOrigin.propTypes = {
    actions: PropTypes.object.isRequired,
    createOrigin: PropTypes.object,
    isLoadingCreate: PropTypes.object,
    errorCreate: PropTypes.object,
    log: PropTypes.object,
};

const mapStateToProps = state => ({
    log: state.logs.log,
    createOrigin: state.auroraConfigOriginsReducer.createOrigin,
    isLoadingCreate: state.auroraConfigOriginsReducer.isLoadingCreate,
    errorCreate: state.auroraConfigOriginsReducer.errorCreate,
});


export default connect(mapStateToProps)(withRouter(CreateConfigOrigin));