import React, { Component } from 'react';
import PropTypes, { func } from 'prop-types';
import * as NotificationActions from '../../actions/notificationAS/notificationAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalDelete from '../layout/modals/modalDelete';
import ModalResponse from '../layout/modals/modalResponse';
import svgs from '../layout/icons';
import Modal from '../layout/modals/detail/index';
//import './style.scss';
import { normalize } from '../tools/format';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';

const columns = [
	{
		name: 'Criterio',
		selector: 'criterion',
		sortable: true
	},
	{
		name: 'Descripción',
		selector: 'description',
		sortable: true
	},

	{
		name: 'Estado',
		selector: 'state',
		sortable: true,
		filterDDL: true
	}
];
let deleteNot = false;
class NotificationsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			NotificationDetail: '',
			commentLeads: [],
			subject: ''
		};
	}

	showModal = () => {
		this.setState({ show: !this.state.show });
	};

	getNotification = () => {
		this.props.actions.getNotification();
	};

	componentDidMount() {
		this.getNotification();
	}

	showDetail = data => {
		let datos = [];
		let comment = [];
		this.setState({
			NotificationDetail: data.message,
			subject: data.subject,
			show: true
		});
	};

	checkDelete = id => {
		ModalDelete({
			actionDelete: this.props.actions.deleteNotification,
			id: id
		});
		deleteNot = true;
	};

	render() {
		let { error, isLoading, notifications, notificationDel } = this.props;
		const btnActions = {
			btnEdit: getAccess(this.props.parentPath, ACCESS.Edit) ? '/notificationa/edit/' : null,
			btnShowDetail: getAccess(this.props.parentPath, ACCESS.Visualize) ? this.showDetail : null,
			btnDelete: getAccess(this.props.parentPath, ACCESS.Delete) ? this.checkDelete : null
		};
		let datos = [];
		let status = 0;
		let text = '';
		let data = {};

		if (notifications) {
			if (notifications.data.error === 'Forbidden') {
				return <div>No tiene permiso para este componente</div>;
			}
			datos = notifications.data;
		}

		if (notificationDel && deleteNot) {
			if (notificationDel.data.status) {
				ModalResponse({
					title: 'Eliminar Notificación',
					text: 'La notificación ha sido eliminada con éxito',
					res: 200
				});
			} else {
				ModalResponse({
					title: 'Eliminar Notificación',
					text: 'No fue posible eliminar la notificación',
					res: 404
				});
			}
			deleteNot = false;
		}

		return (
			<div>
				<h3>Configuraciones para los estados de cartera</h3>

				{
					getAccess(this.props.parentPath, ACCESS.Create) ? (
						<a href="/notificationa/create" className="a-button green-fill">
						<img className="icon-img icon-mas" src={svgs['mas']} alt="" />
							Crear
						</a>
					) : null
				}

				
				{error && <h4>Error: {error.response.data}</h4>}
				{isLoading ? (
					<Loader />
				) : (
					<div>
						<div className="divTable">
							<TableReport
								columns={columns} // Data columns to table
								tabs={false} //Enable tabs
								tabsData={[]} //Load data to tabs check option on tabsData const
								headerCounterTab={true} //Counter tab option
								btnActions={btnActions}
								actionButtons={true} //Enable buttons (edit, delete, copy, open)
								pageSize={10} // Enable page size
								pagination={true} //Enable pagination (true, false)
								paginationNav={'arrows'} //Nav options (arrow, dot)
								paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
								maxSizePagination={6} //Size pagination
								defaultPaginationNumber={1} //Current pagination when start app
								paginationGrouped={10} //Grouped by page
								detailRow={false} //Open column detail with left arrow
								data={datos} //Data for table
							/>
						</div>
						<Modal
							onClose={this.showModal}
							show={this.state.show}
							title="Template de la notificación"
						>
							<div>
								<p>
									<span>Asunto:</span> <p>{this.state.subject}</p>
								</p>
								<p>
									<span>Mensaje:</span>
									<p>{this.state.NotificationDetail}</p>
								</p>
							</div>
						</Modal>
					</div>
				)}
			</div>
		);
	}
}

NotificationsList.propTypes = {
	actions: PropTypes.object.isRequired,
	notifications: PropTypes.object,
	error: PropTypes.object,
	notificationDel: PropTypes.object
};

const mapStateToProps = state => ({
	notifications: state.synergy.notifications,
	notificationDel: state.synergy.notificationDel,
	error: state.synergy.err,
	isLoading: state.synergy.isLoading
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(NotificationActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(NotificationsList);
