import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import configureStore from './store/configureStore';
import './index.scss';
import './siicon.scss';
import './colors.scss';

import App from './router/App';

import { CountryProvider } from './context/countries-context';
import { LoaderProvider } from './context/loader-context';

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <CountryProvider>
            <LoaderProvider>
                <App />
            </LoaderProvider>
        </CountryProvider>
    </Provider>
    , document.getElementById('root'));
