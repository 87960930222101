import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/indexGeneral';
import config from '../config.js';
import { getCookie } from '../utils/cookies';

export function* getSubscriptions(action) {
    const api = API.create(config.url);
    try {
        const { params } = action;
        api.setHeader('Authorization', 'Bearer ' + getCookie('token'))
        let subs = yield call(api.auroraSubscription.getSubscription, params);
        yield put({ type: types.GET_SUBSCRIPTIONS_RECEIVED, data: subs });
    } catch (error) {
        yield put({ type: types.GET_SUBSCRIPTIONS_FAILED, error });
    }
}

export function* deleteSubscription(action) {
    const api = API.create(config.url);
    try {
        const { params } = action;
        api.setHeader('Authorization', 'Bearer ' + getCookie('token'))
        let resp = yield call(api.auroraSubscription.deleteSubscription, params);
    
        yield put({ type: types.DELETE_SUBSCRIPTION_RECEIVED, data: resp });
    } catch (error) {
        
        yield put({ type: types.DELETE_SUBSCRIPTION_FAILED, error });
    }
}