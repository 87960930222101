import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { getCookie } from '../../../utils/cookies';
import { countryOptions, statesOptions } from '../data/data';
import { ChangeStateBookin, ChangeStateBooking, GetSiigerByEmail, SendEmail } from '../helpers/SiigerHelper';
import SiigerTag from '../sshared/SiigerTag/siigerTag';

class SviajePerfil extends Component { 

    //si viene desde mensajes, el prop es el booking del usuairo logueado. this.props.


    constructor(props) {
	    super(props);
        let _email = getCookie('email');

        let _tengoBooking = (this.props.booking != undefined) && (this.props.booking.length > 0) 
        let _bookingState = (_tengoBooking && this.props.bookingIndex != undefined) ? this.props.booking[this.props.bookingIndex].state : 0;
      
        this.state = {
            goToPerfilAlojamiento: false,
            isOwner:  (_email == this.props.contact.email),            
            bookingState: _bookingState,
            tengoBooking: _tengoBooking,
            goTOaprobar: false
        }
    }

    GoToPerfilAlojamiento(p){
        this.setState({ goToPerfilAlojamiento: true });
    }

    //Esto es si viene de la vista de mensajes
    //si es owner de la notificacion, puede aprobarla
    AprobarHuesped(b)
    {
        let statusAprobado = 2;
        let _place_id = this.props.booking[this.props.bookingIndex].placeId;
        let _booking_id = this.props.booking[this.props.bookingIndex].id;
        let _booking_index = this.props.bookingIndex;       
        
        this.setState({
            ...this.state,
            goTOaprobar: true
        });
    }

    //Esto es si viene de la vista de mensajes
    //si es owner de la notificacion, puede recharla
    RechazarHuesped(b)
    {
        let statusRechazado = 3;
        let _place_id = this.props.booking[this.props.bookingIndex].placeId;
        let _booking_id = this.props.booking[this.props.bookingIndex].id;
        let _booking_index = this.props.bookingIndex;
        let _booking = this.props.booking[this.props.bookingIndex];

        ChangeStateBooking(this.props.contact.email, _place_id, _booking_id, _booking_index,  statusRechazado).then(() => {
            
            GetSiigerByEmail(_booking.guestEmail).then((response) => {
                
                let data = {
                    telefono: '',
                    email: '',
                    direccion: '',
                    msg: 'LA SOLICITUD FUE RECHAZADA',
                    guest: response.data.document,
                    host: this.props,
                    subject: 'Alojamiento rechazado',
                    emailFrom: this.props.contact.email,
                    emailTo: _booking.guestEmail,
                    booking: _booking,
                    status: statusRechazado                
                }

                SendEmail(data);
            })
        });
        
        this.setState({
            ...this.state,
            bookingState: statusRechazado
        });   
    }

    //Esto es si viene de la vista de mensajes
    //si es owner de la notificacion, puede cancelarla, el dueño de la reservacion
    CancelarReservacion(b)
    {
        let statusCancelado = 4;
        let _place_id = this.props.booking[this.props.bookingIndex].placeId;
        let _booking_id = this.props.booking[this.props.bookingIndex].id;
        let _booking_index = this.props.bookingIndex;
        let _booking = this.props.booking[this.props.bookingIndex];

        ChangeStateBooking(this.props.contact.email, _place_id, _booking_id, _booking_index, statusCancelado).then(
            
            GetSiigerByEmail(_booking.guestEmail).then((response) => {                
                let data = {
                    telefono: '',
                    email: '',
                    direccion: '',
                    msg: 'LA SOLICITUD FUE CANCELADA',
                    guest: response.data.document,
                    host: this.props,
                    subject: 'Alojamiento cancelado',
                    emailFrom: this.props.contact.email,
                    emailTo: _booking.guestEmail,
                    booking: _booking,
                    status: statusCancelado                
                }

                SendEmail(data);
            })
        );

        this.setState({
            ...this.state,
            bookingState: statusCancelado
        });            
    }

    render() {

        function GetStateDescripcion(s)
        {
            let objIndex = statesOptions.findIndex((obj => obj.id == s));
            let Ciudad = (statesOptions[objIndex] == undefined) ? '' : statesOptions[objIndex].label;    
            return Ciudad;
        }
        
        function GetPaisDescripcion(s)
        {
            let objIndex = countryOptions.findIndex((obj => obj.id == s));
            let Pais = (countryOptions[objIndex] == undefined) ? '' : countryOptions[objIndex].label;    
            return Pais;
        }
        
        function GetInitialName(n){
            const myArray = n.split(" ");
            let _ini = myArray[0][0] + myArray[myArray.length - 1][0];
            return _ini;
        }

        if (this.state.goToPerfilAlojamiento) {
            return (
                <Redirect to={{ pathname: "/siigers/perfil/alojamiento", state: this.props }} />
            )
        }

        if (this.state.goTOaprobar)
        {
            return (
                <Redirect to={{ pathname: "/siigers/viajes/aprobar", state: this.props }} />
            )            
        }

        return(
            <>
                <div className='scard'>  
                    <div className='row'>
                        {this.props.fromMessage && (this.state.tengoBooking)                      
                            ?<>
                                {{
                                    1: <SiigerTag text="POR APROBAR" type="waiting" />,
                                    2: <SiigerTag text="APROBADO" type="success" />,                                    
                                    3: <SiigerTag text="RECHAZADA" type="refused" />,
                                    4: <SiigerTag text="CANCELADA" type="canceled" />,
                                    5: <SiigerTag text="FINALIZADA" type="success" />
                                }[this.state.bookingState]}
                            </>
                            :<></>
                        }

                        <h4>
                            <b>
                                {
                                    (this.props.travel == undefined)
                                    ?
                                        ((this.props.host.type == 1) ? "Apartamento en " : "Casa en " ) + 
                                        GetPaisDescripcion(this.props.host.country) + ", " +
                                        GetStateDescripcion(this.props.host.state) + "."  
                                    :
                                        ((this.props.host.type == 1) ? "Apartamento en " : "Casa en " ) + 
                                        GetPaisDescripcion(this.props.host.country) + ", " +
                                        GetStateDescripcion(this.props.host.state) + "."    
                                }
                            </b>
                        </h4>
                    </div>

                    {/* Descripcion alojamiento */}
                    <div className='row'>
                        <div className='col-1'>

                            {{
                                1 : <i className="fa-solid fa-hotel fa-2xl alojamiento-icon"></i>,
                                2 : <i className="fa-solid fa-house-chimney-user fa-2xl alojamiento-icon"></i>                                                 
                            }[this.props.host.type]}
                        </div>

                        <div className='col'>                                
                            <div className='alojamiento-descripcion'>
                                {{
                                    1: <label>Habitacion individual</label>,
                                    2: <label>Habitacion compartida</label>,                                                                                                      
                                }[this.props.host.place[0].placetype]}                                  
                                                                        
                                <label>{this.props.host.place[0].peoplecount} camas</label>
                                <label><b>{this.props.host.name}</b></label>
                            </div>
                        </div>                    
                        <div className='col-1'>
                            {this.props.hideActions
                                ? <></>
                                :<div className='col-1 align-center'>
                                    <siigo-button-icon-atom
                                        color="tertiary"
                                        size="xl"
                                        icon="fas fa-angle-right"
                                        onClick={ (e) => this.GoToPerfilAlojamiento(this.props)}
                                    ></siigo-button-icon-atom>
                                </div>
                            }
                        </div>
                    </div> 

                    <hr className='alojamiento-linea'></hr>
                    {/* Descripcion duenio */}
                    <div className='row'>
                        <div className='col-1'>
                            <div><div className='avatar-circle avatar-circle-border'> { GetInitialName(this.props.name) } </div></div>
                        </div>
                        <div className='col width-100'>
                            <div className='alojamiento-duenio-descripcion'>
                                <h4><b>{ this.props.name }</b></h4>
                                {/* <label>Product VP</label>
                                <label>Investigación y Desarrollo</label> */}
                                <label>{ this.props.job }</label>
                                <label>{ this.props.division }</label>
                                <label><b>5 (0)  </b><i className="fa-solid fa-star"></i></label>
                            </div>                                        
                        </div>
                    </div> 

                    {this.props.hideActions && !this.props.fromMessage                  
                        ?   <>
                            <hr className='alojamiento-linea'></hr>
                            <div className='col alojamiento-host-descripcion'>
                                <label>
                                    <b>{ this.props.host.name }</b>
                                </label>
                                <hr className="siiger"/>
                                <label>
                                    { this.props.host.description }
                                </label>
                            </div>  
                            </>
                        : <></>
                    }

                    {/* Data de travel solo si se ve desde mensaje  */}
                    {(this.props.fromMessage && this.state.tengoBooking)

                        ?   <>
                                <hr className='alojamiento-linea'></hr>
                                <div className='row'>                                                               
                                    <div className='col-1'>
                                        <i className="fa-solid fa-route fa-2xl alojamiento-icon"></i>
                                    </div>
                                    
                                    <div className='col'>                                
                                        <div className='alojamiento-descripcion'>                                                                                                                  
                                            <label><b>Desde: </b>{ this.props.booking[this.props.bookingIndex].dateFrom } </label>
                                            <label><b>Hasta: </b>{ this.props.booking[this.props.bookingIndex].dateUntil }</label>
                                        </div>
                                    </div>  
                                </div>
                            </>
                        :<></>
                    }

                    {(this.props.fromMessage && this.state.isOwner && (this.state.tengoBooking) && (this.state.bookingState == 1) ) 
                     ?<>
                        <hr className="siiger"/>

                        <div className='row'>
                            <SiigoButtonAtom
                                id='btnAprobarHuesped'
                                color="primary-green"
                                size="l"
                                type="button"
                                text="Aprobar huesped"
                                icon="fas"     
                                onClick = {() => this.AprobarHuesped(this.props)}                               
                            /> 
                        </div>

                        <div className='row'>
                            <SiigoButtonAtom
                                id='btnRechazarHuesped'
                                color="primary-blue"
                                size="l"
                                type="button"
                                text="Rechazar huesped"
                                icon="fas"     
                                onClick = {() => this.RechazarHuesped(this.props)}                               
                            /> 
                        </div>
                     </>
                     :<></>
                    }

                    {/* 
                        Feature: Cancelar hospedaje
                            As a Owner

                            Scenario Si vengo desde la seccion de mensajes 
                                Given y tengo la reserva aprobada
                                then dejo cancelar al owner

                        Feature: Cancelar hospedaje
                            As a huesped

                            Scenario Si vengo desde la seccion de mensajes                         
                                Given y tengo la reserva por aprobar
                                then dejo cancelar al huesped
                    */}         

                    {
                        ((this.state.tengoBooking)  && 
                            (
                                (this.props.fromMessage && this.state.isOwner &&  (this.state.bookingState == 2)) 
                                ||
                                (this.props.fromMessage && !this.state.isOwner &&  (this.state.bookingState == 1))                             
                            )
                        )
                     ?<>
                            <SiigoButtonAtom
                                id='btnCancelarReservacion'
                                color="primary-green"
                                size="l"
                                type="button"
                                text="Cancelar reservación"
                                icon="fas"
                                onClick = {() => this.CancelarReservacion(this.props)}                               
                            /> 
                     </>
                     :<></>
                    }           
                </div>         
            </>
        );
    }
}


export default SviajePerfil;