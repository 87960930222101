import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/indexMarket';
import APIGeneral from '../services/api/indexGeneral';
import config from '../config.js';


export function* getCloudLogs(action) {
    const api = API.create();
    try {
        
        const { params } = action;

        api.setHeader('userdta', 'stark:iidkju===dk0');
        let auth = yield call(api.market.auth);
        api.setHeader('auth', auth.data);
        let cloudLogs = yield call(api.market.getCloudLogs, params);

        yield put({ type: types.CLOUD_LOGS_RECIVED, data: cloudLogs });
    } catch (err) {
        yield put({ type: types.CLOUD_LOGS_FAILED, err });
    }
}


export function* sendExcelObject(action) {
	const api = APIGeneral.create(config.urlExcelApi);
	try {
		const { params } = action;
		let auth = yield call(api.accountant.authExcel, { username: 'admin', password: 'admin' });
		api.setHeader('authorization', auth.data.token);
		let excel = yield call(api.accountant.sendExcelObject, params);
		yield put({ type: types.SEND_EXCEL_OBJECT_RECEIVED, data: excel });
	} catch (err) {
		yield put({ type: types.SEND_EXCEL_OBJECT_FAILED, err });
	}
}
