import React from 'react';
import { useAuth } from '../../context/auth-context';
import BlackListEmail from './index';

const BlackListEmailWrapper = (props) => {
    const { checkAuth } = useAuth();
    return <BlackListEmail {...props} checkAuth={checkAuth} />;
};

export default BlackListEmailWrapper;
