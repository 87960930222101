import React, { Component } from 'react';

export const providerStates = {
    Active: "Activa",
    Inactive: "Inactiva",
}

export const providerStatesClasses = {
    Active: "successState",
    Inactive: "errorState",
}

export class ProviderStates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {

        var state = this.renderStates();
        return (
            <span className={state.clase === undefined ? "" : state.clase}>
                {state.value === undefined ? "N/A" : state.value}
            </span>
        );
    }


    renderStates() {

        var selector = this.props.selector;
        var providerState = this.props.providerState;
        var valueSelector = this.props.valueSelector;

        var resp = {
            value: "",
            clase: "",
        };

        selector = selector === undefined ? "" : selector;
        valueSelector = valueSelector === undefined ? "N/A" : String(valueSelector);

        if (providerState) {

            if (selector === "state") {
                resp.clase = valueSelector === "N/A" ? "" : providerStatesClasses[valueSelector];
                resp.value = valueSelector === "N/A" ? valueSelector : providerStates[valueSelector];
            } else {
                resp.value = valueSelector;
            }

        }
        else {
            resp.value = valueSelector;
        }

        return resp;

    }
}