import getEndPoint from "../../../utils/endpoints";

export default function (api) {
    const urlExport = getEndPoint('exportExcelReport');

    return {
        getConfigReport: params => api.get(`general-report/getConfigReport/${params.id}`),
        filterDataBySource: body => api.post('general-report/filterDataBySource', body),
        downloadReportDataBySource: body => api.post(urlExport, body),
    };
}