import axios from 'axios';
import config from '../../../config';

const sendMail = (mail) => {
  return axios.post(`${config.urlMailProvider}${config.pathSendMail}`, mail).then((res) => res.data);
};

const resetPassword = async (options) => {
  return axios(options);
};

export default {
  sendMail,
  resetPassword,
};