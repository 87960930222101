import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as auroraConfigProviderActions from '../../actions/auroraConfigProvider/auroraConfigProviderActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../layout/modals/detailSubscription/index';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalResponse from '../layout/modals/modalResponse';
import svgs from '../layout/icons';
import './style.scss';
import { getCookie } from '../../utils/cookies';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';
import { AxiosConfig } from "./services";

const columns = [
    {
        name: 'Proveedor',
        selector: 'name',
        sortable: true
    },
    {
        name: 'Descripción',
        selector: 'description',
        sortable: true,
        filterDDL: true
    },
    {
        name: 'País',
        selector: 'country',
        sortable: true,
        filterDDL: true
    },
    {
        name: 'Moneda',
        selector: 'currency',
        sortable: true,
        filterDDL: true
    },
    {
        name: 'Estado',
        selector: 'state',
        sortable: true,
        providerState: true,
        filterDDL: true
    }
];

let deleteProvider = false;
let accessDelete = false;
let accessEdit = false;
let accessCreate = false;
class AuroraConfigProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disableBtnDelete: false,
            provider: {},
            fromDate: undefined,
            toDate: undefined,
            datos: [],
            isLoading: true,
            name: "",
        };
    }

    showModalDisableProvider = (e) => {
        this.setState({ showDisable: !this.state.showDisable });
    }

    disableProvider = () => {
        this.setState({ disableBtnDelete: true });
        const copyObj = Object.assign({}, this.state.provider);
        copyObj.state = "Inactive";
        this.updateProvider(copyObj);
        deleteProvider = true;
    }

    createLogActivity(object) {
        let aditionalData = { disable_id: object._id }

        let params = {
            nameComponent: "Configuracion de Proveedores",
            nameUser: getCookie('name'),
            action: "Disable Provider",
            date: (new Date()).toISOString(),
            aditionalData: JSON.stringify(aditionalData),
        }

        this.props.actions.createLog(params);
    }

    updateProvider(obj) {
        AxiosConfig.updateProviderConfig(obj).then(resp => {
            this.setState({ disableBtnDelete: false });
            this.showModalDisableProvider();
            ModalResponse({
                title: 'Proveedor Deshabilitado',
                res: 200,
            });
            this.createLogActivity(resp.data);
            let name = this.state.name ? this.state.name : "";
            this.loadData(name);

        }).catch(err => {
            this.setState({ disableBtnDelete: false });
            this.showModalDisableProvider();
            ModalResponse({
                title: 'Deshabilitar Proveedor',
                text: "Ha ocurrido un problema, inténtelo más tarde o comuníquese con el Administrador.",
                res: 404
            });
        });
    }


    loadData(filter = "") {
        this.setState({ datos: [], isLoading: true })
        AxiosConfig.getAllProvidersConfig(filter).then(resp => {
            this.setState({ datos: resp.data.providers, isLoading: false });
        }).catch(err => {
            this.setState({ datos: [], isLoading: false });
            ModalResponse({
                title: '¡Error!',
                text: 'Se ha producido un error al tratar de cargar los datos. Comuníquese con el Administrador.',
                res: 404
            });
        });
    }

    componentDidMount() {
        document.title = "Configuración de Proveedores";
        accessCreate = getAccess(this.props.parentPath, ACCESS.create_provider);
        accessEdit = getAccess(this.props.parentPath, ACCESS.edit_provider);
        accessDelete = getAccess(this.props.parentPath, ACCESS.disable_provider);
        this.loadData();
    }

    btnDisableProvider = (data) => {
        if (data.state != "Inactive") {
            this.setState({ provider: data, showDisable: true, openDisable: true });
        }
        else {
            ModalResponse({
                title: '¡Proveedor Deshabilitado!',
                text: 'Este Proveedor ya se encuentra Deshabilitado',
                res: 404
            });
        }
    }

    focusInput = (e) => {
        e.preventDefault();
        e.currentTarget.placeholder = '';
        e.currentTarget.classList.remove("inputError");
        e.currentTarget.classList.remove("inputOk");
        e.currentTarget.classList.add('inputFocus');
        if (e.currentTarget.previousSibling) {
            e.currentTarget.previousSibling.classList.remove('inputError');
            e.currentTarget.previousSibling.classList.remove('inputOk');
            e.currentTarget.previousSibling.classList.add('labelModal');
        }
    };

    blurInput = (e) => {
        e.preventDefault();
        if (e.currentTarget.value == '') {
            e.currentTarget.classList.remove("inputOk");
            e.currentTarget.classList.add('inputError');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelOk');
                e.currentTarget.previousSibling.classList.add('labelError');
            }
        } else {
            e.currentTarget.classList.remove("inputError");
            e.currentTarget.classList.add('inputOk');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelError');
                e.currentTarget.previousSibling.classList.add('labelOk');
            }
        }
    };

    renderModalDisable() {
        return (
            <div>
                <div className="uxDetailDataFilterTitle" style={{ textAlign: "center" }}>
                    <h4 >¿Esta seguro de que desea deshabilitar el proveedor <b>{this.state.provider.name}</b>?</h4>
                </div>
                <div className="uxDetailContainer" style={{ textAlign: "center" }}>
                    <button
                        style={{ margin: "20px", opacity: this.state.disableBtnDelete ? "0.5" : "1.0" }}
                        disabled={this.state.disableBtnDelete}
                        className="orange-fill"
                        type="button"
                        onClick={this.disableProvider}
                    >Deshabilitar
                    </button>
                    <button
                        style={{ margin: "20px", opacity: this.state.disableBtnDelete ? "0.5" : "1.0" }}
                        className="red-fill"
                        disabled={this.state.disableBtnDelete}
                        type="button"
                        onClick={this.showModalDisableProvider}
                    >Cancelar
                    </button>
                </div>
            </div >
        );
    }

    filtrarProviders = e => {
        e.preventDefault();
        let name = this.state.name;
        if (name !== '' && name !== undefined && name !== null) {
            this.loadData(name);
        }
        else {
            ModalResponse({
                title: '¡Hey!',
                text: 'Debe ingresar el nombre del Proveedor para poder realizar la búsqueda.',
                res: 404
            });
        }
    };


    cleanFilter = e => {
        e.preventDefault();
        var name = document.getElementById('name');
        name.value = '';
        name.classList.remove("inputError", "inputOk", "inputFocus");
        this.setState({ name: "" })
        this.loadData();
    };

    handleSetValue(e) {
        this.setState({ name: e.target.value });
    }

    render() {
        let { error } = this.props;

        const btnActions = {
            btnEdit: accessEdit ? '/configprovider/edit/' : null,
            btnDisableProvider: accessDelete ? this.btnDisableProvider : null,
        };


        return (
            <div>
                <div>
                    <h3>Configuración de Proveedores</h3>
                    {accessCreate ?
                        (<a href="/configprovider/create" className="a-button green-fill">
                            <img className="icon-img icon-mas" src={svgs['mas']} alt="" />
                        Crear
                        </a>) : null}

                    <form onSubmit={this.filtrarProviders}>
                        {/* <div className="row divFilter">
                            <DatePicker handleDate={this.handleDate} from={this.state.fromDate} to={this.state.toDate} />
                        </div> */}

                        <div className="row divFilter">

                            <div className="input-container-accountant column4">
                                <label>Nombre Proveedor</label>
                                <input
                                    className="inputFilter customEnterText"
                                    id="name"
                                    name="name"
                                    type="text"
                                    placeholder=""
                                    onFocus={(e) => this.focusInput(e)}
                                    onBlur={(e) => this.blurInput(e)}
                                    onChange={(e) => this.handleSetValue(e)}
                                    value={this.state.name}
                                />
                            </div>

                            <div className="column4">
                                <div className="row">
                                    <div className="column2">
                                        <button className="green-fill btnBuscar" id="submitFilterButton" type="submit">
                                            <div className="row">
                                                <div className="column2">
                                                    <span className="siicon icon-search searchMarket"></span>
                                                </div>
                                                <div className="column2">
                                                    <span>Buscar</span>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="column2">
                                        <button
                                            onClick={this.cleanFilter}
                                            className="orange-fill"
                                            type="button"
                                        >
                                            Limpiar
                                         </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {error && <h4>Error: {error.response.data}</h4>}
                {(this.state.isLoading) ? (
                    <Loader />
                ) : (
                        <div>

                            <div className="divTable">
                                <TableReport
                                    columns={columns} // Data columns to table
                                    tabs={false} //Enable tabs
                                    tabsData={[]} //Load data to tabs check option on tabsData const
                                    headerCounterTab={true} //Counter tab option
                                    btnActions={btnActions}
                                    actionButtons={accessEdit || accessDelete} //Enable buttons (edit, delete, copy, open)
                                    pageSize={30} // Enable page size
                                    pagination={true} //Enable pagination (true, false)
                                    paginationNav={'arrows'} //Nav options (arrow, dot)
                                    paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
                                    maxSizePagination={6} //Size pagination
                                    defaultPaginationNumber={1} //Current pagination when start app
                                    paginationGrouped={10} //Grouped by page
                                    detailRow={false} //Open column detail with left arrow
                                    data={this.state.datos} //Data for table
                                />
                            </div>

                            <Modal
                                onClose={this.showModalDisableProvider}
                                show={this.state.showDisable}
                                title="Deshabiltar Proveedor"
                                open={this.state.openDisable}>
                                {this.renderModalDisable()}
                            </Modal>

                        </div>
                    )}
            </div>
        );

    }


}

AuroraConfigProvider.propTypes = {
    actions: PropTypes.object.isRequired,
    log: PropTypes.object,
};

const mapStateToProps = state => ({
    log: state.logs.log,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(auroraConfigProviderActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(AuroraConfigProvider);