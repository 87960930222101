import React, { Component } from 'react';
import config from '../../config';
import ModalResponse from '../layout/modals/modalResponse';
import './style.scss';
import * as auroraActions from '../../actions/aurora/auroraActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as CryptoJS from 'crypto-js';
import Loader from '../tools/loader';
import svgs from './../layout/icons/index'


import { useTranslation } from "react-i18next";

function i18nTranslation(Component) {
  return function WrappedComponent(props) {
    const { t, i18n } = useTranslation();
    return <Component {...props} t={t} i18n={i18n} />;
  };
}

class LinkAurora extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {},
      generateLink: true,
      isForbbiden: false,
      disable: true,
      disableSelected: false,
      disableSelectedProduct: false,
      productListCO: JSON.parse(config?.productListCO || "{}")
    };
    this.t = props.t;
  }
  copiarAlPortapapeles = (id_elemento) => {
    let link = document.getElementById(id_elemento);
    var aux = document.createElement('input');
    if (link.value !== '') {
      aux.setAttribute('value', link.value);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand('copy');
      document.body.removeChild(aux);
      document.getElementById('spanLink').innerText = this.t('linkAurora.clipBoard.onCopyLink');
    }
    else {
      document.getElementById('spanLink').innerText = this.t('linkAurora.clipBoard.toCopyLink');
    }
  };

  cleanFilter = e => {
    e.preventDefault();
    let rut = document.getElementById('rut');
    rut.value = '';
    rut.classList.remove("inputError", "inputOk", "inputFocus");

    let razonSocial = document.getElementById('razonSocial');
    razonSocial.value = '';
    razonSocial.classList.remove("inputError", "inputOk", "inputFocus");

    let ciudad = document.getElementById('ciudad');
    ciudad.value = '';
    ciudad.classList.remove("inputError", "inputOk", "inputFocus");

    let direccion = document.getElementById('direccion');
    direccion.value = '';
    direccion.classList.remove("inputError", "inputOk", "inputFocus");

    let valor = document.getElementById('valor');
    valor.value = '';
    valor.classList.remove("inputError", "inputOk", "inputFocus");

    let pais = document.getElementById('pais');
    pais.value = '';

    let NumFactura = document.getElementById('NumFactura');
    NumFactura.value = '';
    NumFactura.classList.remove("inputError", "inputOk", "inputFocus");

    let NomProducto = document.getElementById('NomProducto');
    NomProducto.value = '';
    NomProducto.classList.remove("inputError", "inputOk", "inputFocus");

    let name = document.getElementById('name');
    name.value = '';
    name.classList.remove("inputError", "inputOk", "inputFocus");

    let lastName = document.getElementById("lastName");
    lastName.value = "";
    lastName.classList.remove("inputError", "inputOk", "inputFocus");

    let email = document.getElementById('email');
    email.value = '';
    email.classList.remove("inputError", "inputOk", "inputFocus");

    let cellPhone = document.getElementById('cellPhone');
    cellPhone.value = '';
    cellPhone.classList.remove("inputError", "inputOk", "inputFocus");

    let expDate = document.getElementById('expDate');
    expDate.value = '';
    expDate.classList.remove("inputError", "inputOk", "inputFocus");

    let jwtTxt = document.getElementById('jwtTxt');
    jwtTxt.value = '';
    jwtTxt.classList.remove("inputError", "inputOk", "inputFocus");
    this.copiarAlPortapapeles('jwtTxt');

    this.setState({
      formData: {},
      generateLink: false,
      disable: true,
      disableSelected: false,
      disableSelectedProduct: false,
    })
    this.props.actions.cleargetShortedUrl();
  };

  handleChangeselect = (evt) => {
    const data = evt.target.validity.valid ?
      evt.target.type === 'select-one' ? evt.target.options[evt.target.selectedIndex].text : evt.target.value
      : this.state[evt.target.name];

    this.setState(
      {
        ...this.state,
        formData: {
          ...this.state.formData,
          [evt.target.name]: data,
          valor: evt.target.value
        },
        disableSelectedProduct: true,
      }
    );
  }

  handleChange = (evt) => {
    const data = evt.target.validity.valid
      ? evt.target.value
      : this.state[evt.target.name];

    this.setState(
      {
        ...this.state,
        formData: {
          ...this.state.formData,
          [evt.target.name]: data
        }
      }
    );
  };

  modalValidations(country, event, hasError) {
    let modal = false
    if (hasError) {
      ModalResponse({
        title: this.t('linkAurora.modal.title.validateData'),
        text: this.t('linkAurora.modal.text.validateDataFields'),
        res: 404,
      });
      modal = true
    }
    if (country !== 'CO') {
      if (!event.target.email.validity.valid) {
        ModalResponse({
          title: this.t('linkAurora.modal.title.validateEmail'),
          text: this.t('linkAurora.modal.text.validateDataEmail'),
          res: 404,
        });
        modal = true
      }
      if (country === 'CL' && parseInt(event.target.valor.value) < 2504) {
        ModalResponse({
          title: this.t('linkAurora.modal.title.validateAmount'),
          text: this.t('linkAurora.modal.text.validateDataAmount'),
          res: 404,
        });
        modal = true
      }
    }
    return modal
  }

  isDifferentValidation(event) {
    let resName = this.deleteSpecialCharacter(event.target.name.value);
    let resLastName = this.deleteSpecialCharacter(event.target.lastName.value);
    let resCompanyName = this.deleteSpecialCharacter(event.target.razonSocial.value);
    let resCompanyAddress = this.deleteSpecialCharacter(event.target.direccion.value);

    let isDifferent =
      resName.isDifferent &&
      resLastName.isDifferent &&
      resCompanyName.isDifferent &&
      resCompanyAddress.isDifferent;

    let flagInfoChange = !isDifferent ? "1" : "0";

    return flagInfoChange
  }

  formattedDate(date) {
    const [year, month, day] = date.split('-');
    const formattedDate = `${year}-${month}-${day}T23:59:00`;
    return formattedDate
  }

  onHandleCreate = (event) => {
    event.preventDefault();
    const hasError = this.validateFormFields();

    if (this.modalValidations(this.state.formData.pais, event, hasError)) return;

    let accountSiigo =
      config.siigoaccount_aurora != undefined
        ? config.siigoaccount_aurora
        : '00001';


    const url = config.urlAurora;
    const value = (parseInt(this.state.formData.valor)), amount = value;
    const currency = JSON.parse(config.currency)[this.state.formData.pais];
    const siigoResponseUrl = JSON.parse(config.siigoResponseUrl)[this.state.formData.pais];
    const flagInfoChange = this.isDifferentValidation(event)
    const productName = this.state.formData.NomProducto
    const origin = JSON.parse(config.origins)[this.state.formData.pais];
    const EXPDATE = this.formattedDate(event.target.expDate?.value)

    this.state.generateLink = true
    let AuroraHashParams = {
      payerCountry: event.target.pais.value,
      payerCity: event.target.ciudad.value,
      origen: origin,
      productId: event.target.NumFactura.value,
      productName: productName,
      productDesc: event.target.NumFactura?.value + " " + productName,
      productList: [],
      value: value,
      adminName: event.target.name.value,
      adminLastName: event.target.lastName.value,
      adminMail: event.target.email.value,
      adminId: event.target.rut.value,
      nit: event.target.rut.value,
      companyName: event.target.razonSocial.value,
      companyAddress: event.target.direccion.value,
      phoneNumber: event.target.cellPhone.value,
      siigoaccount: accountSiigo,
      amount: amount,
      firma: CryptoJS.SHA256(
        accountSiigo + "-" + value + "-" + amount + "-" + event.target.email.value + "-" + EXPDATE
      ).toString(),
      currency,
      siigoResponseUrl,
      flagInfoChang: flagInfoChange,
      expDate: EXPDATE
    }

    let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(AuroraHashParams));

    let auroraEURL = url + "?s=" + CryptoJS.enc.Base64.stringify(stringifiedData);
    if (this.state.generateLink) { this.props.actions.getShortedUrl({ url: auroraEURL, type: 1 }) };
  };

  validateFormFields() {
    const { valor, ciudad, NumFactura, NomProducto, name, lastName, email, rut, razonSocial, direccion, cellPhone, pais, } = this.state.formData;
    let hasError = pais != 'CO' && [valor, ciudad, NumFactura, NomProducto, name, email, lastName, rut, razonSocial, direccion, cellPhone, pais].some(field => !field || !field.length)
    if (hasError) { this.setState({ generateLink: false }) }
    if (pais === 'CO' && !NomProducto) { hasError = true }
    return hasError
  }

  deleteSpecialCharacter = (word) => {
    let wordModify = word.replace(
      /([^0-9a-zA-ZÁÉÍÓÚáéíóúñÑ.,~!/°:;@#\\$%&_-\s])/g,
      ""
    );
    let string1 = word.normalize("NFD");
    let string2 = wordModify.normalize("NFD");
    let isDiffernt = string1 === string2;
    return { word: wordModify, isDifferent: isDiffernt };
  };

  countrySelect() {
    let svgFlag = this.state.formData.pais == "CO" ? "colombia" : this.state.formData.pais == "CL" ? "chile" : "";
    return <>
      <div className={svgFlag ? 'flagSelect' : ''}>
        {svgFlag &&
          <div>
            <img className="cuntrySelectFlag" src={svgs[svgFlag]} alt="" />
          </div>}
        <select
          disabled={this.state.disableSelected}
          id="pais"
          name="pais"
          onChange={this.handleChange}>

          <option value="">{this.t('linkAurora.Select.defaultValue')}</option>
          <option value="CL">{this.t('linkAurora.Select.CL')}</option>
          <option value="CO">{this.t('linkAurora.Select.CO')} </option>
        </select>
      </div>
    </>
  }

  productCOSelect() {
    let productList = []
    this.state.productListCO.map((data, index) => {
      productList.push(<option key={index} value={data.value}>{data.product}</option>)
    })
    return (
      <>
        <select
          disabled={this.state.disableSelectedProduct}
          id="NomProducto"
          name="NomProducto"
          onChange={this.handleChangeselect}>
          <option value="">{this.t('linkAurora.Select.defaultProductList')}</option>
          {productList}
        </select>
      </>
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.generateLink !== prevState.generateLink) { this.setState({ generateLink: this.state.generateLink }) }
    if (this.props.auroraEURL !== prevProps.auroraEURL) { this.setState({ isForbbiden: this.props.auroraEURL?.data.message == "FORBIDDEN" ? true : false }) }
    if (this.state.formData !== prevState.formData) {
      let disable = this.state.formData.pais === 'CO' || !this.state.formData.pais ? true : false
      this.setState({ disable })
      if (this.state.formData.pais) {
        let disableSelected = true
        this.setState({ disableSelected })
      }
    }
  }

  expDate() {
    return <>
      <div className="input-container">
        <h4 className='expDateLabel'>{this.t('linkAurora.form.expDateLabel')}</h4>
        <input
          disabled={this.state.formData.pais === undefined || this.props.auroraEURL !== undefined}
          className="inputLink"
          type="date"
          name="expDate"
          id="expDate"
          onChange={this.handleChange}
        />
      </div>
    </>
  }

  componentDidMount() {
    document.title = 'Generador Enlaces Aurora';
    this.props.actions.cleargetShortedUrl();
  }

  render() {
    let { error, isLoading, auroraEURL } = this.props;
    let { generateLink, isForbbiden, disable } = this.state;
    let { valor } = this.state.formData;
    let shortedURL = '';

    try {
      if (auroraEURL && !isLoading && generateLink) {
        shortedURL = auroraEURL.data.link;
      }
    } catch (error) {
      ModalResponse({
        title: this.t('linkAurora.modal.title.linkError'),
        text: this.t('linkAurora.modal.text.tryAgain'),
        res: 404,
      });
    }

    if (isForbbiden) {
      ModalResponse({
        title: this.t('linkAurora.modal.title.linkError'),
        text: this.t('linkAurora.modal.text.tryAgain'),
        res: 404,
      });
    }

    if (error) {
      ModalResponse({
        title: this.t('linkAurora.modal.title.internalError'),
        text: this.t('linkAurora.modal.text.contractSupport'),
        res: 404,
      });
    }

    return (
      <div>
        <form onSubmit={this.onHandleCreate}>
          <h2>{this.t('linkAurora.form.auroraLinkGenerator')}</h2>
          <h4>{this.t('linkAurora.form.invoiceDataLabel')}</h4>
          <div className="grid50">
            <div className="input-container">
              <input
                disabled={disable}
                className="inputLink"
                type="number"
                name="rut"
                id="rut"
                placeholder={this.t('linkAurora.form.rut')}
                pattern="/^[0-9]+$/"

                onChange={this.handleChange}
              />
            </div>
            <div className="input-container">
              <input
                disabled={disable}
                className="inputLink"
                type="text"
                name="razonSocial"
                id="razonSocial"
                placeholder={this.t('linkAurora.form.companyName')}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="grid50">
            <div className="input-container">
              <input
                disabled={disable}
                className="inputLink"
                type="text"
                name="ciudad"
                id="ciudad"
                placeholder={this.t('linkAurora.form.city')}
                onChange={this.handleChange}
              />
            </div>
            <div className="input-container">
              {
                this.countrySelect()
              }
            </div>
          </div>
          <div className="grid50">
            <div className="input-container">
              <input
                disabled={disable}
                className="inputLink"
                type="text"
                name="direccion"
                id="direccion"
                placeholder={this.t('linkAurora.form.address')}
                onChange={this.handleChange}
              />
            </div>
            <div className="input-container">
              <input
                disabled={disable}
                className="inputLink"
                type="Number"
                name="valor"
                id="valor"
                placeholder={this.t('linkAurora.form.value')}
                pattern="[0-9]"
                value={valor || ''}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="grid50">
            <div className="input-container">
              <input
                disabled={disable}
                className="inputLink"
                type="text"
                name="NumFactura"
                id="NumFactura"
                placeholder={this.t('linkAurora.form.invoice')}
                onChange={this.handleChange}
              />
            </div>
            <div className="input-container">
              {this.state.formData.pais !== 'CO' ?
                <input
                  disabled={disable}
                  className="inputLink"
                  type="text"
                  name="NomProducto"
                  id="NomProducto"
                  placeholder={this.t('linkAurora.form.productName')}
                  onChange={this.handleChange}
                />
                : this.productCOSelect()
              }
            </div>
            {this.expDate()}
          </div>

          <h4 className='contactDataLabel'>{this.t('linkAurora.form.contactDataLabel')}</h4>
          <div className="input-container">
            <input
              disabled={disable}
              type="text"
              className="inputLink"
              name="name"
              id="name"
              placeholder={this.t('linkAurora.form.contactName')}
              onChange={this.handleChange}
            />
          </div>
          <div className="input-container">
            <input
              disabled={disable}
              type="text"
              className="inputLink"
              name="lastName"
              id="lastName"
              placeholder={this.t('linkAurora.form.contactLastName')}
              onChange={this.handleChange}
            />
          </div>
          <div className="input-container">
            <input
              disabled={disable}
              type="email"
              className="inputLink"
              name="email"
              id="email"
              placeholder={this.t('linkAurora.form.contactEmail')}
              pattern="[^@\s]{3,}@[^@\s]{2,}\.[^@\s]{2,}"
              onChange={this.handleChange}
            />
          </div>
          <div className="input-container">
            <input
              disabled={disable}
              type="number"
              className="inputLink"
              name="cellPhone"
              id="cellPhone"
              placeholder={this.t('linkAurora.form.contactPhone')}
              pattern="[0-9]"
              onChange={this.handleChange}
            />
          </div>

          <button className="button green-fill buttonGenerate">
            {' '}
            {this.t('linkAurora.form.generateLinkBtn')}
          </button>
          <button
            onClick={this.cleanFilter}
            className="orange-fill"
            type="button"
          >
            {this.t('linkAurora.form.clearBtn')}
          </button>
          {isLoading ? <Loader /> : null}
          <h4 className='linkLabel'>{this.t('linkAurora.form.linkLabel')}</h4>
          <div id="signature2" className="grid85 mbottom20">
            <input
              className="inputJWT"
              type="text"
              id="jwtTxt"
              name="jwtTxt"
              value={shortedURL}
              disabled
            />
            <div
              className="divCopy"
              onClick={(e) => this.copiarAlPortapapeles('jwtTxt')}
              onMouseLeave={this.changeSpan}
            >
              <i className="siicon icon-copiar"></i>
              <span id="spanLink">{this.t('linkAurora.form.copyQuoteLink')}</span>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  error: state.aurora.err,
  isLoading: state.aurora.isLoading,
  auroraEURL: state.aurora.shortedUrl,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(auroraActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(i18nTranslation(LinkAurora));
