
export default function (api) {
    return {
        getCountries: body => api.post('updateCountryFive9', body),
        addCountries: body => api.post('updateCountryFive9', body),
        editCountries: body => api.post('updateCountryFive9', body),
        deleteCountries: body => api.post('updateCountryFive9', body),
        getRules: body => api.post('updateRulesFive9', body),
        addRules: body => api.post('updateRulesFive9', body),
        editRules: body => api.post('updateRulesFive9', body),
        deleteRules: body => api.post('updateRulesFive9', body)
    };
}