import axios from 'axios';
import Leads from './services/Leads';
import Notification from './services/NotificationAS';
import Account from './services/Account';
import Accountant from './services/Accountant';
import Aurora from './services/Aurora';
import config from '../../config.js';
import Contracts from './services/Contracts';
import AuthJwt from './services/jwtAuth';
import Package from './services/Package';
import NewPackage from './services/NewPackage';
import AuroraSubscription from './services/AuroraSubscription';
import AuroraPayment from './services/AuroraPayment';
import AuroraTokens from './services/AuroraTokens';

const create = (baseURL = config.urlHubspot) => {
  const api = axios.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    timeout: 100000,
  });

  const leads = new Leads(api);
  const notification = new Notification(api);
  const account = new Account(api);
  const accountant = new Accountant(api);
  const aurora = new Aurora(api);
  const contracts = new Contracts(api);
  const authJwt = new AuthJwt(api);
  const packages = new Package(api);
  const newPackages = new NewPackage(api);
  const auroraSubscription = new AuroraSubscription(api);
  const auroraPayment = new AuroraPayment(api);
  const auroraTokens = new AuroraTokens(api);

  return {
    setHeader: (key, value) => { api.defaults.headers.common[key] = value; },
    removeHeader: (key) => { delete api.defaults.headers.common[key]; },
    leads,
    notification,
    account,
    accountant,
    aurora,
    contracts,
    authJwt,
    packages,
    newPackages,
    auroraSubscription,
    auroraPayment,
    auroraTokens
  };
};

export default {
  create
};
