function getEndPoint(name) {
    const endPoints = JSON.parse(localStorage.getItem('configInst'));
    for (let x = 0; x < endPoints.length; x++) {
        if (endPoints[x].name == name) {
            return endPoints[x].url;
        }
    }
}

export default function (api) {
    return {
        addPayrollPackage: (body) => api.post(getEndPoint('addPayrollPackage'), body),
        getPayrollBalance: (params) => api.get(`${getEndPoint('getPayrollBalance')}?companyKey=${params}`),
        getPayrollPurchase: (params) => api.get(`${getEndPoint('getPayrollPurchase')}?companyKey=${params}`),
        deletePayrollPackage: (body) => api.post(getEndPoint('deletePayrollPackage'), body),
    };
}
