import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalResponse from '../layout/modals/modalResponse';
import Modal from '../layout/modals/auroraModal/index';
import { createLog, getConfigOrigin, updateConfigOrigin } from '../../actions/auroraConfigOrigins/auroraConfigOriginsActions';
import OriginsList from './originsList';
import { getCookie } from '../../utils/cookies';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';
import Loader from '../tools/loader';
import './style.scss';
import { StatusCodes } from 'http-status-codes';

let access = true;

class EditConfigOrigin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newOrigin: {},
            oldOrigin: {},
            showUpdate: false,
            openUpdate: false,
            showSuccess: false,
            openSuccess: false,
            showIdErr: false,
            openIdErr: false,
            showAccErr: false,
            openAccErr: false,
            disableBtnUpdate: false,
            origins: [{ origin: "", state: "Active", }],
            isLoadingGet: true,
        };
    }

    componentDidMount() {
        document.title = "Editar Origenes";
        access = getAccess(this.props.parentPath, ACCESS.edit_origin);

        if (access) {
            if (this.props.id) {
                this.loadOrigin();
            } else {
                this.showModalIdErr();
            }
        } else {
            this.showModalAccErr();
        }
    }

    focusInput = (e) => {
        e.preventDefault();
        e.currentTarget.classList.remove("inputError");
        e.currentTarget.classList.remove("inputOk");
        e.currentTarget.classList.add('inputFocus');
        if (e.currentTarget.previousSibling) {
            e.currentTarget.previousSibling.classList.remove('inputError');
            e.currentTarget.previousSibling.classList.remove('inputOk');
            e.currentTarget.previousSibling.classList.add('labelModal');
        }
    };

    blurInput = (e, optional = true) => {
        e.preventDefault();
        if (e.currentTarget.value === '' && optional) {
            e.currentTarget.classList.remove("inputOk");
            e.currentTarget.classList.add('inputError');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelOk');
                e.currentTarget.previousSibling.classList.add('labelError');
            }
        } else {
            e.currentTarget.classList.remove("inputError");
            e.currentTarget.classList.add('inputOk');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelError');
                e.currentTarget.previousSibling.classList.add('labelOk');
            }
        }
    };

    redirectPage() {
        window.location.assign(encodeURI("/configorigins"));
    }

    renderModalX(text) {
        return (
            <div>
                <div className="uxDetailDataFilterTitle" style={{ textAlign: "center" }}>
                    <h4 >{text}</h4>
                </div>
                <div className="uxDetailContainer" style={{ textAlign: "center" }}>
                    <button
                        style={{ margin: "20px" }}
                        className="orange-fill"
                        type="button"
                        onClick={(e) => { this.redirectPage() }}
                    >Aceptar
                    </button>

                </div>
            </div >
        );
    }

    processResponseGetOrigin() {

        const resp = this.state.configOrigin;
        if (resp && resp.data && (resp.status === StatusCodes.OK || resp.status === StatusCodes.CREATED)) {
            this.setState({ oldOrigin: resp.data, origins: resp.data.origins, isLoadingGet: false });
        } else {
            this.setState({ oldOrigin: {}, isLoadingGet: false });
            this.modalAlert('¡Error!', 'Ha ocurrido un problema, inténtelo más tarde.', 404);
        }

    }

    processResponseUpdateOrigin() {
        this.setState({ disableBtnUpdate: false });
        this.showModalUpdateOrigin();
        const resp = this.state.updateOrigin;
        if (resp && (resp.status === StatusCodes.OK || resp.status === StatusCodes.CREATED)) {
            this.createLogActivity(resp.data);
            this.showModalSuccess();
        } else {
            this.modalAlert('¡Error!', 'Ha ocurrido un problema, inténtelo más tarde.', 404);
        }
    }

    componentDidUpdate() {
        if (this.props.errorOrigin !== this.state.errorOrigin) {
            this.setState({ errorOrigin: this.props.errorOrigin }, () => {
                if (this.state.errorOrigin) {
                    this.modalAlert('¡Error!', 'Ha ocurrido un problema, inténtelo más tarde.', 404);
                }
            });
        }
        if (this.props.configOrigin !== this.state.configOrigin) {
            this.setState({ configOrigin: this.props.configOrigin }, () => {
                if (this.state.configOrigin) this.processResponseGetOrigin();
            });
        }
        if (this.props.updateOrigin !== this.state.updateOrigin) {
            this.setState({ updateOrigin: this.props.updateOrigin }, () => {
                if (this.state.updateOrigin) this.processResponseUpdateOrigin();
            });
        }
    }

    showModalSuccess = () => {
        this.setState({ showSuccess: !this.state.showSuccess, openSuccess: !this.state.openSuccess });
    }

    showModalIdErr = () => {
        this.setState({ showIdErr: !this.state.showIdErr, openIdErr: !this.state.openIdErr });
    }

    showModalAccErr = () => {
        this.setState({ showAccErr: !this.state.showAccErr, openAccErropenAccErr: !this.state.openAccErr });
    }

    showModalUpdateOrigin = (e) => {
        this.setState({ showUpdate: !this.state.showUpdate });
    }

    renderModalConfirm() {
        return (
            <div>
                <div className="uxDetailDataFilterTitle" style={{ textAlign: "center" }}>
                    <h4 >¿Esta seguro que desea editar la información del origen?</h4>
                </div>
                <div className="uxDetailContainer" style={{ textAlign: "center" }}>
                    <button
                        style={{ margin: "20px", opacity: this.state.disableBtnUpdate ? "0.5" : "1.0" }}
                        disabled={this.state.disableBtnUpdate}
                        className="orange-fill"
                        type="button"
                        onClick={this.updateOrigen}
                    >Aceptar
                    </button>
                    <button
                        style={{ margin: "20px", opacity: this.state.disableBtnUpdate ? "0.5" : "1.0" }}
                        className="red-fill"
                        disabled={this.state.disableBtnUpdate}
                        type="button"
                        onClick={this.showModalUpdateOrigin}
                    >Cancelar
                    </button>
                </div>
            </div >
        );
    }

    loadOrigin() {
        this.setState({ oldOrigin: {}, isLoading: true, origins: [{ origin: "", state: "Active" }] });
        this.props.dispatch(getConfigOrigin({ _id: this.props.id }));
    }

    updateOrigen = () => {
        this.setState({ disableBtnUpdate: true });
        this.props.dispatch(updateConfigOrigin({ ...this.state.newOrigin, _id: this.props.id }));
    }

    validValue(value) {
        return value === "" || value === undefined || value === null;
    }

    validarOriginList() {
        if (this.state.origins.length < 1) return false;
        for (let i = 0; i < this.state.origins.length; i++) {
            var origins = this.state.origins[i];
            if (this.validValue(origins.state) || this.validValue(origins.origin)) {
                return false;
            }
        }
        return true;
    }

    modalAlert(title, text, res) {
        ModalResponse({ title, text, res, });
    }

    handleChange = e => {
        let origins = [...this.state.origins];
        origins[e.target.dataset.id][e.target.name] = e.target.value;
        this.setState({ origins });
    };

    addOriginItem = e => {
        e.preventDefault();
        this.setState(prevState => ({
            origins: [...prevState.origins, { state: "Active", origin: "" }]
        }));
    };

    removeOriginItem = e => {
        e.preventDefault();
        let origins = [...this.state.origins];
        origins.splice(e.target.dataset.id, 1);
        this.setState({ origins });
    };

    createLogActivity(object) {
        let aditionalData = { updated_object: object, original_object: this.state.oldOrigin }
        let params = {
            nameComponent: "Editar Origen",
            nameUser: getCookie('name'),
            action: "Edit Origins",
            date: (new Date()).toISOString(),
            aditionalData: JSON.stringify(aditionalData),
        }

        this.props.dispatch(createLog(params));
    }

    btnUpdateOrigin = (e) => {
        e.preventDefault();
        const type = e.target.originType.value;
        const descrip = e.target.description.value;
        const state = e.target.originState.value;
        if (type && descrip && state && this.validarOriginList()) {
            var origin = {
                description: descrip,
                state,
                type,
                origins: this.state.origins
            }
            this.setState({ newOrigin: origin, showUpdate: true, openUpdate: true });
        }
        else {
            this.modalAlert('¡Hey!', 'Te hacen falta campos por ingresar', 404);
        }
    }



    render() {
        const error = this.props.errorOrigin;
        return (
            <div style={{ paddingBottom: "5%" }}>
                <h3>Editar Configuración de Proveedor</h3>
                <br />
                <br />
                {error && <h4>Error: {error}</h4>}
                {(this.state.isLoadingGet) ? (<Loader />) : (
                    <div>
                        <form onSubmit={this.btnUpdateOrigin}>

                            <div className="input-container-accountant">
                                <label>Tipo de Notificación</label>
                                <input
                                    type="text"
                                    name="originType"
                                    id="originType"
                                    defaultValue={this.state.oldOrigin.type ? this.state.oldOrigin.type : ""}
                                    placeholder=""
                                    className="inputFilter customEnterText"
                                    onFocus={(e) => this.focusInput(e)}
                                    onBlur={(e) => this.blurInput(e)}
                                    disabled={!access}
                                />
                            </div>
                            <div className="input-container-accountant">
                                <label>Descripción</label>
                                <input
                                    type="text"
                                    name="description"
                                    id="description"
                                    defaultValue={this.state.oldOrigin.description ? this.state.oldOrigin.description : ""}
                                    placeholder=""
                                    className="inputFilter customEnterText"
                                    onFocus={(e) => this.focusInput(e)}
                                    onBlur={(e) => this.blurInput(e)}
                                    disabled={!access}
                                />
                            </div>

                            <div className="input-container-accountant">
                                <label>Seleccione el Estado</label>
                                <select className="slFilter customEnterText" defaultValue={this.state.oldOrigin.state ? this.state.oldOrigin.state : ""}
                                    id="originState" name="originState"
                                    onFocus={(e) => this.focusInput(e)}
                                    onBlur={(e) => this.blurInput(e)}
                                    disabled={!access}
                                >
                                    <option value="">Ninguno</option>
                                    <option className="successState" value="Active">Activo</option>
                                    <option className="errorState" value="Inactive">Inactivo</option>
                                </select>
                            </div>

                            <h3>Origenes</h3>
                            <hr className="sep" />
                            <OriginsList
                                access={!access}
                                origins={this.state.origins}
                                change={this.handleChange}
                                remove={this.removeOriginItem}
                                focusInput={this.focusInput}
                                blurInput={this.blurInput}
                            />
                            <br />
                            <br />
                            <div className="center">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary margin-bottom-btn"
                                    onClick={this.addOriginItem}
                                    disabled={!access}
                                >
                                    <i>+</i> Agregar Origen
                                </button>
                            </div>
                            <br />
                            <div className="p-top-20 center">
                                <button className="button green-fill" disabled={!access}>Guardar Cambios</button>
                            </div>
                        </form>


                        <Modal
                            onClose={this.showModalUpdateOrigin}
                            show={this.state.showUpdate}
                            title="Editar Origen"
                            open={this.state.openUpdate}>
                            {this.renderModalConfirm()}
                        </Modal>

                        <Modal
                            onClose={(e) => { this.redirectPage() }}
                            show={this.state.showSuccess}
                            title="Origen Actualizado"
                            open={this.state.openSuccess}>
                            {this.renderModalX("¡El origen fue actualizado exitosamente!")}
                        </Modal>
                        <Modal
                            onClose={(e) => { this.redirectPage() }}
                            show={this.state.showAccErr}
                            title="Editar Origen"
                            open={this.state.openAccErr}>
                            {this.renderModalX("¡No tienes permiso para editar origenes! Comunicate con el Administrador.")}
                        </Modal>
                        <Modal
                            onClose={(e) => { this.redirectPage() }}
                            show={this.state.showIdErr}
                            title="Origenes"
                            open={this.state.openIdErr}>
                            {this.renderModalX("¡Error al cargar los datos!")}
                        </Modal>
                    </div>)}
            </div>
        );
    }
}

EditConfigOrigin.propTypes = {
    actions: PropTypes.object.isRequired,
    log: PropTypes.object,
    configOrigin: PropTypes.object,
    isLoadingGet: PropTypes.object,
    errorOrigin: PropTypes.object,
    updateOrigin: PropTypes.object,
    isLoadingUpdate: PropTypes.object,
    errorUpdate: PropTypes.object,
};

const mapStateToProps = state => ({
    log: state.logs.log,
    configOrigin: state.auroraConfigOriginsReducer.configOrigin,
    isLoadingGet: state.auroraConfigOriginsReducer.isLoadingGet,
    errorOrigin: state.auroraConfigOriginsReducer.errorOrigin,
    updateOrigin: state.auroraConfigOriginsReducer.updateOrigin,
    isLoadingUpdate: state.auroraConfigOriginsReducer.isLoadingUpdate,
    errorUpdate: state.auroraConfigOriginsReducer.errorUpdate,
});


export default connect(mapStateToProps)(withRouter(EditConfigOrigin));

