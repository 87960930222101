import axios from 'axios';
import ProductListSynergy from './services/ProductListSynergy';

const create = (baseURL = getEndPoint('synergyProducts')) => {
  const api = axios.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'blob'
    },
    timeout: 60000
  });

  const productListSynergy = new ProductListSynergy(api);

  return {
    setHeader: (key, value) => { api.defaults.headers.common[key] = value; },
    removeHeader: (key) => { delete api.defaults.headers.common[key]; },
    productListSynergy
  };
};

export default {
  create
};

function getEndPoint(name) {
  const endPoints = JSON.parse(localStorage.getItem('configInst'));
  for (let x = 0; x < endPoints.length; x++) {
    if (endPoints[x].name === name) {
      return endPoints[x].url;
    }
  }
}
