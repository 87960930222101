import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/indexHubspotFive9';
import API_LOG from '../services/api/index';


/********** FIVE9 COUNTRIES ***********/
export function* getCountriesFive9(action) {
  const api = API.create();
  try {
    const { params } = action;
    let five9 = yield call(api.five9.getCountries, params);

    yield put({ type: types.GET_FIVE9_COUNTRY_SUCCESS, data: five9 });
  } catch (err) {
    yield put({ type: types.GET_FIVE9_COUNTRY_FAILED, err });
  }
}
export function* addCountriesFive9(action) {
  const api = API.create();
  try {
    const { params } = action;
    let five9 = yield call(api.five9.addCountries, params);

    yield put({ type: types.ADD_FIVE9_COUNTRY_SUCCESS, data: five9 });
  } catch (err) {
    yield put({ type: types.ADD_FIVE9_COUNTRY_FAILED, err });
  }
}
export function* editCountriesFive9(action) {
  const api = API.create();
  try {
    const { params } = action;
    let five9 = yield call(api.five9.editCountries, params);

    yield put({ type: types.EDIT_FIVE9_COUNTRY_SUCCESS, data: five9 });
  } catch (err) {
    yield put({ type: types.EDIT_FIVE9_COUNTRY_FAILED, err });
  }
}

export function* deleteCountriesFive9(action) {
  const api = API.create();
  try {
    const { params } = action;
    let five9 = yield call(api.five9.deleteCountries, params);

    yield put({ type: types.DELETE_FIVE9_COUNTRY_SUCCESS, data: five9 });
  } catch (err) {
    yield put({ type: types.DELETE_FIVE9_COUNTRY_FAILED, err });
  }
}

/********** FIVE9 RULES ***********/
export function* getRulesFive9(action) {

  const api = API.create();
  try {
    const { params } = action;
    let five9 = yield call(api.five9.getRules, params);

    yield put({ type: types.GET_FIVE9_RULES_SUCCESS, data: five9 });
  } catch (err) {
    console.error(err)
    yield put({ type: types.GET_FIVE9_RULES_FAILED, err });
  }
}

export function* addRulesFive9(action) {

  const api = API.create();
  try {
    const { params } = action;
    let five9 = yield call(api.five9.addRules, params);

    yield put({ type: types.ADD_FIVE9_RULES_SUCCESS, data: five9 });
  } catch (err) {
    console.error(err)
    yield put({ type: types.ADD_FIVE9_RULES_FAILED, err });
  }
}

export function* editRulesFive9(action) {

  const api = API.create();
  try {
    const { params } = action;
    let five9 = yield call(api.five9.editRules, params);

    yield put({ type: types.EDIT_FIVE9_RULES_SUCCESS, data: five9 });
  } catch (err) {
    console.error(err)
    yield put({ type: types.EDIT_FIVE9_RULES_FAILED, err });
  }
}

export function* deleteRulesFive9(action) {

  const api = API.create();
  try {
    const { params } = action;
    let five9 = yield call(api.five9.deleteRules, params);

    yield put({ type: types.DELETE_FIVE9_RULES_SUCCESS, data: five9 });
  } catch (err) {
    console.error(err)
    yield put({ type: types.DELETE_FIVE9_RULES_FAILED, err });
  }
}

export function* setLog(action) {
  const api = API_LOG.create();
  try {
    const { params } = action;
    const logDetail = yield call(api.logs.createLog, params); //.then(response => response.data);
    yield put({
      type: types.LOGS_RECEIVED,
      data: logDetail,
    });
  } catch (err) {
    yield put({ type: types.LOGS_REQUEST_FAILED, err });
  }
}