import { createTheme } from "@material-ui/core/styles";

const SIIGO_THEME = createTheme({
  palette: {
    primary: {
      light: "#007ECC",
      main: "#4DBAFF",
      dark: "#009DFF",
      contrastText: "#fff",
    },
    secondary: {
      light: "#A2D675",
      main: "#7AC23A",
      dark: "#619B2E",
      contrastText: "#fff",
    },
  },
});

const CAMPAIGN_TYPES = {
  UPGRADE: {
    LABEL: "Descuento",
    VALUE: "upgrade",
  },
  COMBO: {
    LABEL: "Combo",
    VALUE: "combo",
  },
  DISCOUNT_CODE: {
    LABEL: "Cupón",
    VALUE: "discount-code",
  },
};

const CAMPAIGN_DESTINATIONS = {
  SIIGO_CLOUD: {
    LABEL: "Tienda Siigo",
    VALUE: "siigo-cloud",
    CLIENTS: true,
  },
  SIIGO_COM: {
    LABEL: "Siigo.com",
    VALUE: "siigo-com",
    PLACE: true,
  },
  SIIGO_ACCOUNTANT: {
    LABEL: "Portal Contador",
    VALUE: "siigo-accountant",
  },
};

const CAMPAIGN_PLACES = {
  INIT: {
    LABEL: "Inicio",
    VALUE: "inicio",
  },
  PLANS_AND_PRICES: {
    LABEL: "Planes y precios",
    VALUE: "planes-y-precios",
  },
  ELECTRONIC_BILLING: {
    LABEL: "Facturación electrónica",
    VALUE: "facturacion-electronica",
  },
  ELECTRONIC_PAYROLL: {
    LABEL: "Nómina electrónica",
    VALUE: "nomina-electronica",
  },
  ADMINISTRATIVE_SOFTWARE: {
    LABEL: "Software administrativo",
    VALUE: "software-administrativo",
  },
  PURCHASES_AND_EXPENSES: {
    LABEL: "Compras y gastos",
    VALUE: "compras-y-gastos",
  },
  SIIGO_APP: {
    LABEL: "Siigo App",
    VALUE: "siigo-app",
  },
  COLLECTION_MANAGEMENT: {
    LABEL: "Gestión de cobranza",
    VALUE: "gestion-de-cobranza",
  },
  INVENTARIO: {
    LABEL: "Inventario",
    VALUE: "inventario",
  },
  POS_SYSTEM: {
    LABEL: "Sistema POS",
    VALUE: "sistema-pos",
  },
  QUOTES: {
    LABEL: "Cotizaciones",
    VALUE: "cotizaciones",
  },
  ACCOUNTING: {
    LABEL: "Contabilidad",
    VALUE: "contabilidad",
  },
  COST_CENTER: {
    LABEL: "Centro de costos",
    VALUE: "centro-de-costos",
  },
};

const CAMPAIGN_PERIOD = {
  ANUAL: {
    LABEL: "Anual",
    VALUE: "Anual",
  },
  INSTALLMENTS: {
    LABEL: "Cuotas",
    VALUE: "Installments",
  },
};
const CAMPAIGN_PERIOD_ARRAY = [
  CAMPAIGN_PERIOD.ANUAL,
  CAMPAIGN_PERIOD.INSTALLMENTS,
];

const COUNTRIES = {
  COLOMBIA: "CO",
};

const MAX_INPUT_LENGTHS = {
  TEN: 10,
  TWENTY: 20,
  FIFTY: 50,
};

const PARAMETER_NAME_FOR_CAMPAIGN_TABS = "tabId";
const CAMPAIGN_TABS = {
  CREATE: {
    LABEL: "create",
    VALUE: 0,
  },
  MANAGEMENT: {
    LABEL: "management",
    VALUE: 1,
  },
};

const CAMPAIGN_DISCOUNT_TYPE = {
  PERCENTAGE: {
    LABEL: "Percentage",
    VALUE: "Percentage",
  },
  CURRENCY: {
    LABEL: "Currency",
    VALUE: "Currency",
  },
};

const CAMPAIGN_STATES = {
  ACTIVE: "active",
  INACTIVE: "inactive",
};

const SEARCH_CAMPAIGN_INPUTS_FORM = {
  destination: "destination",
  type: "type",
  code: "code",
  pageNumber: "pageNumber",
  pageDocuments: "pageDocuments",
};

const EVENTS_PROPERTY_NAMES = {
  productCodes: "productCodes",
  discountPercentage: "discountPercentage",
  featuredProduct: "featuredProduct",
  period: "period",
};

const UPDATE_SIIGO_COM_CACHE_BODY = {
  action: "delete",
  type: "KEY",
  status: "publish",
  value: "campaigns",
  id: "campaigns",
  typePublish: "api",
};

const PRODUCT_LIST_ACCOUNTANT_PORTAL = [
  { code: "9460001000008", name: "NE 24" },
  { code: "9460001000007", name: "NE 120" },
  { code: "9460001000006", name: "NE 300" },
  { code: "9460001000010", name: "NE Plus" },
  { code: "9460001000009", name: "NE PRO" },
  { code: "9490001000054", name: "Sistema POS Inicio" },
  { code: "9490001000055", name: "Sistema POS Avanzado" },
  { code: "9380002000046", name: "FE 24" },
  { code: "9380002000104", name: "FE 60" },
  { code: "9380002000047", name: "FE 120" },
  { code: "9380002000048", name: "FE 300" },
  { code: "9380002000034", name: "FE 1500" },
  { code: "9380002000045", name: "FE PRO (500 documentos)" },
  { code: "9410001000106", name: "Profesional Independiente" },
  { code: "9410001000107", name: "Emprendedor" },
  { code: "9410001000108", name: "Premium" },
  { code: "7", name: "Nómina electrónica" },
  { code: "9", name: "Punto de venta POS" },
  { code: "2", name: "Facturación Electrónica" },
  { code: "1", name: "Siigo Nube" },
];

export default {
  SIIGO_THEME,
  CAMPAIGN_TYPES,
  CAMPAIGN_DESTINATIONS,
  CAMPAIGN_PLACES,
  CAMPAIGN_PERIOD,
  CAMPAIGN_PERIOD_ARRAY,
  CAMPAIGN_STATES,
  PARAMETER_NAME_FOR_CAMPAIGN_TABS,
  CAMPAIGN_TABS,
  CAMPAIGN_DISCOUNT_TYPE,
  COUNTRIES,
  MAX_INPUT_LENGTHS,
  SEARCH_CAMPAIGN_INPUTS_FORM,
  EVENTS_PROPERTY_NAMES,
  UPDATE_SIIGO_COM_CACHE_BODY,
  PRODUCT_LIST_ACCOUNTANT_PORTAL,
};
