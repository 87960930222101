import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { checkCookie, isLoggedIn } from '../utils/cookies';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const history = useHistory();

    const verifyAuth = () => {
        const isLoggIn = isLoggedIn();
        setIsAuthenticated(isLoggIn);
        return isLoggIn;
    };

    const checkAuth = () => {
        if (!verifyAuth()) {
            history.push('/');
            return false;
        }
        return true;
    };

    useEffect(() => {
        verifyAuth();
    }, [history]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, verifyAuth, checkAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);