import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './w2cCountry.scss';
import * as Five9Actions from '../../../actions/five9/five9Country.js';
import { bindActionCreators } from 'redux';
import TableReport from '../../tableReport';
import { listHeadersCoutry, statusActions } from '../util';
import ModalCountry from './modalCountry'
import DeleteModal from "../deleteModal/deleteModal";
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import { ACCESS } from '../access';
import { getAccess } from './../../../utils/access';
import { SiigoNotificationGrowlAtom } from "@siigo/siigo-notification-growl-atom-react";

const GET_DATA = 1;
const INSERT_ACTION = 2;
const UPDATE_ACTION = 3;
const SOURCE_COUNTRY = "SOURCE_COUNTRY";
const NOTIFICATION = "five9Notification";

class w2cCountry extends Component {

	constructor(props) {
		super(props);
		this.state = {
			listCountry: [],
			showDeleteModal: false,
			dataToDelete: null,
			countrySelect: {},
			showModal: false,
			actionSelected: INSERT_ACTION,
			showNotification: false,
		}
	}
	changeStateModal = () => {
		return this.state.showModal ? this.setState({ showModal: false }) : this.setState({ showModal: true })
	}
	loadCountryInformation = () => {
		this.props.actions.getCountriesFive9({ action: GET_DATA });
	}

	componentDidMount() {
		this.loadCountryInformation();
		const notification = localStorage.getItem(NOTIFICATION);
		if (notification) {
			this.notificationControl(notification)
			this.showNotification();
			localStorage.setItem(NOTIFICATION, "")
		}
	}

	showDelete = (active = false) => {
		if (active) return this.setState({ showDeleteModal: true });
		return this.setState({ showDeleteModal: false });
	}

	setupDataToDelete = (data) => {
		this.setState({ dataToDelete: data });
	}
	notificationControl = (option) => {
		const res = statusActions[option].success
		this.setState({ detailNotification: res })
	}
	showNotification = (control = false) => {
		if (control) return this.setState({ showNotification: !control });
		return setTimeout(() => {
			this.setState({ showNotification: !control });
		}, 0);
	}

	launchNotificationUnauthorized = () => {
		this.notificationControl("unauthorized_user")
		this.showNotification();
		this.setState({ showNotification: false })
		return null;
	}

	tableActions = {
		btnDropDown: "btn-dropdown",
		type: "btn-dropdown",
		label: "Editar",
		btnOptions: [
			{
				label: "Eliminar",
				id: 1,
			}
		],
		clickBtn:
			getAccess(this.props.parentPath, ACCESS.edit_country) ? (
				(item) => {
					this.setState({ actionSelected: UPDATE_ACTION, countrySelect: item });
					this.changeStateModal();
				}
			)
				:
				(
					(item) => {
						this.launchNotificationUnauthorized("unauthorized_user");
					}

				),
		clickOption: (item, data) => {
			let deletePermission = getAccess(this.props.parentPath, ACCESS.delete_country);
			/**
			 * Se asigna la posición 0 ya que el contexto del componente siempre retorna un 
			 * array de una unica posición con la información de la opción seleccionada
			 */
			let [detail] = data?.detail;
			switch (detail?.id) {
				case 1:
					if (deletePermission) {
						this.setupDataToDelete(item._id)
						this.showDelete(true)
					} else {
						this.launchNotificationUnauthorized("unauthorized_user");
					}
					break;
				default:
					break
			}
		}
	};


	showModalComplement = () => {
		this.setState({ actionSelected: INSERT_ACTION, countrySelect: {} });
		this.changeStateModal();
	}

	notificationMessage = (message = null, time = 5000) =>
		message && (
			<SiigoNotificationGrowlAtom
				className="notification-box"
				timeout={time}
				notification={message}
				position={"bottom-right"}
			/>
		);
	transformDataToTable = (listCountries) => {
		if (Array.isArray(listCountries) && listCountries) {
			return listCountries.map(country => {
				country.stateCountry = country.stateCountry === "true" || country.stateCountry === 'Activo' ? "Activo" : "Inactivo";
				return country;

			});
		}
	}

	render() {
		let { listData } = this.props;
		this.transformDataToTable(listData?.data?.data);
		return (
			<div>
				<div>
					<div className="container-button-add"></div>
					<ModalCountry props={this.props} changeStateModal={this.changeStateModal} showModal={this.state.showModal} loadCountryInformation={this.loadCountryInformation} data={this.state.countrySelect} action={this.state.actionSelected} />
					<div className="title-w2c">W2C Países</div>
					<div className="container-button-add">
						{getAccess(this.props.parentPath, ACCESS.create_country) &&
							<SiigoButtonAtom
								color="primary-green"
								size="l"
								type="button"
								text="Agregar país"
								icon="fas fa-plus"
								onClick={this.showModalComplement}
							/>
						}
					</div>
					<div className="subtitle">Gestiona los países asociados a las reglas de Five9</div>
				</div>
				<br />
				{listData &&
					<div className="divTable">
						<TableReport
							columns={listHeadersCoutry} // Data columns to table
							tabs={false} //Enable tabs
							tabsData={[]} //Load data to tabs check option on tabsData const
							headerCounterTab={true} //Counter tab option
							btnActions={this.tableActions}
							actionButtons={true} //Enable buttons (edit, delete, copy, open)
							pageSize={10} // Enable page size
							pagination={true} //Enable pagination (true, false)
							paginationNav={'arrows'} //Nav options (arrow, dot)
							paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
							maxSizePagination={6} //Size pagination
							defaultPaginationNumber={1} //Current pagination when start app
							paginationGrouped={10} //Grouped by page
							detailRow={false} //Open column detail with left arrow
							data={listData?.data?.data} //Data for table
						/>
					</div>
				}
				{this.state.showDeleteModal &&
					<DeleteModal
						showDeleteModal={this.state.showDeleteModal}
						showDelete={this.showDelete} source={SOURCE_COUNTRY}
						data={this.state.dataToDelete} deleteAction={this.props}
					/>
				}
				{this.state.showNotification &&
					this.notificationMessage([this.state.detailNotification], 5000)
				}
			</div>
		)
	}

}


w2cCountry.propTypes = {
	actions: PropTypes.object.isRequired,
	listData: PropTypes.object,
	error: PropTypes.object
};

const mapStateToProps = state => ({
	listData: state.five9.countries,
	error: state.five9.err,
	isLoading: state.five9.isLoading,
	isCorrect: state.five9.isCorrect
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(Five9Actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(w2cCountry);