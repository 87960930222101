import * as types from '../index';

export const getGroups = () => {
  return { type: types.GET_GROUPS };
};

export const getGroup = msisdn => {
  return { type: types.GET_GROUP, msisdn };
};

export const createGroup = gro => {
  return { type: types.CREATE_GROUP, gro };
};

export const updateGroup = (gro, msisdn) => {
  return { type: types.UPDATE_GROUP, msisdn, gro };
};

export const deleteGroup = msisdn => {
  return { type: types.DELETE_GROUP, msisdn };
};
