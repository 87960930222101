import axios from 'axios';
import Leads from './services/Leads';
import config from '../../config.js';

const create = (baseURL = config.urlHubspot) => {
  const api = axios.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json'
    },
    timeout: 30000
  });

  const leads = new Leads(api);

  return {
    setHeader: (key, value) => { api.defaults.headers.common[key] = value; },
    removeHeader: (key) => { delete api.defaults.headers.common[key]; },
    leads
  };
};

export default {
  create
};
