import React from 'react';
import { useAuth } from '../../context/auth-context';
import AccountantList from './index';

const AccountantListWrapper = (props) => {
    const { checkAuth } = useAuth();
    return <AccountantList {...props} checkAuth={checkAuth} />;
};

export default AccountantListWrapper;
