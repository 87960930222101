import { put, call } from "redux-saga/effects";
import * as types from "../actions";
import API from "../services/api/indexShop";
import { StatusCodes } from "http-status-codes";

const api = API.create();

export function* getProducts(data) {
  try {
    const response = yield call(api.shopService.getProducts, data.params);
    if (response.status === StatusCodes.OK) {
      yield put({
        type: types.GET_PRODUCTS_RECEIVED,
        products: response.data?.data,
      });
    } else {
      throw Object.assign(
        new Error("Error realizando la consulta de productos"),
        { code: 503 }
      );
    }
  } catch (error) {
    yield put({ type: types.GET_PRODUCTS_FAILED, error: error });
  }
}

export function* postCampaign(data) {
  try {
    const response = yield call(api.shopService.postCampaign, data.params);
    if (response.status === StatusCodes.OK) {
      yield put({
        type: types.POST_CAMPAIGN_RECEIVED,
        response: response.data,
      });
    } else {
      throw Object.assign(new Error("Error creando la campaña"), { code: 503 });
    }
  } catch (error) {
    yield put({ type: types.POST_CAMPAIGN_FAILED, error: error });
  }
}

export function* getCampaigns(data) {
  try {
    const response = yield call(api.shopService.getCampaigns, data.params);
    if (response.status === StatusCodes.OK) {
      yield put({
        type: types.GET_CAMPAIGNS_RECEIVED,
        campaigns: response.data,
      });
    } else {
      throw Object.assign(
        new Error("Error realizando la consulta de campañas"),
        { code: 503 }
      );
    }
  } catch (error) {
    yield put({ type: types.GET_CAMPAIGNS_FAILED, error: error });
  }
}

export function* patchCampaignState(data) {
  try {
    const response = yield call(api.shopService.patchCampaignState, data.params);
    if (response.status === StatusCodes.OK) {
      yield put({
        type: types.PATCH_CAMPAIGNSTATE_RECEIVED,
        response: response.data,
      });
    } else {
      throw Object.assign(
        new Error("Error actualizando el estado de la campaña"),
        { code: 503 }
      );
    }
  } catch (error) {
    yield put({ type: types.PATCH_CAMPAIGNSTATE_FAILED, error: error });
  }
}


