import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react'
import { SiigoDatepickerMolecule } from '@siigo/siigo-datepicker-molecule-react'
import { SiigoNumberButtonMolecule } from '@siigo/siigo-number-button-molecule-react'

import Smenu from '../slayout/smenu/smenu'
import './sexplorar.scss';
import SiigoSelectPais from '../sshared/SiigoSelectPais/SiigoSelectPais.js'
import { getCookie } from '../../../utils/cookies'
import { GetOrCreateSiiger } from '../helpers/SiigerHelper'


class Sexplorar extends Component {
    constructor(props) {
        super(props);

        let user = {
            username: getCookie('email'),
            name: getCookie('name'),
            avatar: getCookie('avatar')
        }

        GetOrCreateSiiger(user);

        this.ChildElement = React.createRef();

        // let lastSearch = JSON.parse(localStorage.getItem("siigertravelsearch"));
        // if (lastSearch == undefined){
        //     lastSearch = { tpais: 0,  tciudad: 0,  tcantidad: 1, tdesde: '',   thasta: ''}
        // }      
        let lastSearch = { tpais: 0,  tciudad: 0,  tcantidad: 1, tdesde: '',   thasta: ''};
        let { tpais, tciudad, tcantidad, tdesde, thasta } = lastSearch;

        this.state = { 
            pais: tpais,
            provincia: tciudad,
            from: tdesde,
            until: thasta,
            countPeople: tcantidad
        }
    }

    onChangePeopleNumber(e, val) {
        this.setState({ ...this.state, countPeople: e.target.value });
    }

    onChangeDateFrom(e) {
        this.setState({ ...this.state, from: e.target.value });
    }

    onChangeDateUntil(e){        
        this.setState({ ...this.state, until: e.target.value });
    }    

    searchClick = (e) => {
        e.preventDefault();                  
        const childelement = this.ChildElement.current;
        // childelement.changeName(e,"Testttttt"); 
        this.setState({...this.state,
                         pais: childelement.state.country, 
                         provincia: childelement.state.states,

                      }, () => {                   
                        var siigertravelsearch = {
                            tpais: (this.state.pais == 0) ? 1 : this.state.pais,
                            tciudad: (this.state.provincia == 0) ? 1 : this.state.provincia,
                            tcantidad: this.state.countPeople,
                            tdesde: this.state.from,
                            thasta: this.state.until
                        };

                        localStorage.setItem("siigertravelsearch", JSON.stringify(siigertravelsearch));

                        this.setState({ redirect: true });
                      });    
    };    

    render() {
        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to='/siigers/viajes'/>;
        }

        return(
            <>
                <Smenu />  
                <div className='container siiger'>
                    <div className='scard scard-mas-gap'>
                        
                        <div className=''>
                            <h1 className='scard-title h1-siiger'>¿A dónde viajas?</h1>
                        </div>

                        <div className=''>
                            <div className='col width-100'>
                                <SiigoSelectPais ref={this.ChildElement} disabled={ true }/> 
                            </div>
                        </div>                            
 
                        <div className='align-center'>
                            <div className='col text-center'>                                    
                                <label>Selecciona las fechas de ida y regreso</label>
                            </div>
                        </div>

                        <div className='align-center'>
                            <div className='col-2'>
                                <SiigoDatepickerMolecule
                                    text-options='[{"label": "Ida","hint": "Formato: dd/mm/aaaa"}]'
                                    date-ranges='[{"min": "01/01/2023", "max": "31/12/2050"}]'
                                    required="true" 
                                    error-messages='[{ "format": "format error", "required": "Por favor completa este campo"}]'
                                    onDateChange={ (e) => this.onChangeDateFrom(e) }
                                >                                
                                </SiigoDatepickerMolecule>                        
                            </div>
                        </div>                                
                        <div className='align-center'>
                            <div className='col-2'>                                   
                                <SiigoDatepickerMolecule                                         
                                    text-options='[{"label": "Regreso","hint": "Formato: dd/mm/aaaa"}]'
                                    date-ranges='[{"min": "01/01/2023", "max": "31/12/2050"}]'
                                    required="true" 
                                    error-messages='[{ "format": "format error", "required": "Por favor completa este campo"}]'
                                    onDateChange={ (e) => {this.onChangeDateUntil(e)} }
                                >
                                </SiigoDatepickerMolecule>
                            </div>
                        </div>
                       
                        <div className="align-center width-100">
                            <label>Número de personas que viajan</label>
                        </div>
                        
                        <div className="align-center width-100">                            
                            <div className='col SiigoNumber'>                                
                                <SiigoNumberButtonMolecule
                                    label=""
                                    maxValue={50}
                                    minValue={1}
                                    name="name"                                  
                                    value={ this.state.countPeople }
                                    onChangedNumber={(e, val) => {this.onChangePeopleNumber(e, val)} }
                                />
                            </div>
                        </div>

                        <div className=''>
                            <div className='col width-100'>
                                {/* <Link to="/siigers/viajes"> */}
                                    <SiigoButtonAtom
                                        id='searchBuscarViajesButton'
                                        color="primary-green"                                        
                                        size="l"
                                        type="submit"                                    
                                        text="Buscar"
                                        icon="fas fa-search" 
                                        onClick={ (e) => this.searchClick(e)}                           
                                    />
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Sexplorar;