import React, { Fragment, useState } from 'react';
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import { getCookie } from '../../../utils/cookies';
import './deleteModal.scss';
import { useAuth } from '../../../context/auth-context';
const RULES_MODULE = "Administración five9, módulo reglas";
const COUNTRY_MODULE = "Administración five9, módulo países";
const DELETE_REGISTRY_LOG_COUNTRIES = "Eliminación de un país";
const DELETE_REGISTRY_LOG_RULES = "Eliminación de una regla";


const SOURCE_COUNTRY = "SOURCE_COUNTRY";
const SOURCE_RULES = "SOURCE_RULES";
const DELETE_RULE = 4;

const DeleteModal = ({ showDeleteModal, showDelete, source, data, deleteAction }) => {

    const [showModal, setShowModal] = useState(false);
    const { checkAuth } = useAuth();

    const showModalComplement = () => {
        setShowModal(true);
    }

    const handleSubmit = () => {
        if(!checkAuth()) return;
        const dataToSend = {
            action: DELETE_RULE,
            id: data,
            source: source === SOURCE_RULES ? "deleteRule" : "deleteCountry"
        }
        let deleteResponse = null
        if (source === SOURCE_RULES) deleteResponse = deleteAction.actions.deleteRulesFive9(dataToSend);
        if (source === SOURCE_COUNTRY) deleteResponse = deleteAction.actions.deleteCountriesFive9(dataToSend);
        if (deleteResponse) {
            const actionRegistryLog = source === SOURCE_RULES ? DELETE_REGISTRY_LOG_RULES : DELETE_REGISTRY_LOG_COUNTRIES;
            const moduleRegistry = source === SOURCE_RULES ? RULES_MODULE : COUNTRY_MODULE;
            updateLog(moduleRegistry, getCookie('name'), new Date().toISOString(), actionRegistryLog, formUpdateLogData());
            showDelete(false)
            localStorage.setItem("five9Notification", dataToSend.source);
            //Adiciona reload dado que es necesario realizar refactor de table para que recargue estados
            //Nota: Horas invertidas intentando ajustar el componente table : 12 horas 
            //Si intentas hacer un refactor y no te funciona, aumentar el contador de horas!!
            //pdta: el componente de table migrará a DSM            
            setTimeout(() => {
                window.location.replace(window.location.href)
            }, 1000)
        }
    }

    const formUpdateLogData = () => {
        return data;
    }

    const updateLog = (nameComponent, nameUser, date, action, aditionalData) => {
        let data = {
            nameComponent,
            nameUser,
            date,
            action,
            aditionalData,
        };

        deleteAction.actions.setLog(data);
    };


    return (
        <Fragment>
            {showDeleteModal &&
                <div className="modal-parent caja-complements">
                    <div className="container-notification">
                        <Fragment>
                            <div className="container-content">
                            </div>
                        </Fragment>
                    </div>
                    <div className="caja-delete" id="modal">
                        <div className="h2">
                            <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="icon-fequent-question"
                                color="orange"
                            />
                            <span>{source === SOURCE_RULES ? "Eliminar Regla" : "Eliminar País"}</span>{' '}
                            <FontAwesomeIcon
                                onClick={() => showDelete(false)}
                                icon={faTimes}
                                className="icon-close closeModal"
                            />
                        </div>
                        <div className="content">
                            <Fragment>
                                <div className="container-content">
                                    {source === SOURCE_RULES ?
                                        <p>Al realizar esta acción no podrás volver a acceder a esta regla</p>
                                        :
                                        <p>Al realizar esta acción no podrás volver a acceder a la información del país</p>}
                                </div>
                                <div className="container-buttons">
                                    <div className="float-lf">
                                        <SiigoButtonAtom
                                            color="tertiary"
                                            name="configButton"
                                            size="m"
                                            text="Cancelar"
                                            type="button"
                                            className="cancelButton"
                                            onclick={() => showDelete(false)}
                                        />
                                    </div>
                                    <div className="float-lf">
                                        <SiigoButtonAtom
                                            color="primary"
                                            name="configButton"
                                            size="m"
                                            text="Sí, eliminar"
                                            type="button"
                                            className="addButton"
                                            onclick={(e) => handleSubmit(e)}
                                        />
                                    </div>
                                </div>
                            </Fragment>
                        </div>
                    </div>
                </div>
            }
        </Fragment>
    );
}

export default DeleteModal;