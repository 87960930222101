import SendMailService from "../../services/api/services/SendMail";
import welcomeMailHTML from "./mails/welcome";
import ModalResponse from "../../components/layout/modals/modalResponse";

const MailEnum = {
  welcome: 1,
};

const welcomeMailModel = {
  From: { Name: "SIIGO S.A.", Email: "siigonube@isiigo.com" },
  Subject: "Bienvenido a Siigo",
  HTMLPart: welcomeMailHTML,
};

const getMail = (name, email, pass, mailEnum) => {
  let mail = null;
  switch (mailEnum) {
    case MailEnum.welcome:
      mail = welcomeMailModel;
      // const messageNotPass =
      //   "Si ya te encuentras registrado en la plataforma, utiliza la clave que tienes asignada.";
      let html = welcomeMailModel.HTMLPart;
      html = html.replaceAll("[name]", name);
      html = html.replaceAll("[email]", email);
      html = html.replaceAll("[pass]", pass.newPassword);
      mail.HTMLPart = html;
      break;
    default:
      throw new Error("El Mail aun no a sido definido");
      break;
  }
  return mail;
};

const sendMail = async (nameTo, emailTo, pass, mailEnum) => {
  try {
    const mail = getMail(nameTo, emailTo, pass, mailEnum);
    mail.To = [{ Name: nameTo, Email: emailTo }];
    const response = await SendMailService.sendMail(mail);
    if (response?.Messages[0]?.Status == "success") {
      showAlert("El reenvío de mail de bienvenida se realizó de manera correcta asignando una contraseña aleatoria", 200);
    } else {
      showAlert(
        "No fue posible enviar el mail, inténtalo en unos minutos o comunícate con el administrador",
        500
      );
    }
  } catch (err) {
    showAlert(
      "No fue posible enviar el mail, inténtalo en unos minutos o comunícate con el administrador",
      500
    );
  }
};

const resetPassword = async (userEmail, options) => {
  try {
    if (!userEmail) {
      showAlert(`La empresa no posee correo del administrador`, 500);
      return false;
    }
    const pass = await SendMailService.resetPassword(options);
    showAlert("El reenvío de mail de bienvenida se realizó de manera correcta asignando una contraseña aleatoria", 200);
    return pass;
  } catch (err) {
    showAlert(
      "No hemos podido reestablecer tu contraseña, inténtalo en unos minutos o comunícate con el administrador",
      500
    );
    console.error("ResetPassword ", err.response);
    return false;
  }
};

const showAlert = (message, status) => {
  ModalResponse({
    title: "Reenvío del mail",
    text: message,
    res: status,
  });
};

export default {
  sendMail,
  MailEnum,
  resetPassword,
};
