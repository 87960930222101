import React, { Component } from 'react';
import PropTypes, { func } from 'prop-types';
import * as accountActions from '../../actions/accountStatement/accountAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalDelete from '../layout/modals/modalDelete';
import ModalResponse from '../layout/modals/modalResponse';
import ModalSendEmail from '../layout/modals/modalSendEmail';
import svgs from '../layout/icons';
import Modal from '../layout/modals/detail/index';
//import './style.scss';
import { normalize } from '../tools/format';
import '../tableReport/table-report.scss';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';

const columns = [
	{
		name: 'Cliente',
		selector: 'user',
		sortable: true
	},
	{
		name: 'Nit',
		selector: 'id',
		sortable: true
	},
	{
		name: 'Días vencidos',
		selector: 'daysPastDue',
		sortable: true
	},

	{
		name: 'Total',
		selector: 'totalAmount',
		sortable: true,
		filterDDL: true,
		money: true
	},
	{
		name: 'Vencido',
		selector: 'expiredAmount',
		sortable: true,
		filterDDL: true,
		money: true
	},
	{
		name: 'Por vencer',
		selector: 'toExpiredAmount',
		sortable: true,
		filterDDL: true,
		money: true
	}
];
let deleteNot = false;
let sendEmail = false;
class AccountStatementList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			emailUser: [],
			show: false,
			showInvoice: false,
			invoiceData: []
		};
	}

	getAccountStatement = () => {
		const data = {
			nit: 'null',
			age: '1'
		};
		this.props.actions.getAccountStatement(data);
	};

	componentDidMount() {
		this.getAccountStatement();
	}

	filterAccount = e => {
		e.preventDefault();
		const nit = e.target.nit.value == '' ? 'null' : e.target.nit.value;
		const age = e.target.age.value;

		const data = {
			nit,
			age
		};
		this.props.actions.getAccountStatement(data);
	};

	cleanFilter = e => {
		e.preventDefault();
		document.getElementById('nit').value = '';
		document.getElementById('age').value = '1';
		this.getAccountStatement();
	};
	confirSendEmail = () => {
		if (this.state.emailUser.length == 0) {
			ModalResponse({
				title: 'Envio de correos',
				text: 'Debe seleccionar por lo menos un usuario',
				res: 404
			});
		} else {
			ModalSendEmail({
				actionSend: this.sendUser,
				data: { countEmail: this.state.emailUser.length }
			});
		}
	};

	ckAllItem = (e, data) => {
		const checkboxes = document.getElementsByName('chItem');
		for (var i = 0, n = checkboxes.length; i < n; i++) {
			checkboxes[i].checked = e.currentTarget.checked;
		}

		if (e.currentTarget.checked) {
			let emailUser = [];
			data.map((val, id) => {
				emailUser.push({
					email: val.mailContent.to,
					name: val.mailContent.name,
					id: val.id,
					guid: val.customerGuid
				});
			});
			this.setState({ emailUser });
		} else {
			this.setState({ emailUser: [] });
		}
	};

	showModal = e => {
		this.setState({
			show: !this.state.show
		});
	};

	showModalInvoice = e => {
		this.setState({
			showInvoice: !this.state.showInvoice
		});
	};

	sendUser = () => {
		sendEmail = true;
		this.props.actions.sendEmail({ params: { emails: this.state.emailUser } });
	};

	setUsers = (e, item) => {
		const emailUser = [...this.state.emailUser];
		if (e.currentTarget.checked)
			emailUser.push({
				email: item.mailContent.to,
				name: item.mailContent.name,
				id: item.id,
				guid: item.customerGuid
			});
		else
			emailUser.splice(
				emailUser.indexOf({
					email: item.mailContent.to,
					name: item.mailContent.name,
					id: item.id,
					guid: item.customerGuid
				}),
				1
			);
		this.setState({ emailUser });
	};

	showDetail = data => {
		if (data.hasOwnProperty('invoices')) {
			this.setState({ invoiceData: data.invoices, showInvoice: true });
		} else {
			ModalResponse({
				title: 'Detalle Estados de cartera',
				text: 'El estado de cartera no tiene Facturas',
				res: 404
			});
		}
	};

	render() {
		let { error, isLoading, accounts, email, isLoadingEmail } = this.props;
		const btnActions = {
			ckSend: this.setUsers,
			ckAllItem: this.ckAllItem,
			btnShowDetail: this.showDetail
		};
		let datos = [];
		let status = 0;
		let text = '';
		let data = {};

		if (accounts) {
			if (accounts.status == 200) {
				datos = accounts.data;
			} else {
				ModalResponse({
					title: 'Consulta Estados de cartera',
					text: accounts.data,
					res: 404
				});
			}
		}

		if (email && sendEmail) {
			ModalResponse({
				title: 'Envío de correo',
				text: email.data,
				res: email.status
			});

			sendEmail = false;
		}

		return (
			<div>
				<h3>Estados de cartera</h3>
				{
					getAccess(this.props.parentPath, ACCESS.Send) ? (
						<a
							href="#"
							onClick={this.confirSendEmail}
							className="a-button green-fill"
						>
							<i className="siicon icon-send"></i>
					Enviar
						</a>
					) : null
				}

				<form onSubmit={this.filterAccount}>
					<div className="row divFilter">
						<div className="column3">
							<input
								className="inputFilter"
								id="nit"
								name="nit"
								type="text"
								placeholder="Nit del usuario"
							/>
						</div>
						<div className="column3">
							<select className="slFilter" id="age" name="age">
								<option value="1" selected>
									Vencido 1 a 30
								</option>
								<option value="2">Vencido 31 a 60</option>
								<option value="3">Vencido 61 a 90</option>
								<option value="4">Vencido 91 a 120</option>
								<option value="5">Vencido 121 a 180</option>
								<option value="6">Vencido mas de 30</option>
								<option value="7">Vendico mas de 90</option>
								<option value="8">Vencido mas de 120</option>
								<option value="9">Vencido mas de 180</option>
								<option value="10">Por vencer 0 a 30</option>
								<option value="11">Por vencer 31 a 60</option>
								<option value="12">Por vencer mas de 61</option>
							</select>
						</div>
						<div className="column4"></div>
						<div className="column4">
							<div className="row">
								<div className="column2">
									<button className="green-fill btnBuscar" type="submit">
										<div className="row">
											<div className="column2">
												<span className="siicon icon-search searchMarket"></span>
											</div>
											<div className="column2">
												<span>Buscar</span>
											</div>
										</div>
									</button>
								</div>
								<div className="column2">
									<button
										onClick={this.cleanFilter}
										className="orange-fill"
										type="button"
									>
										Limpiar
									</button>
								</div>
							</div>
						</div>
					</div>
				</form>
				<Modal
					onClose={this.showModal}
					show={isLoadingEmail}
					title="Estados de cartera"
				>
					<Loader />
					<p style={{ textAlign: 'center' }}>Enviando correos...</p>
				</Modal>

				<Modal
					onClose={this.showModalInvoice}
					show={this.state.showInvoice}
					title="Facturas del estado de cartera"
				>
					<div className="divTable">
						<div className="container table-report">
							<div className="table-components">
								<table className="table">
									<thead>
										<tr>
											<th># Factura</th>
											<th>Dias vencidos</th>
											<th>Vencido</th>
											<th>por Vencer</th>
										</tr>
									</thead>
									<tbody>
										{this.state.invoiceData.map((val, id) => {
											return (
												<tr className="border-left">
													<td>{val.invoiceId}</td>
													<td>{val.daysPastDue}</td>
													<td>{val.expiredBalance}</td>
													<td>{val.balanceToExpire}</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</Modal>

				{error && <h4>Error: {error.response.data}</h4>}
				{isLoading ? (
					<Loader />
				) : (
						<div>
							<div className="divTable">
								<TableReport
									columns={columns} // Data columns to table
									tabs={false} //Enable tabs
									tabsData={[]} //Load data to tabs check option on tabsData const
									headerCounterTab={true} //Counter tab option
									btnActions={btnActions}
									actionButtons={true} //Enable buttons (edit, delete, copy, open)
									pageSize={10} // Enable page size
									pagination={true} //Enable pagination (true, false)
									paginationNav={'arrows'} //Nav options (arrow, dot)
									paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
									maxSizePagination={6} //Size pagination
									defaultPaginationNumber={1} //Current pagination when start app
									paginationGrouped={10} //Grouped by page
									detailRow={false} //Open column detail with left arrow
									data={datos} //Data for table
								/>
							</div>
						</div>
					)}
			</div>
		);
	}
}

AccountStatementList.propTypes = {
	actions: PropTypes.object.isRequired,
	accounts: PropTypes.object,
	email: PropTypes.object,
	error: PropTypes.object
};

const mapStateToProps = state => ({
	accounts: state.synergy.accounts,
	email: state.synergy.email,
	error: state.synergy.err,
	isLoading: state.synergy.isLoading,
	isLoadingEmail: state.synergy.isLoadingEmail
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(accountActions, dispatch)
});
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AccountStatementList);
