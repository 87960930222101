import React, { Fragment, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SiigoInputAtom } from '@siigo/siigo-input-atom-react';
import './modal.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react';
import { getCookie } from '../../../../utils/cookies';
import { useAuth } from '../../../../context/auth-context';
const RULES_MODULE = "Administración five9, módulo países";
const UPDATE_REGISTRY_LOG = "Actualizar países";
const INSERT_REGISTRY_LOG = "Insertar países";

const UPDATE_ACTION = 3;
const SOURCE_COUNTRY_SELECT = "country";
const SOURCE_STATE_SELECT = "state";

const Modal = ({ setShowModal, props, loadCountryInformation, data = {}, action }) => {

    const SIGNAL_STATUS = "SIGNAL_STATUS"
    const SIGNAL_COUNTRY = "SIGNAL_COUNTRY"
    const [isButtonActive, setIsButtonActive] = useState(action !== UPDATE_ACTION)
    const [formData, setFormData] = useState({
        country_w2c: data.country ? data.country : "",
        countryCode_w2c: data.country_code ? data.country_code : "",
        indicative_w2c: data.indicative ? data.indicative : "",
        status_w2c: data.stateCountry ? data.stateCountry : "Activo",
        defaultSkill_w2c: data.skill ? data.skill : ""
    })
    const [validateForm, setValidateForm] = useState({
        isCountry_w2c: data.country ? true : false,
        isCountryCode_w2c: data.country_code ? true : false,
        isIndicative_w2c: data.indicative ? true : false,
        isStatus_w2c: true,
        isDefaultSkill_w2c: data.skill ? true : false
    })

    const { checkAuth } = useAuth();

    const [formMessage] = useState({
        tittle: action === UPDATE_ACTION ? "Editar país" : "Agregar país",
        subTittle: action === UPDATE_ACTION ? "Edita la información del país" : "Completa la información para agregar el país",
        cancelButton: "Cancelar",
        sendActionButton: action === UPDATE_ACTION ? "Guardar" : "Agregar"
    })
    useEffect(() => {
        setIsButtonActive(!isAllFieldsComplete(formData))
    });

    const isAllFieldsComplete = (properties) => {
        return Object.values(properties).filter(val => !val).length === 0;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isAllFieldsComplete(validateForm)) {
            if(!checkAuth()) return;
            let tempData = getFormData(action);
            let addResponse = props.actions.addCountriesFive9(tempData);
            if (addResponse) {
                loadCountryInformation(tempData.data);
                setShowModal();
                const setQuery = action === UPDATE_ACTION ? "updateCountry" : "addCountry";
                const actionRegistryLog = action === UPDATE_ACTION ? UPDATE_REGISTRY_LOG : INSERT_REGISTRY_LOG;
                updateLog(RULES_MODULE, getCookie('name'), new Date(), actionRegistryLog, formUpdateLogData(tempData, action === UPDATE_ACTION));
                localStorage.setItem("five9Notification", setQuery);
                //Adiciona reload dado que es necesario realizar refactor de table para que recargue estados
                //Nota: Horas invertidas intentando ajustar el componente table : 12 horas 
                //Si intentas hacer un refactor y no te funciona, aumentar el contador de horas!!
                //pdta: el componente de table migrará a DSM
                setTimeout(() => {
                    window.location.replace(window.location.href);
                }, 1000)

            }
        } else {
            //mensaje de obligatoriedad de campos
            /*Swal.fire({
                title: 'Error',
                text: 'Todos los campos son obligatorios',
                icon: 'error',
            })*/
        }
    }

    const formUpdateLogData = (tempData, isUpdate) => {
        return isUpdate ? "Antiguo registro: " + JSON.stringify(data) + ". Nuevo registro: " + JSON.stringify(tempData) : JSON.stringify(tempData);
    }

    const updateLog = (nameComponent, nameUser, date, action, aditionalData) => {
        let data = {
            nameComponent,
            nameUser,
            date,
            action,
            aditionalData,
        };

        if(!checkAuth()) return;
        props.actions.setLog(data);
    };

    const getFormData = (idAction, idCountry) => {
        return {
            action: idAction,
            id: action === UPDATE_ACTION ? data._id : "",
            data: {
                country: formData.country_w2c,
                country_code: formData.countryCode_w2c,
                indicative: formData.indicative_w2c,
                stateCountry: formData.status_w2c === 'Inactivo' || formData.status_w2c === 'false' ? 'false': 'true',
                skill: formData.defaultSkill_w2c
            }
        }
    }

    const onTextChanged = (e) => {
        e.preventDefault();
        const text = e.target.value;
        const isEmpty = text.length === 0;

        if (e.target.classList.length < 2) return false;
        switch (e?.target?.classList[0]) {
            case 'countryCode_w2c':
                setFormData(oldState => ({ ...oldState, countryCode_w2c: text }))
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isCountryCode_w2c: false }))
                setValidateForm(oldState => ({ ...oldState, isCountryCode_w2c: true }))
                return;
            case 'indicative_w2c':
                setFormData(oldState => ({ ...oldState, indicative_w2c: text }))
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isIndicative_w2c: false }))
                setValidateForm(oldState => ({ ...oldState, isIndicative_w2c: true }))
                return;
            case 'defaultSkill_w2c':
                setFormData(oldState => ({ ...oldState, defaultSkill_w2c: text }))
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isDefaultSkill_w2c: false }))
                setValidateForm(oldState => ({ ...oldState, isDefaultSkill_w2c: true }))
                return;
            case 'statusSelectCountry':
                setFormData(oldState => ({ ...oldState, country_w2c: text }))
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isCountry_w2c: false }))
                setValidateForm(oldState => ({ ...oldState, isCountry_w2c: true }))
                return;
            default:
                return false
        }
        return;
    }

    const getValueBeforeSelect = (source) => {
        switch (source) {
            case SOURCE_STATE_SELECT:
                if (formData.status_w2c) {
                    return formData.status_w2c === "Inactivo" || formData.status_w2c === "false" ? false : true;
                }
                return true;
            default:
                return true;
        }
        return "";
    }

    const handleOptions = (e) => {
        const sourceEvent = e.target.id;
        const sourceData = e.target.value;
        const isEmpty = sourceData.length === 0;
        switch (sourceEvent) {
            case SIGNAL_COUNTRY:
                setFormData(oldState => ({ ...oldState, country_w2c: sourceData }));
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isCountry_w2c: false }));
                setValidateForm(oldState => ({ ...oldState, isCountry_w2c: true }));
                return
            case SIGNAL_STATUS:
                setFormData(oldState => ({ ...oldState, status_w2c: sourceData }))
                if (isEmpty) return setValidateForm(oldState => ({ ...oldState, isStatus_w2c: false }));
                setValidateForm(oldState => ({ ...oldState, isStatus_w2c: true }));
                return
            default:
                break
        }
    }
    return (
        <div className="modal-parent modal-w2c">
            <div className="container-notification">
                <Fragment>
                    <div className="container-content">
                    </div>
                </Fragment>
            </div>
            <div className="modal" id="modal">
                <div className="h2">
                    <span>{formMessage.tittle}</span>{' '}
                    <FontAwesomeIcon
                        onClick={() => setShowModal(false)}
                        icon={faTimes}
                        className="icon-close closeModal"
                    />
                </div>
                <div className="content">
                    <hr />
                    <Fragment>
                        <div className="container-content-w2c">
                            <p>{formMessage.subTittle}</p>
                        </div>
                        <div className="container-main-input">
                            <SiigoInputAtom
                                value={formData.country_w2c}
                                type="text"
                                label={"País"}
                                required={true}
                                className="statusSelectCountry"
                                onchange={onTextChanged}
                            />
                        </div>
                        <div className="group-input">
                            <div className="group-input-left">
                                <SiigoInputAtom
                                    value={formData.countryCode_w2c}
                                    type="text"
                                    label={"Código país"}
                                    required={true}
                                    className="countryCode_w2c"
                                    onchange={onTextChanged}
                                />
                            </div>
                            <div className="group-input-right">
                                <SiigoInputAtom
                                    value={formData.indicative_w2c}
                                    type="text"
                                    label={"Indicativo"}
                                    required={true}
                                    className="indicative_w2c"
                                    onchange={onTextChanged}
                                />
                            </div>
                        </div>
                        <div className="group-input">
                            <div className="group-input-left">
                                <label className="label-state">Estado</label>
                                <label className="label-state mandatory-style">*</label>
                                <select
                                    id={SIGNAL_STATUS}
                                    name="statusSelect"
                                    className="statusSelect"
                                    onChange={(e) => handleOptions(e)}
                                    value={getValueBeforeSelect(SOURCE_STATE_SELECT)}
                                >
                                    <option value="" disabled hidden>Estado</option>
                                    <option value="true">Activo</option>
                                    <option value="false">Inactivo</option>
                                </select>
                            </div>
                            <div className="group-input-right end-align">
                                <SiigoInputAtom
                                    value={formData.defaultSkill_w2c}
                                    type="text"
                                    label={"Skill por defecto"}
                                    required={true}
                                    className="defaultSkill_w2c"
                                    onchange={onTextChanged}
                                />
                            </div>
                        </div>
                        <div className="container-buttons">
                            <div className="float-lf">
                                <SiigoButtonAtom
                                    color="tertiary"
                                    name="configButton"
                                    size="m"
                                    text={formMessage.cancelButton}
                                    type="button"
                                    className="cancelButton"
                                    onClick={() => setShowModal(false)}
                                />
                            </div>
                            {
                                isButtonActive ?
                                    (<div className="float-lf">
                                        <SiigoButtonAtom
                                            color="primary"
                                            name="configButton"
                                            size="m"
                                            text={formMessage.sendActionButton}
                                            type="button"
                                            disabled={isButtonActive}
                                            className={formMessage.sendActionButton}
                                            onClick={(e) => handleSubmit(e)}
                                        />
                                    </div>) : (
                                        <div className="float-lf">
                                            <SiigoButtonAtom
                                                color="primary"
                                                name="configButton"
                                                size="m"
                                                text={formMessage.sendActionButton}
                                                type="button"
                                                className={formMessage.sendActionButton}
                                                onClick={(e) => handleSubmit(e)}
                                            />
                                        </div>
                                    )
                            }
                        </div>
                    </Fragment>
                </div>
            </div>
        </div>
    );
}

export default Modal;