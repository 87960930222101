import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as cloudLogActions from '../../actions/cloudLog/cloudLogAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalResponse from '../layout/modals/modalResponse';
import DatePicker from './datePicker'
import './style.scss';
import { normalize } from '../tools/format';
import Modal from '../layout/modals/detailAccountant/index';
import { licenses } from './licenses'
import { StatusCodes } from 'http-status-codes';

const columns = [
	{
		name: 'Fecha',
		selector: 'date',
		sortable: true
	},
	{
		name: 'Estado',
		selector: 'hState',
		sortable: true
	},
	{
		name: 'NIT',
		selector: 'Nit',
		sortable: true
	},
	{
		name: 'Razón social',
		selector: 'CompanyFullName',
		sortable: true,
		filterDDL: true
	},

	{
		name: 'Email',
		selector: 'Email',
		sortable: true
	},
	{
		name: 'Tarea NSN',
		selector: 'WorkflowInstanceCode',
		sortable: true
	},
	{
		name: 'Source',
		selector: 'Source',
		sortable: true,
	},
	{
		name: 'Productos',
		selector: 'productCodeList',
		sortable: true,
	},
	{
		name: 'Respuesta',
		selector: 'response',
		sortable: true,
	}

];


let downloadEnable = false;
class CloudLog extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}


	componentDidMount() {
		this.queryCurrentDayAccountants();
	}


	queryCurrentDayAccountants = () => {
		let currentDate = new Date();
		const dates = this.handleDate({ startDate: currentDate, endDate: currentDate });
		dates.startDate.setHours(0);
		dates.startDate.setMinutes(0);
		dates.startDate.setSeconds(0);
		dates.startDate.setMilliseconds(0);
		const query = { params: { startDate: dates.startDate, endDate: dates.endDate } };
		this.props.actions.getCloudLog(query);
	}


	filtrarAccountant = e => {
		e.preventDefault();

		const nit = e.target.nit.value;

		const inputDateFromValue = document.getElementsByClassName('inputDatePicker')[0].value;
		const inputDateToValue = document.getElementsByClassName('inputDatePicker')[1].value;

		const data = [

			{ key: { nit: nit }, value: nit },
			{ key: { startDate: this.state.startDate }, value: this.state.startDate },
			{ key: { endDate: inputDateFromValue !== "" ? this.state.endDate : "" }, value: inputDateToValue !== "" ? this.state.endDate : "" },
		];


		let params = {};
		if (nit != '' || (inputDateFromValue != '' && inputDateToValue != '')) {
			data.map((val, id) => {
				if (val.value != '') {
					Object.assign(params, val.key);
				}
			});

			this.props.actions.getCloudLog({ params });
		} else {
			ModalResponse({
				title: '¡Hey!',
				text: 'Debe ingresar al menos un filtro',
				res: 404
			});
		}
	};

	cleanFilter = e => {
		e.preventDefault();
		document.getElementById('nit').value = '';
		this.queryCurrentDayAccountants();
	};


	handleDate = (data) => {

		let startDate = new Date(data.startDate);
		let endDate = new Date(data.endDate);
		endDate.setHours(23);
		endDate.setMinutes(59);
		endDate.setSeconds(59);
		endDate.setMilliseconds(999);
		this.setState({ startDate: String(startDate) });
		this.setState({ endDate: String(endDate) });


		return { startDate: startDate, endDate: endDate };
	}

	focusInput = (e) => {
		e.preventDefault();
		e.currentTarget.placeholder = '';
		e.currentTarget.classList.add('inputFocus');
		if (e.currentTarget.previousSibling)
			e.currentTarget.previousSibling.classList.add('labelModal');
	};

	blurInput = (e) => {
		e.preventDefault();
		if (e.currentTarget.value == '') {
			e.currentTarget.classList.add('inputError');
			if (e.currentTarget.previousSibling)
				e.currentTarget.previousSibling.classList.add('labelError');
		} else {
			e.currentTarget.classList.add('inputOk');
			if (e.currentTarget.previousSibling)
				e.currentTarget.previousSibling.classList.add('labelOk');
		}
	};



	showDetail = data => {
		this.setState({ xlmResponse: JSON.stringify(data.xml), show: !this.state.show, open: !this.state.open });
	};



	renderXMLResponse = (data) => {
		return (
			<div className="uxDetailContainer">
				<div>
					<span class="labelUxDetail">Respuesta XML: </span> <span id="uxDetailProduct"> {data} </span>
				</div>

			</div>
		);
	};


	onHandleDownload = (datos) => {
		downloadEnable = true;
		let dataDownload = null;
		let itemString = '';
		if (datos.length > 0) {
			let columnsReq = columns.map(function (column) {
				let columnAux = [];
				columnAux.title = column.name;
				columnAux.id = column.selector;
				return JSON.stringify(Object.assign({}, columnAux));
			}).join();

			let datosString = datos.map(function (dataItem) {
				return JSON.stringify(Object.assign({}, dataItem));
			}).join();

			let reqExcel = {
				"columns": JSON.parse(`[${columnsReq}]`),
				"data": JSON.parse(`[${datosString}]`),
				"typeFile": "csv"
			};

			this.props.actions.sendExcelObject(reqExcel);

		} else {
			ModalResponse({
				title: 'Descargar excel',
				text: 'No hay datos para descargar',
				res: 404,
			});
		}
	};


	downloadExcel = (data) => {
		var blob = new Blob([atob(data)], { type: 'text/plain; charset=utf-8' });
		if (window.navigator.msSaveBlob) {
			window.navigator.msSaveOrOpenBlob(blob, 'exportData' + new Date().toDateString() + '.csv');
		}
		else {
			var a = window.document.createElement("a");
			a.href = window.URL.createObjectURL(blob, { type: "text/plain; charset=utf-8" });
			a.download = "exportData" + new Date().toDateString() + ".csv";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
		}
	}


	render() {
		let { error, isLoading, cloudLogs, excelData } = this.props;
		const btnActions = {
			chMassiveActions: false,
			chShowResponseXml: this.showDetail,
		};

		let datos = [];

		if (cloudLogs) {
			if (cloudLogs.status == 200) {

				if (cloudLogs.data.status) {

					cloudLogs.data.data.map(function (logData) {
						let individualData = {
							hState: "Desconocido",
							date: logData.date,
							CompanyFullName: logData.body != undefined ? logData.body.CompanyFullName : "No definido.",
							Nit: logData.body != undefined ? logData.body.Nit : "No definido",
							Email: logData.body != undefined ? logData.body.Email : "No definido",
							Mobile: logData.body != undefined ? logData.body.Mobile : "No definido",
							WorkflowInstanceCode: logData.body != undefined ? logData.body.WorkflowInstanceCode : "No definido",
							Source: logData.body != undefined ? logData.body.Source : "No definido",
							productCodeList: logData.body != undefined ? logData.body.productCodeList : "No definido",
							response: logData.response,
							xml: logData.response
						};

						if (logData.body != undefined) {
							if (logData.body.productCodeList != undefined && logData.body.productCodeList.length > 0) {
								individualData.productCodeList = "";
								logData.body.productCodeList.map(function (item) {
									individualData.productCodeList += licenses[item] + " ";
								});
							}
						}


						if (individualData.response != null && individualData.response != undefined && typeof individualData.response == "string") {
							if (individualData.response.indexOf('success:true') != -1 || individualData.response.indexOf('success: true') != -1) {
								individualData.response = "Aprovisionado";
								individualData.hState = "Aprovisionado";
							} else if (individualData.response.indexOf('success:false') != -1 || individualData.response.indexOf('success: false') != -1) {
								individualData.response = "No aprovisionado";
								individualData.hState = "No aprovisionado";
							} else {
								individualData.response = "Error en aprovisionamiento";
								individualData.hState = "Error";
							}
						} else if (individualData.response != undefined && typeof individualData.response == "object") {
							individualData.response = individualData.response.msg;
							individualData.hState = "Actualizado";
						} else {
							individualData.response = "Estado desconocido";
							individualData.hState = "Desconocido";
						}


						datos.push(individualData);
					});
				} else {
					// Mensaje error
				}


			}
		}


		if (excelData) {
			if (excelData.status === StatusCodes.OK) {
				if (downloadEnable) {
					this.downloadExcel(excelData.data);
					downloadEnable = false;
				}
			}
		}

		return (
			<div>
				<div>
					<h3>Siigo Nube Log</h3>
					<form onSubmit={this.filtrarAccountant}>
						<div className="row divFilter">
							<DatePicker handleDate={this.handleDate} />
						</div>

						<div className="row divFilter">
							<div className="input-container-accountant column4">
								<label>NIT</label>
								<input
									className="inputFilter"
									id="nit"
									name="nit"
									type="text"
									placeholder=""
									onFocus={(e) => this.focusInput(e)}
									onBlur={(e) => this.blurInput(e)}
								/>
							</div>

							<div className="column4">
								<div className="row">
									<div>
										<button className="green-fill btnBuscar" id="submitFilterButton" type="submit">
											<div className="row">
												<div>
													<span className="siicon icon-search searchMarket"></span>
												</div>
												<div>
													<span>Buscar</span>
												</div>
											</div>
										</button>
									</div>
									<div className="column2">
										<button
											onClick={this.cleanFilter}
											className="orange-fill"
											type="button"
										>
											Limpiar
                    </button>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
				<Modal
					onClose={this.showDetail}
					show={this.state.show}
					title="Detalle"
					open={this.state.open}>
					<div className="uxDetailResponseXml">
						<h3>Respuesta</h3>
					</div>
					{this.renderXMLResponse(this.state.xlmResponse)}
				</Modal>
				{error && <h4>Error: {error.response.data}</h4>}
				{(isLoading) ? (
					<Loader />
				) : (
						<div>

							<div className="divTable">
								<TableReport
									columns={columns} // Data columns to table
									tabs={false} //Enable tabs
									tabsData={[]} //Load data to tabs check option on tabsData const
									headerCounterTab={true} //Counter tab option
									btnActions={btnActions}
									actionButtons={true} //Enable buttons (edit, delete, copy, open)
									pageSize={50} // Enable page size
									pagination={true} //Enable pagination (true, false)
									paginationNav={'arrows'} //Nav options (arrow, dot)
									paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
									maxSizePagination={6} //Size pagination
									defaultPaginationNumber={1} //Current pagination when start app
									paginationGrouped={10} //Grouped by page
									detailRow={false} //Open column detail with left arrow
									data={datos} //Data for table
								/>
							</div>
							<button className="button green-fill btnDownload" onClick={e => this.onHandleDownload(datos)}>
								{' '}
								Descargar excel
							 </button>
						</div>

					)}
			</div>
		);
	}
}

CloudLog.propTypes = {
	actions: PropTypes.object.isRequired,
	cloudLog: PropTypes.object,
	error: PropTypes.object,
	excelData: PropTypes.object
};

const mapStateToProps = state => ({
	cloudLogs: state.cloudLog.cloudLogs,
	error: state.cloudLog.err,
	isLoading: state.cloudLog.isLoading,
	excelData: state.synergy.excelData,
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(cloudLogActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(CloudLog);
