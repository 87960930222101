import React, { Component } from 'react';
import InvoiceListWrapper from '../../components/contracts/wrapper';

class InvoicesContainer extends Component {
	render() {
		return (
			<div>
				<InvoiceListWrapper parentPath={this.props.path}/>
			</div>
		);
	}
}

export default InvoicesContainer;
