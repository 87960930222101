import React from 'react';
import { useAuth } from '../../context/auth-context';
import InvoiceList from './reportInvoice';

const InvoiceListWrapper = (props) => {
    const { checkAuth } = useAuth();
    return <InvoiceList {...props} checkAuth={checkAuth} />;
};

export default InvoiceListWrapper;
