import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api';

export function* getLogs() {
	const api = API.create();
	try {
		let logs = yield call(api.logs.logs);
		yield put({ type: types.LOGS_RECEIVED, data: logs });
	} catch (err) {
		yield put({ type: types.LOGS_REQUEST_FAILED, err });
	}
}


export function* getQueryLogs(action) {
	const api = API.create();
	try {
		const { params } = action;
		let logs = yield call(api.logs.getQueryLogs, params)

		yield put({ type: types.GET_QUERY_LOGS_RECEIVED, data: logs })
	} catch (err) {
		yield put({ type: types.GET_QUERY_LOGS_FAILED, err })
	}
}

export function* createLogs(action) {
	const api = API.create();
	try {
		const { params } = action;
		const logDetail = yield call(api.logs.createLog, params);
		yield put({ type: types.CREATE_LOG_SUCCESS, data: logDetail, });
	} catch (err) {
		yield put({ type: types.CREATE_LOG_FAILED, err });
	}
}
