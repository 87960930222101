import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { bindActionCreators } from "redux";
import {
  getCampaigns,
  patchCampaignState,
} from "../../../../actions/shop/campaign";
import { connect } from "react-redux";
import moment from "moment";
import ErrorHelper from "../../helpers/error";
import UrlHelper from "../../helpers/url";
import Constants from "../../constants";
import {
  postUpdateCache,
  postUpdateResetData,
} from "../../../../actions/siigoCom/cache";

const DATE_FORMAT = "YYYY/MM/DD HH:mm";
const DEFAULT_PAGE_DOCUMENTS = 10;
const CAMPAIGN_PARAMS = { type: "", code: "", destination: "" };

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  table: {
    minWidth: 650,
  },
  button: {
    margin: theme.spacing(1),
    textTransform: "none",
    "&:hover": {
      border: "none",
    },
  },
  inactiveButton: {
    backgroundColor: "#FAA532",
    color: "#FFF",
    "&:hover": {
      backgroundColor: "#FFD764",
      color: "#C77600",
    },
  },
}));

function mapStateToProps(state) {
  return {
    error: state.shopReducer.error,
    campaigns: state.shopReducer.campaigns,
    isLoading: state.shopReducer.isLoading,
    response: state.shopReducer.response,
    updateCacheError: state.siigoComReducer.error,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCampaignsAction: bindActionCreators(getCampaigns, dispatch),
    patchCampaignStateAction: bindActionCreators(patchCampaignState, dispatch),
    postUpdateCacheAction: bindActionCreators(postUpdateCache, dispatch),
    postUpdateCacheResetDataAction: bindActionCreators(
      postUpdateResetData,
      dispatch
    ),
  };
}

function CampaignList(props) {
  const {
    campaigns,
    error,
    isLoading,
    patchCampaignStateAction,
    postUpdateCacheAction,
    updateCacheError,
    response,
    getCampaignsAction,
    postUpdateCacheResetDataAction,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogData, setDialogData] = useState({
    show: false,
    sended: false,
  });
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [selectedState, setSelectedState] = useState();
  const history = useHistory();
  const queryParams = new URLSearchParams(history?.location?.search);
  let queryParamsParsed = UrlHelper.getParsedQueryParams(
    Constants.SEARCH_CAMPAIGN_INPUTS_FORM,
    queryParams
  );
  const [page, setPage] = useState(Number(queryParamsParsed?.pageNumber) || 1);

  useEffect(() => {
    if (
      response?.status === "OK" &&
      selectedCampaign &&
      selectedState?.length
    ) {
      setDialogData({
        show: true,
        sended: true,
        title: t("publish", { name: t("name") }),
        text: t("stateChanged", {
          context: "female",
          content: `${t("name")} '${selectedCampaign?.code}'`,
          state: t(`shop.campaigns.management.list.actions.${selectedState}`),
        }),
      });
      updateSiigoComCache();

      const queryParams = new URLSearchParams(history?.location?.search);
      let campaignData = {};
      Object.keys(CAMPAIGN_PARAMS).forEach(
        (key) => (campaignData[key] = queryParams.get(key))
      );
      getCampaignsAction(campaignData);
    }
    if (error && campaigns?.data?.length > 0) {
      setDialogData({
        show: true,
        sended: true,
        title: t("publish", { name: t("name") }),
        text: t("notStateChanged", {
          context: "female",
          content: `${t("name")} '${selectedCampaign?.code}'`,
          state: t(`shop.campaigns.management.list.actions.${selectedState}`),
        }),
      });
    }
  }, [response, error]);

  useEffect(() => {
    updateCacheError &&
      setDialogData({
        show: true,
        sended: true,
        title: t("publish", { name: t("name") }),
        text: t(`shop.campaigns.cache.failed`),
      });

    return () => postUpdateCacheResetDataAction();
  }, [updateCacheError]);

  if (error) return ErrorHelper.errorDialog(t, error?.message);

  const handleChangePage = (_, newPage) => {
    setPage(newPage + 1);
    queryParamsParsed["pageNumber"] = newPage + 1;
    queryParamsParsed["pageDocuments"] = DEFAULT_PAGE_DOCUMENTS;
    Object.entries(queryParamsParsed).forEach(([key, value]) => {
      queryParams.set(key, value);
    });
    history.push({
      search: queryParams.toString(),
    });
    getCampaignsAction(queryParamsParsed);
  };

  const updateSiigoComCache = () => {
    selectedCampaign?.destination ===
      Constants.CAMPAIGN_DESTINATIONS.SIIGO_COM.VALUE &&
      postUpdateCacheAction({
        ...Constants.UPDATE_SIIGO_COM_CACHE_BODY,
        value: selectedCampaign?.code,
      });
  };

  const changeCampaignState = (id, state) => {
    patchCampaignStateAction({ id, state });
  };

  const dialog = () => {
    return (
      <Dialog
        open={dialogData.show}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {!dialogData.sended ? (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {dialogData.title}
            </DialogContentText>
          </DialogContent>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">{`${t(
              "shop.campaigns.management.list.buttons.activate"
            )} ${t("name")}`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogData.text}
              </DialogContentText>
            </DialogContent>
          </>
        )}
        <DialogActions>
          {!dialogData.sended && (
            <>
              <Button
                onClick={() =>
                  changeCampaignState(selectedCampaign?.id, selectedState)
                }
                color="primary"
                variant="contained"
                className={classes.primaryButton}
                autoFocus
              >
                {t("yes")}
              </Button>
              <Button
                onClick={() =>
                  setDialogData({
                    show: false,
                  })
                }
                variant="outlined"
                color="primary"
              >
                {t("no")}
              </Button>
            </>
          )}
          {dialogData.sended && (
            <>
              <Button
                onClick={() =>
                  setDialogData({
                    show: false,
                  })
                }
                color="primary"
                variant="contained"
                className={classes.primaryButton}
                autoFocus
              >
                {t("accept")}
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    );
  };

  const getCampaignType = (campaign) => {
    let campaignType =
      Constants.CAMPAIGN_TYPES[campaign?.type.toUpperCase()]?.LABEL;
    for (let index = 0; index < campaign?.configurations?.length; index++) {
      const configuration = campaign?.configurations[index];
      if (
        !configuration?.period &&
        campaign?.type === Constants.CAMPAIGN_TYPES.UPGRADE.VALUE
      ) {
        campaignType = Constants.CAMPAIGN_TYPES.DISCOUNT_CODE.LABEL;
        break;
      }
    }
    return campaignType;
  };

  const makeRows = () => {
    return campaigns?.data?.map((campaign, index) => (
      <TableRow key={index}>
        <TableCell component="th" scope="row">
          <p>{index + 1}</p>
        </TableCell>
        <TableCell component="th" scope="row">
          <p>{campaign?.name}</p>
        </TableCell>
        <TableCell component="th" scope="row">
          <p>{campaign?.code}</p>
        </TableCell>
        <TableCell component="th" scope="row">
          <p>{getCampaignType(campaign)}</p>
        </TableCell>
        <TableCell component="th" scope="row">
          <p>{moment(campaign?.date?.start).format(DATE_FORMAT)}</p>
        </TableCell>
        <TableCell component="th" scope="row">
          {campaign?.date?.end && (
            <p>{moment(campaign?.date?.end).format(DATE_FORMAT)}</p>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          <p>{t(`shop.campaigns.management.list.states.${campaign?.state}`)}</p>
        </TableCell>
        <TableCell component="th" scope="row">
          <Button
            className={`${classes.button} ${classes.inactiveButton}`}
            onClick={() => {
              setSelectedCampaign(campaign);
              setSelectedState(Constants.CAMPAIGN_STATES.INACTIVE);
              setDialogData({
                show: true,
                sended: false,
                title: t("change", {
                  state: t("shop.campaigns.management.list.buttons.inactivate"),
                  content: `${t("name")} ${campaign?.code}`,
                }),
              });
            }}
            variant="contained"
            type="button"
            disabled={campaign?.state === Constants.CAMPAIGN_STATES.INACTIVE}
          >
            {t("shop.campaigns.management.list.buttons.inactivate")}
          </Button>
          <Button
            className={classes.button}
            onClick={() => {
              setSelectedCampaign(campaign);
              setSelectedState(Constants.CAMPAIGN_STATES.ACTIVE);
              setDialogData({
                show: true,
                sended: false,
                title: t("change", {
                  state: t("shop.campaigns.management.list.buttons.activate"),
                  content: `${t("name")} ${campaign?.code}`,
                }),
              });
            }}
            color="primary"
            variant="contained"
            type="button"
            disabled={campaign?.state === Constants.CAMPAIGN_STATES.ACTIVE}
          >
            {t("shop.campaigns.management.list.buttons.activate")}
          </Button>
        </TableCell>
      </TableRow>
    ));
  };

  return !isLoading && campaigns ? (
    <div className={classes.detailSection}>
      {dialog()}
      <h5>{t("shop.campaigns.management.list.title")}</h5>
      {campaigns?.data?.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    {t("shop.campaigns.management.list.counting")}
                  </TableCell>
                  <TableCell>
                    {t("shop.campaigns.management.list.name")}
                  </TableCell>
                  <TableCell>
                    {t("shop.campaigns.management.list.code")}
                  </TableCell>
                  <TableCell>
                    {t("shop.campaigns.management.list.type")}
                  </TableCell>
                  <TableCell>
                    {t("shop.campaigns.management.list.date.start")}
                  </TableCell>
                  <TableCell>
                    {t("shop.campaigns.management.list.date.end")}
                  </TableCell>
                  <TableCell>
                    {t("shop.campaigns.management.list.state")}
                  </TableCell>
                  <TableCell>
                    {t("shop.campaigns.management.list.action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{makeRows()}</TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[DEFAULT_PAGE_DOCUMENTS]}
            backIconButtonText={t("previousPage")}
            nextIconButtonText={t("nextPage")}
            component="div"
            count={campaigns?.total}
            rowsPerPage={DEFAULT_PAGE_DOCUMENTS}
            page={page - 1}
            onPageChange={handleChangePage}
          />
        </>
      ) : (
        <h5>{t("shop.campaigns.management.list.notFound")}</h5>
      )}
    </div>
  ) : null;
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignList);
