import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as statePaymentReportActions from '../../actions/statePaymentReport/statePaymentReportActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalResponse from '../layout/modals/modalResponse';
import DatePicker from '../tools/datePicker';
import './style.scss';
import { PaymentStates, paymentStates } from './states';
import { StatusCodes } from 'http-status-codes';


const columns = [
    {
        name: 'Identificación del cliente',
        selector: 'data.adminId',
        sortable: true
    },
    {
        name: 'Nombre de la empresa',
        selector: 'data.companyName',
        sortable: true,
    },
    {
        name: 'Origen',
        selector: 'data.origen',
        sortable: true
    },
    {
        name: 'Fecha de la transacción',
        selector: 'data.creationDate',
        sortable: true
    },
    {
        name: 'Estado de la transacción',
        selector: 'data.orderTxStatus',
        sortable: true,
        filterDDL: true
    },
    {
        name: 'Nombre del producto',
        selector: 'data.productName',
        sortable: true
    },
    {
        name: 'Monto de la transacción',
        selector: 'data.value',
        sortable: true,
    },
    {
        name: 'Medio de pago',
        selector: 'data.paymentMethodType',
        sortable: true,
    },
    {
        name: 'Referencia interna aurora',
        selector: '_id',
        sortable: true,
    },
    {
        name: 'Serial',
        selector: 'data.serial',
        sortable: true,
    },
    {
        name: 'Codigo estado de ultima transacción',
        selector: 'data.responseCodePlataform',
        sortable: true,
    },
    {
        name: 'Descripción de estado de ultima transacción',
        selector: 'data.responseDescriptionPlataform',
        sortable: true,
    }
];


class StatePaymentReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: undefined,
            toDate: undefined,
            payment: {
                data: {}
            }

        };

    }



    componentDidMount() {
        document.title = "Reporte de Estados de pago";
        
    }

    queryCurrentDayPayments = () => {

        let currentDateFrom = new Date();
        currentDateFrom.setHours(0);
        currentDateFrom.setMinutes(0);
        currentDateFrom.setSeconds(0);
        currentDateFrom.setMilliseconds(0);

        let currentDateTo = new Date();
        currentDateTo.setHours(23);
        currentDateTo.setMinutes(59);
        currentDateTo.setSeconds(59);
        currentDateTo.setMilliseconds(999);

        const query = {
            data: {
                "data.isRecurrentPay": { "$exists": true, "$eq": "true" },
                date: { "$gt": currentDateFrom.toISOString(), "$lt": currentDateTo.toISOString() }
            },
            sort: { "date": -1 }
        };
        this.props.actions.getStatePayments(query);
        
    }


    filtrarPayments = e => {
        e.preventDefault();

        const payerDoc = e.target.payerDoc.value;
        //const email = e.target.email.value;
        // const payerName = e.target.payerName.value;
        const id = e.target.id.value;
        const inputDateFromValue = document.getElementsByClassName('inputDatePicker')[0].value;
        const inputDateToValue = document.getElementsByClassName('inputDatePicker')[1].value;
        const transactionId = e.target.transactionId.value;
        const originalTransactionId = e.target.originalTransactionId.value;

        let query = {
            data: {
            },
            sort: { "date": -1 }
        }

        if ((originalTransactionId != "" || payerDoc != '' || id != '' || transactionId != "")  && (inputDateFromValue != '' && inputDateToValue != '')) {
            if (payerDoc !== '' && payerDoc !== undefined && payerDoc !== null) {
                query.data['data.adminId'] = { "$regex": ".*" + payerDoc + ".*", "$options": "i" };
            }
            if (id !== '' && id !== undefined && id !== null) {
                query.data["_id"] = id;
            }
            
            if (transactionId !== '' && transactionId !== undefined && transactionId !== null) {
                query.data["data.productId"] = { "$regex": ".*" + transactionId + ".*", "$options": "i" };
            }
            if (originalTransactionId !== '' && originalTransactionId !== undefined && originalTransactionId !== null) {
                query.data["data.serial"] = { "$regex": ".*" + originalTransactionId + ".*", "$options": "i" };
            }
           
            if (inputDateFromValue !== '' && inputDateFromValue !== undefined && inputDateFromValue !== null) {
                query.data.date = { ...query.data.date, $gt: this.state.fromDate };
            }
            if (inputDateToValue !== '' && inputDateToValue !== undefined && inputDateToValue !== null) {
                query.data.date = { ...query.data.date, $lt: this.state.toDate };
            }
            this.props.actions.getStatePayments(query);
        } else {
            ModalResponse({
                title: '¡Hey!',
                text: 'Debe ingresar al menos un filtro',
                res: 404
            });
            var list = document.getElementsByClassName('inputFilter')
            for (var i = 0; i < list.length; ++i) {
                list[i].classList.remove('labelOk');
                list[i].classList.add('inputError');
            }
        }
    };

    cleanFilter = e => {
        e.preventDefault();
        var payerDoc = document.getElementById('payerDoc');
        payerDoc.value = '';
        payerDoc.classList.remove("inputError", "inputOk", "inputFocus");

        var id = document.getElementById('id');
        id.value = '';
        id.classList.remove("inputError", "inputOk", "inputFocus");

        var transactionId = document.getElementById('transactionId');
        transactionId.value = '';
        transactionId.classList.remove("inputError", "inputOk", "inputFocus");

        var originalTransactionId = document.getElementById('originalTransactionId');
        originalTransactionId.value = '';
        originalTransactionId.classList.remove("inputError", "inputOk", "inputFocus");

        this.setState({ fromDate: undefined, toDate: undefined });
        this.queryCurrentDayPayments();
    };


    showDetail = data => {
        this.setState({ payment: data, showDetail: true, openDetail: true });
    };

    handleDate = (data) => {
        let fromDate = undefined;
        let toDate = undefined;
        if (data.from != undefined) {
            fromDate = new Date(data.from);
            fromDate.setHours(0);
            fromDate.setMinutes(0);
            fromDate.setSeconds(0);
            fromDate.setMilliseconds(0);
        }
        if (data.to != undefined) {
            toDate = new Date(data.to);
            toDate.setHours(23);
            toDate.setMinutes(59);
            toDate.setSeconds(59);
            toDate.setMilliseconds(999);
        }
        this.setState({ fromDate });
        this.setState({ toDate });
        return { from: fromDate, to: toDate };
    }
    
    focusInput = (e) => {
        e.preventDefault();
        var list = document.getElementsByClassName('inputFilter')
        for (var i = 0; i < list.length; ++i) {
            list[i].classList.remove('inputError');
        }
    };


    render() {
        let { error, isLoading, payments, excelData } = this.props;
        
        
        const btnActions = {

            btnShowDetail: this.showDetail.bind(this),
        }

        let datos = [];

        if (payments) {

            if (payments.status == StatusCodes.CREATED) {
                datos = payments.data;
                datos.map(function (datos) {
                    datos.data.serial = (datos.data.serial === undefined || datos.data.serial == "" || datos.data.serial == null) ? "N/A" : datos.data.serial;
                    datos.data.productName = (datos.data.productName === undefined || datos.data.productName == "" || datos.data.productName == null) ? "N/A" : datos.data.productName;
                    datos.data.responseCodePlataform = datos.data.responseCodePlataform === undefined ? "N/A" : datos.data.responseCodePlataform;
                    datos.data.responseDescriptionPlataform = datos.data.responseDescriptionPlataform === undefined ? "N/A" : datos.data.responseDescriptionPlataform;
                    datos.data.paymentMethodType = datos.data.paymentMethodType === undefined ? "N/A" : datos.data.paymentMethodType;
                    return payments;
                });

            }
        }
        return (
            <div>
                <div>
                    <h3>Reporte de Estados de pago</h3>
                    <form onSubmit={this.filtrarPayments}>
                        <div className="row divFilter">
                            <DatePicker handleDate={this.handleDate} from={this.state.fromDate} to={this.state.toDate} />
                            <div className="column4"></div>
                        </div>

                        <div className="row divFilter">



                            <div className="input-container-accountant column4">
                                <label>Identifiación Cliente</label>
                                <input
                                    className="inputFilter"
                                    id="payerDoc"
                                    name="payerDoc"
                                    type="text"
                                    placeholder=""
                                    onFocus={(e) => this.focusInput(e)}
                                    
                                />
                            </div>
                            <div className="input-container-accountant column4">
                                <label>Cotización, factura, Cuenta </label>
                                <input
                                    className="inputFilter"
                                    id="transactionId"
                                    name="transactionId"
                                    type="text"
                                    placeholder=""
                                    onFocus={(e) => this.focusInput(e)}
                                    
                                />
                            </div>
                            <div className="input-container-accountant column4">
                                <label>Referencia interna aurora</label>
                                <input
                                    className="inputFilter"
                                    id="id"
                                    name="id"
                                    type="text"
                                    placeholder=""
                                    onFocus={(e) => this.focusInput(e)}
                                   
                                />
                            </div>
                            <div className="input-container-accountant column4">
                                <label>Serial</label>
                                <input
                                    className="inputFilter"
                                    id="originalTransactionId"
                                    name="originalTransactionId"
                                    type="text"
                                    placeholder=""
                                    onFocus={(e) => this.focusInput(e)}
                                   
                                />
                            </div>
                            <div className="column4">
                                <div className="row">
                                    <div className="column2">
                                        <button className="green-fill btnBuscar" id="submitFilterButton" type="submit">
                                            <div className="row">
                                                <div className="column2">
                                                    <span className="siicon icon-search searchMarket"></span>
                                                </div>
                                                <div className="column2">
                                                    <span>Buscar</span>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="column2">
                                        <button
                                            onClick={this.cleanFilter}
                                            className="orange-fill"
                                            type="button"
                                        >
                                            Limpiar
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {error && <h4>Error: {error.response.data}</h4>}
                {(isLoading) ? (
                    <Loader />
                ) : (
                        <div>

                            <div className="divTable">
                                <TableReport
                                    columns={columns} // Data columns to table
                                    tabs={false} //Enable tabs
                                    tabsData={[]} //Load data to tabs check option on tabsData const
                                    headerCounterTab={true} //Counter tab option
                                    btnActions={btnActions}
                                    pageSize={50} // Enable page size
                                    pagination={true} //Enable pagination (true, false)
                                    paginationNav={'arrows'} //Nav options (arrow, dot)
                                    paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
                                    maxSizePagination={6} //Size pagination
                                    defaultPaginationNumber={1} //Current pagination when start app
                                    paginationGrouped={10} //Grouped by page
                                    detailRow={false} //Open column detail with left arrow
                                    data={datos} //Data for table
                                />
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

StatePaymentReport.propTypes = {
    actions: PropTypes.object,
    error: PropTypes.object,
};

const mapStateToProps = state => ({
    payments: state.statePaymentReport.payments,
    error: state.statePaymentReport.error,
    isLoading: state.statePaymentReport.isLoading,

});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(statePaymentReportActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(StatePaymentReport);

