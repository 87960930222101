import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api';

export function* createComponent(action) {
  const api = API.create();
  try {
    const { comp } = action;
    const componentDetail = yield call(api.components.createComponent, comp); //.then(response => response.data);
    yield put({
      type: types.CREATE_COMPONENT_SUCCESS,
      data: componentDetail
    });
  } catch (err) {
    yield put({ type: types.CREATE_COMPONENT_FAILED, err });
  }
}

export function* updateComponent(action) {
  const api = API.create();
  try {
    const { comp, msisdn } = action;
    const componentDetail = yield call(
      api.components.updateComponent,
      comp,
      msisdn
    );
    yield put({
      type: types.UPDATE_COMPONENT_SUCCESS,
      data: componentDetail
    });
  } catch (err) {
    yield put({ type: types.UPDATE_COMPONENT_FAILED, err });
  }
}

export function* deleteComponent(action) {
  const api = API.create();
  const { msisdn } = action;
  try {
    const response = yield call(api.components.deleteComponent, msisdn);
    yield put({ type: types.DELETE_COMPONENT_SUCCESS, data: response });
    yield put({ type: types.GET_COMPONENTS });
  } catch (err) {
    yield put({ type: types.DELETE_COMPONENT_FAILED, err });
  }
}

export function* getComponents() {
  const api = API.create();

  try {
    let components = yield call(api.components.components);
    yield put({ type: types.COMPONENTS_RECEIVED, data: components });
  } catch (err) {
    yield put({ type: types.COMPONENTS_REQUEST_FAILED, err });
  }
}

export function* getComponent(action) {
  const api = API.create();
  try {
    const { msisdn } = action;
    let components = yield call(api.components.componentDetail, msisdn);
    yield put({ type: types.COMPONENT_RECEIVED, data: components });
  } catch (err) {
    yield put({ type: types.COMPONENT_REQUEST_FAILED, err });
  }
}
