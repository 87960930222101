import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import ModalResponse from '../layout/modals/modalResponse';

import * as ComponentActions from '../../actions/components/componentActions';
import { StatusCodes } from 'http-status-codes';
let save = false;
class ComponentCreate extends Component {
  onHandleCreate = event => {
    event.preventDefault();

    let name = event.target.name.value;
    let description = event.target.description.value;
    let icon = event.target.icon.value;
    let order = event.target.order.value;
    let path = event.target.path.value;
    let parent =
      event.target.parent.value === '-9' ? [] : event.target.parent.value;
    const data = {
      name,
      description,
      icon,
      order,
      path,
      parent
    };

    this.props.actions.createComponent(data);
    save = true;
  };

  componentDidMount() {
    document.title = 'Create Component';
    this.props.actions.getComponents();
  }

  render() {
    const { isLoading, component, components } = this.props;

    let comps = [];
    let text = '';
    if (components) {
      if (components.data.error === 'Forbidden') {
        return <div>No tiene permiso para este componente</div>;
      }
      comps = components.data;
    }
    if (component && save && !isLoading) {
      if (component.status === StatusCodes.CREATED) {
        this.props.history.push('/components');
      } else {
        text = 'No fue posible guardar el Componente';
      }

      save = false;
    }
    return (
      <div>
        {text !== '' ? (
          <ModalResponse title={'Crear!'} text={text} res={404} />
        ) : null}
        <form onSubmit={this.onHandleCreate}>
          <h3>Crear Componente</h3>
          <div className="input-container">
            <i className="sii sii-user icon" />
            <input type="text" name="name" id="name" placeholder="Nombre" />
          </div>
          <div className="input-container">
            <i className="sii sii-user icon" />
            <input
              type="text"
              name="description"
              id="description"
              placeholder="Descripcion"
            />
          </div>
          <div className="input-container">
            <i className="sii sii-user icon" />
            <input type="text" name="icon" id="icon" placeholder="Icon" />
          </div>
          <div className="input-container">
            <i className="sii sii-user icon" />
            <input type="text" name="order" id="order" placeholder="Orden" />
          </div>
          <div className="input-container">
            <i className="sii sii-user icon" />
            <input type="text" name="path" id="path" placeholder="Path" />
          </div>
          <div className="input-container">
            <i className="sii sii-user icon" />
            <select name="parent" id="parent">
              <option value="-9">Raiz</option>
              {comps.map((comp, index) => {
                return (
                  <option key={index} value={comp._id}>
                    {comp.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <button className="button green-fill">Guardar</button>
          </div>
        </form>
      </div>
    );
  }
}

ComponentCreate.propTypes = {
  actions: PropTypes.object.isRequired,
  component: PropTypes.object,
  error: PropTypes.object
};

const mapStateToProps = state => ({
  component: state.components.component,
  components: state.components.components,
  error: state.components.err,
  isLoading: state.components.isLoading
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(ComponentActions, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ComponentCreate));
