import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api';

const ACTIONS = {
  CREATE: "create",
  EDIT: "edit",
  DELETE: "delete"
}

export function* createUser(action) {
  const api = API.create();
  try {
    const { user } = action;
    const userDetail = yield call(api.users.createUser, user).then(
      response => response.data
    );
    yield put({
      type: types.CREATE_USER_SUCCESS, data: {
        ...userDetail, action: ACTIONS.CREATE
      }
    });
  } catch (error) {
    yield put({ type: types.CREATE_USER_FAILED, error, action: ACTIONS.CREATE });
  }
}

export function* updateUser(action) {
  const api = API.create();
  try {
    const { user, msisdn } = action;
    const userDetail = yield call(api.users.updateUser, user, msisdn);
    yield put({
      type: types.UPDATE_USER_SUCCESS, data: {
        ...userDetail, action: ACTIONS.EDIT
      }
    });
  } catch (error) {
    yield put({ type: types.UPDATE_USER_FAILED, error, action: ACTIONS.EDIT });
  }
}

export function* deleteUser(action) {
  const api = API.create();
  const { msisdn } = action;

  try {
    const response = yield call(api.users.deleteUser, msisdn);
    yield put({ type: types.DELETE_USER_SUCCESS, response, action: ACTIONS.DELETE });
    yield put({ type: types.GET_USERS });
  } catch (error) {
    yield put({ type: types.DELETE_USER_FAILED, error, action: ACTIONS.DELETE });
  }
}

export function* getUsers() {
  const api = API.create();
  try {
    let users = yield call(api.users.users);
    yield put({ type: types.USERS_RECEIVED, data: users });
  } catch (err) {
    yield put({ type: types.USERS_REQUEST_FAILED, err });
  }
}

export function* getUser(action) {
  const api = API.create();
  try {
    const { msisdn } = action;
    let user = yield call(api.users.userDetail, msisdn);

    yield put({ type: types.USER_RECEIVED, data: user });
  } catch (err) {
    yield put({ type: types.USER_REQUEST_FAILED, err });
  }
}

export function* getPerfil(action) {
  const api = API.create();
  try {
    const { msisdn } = action;
    let user = yield call(api.users.userPerfil, msisdn);

    yield put({ type: types.USER_PERFIL_RECEIVED, data: user });
  } catch (err) {
    yield put({ type: types.USER_PERFIL_REQUEST_FAILED, err });
  }
}

export function* setAvatar(action) {
  const api = API.create();
  try {
    const { avatar, msisdn } = action;
    let user = yield call(api.users.userAvatar, avatar, msisdn);

    yield put({ type: types.USER_AVATAR_RECEIVED, data: user });
    yield put({ type: types.GET_USER_PERFIL, msisdn });
  } catch (err) {
    yield put({ type: types.USER_AVATAR_REQUEST_FAILED, err });
  }
}

export function* getMenuUser(action) {
  const api = API.create();
  try {
    const { msisdn } = action;
    const menu = yield call(api.users.menuUser, msisdn);
    yield put({ type: types.GET_MENU_USER_RECEIVED, data: menu });
  } catch (err) {
    yield put({ type: types.GET_MENU_USER_FAILED, err });
  }
}
