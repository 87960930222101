import axios from 'axios';
import ActivationManagement from './services/ActivationManagement';
import config from '../../config';

const create = () => {
  const api = axios.create({
    baseURL: config.apiBaseUrl || '',
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      Authorization: `bearer ${config.activationManagementToken}`
    },
    timeout: 30000
  });

  const activationManagement = new ActivationManagement(api);

  return {
    setHeader: (key, value) => { api.defaults.headers.common[key] = value; },
    removeHeader: (key) => { delete api.defaults.headers.common[key]; },
    activationManagement
  };
};

export default {
  create
};
