import axios from "axios";
import SiigoComService from "./services/SiigoCom";
import config from "../../config";

const create = (baseURL = config.url) => {
  const api = axios.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
      "content-type": "application/json",
    },
    timeout: 30000,
  });

  const siigoComService = new SiigoComService(api);

  return {
    setHeader: (key, value) => { api.defaults.headers.common[key] = value; },
    removeHeader: (key) => { delete api.defaults.headers.common[key]; },
    siigoComService,
  };
};

export default {
  create,
};