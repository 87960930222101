import React, { Component } from 'react';
import Sheadmodal from '../slayout/sheader/sheadermodal';

import { SiigoSelectAtom } from '@siigo/siigo-select-atom-react'
import { SiigoCheckboxAtom } from '@siigo/siigo-checkbox-atom-react'
import { SiigoInputAtom  } from '@siigo/siigo-input-atom-react'
import { SiigoTextAreaAtom } from '@siigo/siigo-text-area-atom-react'
import { SiigoButtonAtom } from '@siigo/siigo-button-atom-react'

import Sperfilanfitrionespacio from './sperfilanfitrionespacio';
import SiigoSelectPais from '../sshared/SiigoSelectPais/SiigoSelectPais.js';
import { Redirect } from 'react-router-dom'

import { tipoAlojamientoOptions, conQuienCompartoAlojamiento } from '../data/data' 
import { UpdateSiiger, UpdateSiigerPerfilHost } from '../helpers/SiigerHelper';

class Sperfilanfitrion extends Component {
    constructor(props) {
        super(props);
        this.countryElement = React.createRef();
        this.spotElement  = React.createRef();
        let siiger = JSON.parse(localStorage.getItem('siiger'));

        //tipo de alojamiento
        let _titems = tipoAlojamientoOptions;
        let objIndex = tipoAlojamientoOptions.findIndex((obj => obj.id == siiger.host.type ));
        _titems[objIndex].selected = true;

        //comparto casa
        let _comItems = conQuienCompartoAlojamiento
        objIndex = conQuienCompartoAlojamiento.findIndex((obj => obj.id == siiger.host.familyHost ));
        _comItems[objIndex].selected = true;

        this.state = { 
            itemsCompartoCasa: _comItems,
            itemsAlojamiento: _titems,
            siiger : JSON.parse(localStorage.getItem('siiger'))
        };  
    }

    savePerfilClick = () => {
        const dataCountryElement = this.countryElement.current;
        const placesElement = this.spotElement.current;

        let childPlaces = this.state.siiger.host.place;
        childPlaces[0].peoplecount = placesElement.state.pCount;
        childPlaces[0].placetype = placesElement.state.tAcomo;
        childPlaces[0].bedtype = placesElement.state.tBed;

        this.setState({ 
            itemsAlojamiento: this.state.itemsAlojamiento,
            siiger: { ...this.state.siiger,
                host: { ...this.state.siiger.host, 
                    country: dataCountryElement.state.country,
                    state: dataCountryElement.state.states,
                    place: childPlaces,
                    } 
                } 
            },        
            () => { 
                localStorage.setItem("siiger", JSON.stringify(this.state.siiger));
                UpdateSiigerPerfilHost(this.state.siiger); //esto deberia devolver una promesa.... ¬¬ #toDo
                this.setState({redirect: true});
            });                                   
    }

    //ok
    onChangeSharedAlojamiento(e){
        let citems = conQuienCompartoAlojamiento;
        let objIndex = conQuienCompartoAlojamiento.findIndex((obj => obj.id == e.target.value ));
        citems[objIndex].selected = true;       

        this.setState({ 
            itemsCompartoCasa: citems,
            itemsAlojamiento: this.state.itemsAlojamiento,
            siiger: { ...this.state.siiger,
            host: { ...this.state.siiger.host, 
            familyHost: e.target.value } } },        
            () => { 
                localStorage.setItem("siiger", JSON.stringify(this.state.siiger));
            });
    }

    //ok
    onChangeTipoAlojamiento(e){

        let jitems = tipoAlojamientoOptions;
        let objIndex = tipoAlojamientoOptions.findIndex((obj => obj.id == e.target.value ));
        jitems[objIndex].selected = true;        

        this.setState({ 
            itemsCompartoCasa: this.state.itemsCompartoCasa,             
            itemsAlojamiento: jitems,
            siiger: { ...this.state.siiger,
            host: { ...this.state.siiger.host, 
            type: e.target.value } } },        
            () => { 
                localStorage.setItem("siiger", JSON.stringify(this.state.siiger));
            });
    }

    //ok
    onChangePets(e){    
        this.setState({ 
            itemsCompartoCasa: this.state.itemsCompartoCasa,            
            itemsAlojamiento: this.state.itemsAlojamiento,
            siiger: { ...this.state.siiger,
            host: { ...this.state.siiger.host, 
            pets: (e.detail.length) ? true : false } } },        
            () => { ;
                localStorage.setItem("siiger", JSON.stringify(this.state.siiger));
            })
    }

    //ok
    onChangeGarage(e){  
        this.setState({ 
            itemsCompartoCasa: this.state.itemsCompartoCasa,            
            itemsAlojamiento: this.state.itemsAlojamiento,
            siiger: { ...this.state.siiger,
            host: { ...this.state.siiger.host, 
            garage: (e.detail.length) ? true : false } } },        
            () => {
                localStorage.setItem("siiger", JSON.stringify(this.state.siiger)); 
            });              
    }

    //ok
    onChangeCarpa(e){    
        this.setState({ 
            itemsCompartoCasa: this.state.itemsCompartoCasa,                        
            itemsAlojamiento: this.state.itemsAlojamiento,
            siiger: { ...this.state.siiger,
            host: { ...this.state.siiger.host,  
            tentAllow: (e.detail.length) ? true : false } } },        
            () => {
                localStorage.setItem("siiger", JSON.stringify(this.state.siiger)); 
            });             
    }  

    //ok
    onChangeNombreMorada(e){
        this.setState({
            itemsCompartoCasa: this.state.itemsCompartoCasa,                        
            itemsAlojamiento: this.state.itemsAlojamiento,
            siiger: { ...this.state.siiger, 
            host: { ...this.state.siiger.host, 
            name: e.target.value } } },
            () => {
                localStorage.setItem("siiger", JSON.stringify(this.state.siiger)); 
            });                                    
    }

    //ok
    onChangeDescripcion(e) {
        this.setState({ 
            itemsCompartoCasa: this.state.itemsCompartoCasa,                        
            itemsAlojamiento: this.state.itemsAlojamiento,
            siiger: { ...this.state.siiger, 
            host: { ...this.state.siiger.host, 
            description: e.target.value } } },
            () => {
                localStorage.setItem("siiger", JSON.stringify(this.state.siiger)); 
            });                  
    }


    render(){
        const { redirect } = this.state;

        if (redirect) {
            return <Redirect to='/siigers/perfil'/>;
        }

        return(
            <>
                <Sheadmodal urlReturn="/siigers/perfil" caption="Perfil de anfitrión"/>
                <div className='container siiger'>
                    <div className='anfitrion-container'>
                        {/* ¿Qué tipo de alojamiento dispones? */}
                        <div className=''>                                                
                            <div className='col'>
                                <h4><b>¿Qué tipo de alojamiento dispones?</b></h4>
                                <SiigoSelectAtom 
                                    label="Tipo de alojamiento"
                                    options={ this.state.itemsAlojamiento }
                                    onChangeData={ (e) => {this.onChangeTipoAlojamiento(e)} }
                                >
                                </SiigoSelectAtom>                            
                            </div>
                        </div>
                        {/* ¿Dónde se encuentra tu alojamiento? */}
                        <div className=''>
                            <div className='col'>
                                <h4><b>¿Dónde se encuentra tu alojamiento?</b></h4>
                            </div>
                        </div>                    
                        <div className=''>
                            <div className='col'>   
                                <SiigoSelectPais ref={this.countryElement} country={ this.state.siiger.host.country } state={ this.state.siiger.host.state } disabled={ false } />                                                    
                            </div>
                        </div>
                        {/* ¿Qué comodidades y/o características tiene? */}
                        <div className=''>
                            <h4><b>¿Qué comodidades y/o características tiene?</b></h4>   
                        </div>
                        <div className=''>
                            <div className='col'>
                            <SiigoCheckboxAtom
                                direction="column"
                                label=""
                                name="checkGarage"
                                onchange={ (e) => this.onChangeGarage(e) }
                                options={[
                                    {
                                        checked: this.state.siiger.host.garage,
                                        indeterminate: false,
                                        label: 'Parqueadero',
                                        name: 'Op1'
                                    }
                                ]}
                            />

                            <hr className="siiger"/>

                            <SiigoCheckboxAtom
                                direction="column"
                                label=""
                                name="checkCarpa"
                                onchange={ (e) => this.onChangeCarpa(e) }
                                options={[
                                    {
                                        checked: this.state.siiger.host.tentAllow,
                                        indeterminate: false,
                                        label: 'Carpa (camping)',
                                        name: 'Op2'
                                    }
                                ]}
                            />



                            </div>
                        </div>
                        {/* ¿Con quien compartes tu alojamiento? */}
                        <div className=''>
                            <h4><b>¿Con quien compartes tu alojamiento?</b></h4>   
                        </div>
                        <div className=''>
                            <div className='col'>                        

                                <SiigoSelectAtom 
                                    label="Comparto con... ,"
                                    options={ this.state.itemsCompartoCasa }
                                    onChangeData={ (e) => {this.onChangeSharedAlojamiento(e)} }
                                >
                                </SiigoSelectAtom>                                  
                            </div>
                        </div>
                        {/* ¿Vives con mascotas? */}
                        <div className=''>
                            <h4><b>¿Vives con mascotas?</b></h4>  
                        </div> 
                        <div className=''>                    
                            <div className='col'>

                                <SiigoCheckboxAtom
                                direction="column"
                                label=""
                                name="checkPets"
                                onchange={ (e) => this.onChangePets(e) }
                                options={[
                                    {
                                        checked:  this.state.siiger.host.pets,
                                        indeterminate: false,
                                        label: 'Tengo mascotas',
                                        name: 'Op1'
                                    }
                                ]}
                                />                            

                            </div>
                        </div>
                        {/* ¿Qué espacio(s) puedes habilitar para alojar? */}
                        <div className=''>
                            <h4><b>¿Qué espacio(s) puedes habilitar para alojar?</b></h4>   
                        </div>
                        <div className='row'>
                            <div className='col width-100'>
                                <Sperfilanfitrionespacio ref={this.spotElement} siiger= { this.state.siiger }/>
                            </div>
                        </div>

                        {/* ¿Qué más nos puedes contar? */}

                        <div className=''>
                            <h4><b>¿Qué más nos puedes contar?</b></h4>   
                        </div>
                        <div className=''>                                
                            <div className='col'>
                                <SiigoInputAtom
                                    align="left"
                                errorMessage="Por favor completa el nombre de tu morada."
                                hint="Ej: Apartamento en Colina Campestre          "
                                id="idNombreMorada"
                                label="Nombre de tu morada"
                                maxLength={200}
                                minLength={0}
                                name="inputHostName"
                                onchange={ (e) => {this.onChangeNombreMorada(e)} }
                                pressCleanButton={function noRefCheck(){}}
                                requiredMessage="Este campo es requerido."
                                type="text"
                                value={ this.state.siiger.host.name }
                                />

                                <hr className="siiger"/>

                                <SiigoTextAreaAtom
                                    cols="45"
                                    id="textDescripcionMorada"
                                    errorMessage="Error personalizado"
                                    hint=""
                                    label="Escribe una descipción aquí..."                               
                                    maxLength={400}
                                    minLength={0}
                                    name=""
                                    onchange={ (e) => { this.onChangeDescripcion(e) } }
                                    requiredMessage="Por favor deje una descripción."
                                    showRenderCount="false"
                                    value={ this.state.siiger.host.description }
                                >
                                </SiigoTextAreaAtom>
                            </div>
                        </div>    
                        <hr className="siiger"/> 
                        
                            <div className='col width-100'>
                                <SiigoButtonAtom
                                    id='savePerfil'
                                    color="primary-green"
                                    size="l"
                                    type="submit"
                                    text="Guardar cambios"
                                    icon="fas"  
                                    onClick={ (e) => this.savePerfilClick(e)}                                   
                                />           
                            </div>                         
                        
                        <hr className="siiger"/>                                                                       
                    </div>           
                </div>
            </>
        )
    }
}

export default Sperfilanfitrion;
