/***** REGISTER USER ACTIONS ******/
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

/***** AUTHENTICATION ACTIONS ******/
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

/***** PLANS ACTIONS ******/
export const GET_PLANS = 'GET_PLANS';
export const PLANS_RECEIVED = 'PLANS_RECEIVED';
export const PLANS_REQUEST_FAILED = 'PLANS_REQUEST_FAILED';

export const GET_PLAN = 'GET_PLAN';
export const SET_PLAN = 'SET_PLAN';
export const PLAN_RECEIVED = 'PLAN_RECEIVED';
export const PLAN_REQUEST_FAILED = 'PLAN_REQUEST_FAILED';

export const CREATE_PLAN = 'CREATE_PLAN';
export const CREATE_PLAN_SUCCESS = 'CREATE_PLAN_SUCCESS';
export const CREATE_PLAN_FAILED = 'CREATE_PLAN_FAILED';

export const UPDATE_PLAN = 'UPDATE_PLAN';
export const UPDATE_PLAN_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_PLAN_FAILED = 'UPDATE_PLAN_FAILED';

export const DELETE_PLAN = 'DELETE_PLAN';
export const DELETE_PLAN_SUCCESS = 'DELETE_PLAN_SUCCESS';
export const DELETE_PLAN_FAILED = 'DELETE_PLAN_FAILED';

/****** CUSTOMERS ACTIONS ****/
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const CUSTOMERS_RECEIVED = 'CUSTOMERS_RECEIVED';
export const CUSTOMERS_REQUEST_FAILED = 'CUSTOMERS_REQUEST_FAILED';

export const GET_CUSTOMER = 'GET_CUSTOMER';
export const CUSTOMER_RECEIVED = 'CUSTOMER_RECEIVED';
export const CUSTOMER_REQUEST_FAILED = 'CUSTOMER_REQUEST_FAILED';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILED = 'CREATE_CUSTOMER_FAILED';

export const UPDATE_CUSTOMER = 'UPDATE_PLAN';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_PLAN_SUCCESS';
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_PLAN_FAILED';

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_FAILED = 'DELETE_CUSTOMER_FAILED';

/****** USERS ACTIONS ****/
export const GET_USERS = 'GET_USERS';
export const USERS_RECEIVED = 'USERS_RECEIVED';
export const USERS_REQUEST_FAILED = 'USERS_REQUEST_FAILED';

export const GET_USER = 'GET_USER';
export const USER_RECEIVED = 'USER_RECEIVED';
export const USER_REQUEST_FAILED = 'USER_REQUEST_FAILED';

export const GET_USER_PERFIL = 'GET_USER_PERFIL';
export const USER_PERFIL_RECEIVED = 'USER_PERFIL_RECEIVED';
export const USER_PERFIL_REQUEST_FAILED = 'USER_PERFIL_REQUEST_FAILED';

export const SET_USER_AVATAR = 'SET_USER_AVATAR';
export const USER_AVATAR_RECEIVED = 'USER_AVATAR_RECEIVED';
export const USER_AVATAR_REQUEST_FAILED = 'USER_AVATAR_REQUEST_FAILED';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const GET_USER_HAS_MOVEMENTS = 'GET_USER_HAS_MOVEMENTS';
export const GET_USER_HAS_MOVEMENTS_SUCCESS = 'GET_USER_HAS_MOVEMENTS_SUCCESS';
export const GET_USER_HAS_MOVEMENTS_FAILED = 'GET_USER_HAS_MOVEMENTS_FAILED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';

export const GET_MENU_USER = 'GET_MENU_USER';
export const GET_MENU_USER_RECEIVED = 'GET_MENU_USER_RECEIVED';
export const GET_MENU_USER_FAILED = 'GET_MENU_USER_FAILED';

/***** DEPENDENCES ACTIONS ******/
export const GET_DEPENDENCES = 'GET_DEPENDENCES';
export const DEPENDENCES_RECEIVED = 'DEPENDENCES_RECEIVED';
export const DEPENDENCES_REQUEST_FAILED = 'DEPENDENCES_REQUEST_FAILED';

export const GET_DEPENDENCE = 'GET_DEPENDENCE';
export const DEPENDENCE_RECEIVED = 'DEPENDENCE_RECEIVED';
export const DEPENDENCE_REQUEST_FAILED = 'DEPENDENCE_REQUEST_FAILED';

export const CREATE_DEPENDENCE = 'CREATE_DEPENDENCE';
export const CREATE_DEPENDENCE_SUCCESS = 'CREATE_DEPENDENCE_SUCCESS';
export const CREATE_DEPENDENCE_FAILED = 'CREATE_DEPENDENCE_FAILED';

export const UPDATE_DEPENDENCE = 'UPDATE_DEPENDENCE';
export const UPDATE_DEPENDENCE_SUCCESS = 'UPDATE_DEPENDENCE_SUCCESS';
export const UPDATE_DEPENDENCE_FAILED = 'UPDATE_DEPENDENCE_FAILED';

export const DELETE_DEPENDENCE = 'DELETE_DEPENDENCE';
export const DELETE_DEPENDENCE_SUCCESS = 'DELETE_DEPENDENCE_SUCCESS';
export const DELETE_DEPENDENCE_FAILED = 'DELETE_DEPENDENCE_FAILED';

/***** COMPONENTS ACTIONS ******/
export const GET_COMPONENTS = 'GET_COMPONENTS';
export const COMPONENTS_RECEIVED = 'COMPONENTS_RECEIVED';
export const COMPONENTS_REQUEST_FAILED = 'COMPONENTS_REQUEST_FAILED';

export const GET_COMPONENT = 'GET_COMPONENT';
export const COMPONENT_RECEIVED = 'COMPONENT_RECEIVED';
export const COMPONENT_REQUEST_FAILED = 'COMPONENT_REQUEST_FAILED';

export const CREATE_COMPONENT = 'CREATE_COMPONENT';
export const CREATE_COMPONENT_SUCCESS = 'CREATE_COMPONENT_SUCCESS';
export const CREATE_COMPONENT_FAILED = 'CREATE_COMPONENT_FAILED';

export const UPDATE_COMPONENT = 'UPDATE_COMPONENT';
export const UPDATE_COMPONENT_SUCCESS = 'UPDATE_COMPONENT_SUCCESS';
export const UPDATE_COMPONENT_FAILED = 'UPDATE_COMPONENT_FAILED';

export const DELETE_COMPONENT = 'DELETE_COMPONENT';
export const DELETE_COMPONENT_SUCCESS = 'DELETE_COMPONENT_SUCCESS';
export const DELETE_COMPONENT_FAILED = 'DELETE_COMPONENT_FAILED';

/***** GROUPS ACTIONS ******/
export const GET_GROUPS = 'GET_GROUPS';
export const GROUPS_RECEIVED = 'GROUPS_RECEIVED';
export const GROUPS_REQUEST_FAILED = 'GROUPS_REQUEST_FAILED';

export const GET_GROUP = 'GET_GROUP';
export const GROUP_RECEIVED = 'GROUP_RECEIVED';
export const GROUP_REQUEST_FAILED = 'GROUP_REQUEST_FAILED';

export const CREATE_GROUP = 'CREATE_GROUP';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED';

export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED';

export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED';

/********* UTILS ACTIONS *********/
export const CLEAR_DATA_FORM = 'CLEAR_DATA_FORM';

/****** COUNTRIES ACTIONS ****/
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const COUNTRIES_RECEIVED = 'COUNTRIES_RECEIVED';
export const COUNTRIES_REQUEST_FAILED = 'COUNTRIES_REQUEST_FAILED';

/****** SEARCH EMAIL ****/
export const EMAIL_GET_COMPANIES = 'EMAIL_GET_COMPANIES';
export const EMAIL_COPANIES_RECEIVED = 'EMAIL_COPANIES_RECEIVED';
export const EMAIL_COMPANIES_REQUEST_FAILED = 'EMAIL_COMPANIES_REQUEST_FAILED';

/****** MARKET ACTIONS ****/
export const GET_COMPANIES = 'GET_COMPANIES';
export const COMPANIES_RECEIVED = 'COMPANIES_RECEIVED';
export const COMPANIES_REQUEST_FAILED = 'COMPANIES_REQUEST_FAILED';

export const GET_CONFIG = 'GET_CONFIG';
export const CONFIG_RECEIVED = 'CONFIG_RECEIVED';
export const CONFIG_REQUEST_FAILED = 'CONFIG_REQUEST_FAILED';

export const SET_LOG = 'SET_LOG';
export const GET_LOGS = 'GET_LOGS';
export const GET_QUERY_LOGS = 'GET_QUERY_LOGS';
export const GET_QUERY_LOGS_RECEIVED = 'GET_QUERY_LOGS_RECEIVED';
export const GET_QUERY_LOGS_FAILED = 'GET_QUERY_LOGS_FAILED';
export const LOGS_RECEIVED = 'LOGS_RECEIVED';
export const LOGS_REQUEST_FAILED = 'LOGS_REQUEST_FAILED';

export const GET_QUERY_SURVEYS = 'GET_QUERY_SURVEYS';
export const GET_QUERY_SURVEYS_FAILED = 'GET_QUERY_SURVEYS_FAILED';
export const GET_SURVEYS = 'GET_SURVEYS';
export const GET_SURVEYS_FAILED = 'GET_SURVEYS_FAILED';

export const GET_LEADS = 'GET_LEADS';
export const LEADS_RECEIVED = 'LEADS_RECEIVED';
export const LEADS_REQUEST_FAILED = 'LEADS_REQUEST_FAILED';

export const GET_TASK = 'GET_TASK';
export const TASK_RECEIVED = 'TASK_RECEIVED';
export const TASK_REQUEST_FAILED = 'TASK_REQUEST_FAILED';

export const SET_COMPANY = 'GET_COMPANY';
export const COMPANY_RECEIVED = 'COMPANY_RECEIVED';
export const COMPANY_REQUEST_FAILED = 'COMPANY_REQUEST_FAILED';

export const GET_ADDONS = 'GET_ADDONS';
export const ADDONS_RECEIVED = 'ADDONS_RECEIVED';
export const ADDONS_REQUEST_FAILED = 'ADDONS_REQUEST_FAILED';

export const MODIFY_COMPANY = 'MODIFY_COMPANY';
export const MODIFY_COMPANY_RECEIVED = 'MODIFY_COMPANY_RECEIVED';
export const MODIFY_COMPANY_FAILED = 'MODIFY_COMPANY_FAILED';

export const GET_COMPANY_LOGS = 'GET_COMPANY_LOGS';
export const GET_COMPANY_LOGS_RECEIVED = 'GET_COMPANY_LOGS_RECEIVED';
export const GET_COMPANY_LOGS_FAILED = 'GET_COMPANY_LOGS_FAILED';

export const CHANGE_COMPANY_ADDON_STATE ='CHANGE_COMPANY_ADDON_STATE';
export const CHANGE_COMPANY_ADDON_STATE_RECEIVED = 'CHANGE_COMPANY_ADDON_STATE_RECEIVED';
export const CHANGE_COMPANY_ADDON_STATE_FAILED = 'CHANGE_COMPANY_ADDON_STATE_FAILED';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const NOTIFICATIONS_RECEIVED = 'NOTIFICATIONS_RECEIVED';
export const NOTIFICATIONS_REQUEST_FAILED = 'NOTIFICATIONS_REQUEST_FAILED';

export const CREATE_NOTIFICATION = 'CREATE_NOTIFICATION';
export const CREATE_NOTIFICATION_RECEIVED = 'CREATE_NOTIFICATION_RECEIVED';
export const CREATE_NOTIFICATION_REQUEST_FAILED =
  'CREATE_NOTIFICATION_REQUEST_FAILED';

export const EDIT_NOTIFICATION = 'EDIT_NOTIFICATION';
export const EDIT_NOTIFICATION_RECEIVED = 'EDIT_NOTIFICATION_RECEIVED';
export const EDIT_NOTIFICATION_REQUEST_FAILED =
  'EDIT_NOTIFICATION_REQUEST_FAILED';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_RECEIVED = 'DELETE_NOTIFICATION_RECEIVED';
export const DELETE_NOTIFICATION_REQUEST_FAILED =
  'DELETE_NOTIFICATION_REQUEST_FAILED';

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const ACCOUNTS_RECEIVED = 'ACCOUNTS_RECEIVED';
export const ACCOUNTS_REQUEST_FAILED = 'ACCOUNTS_REQUEST_FAILED';

export const SEND_EMAIL = 'SEND_EMAIL';
export const SEND_EMAIL_RECEIVED = 'SEND_EMAIL_RECEIVED';
export const SEND_EMAIL_REQUEST_FAILED = 'SEND_EMAIL_REQUEST_FAILED';

export const GET_ACCOUNTANTS = 'GET_ACCOUNTANTS';
export const GET_ACCOUNTANTS_RECEIVED = 'GET_ACCOUNTANTS_RECEIVED';
export const GET_ACCOUNTANTS_FAILED = 'GET_ACCOUNTANTS_FAILED';

export const SEND_EMAIL_ACCOUNTANT = 'SEND_EMAIL_ACCOUNTANT';
export const SEND_EMAIL_ACCOUNTANT_RECEIVED = 'SEND_EMAIL_ACCOUNTANT_RECEIVED';
export const SEND_EMAIL_ACCOUNTANT_FAILED = 'SEND_EMAIL_ACCOUNTANT_FAILED';

export const MODIFY_ACCOUNTANT = 'MODIFY_ACCOUNTANT';
export const MODIFY_ACCOUNTANT_RECEIVED = 'MODIFY_ACCOUNTANT_RECEIVED';
export const MODIFY_ACCOUNTANT_FAILED = 'MODIFY_ACCOUNTANT_FAILED';

export const SEND_MASSIVE_EMAIL_ACCOUNTANT = 'SEND_MASSIVE_EMAIL_ACCOUNTANT';
export const SEND_MASSIVE_EMAIL_ACCOUNTANT_RECEIVED =
  'SEND_MASSIVE_EMAIL_ACCOUNTANT_RECEIVED';
export const SEND_MASSIVE_EMAIL_ACCOUNTANT_FAILED =
  'SEND_MASSIVE_EMAIL_ACCOUNTANT_FAILED';

export const VERIFY_NIT_EDITION = 'VERIFY_NIT_EDITION';
export const VERIFY_NIT_EDITION_RECEIVED = 'VERIFY_NIT_EDITION_RECEIVED';
export const VERIFY_NIT_EDITION_FAILED = 'VERIFY_NIT_EDITION_FAILED';


// TYPES USERS COMPANY
export const CREATE_USER_COMPANY_MARKET = 'CREATE_USER_COMPANY_MARKET';
export const CREATE_USER_COMPANY_MARKET_SUCCESS = 'CREATE_USER_COMPANY_MARKET_SUCCESS';
export const CREATE_USER_COMPANY_MARKET_FAILED = 'CREATE_USER_COMPANY_MARKET_FAILED';

export const GET_USERS_COMPANY_MARKET = 'GET_USERS_COMPANY_MARKET';
export const GET_USERS_COMPANY_MARKET_SUCCESS = 'GET_USERS_COMPANY_MARKET_SUCCESS';
export const GET_USERS_COMPANY_MARKET_FAILED = 'GET_USERS_COMPANY_MARKET_FAILED';

export const EDIT_USER_COMPANY_MARKET = 'EDIT_USER_COMPANY_MARKET';
export const EDIT_USER_COMPANY_MARKET_SUCCESS = 'EDIT_USER_COMPANY_MARKET_SUCCESS';
export const EDIT_USER_COMPANY_MARKET_FAILED = 'EDIT_USER_COMPANY_MARKET_FAILED';

export const DELETE_USER_COMPANY_MARKET = 'DELETE_USER_COMPANY_MARKET';
export const DELETE_USER_COMPANY_MARKET_SUCCESS = 'DELETE_USER_COMPANY_MARKET_SUCCESS';
export const DELETE_USER_COMPANY_MARKET_FAILED = 'DELETE_USER_COMPANY_MARKET_FAILED';

/********* Aurora Actions ********/

export const GET_SHORTED_URL = 'GET_SHORTED_URL';
export const GET_SHORTED_URL_RECEIVED = 'GET_SHORTED_URL_RECEIVED';
export const GET_SHORTED_URL_FAILED = 'GET_SHORTED_URL_FAILED';
export const GET_SHORTED_URL_CLEARED = 'GET_SHORTED_URL_CLEARED';

/********* Download Excel Actions ********/

export const SEND_EXCEL_OBJECT = 'SEND_EXCEL_OBJECT';
export const SEND_EXCEL_OBJECT_RECEIVED = 'SEND_EXCEL_OBJECT_RECEIVED';
export const SEND_EXCEL_OBJECT_FAILED = 'SEND_EXCEL_OBJECT_FAILED';

/******** Cloud Log **********/

export const GET_CLOUD_LOGS = 'GET_CLOUD_LOGS';
export const CLOUD_LOGS_RECIVED = 'CLOUD_LOGS_RECIVED';
export const CLOUD_LOGS_FAILED = 'CLOUD_LOGS_FAILED';
/********** Contracts Actions  ************************/
export const GET_INVOICES = 'GET_INVOICES';
export const INVOICES_RECEIVED = 'INVOICES_RECEIVED';
export const INVOICES_REQUEST_FAILED = 'INVOICES_REQUEST_FAILED';

export const GET_PACKAGE = 'GET_PACKAGE';
export const GET_PACKAGE_RECEIVED = 'GET_PACKAGE_RECEIVED';
export const GET_PACKAGE_FAILED = 'GET_PACKAGE_FAILED';

export const ADD_PACKAGE = 'ADD_PACKAGE';
export const ADD_PACKAGE_RECEIVED = 'ADD_PACKAGE_RECEIVED';
export const ADD_PACKAGE_FAILED = 'ADD_PACKAGE_FAILED';

export const DELETE_BALANCE = 'DELETE_BALANCE';
export const DELETE_BALANCE_RECEIVED = 'DELETE_BALANCE_RECEIVED';
export const DELETE_BALANCE_FAILED = 'DELETE_BALANCE_FAILED';

export const GET_BALANCE_PACK = 'GET_BALANCE_PACK';
export const GET_BALANCE_PACK_RECEIVED = 'GET_BALANCE_PACK_RECEIVED';
export const GET_BALANCE_PACK_FAILED = 'GET_BALANCE_PACK_FAILED';

export const GET_PACKAGE_DETAIL = 'GET_PACKAGE_DETAIL';
export const GET_PACKAGE_DETAIL_RECEIVED = 'GET_PACKAGE_DETAIL_RECEIVED';
export const GET_PACKAGE_DETAIL_FAILED = 'GET_PACKAGE_DETAIL_FAILED';

export const RESEND_INVOICE_EMAIL = 'RESEND_INVOICE_EMAIL';
export const RESEND_INVOICE_EMAIL_RECEIVED = 'RESEND_INVOICE_EMAIL_RECEIVED';
export const RESEND_INVOICE_EMAIL_FAILED = 'RESEND_INVOICE_EMAIL_FAILED';


export const ADD_PAYROLL_PACKAGE = 'ADD_PAYROLL_PACKAGE';
export const ADD_PAYROLL_PACKAGE_RECEIVED = 'ADD_PAYROLL_PACKAGE_RECEIVED';
export const ADD_PAYROLL_PACKAGE_FAILED = 'ADD_PAYROLL_PACKAGE_FAILED';

export const GET_PAYROLL_PACKAGE_DETAIL = 'GET_PAYROLL_PACKAGE_DETAIL';
export const GET_PAYROLL_PACKAGE_DETAIL_RECEIVED = 'GET_PAYROLL_PACKAGE_DETAIL_RECEIVED';
export const GET_PAYROLL_PACKAGE_DETAIL_FAILED = 'GET_PAYROLL_PACKAGE_DETAIL_FAILED';

export const GET_PAYROLL_PACKAGE_BALANCE = 'GET_PAYROLL_PACKAGE_BALANCE';
export const GET_PAYROLL_PACKAGE_BALANCE_RECEIVED = 'GET_PAYROLL_PACKAGE_BALANCE_RECEIVED';
export const GET_PAYROLL_PACKAGE_BALANCE_FAILED = 'GET_PAYROLL_PACKAGE_BALANCE_FAILED';

export const DELETE_PAYROLL_PACKAGE = 'DELETE_PAYROLL_PACKAGE';
export const DELETE_PAYROLL_PACKAGE_RECEIVED = 'DELETE_PAYROLL_PACKAGE_RECEIVED';
export const DELETE_PAYROLL_PACKAGE_FAILED = 'DELETE_PAYROLL_PACKAGE_FAILED';
//Carga masiva de obsequios

export const PROCESS_FILE_NSN = 'PROCESS_FILE_NSN';
export const PROCESS_FILE_NSN_RECEIVED = 'PROCESS_FILE_NSN_RECEIVED';
export const PROCESS_FILE_NSN_FAILED = 'PROCESS_FILE_FAILED';

export const DOWNLOAD_FILE_NSN = 'DOWNLOAD_FILE_NSN';
export const DOWNLOAD_FILE_NSN_RECEIVED = 'DOWNLOAD_FILE_NSN_RECEIVED';
export const DOWNLOAD_FILE_NSN_FAILED = 'DOWNLOAD_FILE_FAILED';

//Aurora Subscriptions Actions
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const GET_SUBSCRIPTIONS_RECEIVED = 'GET_SUBSCRIPTIONS_RECEIVED';
export const GET_SUBSCRIPTIONS_FAILED = 'GET_SUBSCRIPTIONS_FAILED';

export const DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION_RECEIVED = 'DELETE_SUBSCRIPTION_RECEIVED';
export const DELETE_SUBSCRIPTION_FAILED = 'DELETE_SUBSCRIPTION_FAILED';

export const GET_RECURRENTPAYMENTS = 'GET_RECURRENTPAYMENTS';
export const GET_RECURRENTPAYMENTS_RECEIVED = 'GET_RECURRENTPAYMENTS_RECEIVED';
export const GET_RECURRENTPAYMENTS_FAILED = 'GET_RECURRENTPAYMENTS_FAILED';

export const GET_STATEPAYMENTS = 'GET_STATEPAYMENTS';
export const GET_STATEPAYMENTS_RECEIVED = 'GET_STATEPAYMENTS_RECEIVED';
export const GET_STATEPAYMENTS_FAILED = 'GET_STATETPAYMENTS_FAILED';
export const GET_PAYMENTS_CLEARED = 'GET_PAYMENTS_CLEARED';

//Aurora Tokens Actions
export const GET_TOKENS = 'GET_TOKENS';
export const GET_TOKENS_RECEIVED = 'GET_TOKENS_RECEIVED';
export const GET_TOKENS_FAILED = 'GET_TOKENS_FAILED';

//Aurora Config Providers
export const GET_PROVIDERS = 'GET_PROVIDERS';
export const GET_PROVIDERS_RECEIVED = 'GET_PROVIDERS_RECEIVED';
export const GET_PROVIDERS_FAILED = 'GET_PROVIDERS_FAILED';

export const GET_PROVIDER = 'GET_PROVIDER';
export const GET_PROVIDER_RECEIVED = 'GET_PROVIDER_RECEIVED';
export const GET_PROVIDER_FAILED = 'GET_PROVIDER_FAILED';

export const CREATE_PROVIDER = 'CREATE_PROVIDER';
export const CREATE_PROVIDER_SUCCESS = 'CREATE_PROVIDER_SUCCESS';
export const CREATE_PROVIDER_FAILED = 'CREATE_PROVIDER_FAILED';

export const UPDATE_PROVIDER = 'UPDATE_PROVIDER';
export const UPDATE_PROVIDER_SUCCESS = 'UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_FAILED = 'UPDATE_PROVIDER_FAILED';

export const CREATE_LOG = "CREATE_LOG";
export const CREATE_LOG_SUCCESS = "CREATE_LOG_SUCCESS";
export const CREATE_LOG_FAILED = "CREATE_LOG_FAILED";

//Aurora Config Origins
export const GET_ORIGINS = 'GET_ORIGINS';
export const GET_ORIGINS_RECEIVED = 'GET_ORIGINS_RECEIVED';
export const GET_ORIGINS_FAILED = 'GET_ORIGINS_FAILED';

export const GET_ORIGIN = 'GET_ORIGIN';
export const GET_ORIGIN_RECEIVED = 'GET_ORIGIN_RECEIVED';
export const GET_ORIGIN_FAILED = 'GET_ORIGIN_FAILED';

export const CREATE_ORIGIN = 'CREATE_ORIGIN';
export const CREATE_ORIGIN_SUCCESS = 'CREATE_ORIGIN_SUCCESS';
export const CREATE_ORIGIN_FAILED = 'CREATE_ORIGIN_FAILED';

export const UPDATE_ORIGIN = 'UPDATE_ORIGIN';
export const UPDATE_ORIGIN_SUCCESS = 'UPDATE_ORIGIN_SUCCESS';
export const UPDATE_ORIGIN_FAILED = 'UPDATE_ORIGIN_FAILED';

/******** GENERAL REPORT ACTION **********/

export const GET_CONFIG_GENERAL_REPORT = 'GET_CONFIG_GENERAL_REPORT';
export const GET_CONFIG_GENERAL_REPORT_RECEIVED = 'GET_CONFIG_GENERAL_REPORT_RECEIVED';
export const GET_CONFIG_GENERAL_REPORT_FAILED = 'GET_CONFIG_GENERAL_REPORT_FAILED';

export const FILTER_DATA_BY_SOURCE = 'FILTER_DATA_BY_SOURCE';
export const FILTER_DATA_BY_SOURCE_RECEIVED = 'FILTER_DATA_BY_SOURCE_RECEIVED';
export const FILTER_DATA_BY_SOURCE_FAILED = 'FILTER_DATA_BY_SOURCE_FAILED';

export const DOWNLOAD_REPORT_DATA_BY_SOURCE = 'DOWNLOAD_REPORT_DATA_BY_SOURCE'
export const DOWNLOAD_REPORT_DATA_BY_SOURCE_RECEIVED = 'DOWNLOAD_REPORT_DATA_BY_SOURCE_RECEIVED'
export const DOWNLOAD_REPORT_DATA_BY_SOURCE_FAILED = 'DOWNLOAD_REPORT_DATA_BY_SOURCE_FAILED'

/******** FIVE 9 ACTIONS **********/

/* FIVE 9 COUNTRIES */
export const GET_FIVE9_COUNTRY = 'GET_FIVE9_COUNTRY';
export const GET_FIVE9_COUNTRY_SUCCESS = 'GET_FIVE9_COUNTRY_SUCCESS';
export const GET_FIVE9_COUNTRY_FAILED = 'GET_FIVE9_COUNTRY_FAILED';
export const SET_LOG_FIVE9_COUNTRY = 'SET_LOG_FIVE9_COUNTRY';


export const ADD_FIVE9_COUNTRY = 'ADD_FIVE9_COUNTRY';
export const ADD_FIVE9_COUNTRY_SUCCESS = 'ADD_FIVE9_COUNTRY_SUCCESS';
export const ADD_FIVE9_COUNTRY_FAILED = 'ADD_FIVE9_COUNTRY_FAILED';

export const EDIT_FIVE9_COUNTRY = 'EDIT_FIVE9_COUNTRY';
export const EDIT_FIVE9_COUNTRY_SUCCESS = 'EDIT_FIVE9_COUNTRY_SUCCESS';
export const EDIT_FIVE9_COUNTRY_FAILED = 'EDIT_FIVE9_COUNTRY_FAILED';

export const DELETE_FIVE9_COUNTRY = 'DELETE_FIVE9_COUNTRY';
export const DELETE_FIVE9_COUNTRY_SUCCESS = 'DELETE_FIVE9_COUNTRY_SUCCESS';
export const DELETE_FIVE9_COUNTRY_FAILED = 'DELETE_FIVE9_COUNTRY_FAILED';

/* FIVE 9 RULES */
export const GET_FIVE9_RULES = 'GET_FIVE9_RULES';
export const GET_FIVE9_RULES_SUCCESS = 'GET_FIVE9_RULES_SUCCESS';
export const GET_FIVE9_RULES_FAILED = 'GET_FIVE9_RULES_FAILED';
export const SET_LOG_FIVE9_RULES = 'SET_LOG_FIVE9_RULES';

export const ADD_FIVE9_RULES = 'ADD_FIVE9_RULES';
export const ADD_FIVE9_RULES_SUCCESS = 'ADD_FIVE9_RULES_SUCCESS';
export const ADD_FIVE9_RULES_FAILED = 'ADD_FIVE9_RULES_FAILED';

export const EDIT_FIVE9_RULES = 'EDIT_FIVE9_RULES';
export const EDIT_FIVE9_RULES_SUCCESS = 'EDIT_FIVE9_RULES_SUCCESS';
export const EDIT_FIVE9_RULES_FAILED = 'EDIT_FIVE9_RULES_FAILED';

export const DELETE_FIVE9_RULES = 'DELETE_FIVE9_RULES';
export const DELETE_FIVE9_RULES_SUCCESS = 'DELETE_FIVE9_RULES_SUCCESS';
export const DELETE_FIVE9_RULES_FAILED = 'DELETE_FIVE9_RULES_FAILED';

//Carga lista productos Synergy
export const SEND_EXCEL_OBJECT_SYNERGY = 'SEND_EXCEL_OBJECT_SYNERGY';
export const SEND_EXCEL_OBJECT_RECEIVED_SYNERGY = 'SEND_EXCEL_OBJECT_RECEIVED_SYNERGY';
export const SEND_EXCEL_OBJECT_FAILED_SYNERGY = 'SEND_EXCEL_OBJECT_FAILED_SYNERGY';


//Descarga lista productos Synergy
export const DOWNLOAD_FILE_SYNERGY = 'DOWNLOAD_FILE_SYNERGY';
export const DOWNLOAD_FILE_SYNERGY_RECEIVED = 'DOWNLOAD_FILE_SYNERGY_RECEIVED';
export const DOWNLOAD_FILE_SYNERGY_FAILED = 'DOWNLOAD_FILE_SYNERGY_FAILED';


// Black List Email
export const GET_BLACK_LIST_EMAIL = 'GET_BLACK_LIST_EMAIL';
export const GET_BLACK_LIST_EMAIL_RECEIVED = 'GET_BLACK_LIST_EMAIL_RECEIVED';
export const GET_BLACK_LIST_EMAIL_FAILED = 'GET_BLACK_LIST_EMAIL_FAILED';

export const DELETE_BLACK_LIST_EMAIL = 'DELETE_BLACK_LIST_EMAIL';
export const DELETE_BLACK_LIST_EMAIL_RECEIVED = 'DELETE_BLACK_LIST_EMAIL_RECEIVED';
export const DELETE_BLACK_LIST_EMAIL_FAILED = 'DELETE_BLACK_LIST_EMAIL_FAILED';

//Data source
export const GET_DATASOURCE = 'GET_DATASOURCE';
export const DATASOURCE_RECEIVED = 'DATASOURCE_RECEIVED';
export const DATASOURCE_REQUEST_FAILED = 'DATASOURCE_REQUEST_FAILED';

export const GET_DETAIL_DATASOURCE = 'GET_DETAIL_DATASOURCE';
export const GET_DETAIL_DATASOURCE_RECEIVED = 'GET_DETAIL_DATASOURCE_RECEIVED';
export const GET_DETAIL_DATASOURCE_FAILED = 'GET_DETAIL_DATASOURCE_FAILED';
export const EDIT_DATASOURCE = 'EDIT_DATASOURCE';
export const EDIT_DATASOURCE_RECEIVED = 'EDIT_DATASOURCE_RECEIVED';
export const EDIT_DATASOURCE_FAILED = 'EDIT_DATASOURCE_FAILED';

export const ADD_DATASOURCE = 'ADD_DATASOURCE';
export const ADD_DATASOURCE_RECEIVED = 'ADD_DATASOURCE_RECEIVED';
export const ADD_DATASOURCE_REQUEST_FAILED = 'ADD_DATASOURCE_REQUEST_FAILED';

export const GET_PROPERTIES = 'GET_PROPERTIES';
export const PROPERTIES_RECEIVED = 'PROPERTIES_RECEIVED';
export const PROPERTIES_REQUEST_FAILED = 'PROPERTIES_REQUEST_FAILED';

export const GET_COLLECTION = 'GET_COLLECTION';
export const COLLECTION_RECEIVED = 'COLLECTION_RECEIVED';
export const COLLECTION_REQUEST_FAILED = 'COLLECTION_REQUEST_FAILED';

export const SAVE_DATA = 'SAVE_DATA';
export const SAVE_DATA_RECEIVED = 'SAVE_DATA_RECEIVED';
export const SAVE_DATA_REQUEST_FAILED = 'SAVE_DATA_REQUEST_FAILED';

export const REPORT_DATA = 'REPORT_DATA';
export const REPORT_DATA_RECEIVED = 'REPORT_DATA_RECEIVED';
export const REPORT_DATA_REQUEST_FAILED = 'REPORT_DATA_REQUEST_FAILED';


export const SAVE_CAMPAIGN = 'SAVE_CAMPAIGN';
export const SAVE_CAMPAIGN_FAILED = 'SAVE_CAMPAIGN_FAILED';
export const SAVE_CAMPAIGN_RECEIVED = 'SAVE_CAMPAIGN_RECEIVED';
export const  GET_CAMPAIGN = 'GET_CAMPAIGN';
export const  GET_CAMPAIGN_RECEIVED = 'GET_CAMPAIGN_RECEIVED';
export const  CLEAR_CAMPAIGN_STATE = 'CLEAR_CAMPAIGN_STATE';


/******** SHOP ACTIONS **********/
// Campaign
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_RECEIVED = 'GET_PRODUCTS_RECEIVED';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';

export const POST_CAMPAIGN = 'POST_CAMPAIGN';
export const POST_CAMPAIGN_RECEIVED = 'POST_CAMPAIGN_RECEIVED';
export const POST_CAMPAIGN_FAILED = 'POST_CAMPAIGN_FAILED';

export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_CAMPAIGNS_RECEIVED = 'GET_CAMPAIGNS_RECEIVED';
export const GET_CAMPAIGNS_FAILED = 'GET_CAMPAIGNS_FAILED';

export const PATCH_CAMPAIGNSTATE = 'PATCH_CAMPAIGNSTATE';
export const PATCH_CAMPAIGNSTATE_RECEIVED = 'PATCH_CAMPAIGNSTATE_RECEIVED';
export const PATCH_CAMPAIGNSTATE_FAILED = 'PATCH_CAMPAIGNSTATE_FAILED';

/******** SHOP ACTIONS **********/
// Cache
export const POST_UPDATE_CACHE = 'POST_UPDATE_CACHE';
export const POST_UPDATE_CACHE_RECEIVED = 'POST_UPDATE_CACHE_RECEIVED';
export const POST_UPDATE_CACHE_FAILED = 'POST_UPDATE_CACHE_FAILED';
export const POST_UPDATE_CACHE_RESET = 'POST_UPDATE_CACHE_RESET';

/********* UsersDomain Actions ********/
export const GET_USERS_USERS_DOMAIN = 'GET_USERS_USERS_DOMAIN';
export const GET_USERS_USERS_DOMAIN_RECEIVED = 'GET_USERS_USERS_DOMAIN_RECEIVED';
export const GET_USERS_USERS_DOMAIN_FAILED = 'GET_USERS_USERS_DOMAIN_FAILED';

export const CREATE_USER_USERS_DOMAIN = 'CREATE_USER_USERS_DOMAIN';
export const CREATE_USER_USERS_DOMAIN_SUCCESS = 'CREATE_USER_USERS_DOMAIN_SUCCESS';
export const CREATE_USER_USERS_DOMAIN_FAILED = 'CREATE_USER_USERS_DOMAIN_FAILED';

export const UPDATE_USER_USERS_DOMAIN = 'UPDATE_USER_USERS_DOMAIN';
export const UPDATE_USER_USERS_DOMAIN_SUCCESS = 'UPDATE_USER_USERS_DOMAIN_SUCCESS';
export const UPDATE_USER_USERS_DOMAIN_FAILED = 'UPDATE_USER_USERS_DOMAIN_FAILED';

export const DELETE_USER_USERS_DOMAIN = 'DELETE_USER_USERS_DOMAIN';
export const DELETE_USER_USERS_DOMAIN_SUCCESS = 'DELETE_USER_USERS_DOMAIN_SUCCESS';
export const DELETE_USER_USERS_DOMAIN_FAILED = 'DELETE_USER_USERS_DOMAIN_FAILED';

/******** MENU ACTIVATION **********/
export const SAVE_ACTIVATION_MANAGEMENT= 'SAVE_ACTIVATION_MANAGEMENT';
export const SAVE_ACTIVATION_MANAGEMENT_RECEIVED = 'SAVE_ACTIVATION_MANAGEMENT_RECEIVED';
export const SAVE_ACTIVATION_MANAGEMENT_REQUEST_FAILED = 'SAVE_ACTIVATION_MANAGEMENT_REQUEST_FAILED';
export const CLEAR_ACTIVATION_MANAGEMENT_STATE= 'CLEAR_ACTIVATION_MANAGEMENT_STATE';

export const REMOVE_ACTIVATION_MANAGEMENT= 'REMOVE_ACTIVATION_MANAGEMENT';
export const REMOVE_ACTIVATION_MANAGEMENT_RECEIVED = 'REMOVE_ACTIVATION_MANAGEMENT_RECEIVED';
export const REMOVE_ACTIVATION_MANAGEMENT_REQUEST_FAILED = 'REMOVE_ACTIVATION_MANAGEMENT_REQUEST_FAILED';
export const CLEAR_REMOVE_ACTIVATION_MANAGEMENT_STATE= 'CLEAR_REMOVE_ACTIVATION_MANAGEMENT_STATE';