import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalResponse from '../layout/modals/modalResponse';
import './style.scss';
import Loader from '../tools/loader';
import Modal from '../layout/modals/detailSubscription/index';
import { createLog } from '../../actions/auroraConfigProvider/auroraConfigProviderActions';
import ConfigList from './configList';
import { getCookie } from '../../utils/cookies';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';
import { AxiosConfig } from './services';


let access = true;
class EditConfigProvider extends Component {

    constructor(props) {
        super(props);
        this.state = {

            newProvider: {},
            oldProvider: {},
            showUpdate: false,
            openUpdate: false,
            showSuccess: false,
            openSuccess: false,
            showIdErr: false,
            openIdErr: false,
            showAccErr: false,
            openAccErr: false,
            disableBtnUpdate: false,
            resources: [{ title: "", name: "", state: "Active", value: "", config: "", promo: "" }],
            isLoading: true,
        };
    }

    componentDidMount() {
        document.title = "Editar Proveedor";
        access = getAccess(this.props.parentPath, ACCESS.edit_provider);

        if (access) {
            if (this.props.id) {
                this.loadProvider();
            } else {
                this.showModalIdErr();
            }
        } else {
            this.showModalAccErr();
        }
    }

    focusInput = (e) => {
        e.preventDefault();
        e.currentTarget.classList.remove("inputError");
        e.currentTarget.classList.remove("inputOk");
        e.currentTarget.classList.add('inputFocus');
        if (e.currentTarget.previousSibling) {
            e.currentTarget.previousSibling.classList.remove('inputError');
            e.currentTarget.previousSibling.classList.remove('inputOk');
            e.currentTarget.previousSibling.classList.add('labelModal');
        }
    };

    blurInput = (e, optional = true) => {
        e.preventDefault();
        if (e.currentTarget.value == '' && optional) {
            e.currentTarget.classList.remove("inputOk");
            e.currentTarget.classList.add('inputError');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelOk');
                e.currentTarget.previousSibling.classList.add('labelError');
            }
        } else {
            e.currentTarget.classList.remove("inputError");
            e.currentTarget.classList.add('inputOk');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelError');
                e.currentTarget.previousSibling.classList.add('labelOk');
            }
        }
    };

    renderModalX(text) {
        return (
            <div>
                <div className="uxDetailDataFilterTitle" style={{ textAlign: "center" }}>
                    <h4 >{text}</h4>
                </div>
                <div className="uxDetailContainer" style={{ textAlign: "center" }}>
                    <button
                        style={{ margin: "20px" }}
                        className="orange-fill"
                        type="button"
                        onClick={(e) => { this.props.history.push('/configproviders') }}
                    >Aceptar
                    </button>

                </div>
            </div >
        );
    }

    showModalSuccess = () => {
        this.setState({ showSuccess: !this.state.showSuccess, openSuccess: !this.state.openSuccess });
    }

    showModalIdErr = () => {
        this.setState({ showIdErr: !this.state.showIdErr, openIdErr: !this.state.openIdErr });
    }

    showModalAccErr = () => {
        this.setState({ showAccErr: !this.state.showAccErr, openAccErropenAccErr: !this.state.openAccErr });
    }

    renderModalConfirm() {
        return (
            <div>
                <div className="uxDetailDataFilterTitle" style={{ textAlign: "center" }}>
                    <h4 >¿Esta seguro que desea editar la información del proveedor?</h4>
                </div>
                <div className="uxDetailContainer" style={{ textAlign: "center" }}>
                    <button
                        style={{ margin: "20px", opacity: this.state.disableBtnUpdate ? "0.5" : "1.0" }}
                        disabled={this.state.disableBtnUpdate}
                        className="orange-fill"
                        type="button"
                        onClick={this.updateProvider}
                    >Aceptar
                    </button>
                    <button
                        style={{ margin: "20px", opacity: this.state.disableBtnUpdate ? "0.5" : "1.0" }}
                        className="red-fill"
                        disabled={this.state.disableBtnUpdate}
                        type="button"
                        onClick={this.showModalUpdateProvider}
                    >Cancelar
                    </button>
                </div>
            </div >
        );
    }

    showModalUpdateProvider = (e) => {
        this.setState({ showUpdate: !this.state.showUpdate });
    }

    loadProvider() {
        this.setState({ oldProvider: {}, isLoading: true, resources: [{ title: "", name: "", state: "Active", value: "", config: "", promo: "" }] });
        AxiosConfig.getProviderIdConfig(this.props.id).then(resp => {
            this.setState({ oldProvider: resp.data, isLoading: false, resources: resp.data.resources });

        }).catch(err => {
            this.setState({ oldProvider: {}, isLoading: false });
            ModalResponse({
                title: 'Actualizar Proveedor',
                text: "Ha ocurrido un problema, inténtelo más tarde o comuníquese con el Administrador.",
                res: 404
            });
        });
    }

    updateProviderApi(obj) {
        AxiosConfig.updateProviderConfig(obj).then(resp => {
            this.setState({ disableBtnUpdate: false });
            this.showModalUpdateProvider();
            this.createLogActivity(resp.data);
            this.showModalSuccess();

        }).catch(err => {
            this.setState({ disableBtnUpdate: false });
            this.showModalUpdateProvider();
            ModalResponse({
                title: 'Editar Proveedor',
                text: "Ha ocurrido un problema, inténtelo más tarde o comuníquese con el Administrador.",
                res: 404
            });
        });
    }

    createLogActivity(object) {
        let aditionalData = { updated_object: object, original_object: this.state.oldProvider }
        let params = {
            nameComponent: "Editar Proveedor",
            nameUser: getCookie('name'),
            action: "Edit Provider",
            date: (new Date()).toISOString(),
            aditionalData: JSON.stringify(aditionalData),
        }

        this.props.dispatch(createLog(params));
    }

    updateProvider = () => {
        this.setState({ disableBtnUpdate: true });
        this.updateProviderApi(this.state.newProvider);
    }

    validValue(value) {
        return value === "" || value === undefined || value === null;
    }

    validarResources() {
        if (this.state.resources.length < 1) return false;
        for (let i = 0; i < this.state.resources.length; i++) {
            var resource = this.state.resources[i];

            if (this.validValue(resource.value) || this.validValue(resource.name) || this.validValue(resource.state) || this.validValue(resource.title)) {
                return false;
            }
        }
        return true;
    }


    handleChange = e => {
        const label = {
            nameConfig: "name",
            valueConfig: "value",
            title: "title",
            state: "state",
            config: "config",
            promo: "promo"
        }
        if (Object.keys(label).includes(e.target.name)) {
            let resources = [...this.state.resources];
            resources[e.target.dataset.id][label[e.target.name]] = e.target.value;
            this.setState({ resources });
        }
    };


    addResource = e => {
        e.preventDefault();
        this.setState(prevState => ({
            resources: [...prevState.resources, { title: "", name: "", state: "Active", value: "", config: "", promo: "" }]
        }));
    };

    removeResource = e => {
        e.preventDefault();
        let resources = [...this.state.resources];
        resources.splice(e.target.dataset.id, 1);
        this.setState({ resources });
    };


    btnUpdateProvider = (e) => {
        e.preventDefault();
        const title = e.target.titleProv.value;
        const name = e.target.nameProv.value;
        const descrip = e.target.description.value;
        const state = e.target.providerState.value;
        const credentials = e.target.credentials.value;
        const country = e.target.country.value;
        const currency = e.target.currency.value;
        const testMode = (e.target.testMode.value == "true") ? true : false;

        if (!this.validValue(testMode) && !this.validValue(title) && !this.validValue(name) && !this.validValue(descrip) && !this.validValue(state) && !this.validValue(credentials) && !this.validValue(country) && !this.validValue(currency) && this.validarResources()) {
            var provider = {
                _id: this.props.id,
                title,
                name,
                description: descrip,
                state,
                credentials,
                country,
                currency,
                testMode,
                resources: this.state.resources
            }
            this.setState({ newProvider: provider, showUpdate: true, openUpdate: true });
        }
        else {
            ModalResponse({
                title: '¡Hey!',
                text: 'Te hacen falta campos por ingresar',
                res: 404
            });
        }
    }


    render() {

        const { error } = this.props;

        return (
            <div style={{ paddingBottom: "5%" }}>
                <h3>Editar Configuración de Proveedor</h3>
                <br />
                <br />
                {error && <h4>Error: {error.response.data}</h4>}
                {(this.state.isLoading) ? (<Loader />) :
                    (
                        <div>
                            <form onSubmit={this.btnUpdateProvider}>
                                <div className="input-container-accountant">
                                    <label>Nombre del Provedor</label>
                                    <input
                                        type="text"
                                        name="nameProv"
                                        id="nameProv"
                                        defaultValue={this.state.oldProvider.name ? this.state.oldProvider.name : ""}
                                        placeholder=""
                                        className="inputFilter customEnterText"
                                        onFocus={(e) => this.focusInput(e)}
                                        onBlur={(e) => this.blurInput(e)}
                                        disabled={!access}
                                    />
                                </div>
                                <div className="input-container-accountant">
                                    <label>Título del Provedor</label>
                                    <input
                                        type="text"
                                        name="titleProv"
                                        id="titleProv"
                                        defaultValue={this.state.oldProvider.title ? this.state.oldProvider.title : ""}
                                        placeholder=""
                                        className="inputFilter customEnterText"
                                        onFocus={(e) => this.focusInput(e)}
                                        onBlur={(e) => this.blurInput(e)}
                                        disabled={!access}
                                    />
                                </div>
                                <div className="input-container-accountant">
                                    <label>Descripción</label>
                                    <input
                                        type="text"
                                        name="description"
                                        id="description"
                                        defaultValue={this.state.oldProvider.description ? this.state.oldProvider.description : ""}
                                        placeholder=""
                                        className="inputFilter customEnterText"
                                        onFocus={(e) => this.focusInput(e)}
                                        onBlur={(e) => this.blurInput(e)}
                                        disabled={!access}
                                    />
                                </div>
                                <div className="input-container-accountant">
                                    <label>Credenciales (JSON)</label>
                                    <textarea
                                        type="text"
                                        name="credentials"
                                        id="credentials"
                                        rows="6"
                                        defaultValue={this.state.oldProvider.credentials ? this.state.oldProvider.credentials : ""}
                                        placeholder=""
                                        className="inputFilter customEnterText"
                                        onFocus={(e) => this.focusInput(e)}
                                        onBlur={(e) => this.blurInput(e)}
                                        disabled={!access}
                                    />
                                </div>
                                <div className="input-container-accountant">
                                    <label>Estado del Proveedor</label>
                                    <select className="slFilter customEnterText" defaultValue={this.state.oldProvider.state ? this.state.oldProvider.state : ""} id="providerState" name="providerState"
                                        onFocus={(e) => this.focusInput(e)}
                                        onBlur={(e) => this.blurInput(e)}
                                        disabled={!access}
                                    >
                                        <option value="">Ninguno</option>
                                        <option className="successState" value="Active">Activo</option>
                                        <option className="errorState" value="Inactive">Inactivo</option>
                                    </select>
                                </div>
                                <div className="input-container-accountant">
                                    <label>País</label>
                                    <input
                                        type="text"
                                        name="country"
                                        id="country"
                                        defaultValue={this.state.oldProvider.country ? this.state.oldProvider.country : ""}
                                        placeholder=""
                                        className="inputFilter customEnterText"
                                        onFocus={(e) => this.focusInput(e)}
                                        onBlur={(e) => this.blurInput(e)}
                                        disabled={!access}
                                    />
                                </div>
                                <div className="input-container-accountant">
                                    <label>Moneda</label>
                                    <input
                                        type="text"
                                        name="currency"
                                        id="currency"
                                        defaultValue={this.state.oldProvider.currency ? this.state.oldProvider.currency : ""}
                                        placeholder=""
                                        className="inputFilter customEnterText"
                                        onFocus={(e) => this.focusInput(e)}
                                        onBlur={(e) => this.blurInput(e)}
                                        disabled={!access}
                                    />
                                </div>
                                <div className="input-container-accountant">
                                    <label>Estado Test Mode</label>
                                    <select className="slFilter customEnterText" defaultValue={(this.state.oldProvider.testMode == true) ? "true" : "false"} id="testMode" name="testMode"
                                        onFocus={(e) => this.focusInput(e)}
                                        onBlur={(e) => this.blurInput(e)}
                                        disabled={!access}
                                    >
                                        <option value="">Ninguno</option>
                                        <option className="successState" value="true">Activo</option>
                                        <option className="errorState" value="false">Inactivo</option>
                                    </select>
                                </div>

                                <h3>Configuraciones</h3>
                                <hr className="sep" />
                                <ConfigList
                                    access={!access}
                                    resources={this.state.resources}
                                    change={this.handleChange}
                                    remove={this.removeResource}
                                    focusInput={this.focusInput}
                                    blurInput={this.blurInput}
                                />
                                <br />
                                <br />
                                <div className="center">
                                    <button
                                        type="button"
                                        className="btn btn-outline-primary margin-bottom-btn"
                                        onClick={this.addResource}
                                        disabled={!access}
                                    >
                                        <i>+</i> Agregar Configuración
                                    </button>
                                </div>
                                <br />
                                <div className="p-top-20 center">
                                    <button className="button green-fill" disabled={!access}>Guardar Cambios</button>
                                </div>
                            </form>

                            <Modal
                                onClose={this.showModalUpdateProvider}
                                show={this.state.showUpdate}
                                title="Editar Proveedor"
                                open={this.state.openUpdate}>
                                {this.renderModalConfirm()}
                            </Modal>

                            <Modal
                                onClose={(e) => { this.props.history.push('/configproviders') }}
                                show={this.state.showSuccess}
                                title="Proveedor Actualizado"
                                open={this.state.openSuccess}>
                                {this.renderModalX("¡El proveedor fue actualizado exitosamente!")}
                            </Modal>
                            <Modal
                                onClose={(e) => { this.props.history.push('/configproviders') }}
                                show={this.state.showAccErr}
                                title="Editar Proveedor"
                                open={this.state.openAccErr}>
                                {this.renderModalX("¡No tienes permiso para editar proveedores! Comunicate con el Administrador.")}
                            </Modal>
                            <Modal
                                onClose={(e) => { this.props.history.push('/configproviders') }}
                                show={this.state.showIdErr}
                                title="Proveedores"
                                open={this.state.openIdErr}>
                                {this.renderModalX("¡Error al cargar los datos!")}
                            </Modal>
                        </div>
                    )}
            </div>
        );
    }



}

EditConfigProvider.propTypes = {
    actions: PropTypes.object.isRequired,
    log: PropTypes.object,
};

const mapStateToProps = state => ({
    log: state.logs.log,
});

/*const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(GroupActions, dispatch)
});*/
export default connect(mapStateToProps)(withRouter(EditConfigProvider));
