import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as auroraConfigOriginsActions from '../../actions/auroraConfigOrigins/auroraConfigOriginsActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '../layout/modals/auroraModal/index';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalResponse from '../layout/modals/modalResponse';
import svgs from '../layout/icons';
import { getCookie } from '../../utils/cookies';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';
import { StatusCodes } from 'http-status-codes';


const columns = [
    {
        name: 'Tipo de Notificación',
        selector: 'type',
        sortable: true
    },
    {
        name: 'Descripción',
        selector: 'description',
        sortable: true,
        filterDDL: true
    },

    {
        name: 'Estado',
        selector: 'state',
        sortable: true,
        originState: true,
        filterDDL: true
    }
];

let accessDelete = false;
let accessEdit = false;
let accessCreate = false;
let update = false;
class AuroraConfigOrigins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disableBtnDelete: false,
            origin: {},
            datos: [],
            isLoading: true,
            type: "",
            showModalDisableOrigin: false,
            showSuccess: false,

        };
    }

    showModalDisableOrigin = (e) => {
        this.setState({ showDisable: !this.state.showDisable });
    }

    disableOrigin = () => {
        this.setState({ disableBtnDelete: true });
        const copyObj = Object.assign({}, this.state.origin);
        copyObj.state = "Inactive";
        update = true;
        this.props.actions.updateConfigOrigin(copyObj);
    }

    createLogActivity(object) {
        let aditionalData = { disable_id: object._id }

        let params = {
            nameComponent: "Configuracion de Origenes",
            nameUser: getCookie('name'),
            action: "Disable Origin",
            date: (new Date()).toISOString(),
            aditionalData: JSON.stringify(aditionalData),
        }

        this.props.actions.createLog(params);
    }

    componentDidMount() {
        document.title = "Configuración de Origenes";
        accessCreate = getAccess(this.props.parentPath, ACCESS.create_origin);
        accessEdit = getAccess(this.props.parentPath, ACCESS.edit_origin);
        accessDelete = getAccess(this.props.parentPath, ACCESS.disable_origin);
        this.props.actions.getConfigOrigins({ type: "" });
    }

    btnDisableOrigin = (data) => {
        this.setState({ origin: data, showDisable: true, openDisable: true });
    }

    focusInput = (e) => {
        e.preventDefault();
        e.currentTarget.placeholder = '';
        e.currentTarget.classList.remove("inputError");
        e.currentTarget.classList.remove("inputOk");
        e.currentTarget.classList.add('inputFocus');
        if (e.currentTarget.previousSibling) {
            e.currentTarget.previousSibling.classList.remove('inputError');
            e.currentTarget.previousSibling.classList.remove('inputOk');
            e.currentTarget.previousSibling.classList.add('labelModal');
        }
    };

    blurInput = (e) => {
        e.preventDefault();
        if (e.currentTarget.value === '') {
            e.currentTarget.classList.remove("inputOk");
            e.currentTarget.classList.add('inputError');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelOk');
                e.currentTarget.previousSibling.classList.add('labelError');
            }
        } else {
            e.currentTarget.classList.remove("inputError");
            e.currentTarget.classList.add('inputOk');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelError');
                e.currentTarget.previousSibling.classList.add('labelOk');
            }
        }
    };

    renderModalDisable() {
        return (
            <div>
                <div className="uxDetailDataFilterTitle" style={{ textAlign: "center" }}>
                    <h4 >¿Esta seguro de que desea deshabilitar el origen <b>{this.state.origin.type}</b>?</h4>
                </div>
                <div className="uxDetailContainer" style={{ textAlign: "center" }}>
                    <button
                        style={{ margin: "20px", opacity: this.state.disableBtnDelete ? "0.5" : "1.0" }}
                        disabled={this.state.disableBtnDelete}
                        className="orange-fill"
                        type="button"
                        onClick={this.disableOrigin}
                    >Deshabilitar
                    </button>
                    <button
                        style={{ margin: "20px", opacity: this.state.disableBtnDelete ? "0.5" : "1.0" }}
                        className="red-fill"
                        disabled={this.state.disableBtnDelete}
                        type="button"
                        onClick={this.showModalDisableOrigin}
                    >Cancelar
                    </button>
                </div>
            </div >
        );
    }

    filtrarOrigins = e => {
        e.preventDefault();
        let type = this.state.type;
        if (type !== '' && type !== undefined && type !== null) {
            this.setState({ isLoading: true });
            this.props.actions.getConfigOrigins({ type });
        }
        else {
            this.modalAlert('¡Hey!', 'Debe ingresar el nombre del Tipo de Origen para poder realizar la búsqueda.', 404);
        }
    };


    cleanFilter = e => {
        e.preventDefault();
        var type = document.getElementById('type');
        type.value = '';
        type.classList.remove("inputError", "inputOk", "inputFocus");
        this.setState({ type: "", isLoading: true })
        this.props.actions.getConfigOrigins({ type: "" });
    };

    handleSetValue(e) {
        this.setState({ type: e.target.value });
    }

    modalAlert(title, text, res) {
        ModalResponse({ title, text, res, });
    }


    processResponseGetOrigins() {
        const resp = this.state.configOrigins;
        if (resp && resp.data && resp.data.origins && (resp.status === StatusCodes.OK || resp.status === StatusCodes.CREATED)) {
            this.setState({ datos: resp.data.origins, isLoading: false });
        } else {
            this.setState({ isLoading: false });
            this.modalAlert('¡Error!', 'Se ha producido un error al tratar de cargar los datos. Comuníquese con el Administrador.', 404);
        }
    }

    processResponseUpdateOrigin() {
        this.setState({ disableBtnDelete: false });
        this.showModalDisableOrigin();
        const resp = this.state.updateOrigin;
        if (resp && resp.data && (resp.status === StatusCodes.OK || resp.status === StatusCodes.CREATED)) {
            this.createLogActivity(resp.data);
            let type = this.state.type ? this.state.type : "";
            this.setState({ isLoading: true });
            this.modalAlert('Origen Deshabilitado', null, 200);
            this.props.actions.getConfigOrigins({ type });
            update = false;
        } else {
            this.modalAlert('¡Error!', 'Ha ocurrido un problema, inténtelo más tarde o comuníquese con el Administrador.', 404);
        }
    }

    componentDidUpdate() {
        if (this.props.errorOrigins !== this.state.errorOrigins) {
            this.setState({ errorOrigins: this.props.errorOrigins }, () => {
                if (this.state.errorOrigins) {
                    this.modalAlert('¡Error!', 'Se ha producido un error al tratar de cargar los datos. Comuníquese con el Administrador.', 404);
                }
            });
        }
        if (this.props.errorUpdate !== this.state.errorUpdate) {
            this.setState({ errorUpdate: this.props.errorUpdate }, () => {
                if (this.state.errorUpdate) {
                    this.modalAlert('¡Error!', 'Ha ocurrido un problema, inténtelo más tarde o comuníquese con el Administrador.', 404);
                }
            });
        }
        if (this.props.configOrigins !== this.state.configOrigins) {
            this.setState({ configOrigins: this.props.configOrigins }, () => {
                if (this.state.configOrigins) this.processResponseGetOrigins();
            });
        }
        if (this.props.updateOrigin !== this.state.updateOrigin) {
            this.setState({ updateOrigin: this.props.updateOrigin }, () => {
                if (this.state.updateOrigin && update) this.processResponseUpdateOrigin();
            });
        }
    }

    showModalSuccess = () => {
        this.setState({ showSuccess: !this.state.showSuccess, openSuccess: !this.state.openSuccess });
    }

    renderModalX(text) {
        return (
            <div>
                <div className="uxDetailDataFilterTitle" style={{ textAlign: "center" }}>
                    <h4 >{text}</h4>
                </div>
                <div className="uxDetailContainer" style={{ textAlign: "center" }}>
                    <button
                        style={{ margin: "20px" }}
                        className="orange-fill"
                        type="button"
                        onClick={(e) => { this.showModalSuccess() }}
                    >Aceptar
                    </button>

                </div>
            </div >
        );
    }

    render() {

        let error = this.state.errorOrigins;
        let datos = this.state.datos;
        const btnActions = {
            btnEditOrigin: accessEdit ? '/configorigin/edit/' : null,
            btnDisableOrigin: accessDelete ? this.btnDisableOrigin : null,
        };

        return (
            <div>
                <div>
                    <h3>Configuración de Origenes y Notificaciones</h3>
                    {accessCreate ?
                        (<a href="/configorigin/create" className="a-button green-fill">
                            <img className="icon-img icon-mas" src={svgs['mas']} alt="" />
                            Crear
                        </a>) : null}

                    <form onSubmit={this.filtrarOrigins}>

                        <div className="row divFilter">

                            <div className="input-container-accountant column4">
                                <label>Tipo de Notificación</label>
                                <input
                                    className="inputFilter customEnterText"
                                    id="type"
                                    name="type"
                                    type="text"
                                    placeholder=""
                                    onFocus={(e) => this.focusInput(e)}
                                    onBlur={(e) => this.blurInput(e)}
                                    onChange={(e) => this.handleSetValue(e)}
                                    value={this.state.type}
                                />
                            </div>

                            <div className="column4">
                                <div className="row">
                                    <div className="column2">
                                        <button className="green-fill btnBuscar" id="submitFilterButton" type="submit">
                                            <div className="row">
                                                <div className="column2">
                                                    <span className="siicon icon-search searchMarket"></span>
                                                </div>
                                                <div className="column2">
                                                    <span>Buscar</span>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="column2">
                                        <button
                                            onClick={this.cleanFilter}
                                            className="orange-fill"
                                            type="button"
                                        >
                                            Limpiar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {error && <h4>Error: {String(error)}</h4>}
                {(this.state.isLoading) ? (
                    <Loader />
                ) : (
                    <div>

                        <div className="divTable">
                            <TableReport
                                columns={columns} // Data columns to table
                                tabs={false} //Enable tabs
                                tabsData={[]} //Load data to tabs check option on tabsData const
                                headerCounterTab={true} //Counter tab option
                                btnActions={btnActions}
                                actionButtons={accessEdit || accessDelete} //Enable buttons (edit, delete, copy, open)
                                pageSize={30} // Enable page size
                                pagination={true} //Enable pagination (true, false)
                                paginationNav={'arrows'} //Nav options (arrow, dot)
                                paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
                                maxSizePagination={6} //Size pagination
                                defaultPaginationNumber={1} //Current pagination when start app
                                paginationGrouped={10} //Grouped by page
                                detailRow={false} //Open column detail with left arrow
                                data={datos} //Data for table
                            />
                        </div>

                        <Modal
                            onClose={this.showModalDisableOrigin}
                            show={this.state.showDisable}
                            title="Deshabilitar Origen"
                            open={this.state.openDisable}>
                            {this.renderModalDisable()}
                        </Modal>

                        <Modal
                            onClose={(e) => { this.showModalSuccess() }}
                            show={this.state.showSuccess}
                            title="Origen Deshabilitado"
                            open={this.state.openSuccess}>
                            {this.renderModalX("¡El origen fue deshabilitado exitosamente!")}
                        </Modal>

                    </div>
                )}
            </div>
        );
    }

}

AuroraConfigOrigins.propTypes = {
    actions: PropTypes.object.isRequired,
    configOrigins: PropTypes.object,
    errorOrigins: PropTypes.object,
    errorUpdate: PropTypes.object,
    updateOrigins: PropTypes.object,
    log: PropTypes.object,
};

const mapStateToProps = state => ({
    configOrigins: state.auroraConfigOriginsReducer.configOrigins,
    errorOrigins: state.auroraConfigOriginsReducer.errorOrigins,
    isLoading: state.auroraConfigOriginsReducer.isLoading,
    updateOrigin: state.auroraConfigOriginsReducer.updateOrigin,
    errorUpdate: state.auroraConfigOriginsReducer.errorUpdate,
    log: state.logs.log,
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(auroraConfigOriginsActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(AuroraConfigOrigins);

