import axios from 'axios';
import config from '../../config';
import { getCookie } from "../../utils/cookies";
import { uuidV4Generator } from '../../utils/randomizer';

export const getAddons = async () => {
    let dataLocal = JSON.parse(localStorage.getItem("Company"));
    let tenantId = dataLocal.CloudTenantConnectionUser.split("SIIGOCloudTenant_")[1];
    const response = await axios({
        method: 'get',
        headers: {
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getCookie('token')}`,
            'backoffice-service-country': 'COL',
            'backoffice-service-name': 'addons-service',
            'backoffice-request-id': uuidV4Generator(),
        },
        url: `${config.url}bridge?cloudTenantId=${tenantId}`,
    });

    return response.data;
};