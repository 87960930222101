import * as types from '../actions';

const marketReducer = (state = { isLoading: false }, action = null) => {
  switch (action.type) {
    case types.GET_COMPANIES:
      return { ...state, isLoading: true };
    case types.COMPANIES_RECEIVED:
      return {
        ...state,
        isLoading: false,
        companies: action.data,
      };
    case types.COMPANIES_REQUEST_FAILED:
      return { ...state, isLoading: false, companies: action.error };
    case types.EMAIL_GET_COMPANIES:
      return { ...state, isLoadingEmailCompanies: true };
    case types.EMAIL_COPANIES_RECEIVED:
      return {
        ...state, isLoadingEmailCompanies: false, emailCompanies: action.data
      };
    case types.EMAIL_COMPANIES_REQUEST_FAILED:
      return { ...state, isLoadingEmailCompanies: false, emailCompanies: action.error };
    case types.SET_PLAN:
      return { ...state, isLoading: true };
    case types.PLAN_RECEIVED:
      return { ...state, isLoading: false, plan: action.data };
    case types.PLAN_REQUEST_FAILED:
      return { ...state, isLoading: false, plan: action.error };
    case types.GET_TASK:
      return { ...state, isLoadingTask: true };
    case types.TASK_RECEIVED:
      return { ...state, isLoadingTask: false, task: action.data };
    case types.TASK_REQUEST_FAILED:
      return { ...state, isLoadingTask: false, task: action.error };
    case types.SET_COMPANY:
      return { ...state, isLoadingCompany: true };
    case types.COMPANY_RECEIVED:
      return { ...state, isLoadingCompany: false, company: action.data };
    case types.COMPANY_REQUEST_FAILED:
      return { ...state, isLoadingCompany: false, company: action.error };
    case types.MODIFY_COMPANY:
      return { ...state, isLoadingModify: false };
    case types.MODIFY_COMPANY_RECEIVED:
      return { ...state, isLoadingModify: false, modify: action.data };
    case types.MODIFY_COMPANY_FAILED:
      return { ...state, isLoadingModify: false, modify: action.error };

    case types.GET_COMPANY_LOGS:
      return { ...state, isLoading: true };
    case types.GET_COMPANY_LOGS_RECEIVED:
      return { ...state, isLoading: false, companyLogs: action.data };
    case types.GET_COMPANY_LOGS_FAILED:
      return { ...state, isLoading: false, companyLogs: action.error };

    case types.GET_PACKAGE:
      return { ...state, packages: [] };
    case types.GET_PACKAGE_RECEIVED:
      return { ...state, packages: action.data };
    case types.GET_PACKAGE_FAILED:
      return { ...state, packages: action.error };

    case types.ADD_PACKAGE:
      return { ...state, isLoadingPack: true };
    case types.ADD_PACKAGE_RECEIVED:
      return { ...state, isLoadingPack: false, pack: action.data };
    case types.ADD_PACKAGE_FAILED:
      return { ...state, isLoadingPack: false, pack: action.error };

    case types.GET_BALANCE_PACK:
      return { ...state };
    case types.GET_BALANCE_PACK_RECEIVED:
      return { ...state, balance: action.data == null ? '0' : action.data };
    case types.GET_BALANCE_PACK_FAILED:
      return { ...state, balance: action.error };

    case types.DELETE_BALANCE:
      return { ...state, isLoadingPackDelete: true };
    case types.DELETE_BALANCE_RECEIVED:
      return { ...state, isLoadingPackDelete: false, deleteBalance: action.data };
    case types.DELETE_BALANCE_FAILED:
      return { ...state, isLoadingPackDelete: false, deleteBalance: action.error };

    case types.GET_PACKAGE_DETAIL:
      return { ...state, packageDetail: [] };
    case types.GET_PACKAGE_DETAIL_RECEIVED:
      return {
        ...state,
        packageDetail: action.data == null ? [] : action.data,
      };
    case types.GET_PACKAGE_DETAIL_FAILED:
      return { ...state, packageDetail: action.error };

    case types.GET_USERS_COMPANY_MARKET:
      return { ...state, isLoading: true };
    case types.GET_USERS_COMPANY_MARKET_SUCCESS:
      return { ...state, isLoading: false, users: action.data };
    case types.GET_USERS_COMPANY_MARKET_FAILED:
      return { ...state, isLoading: false, users: action.error };

    //AddOns
    case types.GET_ADDONS:
      return { ...state };
    case types.ADDONS_RECEIVED:
      return { ...state, complement: action.data };
    case types.ADDONS_REQUEST_FAILED:
      return { ...state, complement: action.error };


    case types.GET_PAYROLL_PACKAGE_BALANCE:
      return { ...state };
    case types.GET_PAYROLL_PACKAGE_BALANCE_RECEIVED:
      return { ...state, balanceNE: action.data == null ? '0' : action.data };
    case types.GET_PAYROLL_PACKAGE_BALANCE_FAILED:
      return { ...state, balanceNE: action.error };

    case types.ADD_PAYROLL_PACKAGE:
      return { ...state, isLoadingPack: true };
    case types.ADD_PAYROLL_PACKAGE_RECEIVED:
      return { ...state, isLoadingPack: false, packPayroll: action.data };
    case types.ADD_PAYROLL_PACKAGE_FAILED:
      return { ...state, isLoadingPack: false, packPayroll: action.error };

    case types.GET_PAYROLL_PACKAGE_DETAIL:
      return { ...state, payrollDetail: [] };
    case types.GET_PAYROLL_PACKAGE_DETAIL_RECEIVED:
      return {
        ...state,
        payrollDetail: action.data == null ? [] : action.data,
      };
    case types.GET_PAYROLL_PACKAGE_DETAIL_FAILED:
      return { ...state, payrollDetail: action.error };

    case types.DELETE_PAYROLL_PACKAGE:
      return { ...state, isLoadingPackDelete: true };
    case types.DELETE_PAYROLL_PACKAGE_RECEIVED:
      return { ...state, isLoadingPackDelete: false, deletePayroll: action.data };
    case types.DELETE_PAYROLL_PACKAGE_FAILED:
      return { ...state, isLoadingPackDelete: false, deletePayroll: action.error };

    // Edit User
    case types.EDIT_USER_COMPANY_MARKET:
      return { ...state, isLoading: true };
    case types.EDIT_USER_COMPANY_MARKET_SUCCESS:
      return { ...state, isLoading: false, users: action.data };
    case types.EDIT_USER_COMPANY_MARKET_FAILED:
      return { ...state, isLoading: false, users: action.error };

    // User Has Movements
    case types.GET_USER_HAS_MOVEMENTS:
      return { ...state, isLoading: true };
    case types.GET_USER_HAS_MOVEMENTS_SUCCESS:
      return { ...state, isLoading: false, hasMovements: action.data };
    case types.GET_USER_HAS_MOVEMENTS_FAILED:
      return { ...state, isLoading: false, hasMovements: action.error };

    // Delete User
    case types.DELETE_USER_COMPANY_MARKET:
      return { ...state, isLoading: true };
    case types.DELETE_USER_COMPANY_MARKET_SUCCESS:
      return { ...state, isLoading: false, users: action.data };
    case types.DELETE_USER_COMPANY_MARKET_FAILED:
      return { ...state, isLoading: false, users: action.error };

    case types.CREATE_USER_COMPANY_MARKET:
      return { ...state, isLoading: true };
    case types.CREATE_USER_COMPANY_MARKET_SUCCESS:
      return { ...state, isLoading: false, users: action.data };
    case types.CREATE_USER_COMPANY_MARKET_FAILED:
      return { ...state, isLoading: false, users: action.error };

    case types.VERIFY_NIT_EDITION:
      return { ...state, isLoading: true };
    case types.VERIFY_NIT_EDITION_RECEIVED:
      return { ...state, isLoading: false, verifyNitEdition: action.data };
    case types.VERIFY_NIT_EDITION_FAILED:
      return { ...state, isLoading: false, verifyNitEdition: action.error };

    default:
      return state;
  }
};

export default marketReducer;
