import * as types from "../actions";

const siigoComReducer = (state = { isLoading: false }, action = null) => {
  switch (action.type) {
    case types.POST_UPDATE_CACHE:
      return { ...state, isLoading: true };

    case types.POST_UPDATE_CACHE_RECEIVED:
      return {
        ...state,
        isLoading: false,
        response: action.response,
      };

    case types.POST_UPDATE_CACHE_FAILED:
      return { ...state, isLoading: false, error: action.error };

    case types.POST_UPDATE_CACHE_RESET:
      return { ...state, error: null, response: null };

    default:
      return state;
  }
};

export default siigoComReducer;
