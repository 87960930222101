import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { postCampaign } from "../../../../../actions/shop/campaign";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import {
  CampaignConfigurationsBuilder,
  CampaignConfigurationsModel,
} from "../../../../../models";
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import Constants from "../../../constants";
import Loader from "../../../../tools/loader";
import moment from "moment";
import {
  postUpdateCache,
  postUpdateResetData,
} from "../../../../../actions/siigoCom/cache";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 30,
    padding: 20,
    border: "black 2px solid",
    borderRadius: 15,
  },
  primaryButton: {
    "&:hover": {
      color: "white",
    },
  },
  secondaryButton: {
    "&:hover": {
      backgroundColor: theme.palette.secondary,
      color: "white",
    },
  },
}));

function mapStateToProps(state) {
  return {
    error: state.shopReducer.error,
    response: state.shopReducer.response,
    isLoading: state.shopReducer.isLoading,
    updateCacheError: state.siigoComReducer.error,
  };
}

function clearInputsValue() {
  document.getElementById("campaign-code").value = null;
  document.getElementById("campaign-name").value = null;
  document.getElementById("campaign-start-date").value = null;
  document.getElementById("campaign-end-date").value = null;
}

function mapDispatchToProps(dispatch) {
  return {
    postCampaignAction: bindActionCreators(postCampaign, dispatch),
    postUpdateCacheAction: bindActionCreators(postUpdateCache, dispatch),
    postUpdateCacheResetDataAction: bindActionCreators(
      postUpdateResetData,
      dispatch
    ),
  };
}

function Footer(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogData, setDialogData] = useState({
    show: false,
    sended: false,
  });
  const [finalCampaignInformation, setFinalCampaignInformation] = useState({});
  let {
    campaignInformation,
    setCampaignInformation,
    postCampaignAction,
    postUpdateCacheAction,
    updateCacheError,
    response,
    error,
    postUpdateCacheResetDataAction,
  } = props;

  useEffect(() => {
    if (response?.status === "OK" && campaignInformation.name) {
      setDialogData({
        show: true,
        sended: true,
        title: t("publish", { name: t("name") }),
        text: t("published", {
          context: "female",
          content: `${t("name")} '${campaignInformation.code}'`,
        }),
      });
      updateSiigoComCache();
      setCampaignInformation({});
      clearInputsValue();
    }
    if (error) {
      setDialogData({
        show: true,
        sended: true,
        title: t("publish", { name: t("name") }),
        text: t("notPublished", {
          context: "female",
          content: `${t("name")} '${campaignInformation.code}'`,
        }),
      });
    }
  }, [response, error]);

  useEffect(() => {
    updateCacheError &&
      setDialogData({
        show: true,
        sended: true,
        title: t("publish", { name: t("name") }),
        text: t(`shop.campaigns.cache.failed`),
      });

    return () => postUpdateCacheResetDataAction();
  }, [updateCacheError]);

  const updateSiigoComCache = () => {
    campaignInformation?.destination ===
      Constants.CAMPAIGN_DESTINATIONS.SIIGO_COM.VALUE &&
      postUpdateCacheAction({
        ...Constants.UPDATE_SIIGO_COM_CACHE_BODY,
        value: campaignInformation?.code,
      });
  };

  const excludedPeriodValidations = (campaign) =>
    [Constants.CAMPAIGN_DESTINATIONS.SIIGO_COM.VALUE].includes(
      campaign?.destination
    ) ||
    [Constants.CAMPAIGN_TYPES.DISCOUNT_CODE.VALUE].includes(campaign?.type);

  const parseCampaignConfigurations = (campaign) => {
    let newConfiguration = [];
    for (let index = 0; index < campaign.configurations.length; index++) {
      const element = campaign.configurations[index];
      if (
        element.productCodes?.length &&
        element.discountPercentage &&
        (element.period?.length || excludedPeriodValidations(campaign))
      ) {
        const campaignConfigurationModel = new CampaignConfigurationsModel(
          {
            periodType: element.period,
            discountValue: Number(element.discountPercentage),
            products: element.productCodes,
            featured: element.featuredProduct,
            order: element.id,
          },
          campaign.destination,
          campaign.type
        );

        const campaignConfiguration = new CampaignConfigurationsBuilder()
          .decorate(campaignConfigurationModel)
          .setInstallmentsPeriod(campaign.type, {
            number: Number(element.numberOfInstallment),
            minimumValue: Number(element.installmentMinimumValue),
          })
          .build();
        newConfiguration.push(campaignConfiguration);
      }
    }
    return newConfiguration;
  };

  const parseData = (campaign) => {
    let campaignConfigurationParsed = parseCampaignConfigurations(campaign);
    const campaignPropsToCheck = [
      campaign.name,
      campaign.code,
      campaign.type,
      campaign.destination,
      campaign.startDate,
      campaign.endDate,
      campaignConfigurationParsed,
    ];
    if([Constants.CAMPAIGN_DESTINATIONS.SIIGO_ACCOUNTANT.VALUE].includes(campaign.destination) 
      && [Constants.CAMPAIGN_TYPES.UPGRADE.VALUE, Constants.CAMPAIGN_TYPES.DISCOUNT_CODE.VALUE].includes(campaign.type)){
        campaignPropsToCheck.push(campaign.partnerCategoriesSelected)   
    }
    if (!campaignPropsToCheck.every((campaignProp) => campaignProp?.length)) {
      return;
    }
    let parsedCampaign = {
      state: "active",
      name: campaign.name,
      code: campaign.code,
      type: campaign.type,
      destination: campaign.destination,
      productType: "Child",
      date: {
        start: moment(campaign.startDate)
          .add(5, "hours")
          .format("YYYY-MM-DDTHH:mm:ss"),
        end: moment(campaign.endDate)
          .add(5, "hours")
          .format("YYYY-MM-DDTHH:mm:ss"),
      },
      configurations: campaignConfigurationParsed,
    };
    if (
      campaign.destination == Constants.CAMPAIGN_DESTINATIONS.SIIGO_COM.VALUE &&
      campaign.place?.length
    ) {
      parsedCampaign.place = campaign.place;
    }
    if (
      (campaign.destination ==
        Constants.CAMPAIGN_DESTINATIONS.SIIGO_CLOUD.VALUE &&
        campaign.includeProductsSelected.length) ||
      campaign.excludeProductsSelected.length ||
      campaign.partnerCategoriesSelected.length
    ) {
      parsedCampaign["rules"] = {
        match: {
          include: {
            products: campaign.includeProductsSelected,
            serials: campaign.partnerCategoriesSelected
          },
          exclude: {
            products: campaign.excludeProductsSelected,
          },
        },
      };
    }

    /**
     * Se agrega asignación en type debido a que las campañas de discount-code y upgrade manejan el mismo tipo
     */
    if (
      [Constants.CAMPAIGN_TYPES.DISCOUNT_CODE.VALUE].includes(
        parsedCampaign?.type
      )
    )
      parsedCampaign.type = Constants.CAMPAIGN_TYPES.UPGRADE.VALUE;

    return parsedCampaign;
  };

  if (props.isLoading) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={Constants.SIIGO_THEME}>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <Typography variant="body1" gutterBottom>
              {t("publishImportantMessage", {
                publish: t("publish", { name: t("name") }),
              })}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classes.secondaryButton}
              onClick={() => {
                const parsedData = parseData(campaignInformation);
                if (!parsedData) {
                  setDialogData({
                    show: true,
                    sended: true,
                    title: t("publish", { name: t("name") }),
                    text: t("incompleteForm"),
                  });
                  return;
                }
                setFinalCampaignInformation(parsedData);
                setDialogData({
                  show: true,
                  sended: false,
                  title: t("publish", { name: t("name") }),
                  text: t("confirmPublish", {
                    context: "female",
                    content: `${t("name")} '${campaignInformation.code}'`,
                  }),
                });
              }}
            >
              {t("publish", { name: t("name") })}
            </Button>
          </Grid>
        </Grid>
        <div>
          <Dialog
            open={dialogData.show}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {dialogData.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogData.text}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {!dialogData.sended && (
                <>
                  <Button
                    onClick={() => postCampaignAction(finalCampaignInformation)}
                    color="primary"
                    variant="contained"
                    className={classes.primaryButton}
                    autoFocus
                  >
                    {t("yes")}
                  </Button>
                  <Button
                    onClick={() =>
                      setDialogData({
                        show: false,
                      })
                    }
                    variant="outlined"
                    color="primary"
                  >
                    {t("no")}
                  </Button>
                </>
              )}
              {dialogData.sended && (
                <>
                  <Button
                    onClick={() =>
                      setDialogData({
                        show: false,
                      })
                    }
                    color="primary"
                    variant="contained"
                    className={classes.primaryButton}
                    autoFocus
                  >
                    {t("accept")}
                  </Button>
                </>
              )}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
