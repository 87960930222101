import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as statePaymentReportActions from '../../actions/statePaymentReport/statePaymentReportActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalResponse from '../layout/modals/modalResponse';
import DatePicker from '../tools/datePicker';
import Modal from '../layout/modals/auroraModal';
import "./style.scss";
import { AxiosRefunds } from './services';
import CryptoJS from 'crypto-js';
import { StatusCodes } from 'http-status-codes';
const columns = [
  {
    name: 'Referencia interna aurora',
    selector: '_id',
    sortable: true,
  },
  {
    name: 'Fecha de la transacción',
    selector: 'data.creationDate',
    sortable: true
  },
  {
    name: 'Estado de la transacción',
    selector: 'data.orderTxStatus',
    sortable: true,
    filterDDL: true
  },
  {
    name: 'Identificación del cliente',
    selector: 'data.adminId',
    sortable: true
  },
  {
    name: 'Cotizacion',
    selector: 'data.productId',
    sortable: true
  },
  {
    name: 'Nombre del producto',
    selector: 'data.productName',
    sortable: true
  },
  {
    name: 'Monto de la transacción',
    selector: 'data.value',
    sortable: true,
  },
  {
    name: 'Medio de pago',
    selector: 'data.paymentMethodType',
    sortable: true,
  },
  {
    name: 'Reembolsado',
    selector: 'data.refundStatus',
    sortable: true,
  },

];


class RefundAurora extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fromDate: undefined,
      toDate: undefined,
      payments: this.props.payments,
      payment: null,
      showRefund: false,
      openDetail: false,
      reasonRefund: "",
      showFormError: false,
      isLoadingRefund: false
    };
  }

  componentDidMount() {
    document.title = "Reembolsos Aurora";
    this.props.actions.clearGetPayments();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.payments !== this.props.payments) { this.setState({ payments: this.props.payments }) }
  }

  filterPayments = e => {
    e.preventDefault();

    const id = e.target.id.value;
    const nit = e.target.nit.value;
    const cotizacion = e.target.cotizacion.value;
    const fromDate = this.state.fromDate;
    const toDate = this.state.toDate;

    let query = { data: {}, sort: { "date": -1 } }

    if ((id != '' || nit != '' || cotizacion != '') && (fromDate != undefined && toDate != undefined)) {

      query.data["data.orderTxStatus"] = 'APPROVED'
      query.data["data.payerCountry"] = 'CO'

      if (id) {
        query.data["_id"] = id;
      }
      if (nit) {
        query.data['data.adminId'] = nit;
      }
      if (cotizacion) {
        query.data["data.productId"] = cotizacion;
      }
      if (fromDate !== '') {
        query.data.date = { ...query.data.date, $gt: fromDate };
      }
      if (toDate !== '') {
        query.data.date = { ...query.data.date, $lt: toDate };
      }

      this.props.actions.getStatePayments(query);

    } else {
      ModalResponse({
        title: '¡Ingresa los datos!',
        text: 'Debes especificar el rango de fechas más un criterio',
        res: 404
      });
      let list = document.getElementsByClassName('inputFilter')
      for (const element of list) {
        element.classList.remove('labelOk');
        element.classList.add('inputError');
      }
    }
  };

  cleanFilter = e => {
    e.preventDefault();

    let id = document.getElementById('id');
    id.value = '';
    id.classList.remove("inputError", "inputOk", "inputFocus");

    let nit = document.getElementById('nit');
    nit.value = '';
    nit.classList.remove("inputError", "inputOk", "inputFocus");

    let cotizacion = document.getElementById('cotizacion');
    cotizacion.value = '';
    cotizacion.classList.remove("inputError", "inputOk", "inputFocus");

    this.setState({ fromDate: undefined, toDate: undefined });

    this.props.actions.clearGetPayments();

  };

  showRefund = data => {
    this.setState({ reasonRefund : ""})
    this.setState({ payment: data, showRefund: true, openDetail: true });
  };

  showModalRefund = (e) => {
    this.setState({ showRefund: !this.state.showRefund });
  };

  refundTransaction = (e) => {
    e.preventDefault();
    if (!this.state.reasonRefund) {
      this.setState({ showFormError: true });
      return;
    }

    let data = {
      reference: this.state.payment._id,
      paymentMethodType: this.state.payment.data.paymentMethodType,
      orderId: this.state.payment.data.orderId,
      description: this.state.reasonRefund,
      transactionId: this.state.payment.data.lastTransactionId,
      paymentPlatform: this.state.payment.data.paymentPlatform,
      payerCountry: this.state.payment.data.payerCountry
    }
    data = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
    data = CryptoJS.enc.Base64.stringify(data);
    this.setState({isLoadingRefund: true})
    this.showModalRefund()
    AxiosRefunds.refundTransaction({data}).then(resp => {
      this.setState({isLoadingRefund: false})
      ModalResponse({
        title: resp.data.message,
        text: "El reembolso tarda de 2 - 5 minutos en reflejarse, por favor actualiza la pagina",
        res: 200
      });
      
      let pay = this.state.payment;
      pay.data.refundStatus = "PENDING";
      this.setState({payment: pay});

    }).catch(() => {
      this.setState({isLoadingRefund: false})
      ModalResponse({
        title: "No se pudo realizar el reembolso del pago",
        res: 400
      });
    })
  }
  
  handleChange = ({ target }) => {
    if (!target.value) {
      this.setState({ showFormError: true });
    } else {
      this.setState({ showFormError: false });
    }
    this.setState({ reasonRefund: target.value });
  }

  handleDate = (data) => {
    let fromDate = undefined;
    let toDate = undefined;
    if (data.from != undefined) {
      fromDate = new Date(data.from);
      fromDate.setHours(0);
      fromDate.setMinutes(0);
      fromDate.setSeconds(0);
      fromDate.setMilliseconds(0);
    }
    if (data.to != undefined) {
      toDate = new Date(data.to);
      toDate.setHours(23);
      toDate.setMinutes(59);
      toDate.setSeconds(59);
      toDate.setMilliseconds(999);
    }
    this.setState({ fromDate, toDate });
    return { fromDate, toDate };
  }

  focusInput = (e) => {
    e.preventDefault();
    let list = document.getElementsByClassName('inputFilter')
    for (const element of list) {
      element.classList.remove('inputError');
    }
  };

  render() {
    let { error, isLoading } = this.props;
    let { payments } = this.state;

    const btnActions = {
      btnRefund: this.showRefund.bind(this),
    }
    let datos = [];

    if (payments) {
      if (payments.status == StatusCodes.CREATED) {
        datos = payments.data;
      } else {
        ModalResponse(
          {
            title: '¡Ups!',
            text: 'Ocurrio un error realizando la consulta, intenta nuevamente',
            res: 404
          })
      }
    }

    return (
      <div>
        {
          this.state.isLoadingRefund &&
          <div className='loaderRefund'>
            <Loader />
          </div>
        }

        <h3>Solicitud de reembolsos de pago</h3>
        <div>
          <form onSubmit={this.filterPayments}>
            <div className="row divFilter">
              <DatePicker handleDate={this.handleDate} from={this.state.fromDate} to={this.state.toDate} />
              <div className="column4"></div>
            </div>

            <div className="row divFilter">

              <div className="input-container-accountant column4">
                <label>Referencia de pago</label>
                <input
                  className="inputFilter"
                  id="id"
                  name="id"
                  type="text"
                  placeholder=""
                  onFocus={(e) => this.focusInput(e)}

                />
              </div>
              <div className="input-container-accountant column4">
                <label>NIT de Cliente</label>
                <input
                  className="inputFilter"
                  id="nit"
                  name="nit"
                  type="text"
                  placeholder=""
                  onFocus={(e) => this.focusInput(e)}

                />
              </div>
              <div className="input-container-accountant column4">
                <label>N° Cotización</label>
                <input
                  className="inputFilter"
                  id="cotizacion"
                  name="cotizacion"
                  type="text"
                  placeholder=""
                  onFocus={(e) => this.focusInput(e)}

                />
              </div>
              <div className="column4">
                <div className="row">
                  <div className="">
                    <button className="green-fill btnBuscar" id="submitFilterButton" type="submit">
                      <div className="row">
                        <div className="column2">
                          <span className="siicon icon-search searchMarket"></span>
                        </div>
                        <div className="column2">
                          <span>Buscar</span>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="column2">
                    <button
                      onClick={this.cleanFilter}
                      className="orange-fill"
                      type="button"
                    >
                      Limpiar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        {error && <h4>Error: {error.response.data}</h4>}
        {(isLoading) ? (
          <Loader />
        ) : (
          <div>

            <div className="divTable">
              <TableReport
                columns={columns} // Data columns to table
                tabs={false} //Enable tabs
                tabsData={[]} //Load data to tabs check option on tabsData const
                headerCounterTab={true} //Counter tab option
                btnActions={btnActions}
                actionButtons={true}
                pageSize={50} // Enable page size
                pagination={true} //Enable pagination (true, false)
                paginationNav={'arrows'} //Nav options (arrow, dot)
                paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
                maxSizePagination={6} //Size pagination
                defaultPaginationNumber={1} //Current pagination when start app
                paginationGrouped={10} //Grouped by page
                detailRow={false} //Open column detail with left arrow
                data={datos} //Data for table
              />
            </div>
            <Modal
              onClose={this.showModalRefund}
              show={this.state.showRefund}
              title="Reembolsar transacción"
              open={this.state.openDetail}
            >
              <div>
                <div>
                  <h5>Motivo de reembolso:</h5>
                </div>
                <div className='mb-4'>
                  <textarea
                    type="text"
                    rows="3"
                    name="description"
                    id="description"
                    placeholder="Ingrese el motivo del reembolso"
                    value={this.state.reasonRefund}
                    onChange={this.handleChange}
                    maxLength={150}
                    className="inputTextAreaRefund"
                  />
                  {this.state.showFormError &&
                    <span className="span-danger" >El campo motivo del reembolso es obligatorio</span>
                  }
                </div>
                <div>
                  <button onClick={this.refundTransaction}>Reembolsar</button>
                </div>
              </div>


            </Modal>
          </div>
        )}
      </div>
    );
  }
}

RefundAurora.propTypes = {
  actions: PropTypes.object,
  error: PropTypes.object,
};

const mapStateToProps = state => ({
  payments: state.statePaymentReport.payments,
  error: state.statePaymentReport.error,
  isLoading: state.statePaymentReport.isLoading,

});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(statePaymentReportActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(RefundAurora);

