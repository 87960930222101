import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/indexGeneral';
import config from '../config.js';

export function* getNotifiactions(action) {
	const api = API.create(config.urlNotificationAS);
	try {
		const { params } = action;
		let notifications = yield call(api.notification.getNotifications);
		yield put({ type: types.NOTIFICATIONS_RECEIVED, data: notifications });
	} catch (err) {
		yield put({ type: types.NOTIFICATIONS_REQUEST_FAILED, err });
	}
}

export function* createNotifiactions(action) {
	const api = API.create(config.urlNotificationAS);
	try {
		const { params } = action;
		let notifications = yield call(api.notification.createNotification, params);
		yield put({
			type: types.CREATE_NOTIFICATION_RECEIVED,
			data: notifications
		});
	} catch (err) {
		yield put({ type: types.CREATE_NOTIFICATION_REQUEST_FAILED, err });
	}
}

export function* editNotifiactions(action) {
	const api = API.create(config.urlNotificationAS);
	try {
		const { params } = action;
		let notification = yield call(api.notification.editNotification, params);
		yield put({
			type: types.EDIT_NOTIFICATION_RECEIVED,
			data: notification
		});
	} catch (err) {
		yield put({ type: types.EDIT_NOTIFICATION_REQUEST_FAILED, err });
	}
}

export function* deleteNotifiactions(action) {
	const api = API.create(config.urlNotificationAS);
	try {
		const { id } = action;
		let notification = yield call(api.notification.deleteNotification, id);
		yield put({
			type: types.DELETE_NOTIFICATION_RECEIVED,
			data: notification
		});
		yield put({ type: types.GET_NOTIFICATIONS });
	} catch (err) {
		yield put({ type: types.DELETE_NOTIFICATION_REQUEST_FAILED, err });
	}
}

export function* getAccountStatemets(action) {
	const api = API.create(config.urlNotificationAS);
	try {
		const { params } = action;
		let accounts = yield call(api.account.getAccountStatements, params);
		yield put({ type: types.ACCOUNTS_RECEIVED, data: accounts });
	} catch (err) {
		yield put({ type: types.ACCOUNTS_REQUEST_FAILED, err });
	}
}

export function* sendEmail(action) {
	const api = API.create(config.urlNotificationAS);
	try {
		const { params } = action;
		let email = yield call(api.account.sendEmail, params);
		yield put({ type: types.SEND_EMAIL_RECEIVED, data: email });
	} catch (err) {
		yield put({ type: types.SEND_EMAIL_REQUEST_FAILED, err });
	}
}

export function* getAccountant(action) {
	const api = API.create(config.urlProvisioning);
	try {
		const { params } = action;
		let accountants = yield call(api.accountant.getAccountant, {
			params: JSON.stringify(params)
		});
		yield put({ type: types.GET_ACCOUNTANTS_RECEIVED, data: accountants });
	} catch (err) {
		yield put({ type: types.GET_ACCOUNTANTS_FAILED, err });
	}
}

export function* modifyAccountant(action) {
	const api = API.create(config.urlProvisioning);
	try {
		const { params } = action;
		let modifyAccountantRes = yield call(api.accountant.modifyAccountant, params);
		yield put({ type: types.MODIFY_ACCOUNTANT_RECEIVED, data: modifyAccountantRes });
	} catch (err) {
		yield put({ type: types.MODIFY_ACCOUNTANT_FAILED, err });
	}
}


export function* sendEmailAccountant(action) {
	const api = API.create(config.urlProvisioning);
	try {
		const { params } = action;
		let email = yield call(api.accountant.sendEmail, params);
		yield put({ type: types.SEND_EMAIL_ACCOUNTANT_RECEIVED, data: email });
	} catch (err) {
		yield put({ type: types.SEND_EMAIL_ACCOUNTANT_FAILED, err });
	}
}

export function* resendMassiveEmails(action) {
	const api = API.create(config.urlProvisioning);
	try {
		const { params } = action;
		let resendEmails = yield call(api.accountant.resendMassiveEmails, params);
		yield put({ type: types.SEND_MASSIVE_EMAIL_ACCOUNTANT_RECEIVED, data: resendEmails });
	} catch (err) {
		yield put({ type: types.SEND_MASSIVE_EMAIL_ACCOUNTANT_FAILED, err });
	}
}


export function* sendExcelObject(action) {
	const api = API.create(config.urlExcelApi);
	try {
		const { params } = action;
		let auth = yield call(api.accountant.authExcel, { username: 'admin', password: 'admin' });
		api.setHeader('authorization', auth.data.token);
		let excel = yield call(api.accountant.sendExcelObject, params);
		yield put({ type: types.SEND_EXCEL_OBJECT_RECEIVED, data: excel });
	} catch (err) {
		yield put({ type: types.SEND_EXCEL_OBJECT_FAILED, err });
	}
}
