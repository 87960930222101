import { put, call } from "redux-saga/effects";
import * as types from "../actions";
import API from "../services/api/indexSiigoCom";
import { StatusCodes } from "http-status-codes";

const api = API.create();

export function* postUpdateCache(data) {
  try {
    const response = yield call(
      api.siigoComService.postUpdateCache,
      data.params
    );

    if (response?.status === StatusCodes.CREATED) {
      yield put({
        type: types.POST_UPDATE_CACHE_RECEIVED,
        response: response.data,
      });
    } else {
      throw Object.assign(new Error("Error actualizando la cache"), {
        code: 503,
      });
    }
  } catch (error) {
    yield put({ type: types.POST_UPDATE_CACHE_FAILED, error: error });
  }
}
