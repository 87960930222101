import config from "../../../config";


function getEndPoint(name) {
  const endPoints = JSON.parse(localStorage.getItem('configInst'));
  for (let x = 0; x < endPoints.length; x++) {
    if (endPoints[x].name == name) {
      return endPoints[x].url;
    }
  }
}

export default function (api) {
  return {
    auth: () => api.get(getEndPoint('auth')),
    companies: params => api.get(`${config.url}bridge`, { params }),
    upgradeCompany: params => api.put(`${config.url}bridge/${params.companyKey}`, params),
    getTask: params => api.get(getEndPoint('getTask'), { params }),
    createCompany: params =>
      api.post(`${config.url}bridge`, params, {}),
    modifyCompany: params => api.patch(`${config.url}bridge/${params.companyKey}`, params),
    getCompanyLogs: body =>
      api.post(getEndPoint('getCompanyLogs'), body),
    clearCache: () => api.get(getEndPoint('clearCache')),
    getCloudLogs: params => api.get(getEndPoint('getCloudLogs'), { params }),
    processNSN: body => api.post(getEndPoint('processNSN'), body),
    getAddons: (params) => api.get(`${getEndPoint('getAddOns')}?cloudTenantId=${params}`),
    changeCompanyAddonState: body => api.post(getEndPoint('changeCompanyAddonState'), body),
    getCompanyUsers: params => api.get(`${config.url}bridge/${params}`),
    emailGetCompanies: params => api.get(`${config.url}bridge?email=${params}`),
    verifyNitEdition: params => api.get(`${config.url}bridge`, { params })
  };
}
