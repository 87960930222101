import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as CampaignActions from '../../actions/campaign/campaignActions';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import "./campaigns.scss";
import ModalResponse from '../layout/modals/modalResponse';
import { Modal, Button } from 'rsuite';

class CampaignComponent extends Component {
  
	constructor(props) {
		super(props);
		this.state = {
      uploadtedLocal : false,
      filePath: null,
      file: null,
      openModal : false,
      btnSaveInputTitle :'Cargar Nueva imagen',
      btnSaveTitle :'Guardar Imagen',
      loadingImage : false
		};
	}
  componentDidMount() {
    this.props.actions.getCampaignUrl();
  }
    getFile = () => {
        document.getElementById('upfile').click();
      };
      sub = e => {


      const fileContext = e.target.files[0];
      const maxSize = 5 * 1024 * 1024; 

        if (fileContext.size > maxSize) {
          ModalResponse({
            title: 'Error!',
            text: 'El tamaño de la imagen debe ser menor a 5MB'
         });
         return false;
        }
      
        if (!e.target.files[0].name.match(/\.(jpg|jpeg|png)$/)) {
          ModalResponse({
            title: 'Error!',
            text: 'Seleccione una imagen valida'
          });
          return false;
        }

        this.setState({filePath : URL.createObjectURL(e.target.files[0]),file: e.target.files[0], uploadtedLocal : true})
        var file = document
          .getElementById('upfile')
          .value.split('\\')
          .pop();
        if (file !== '') document.getElementById('btnUploadImage').innerHTML = file;

      };

        saveFile = event => {
          this.handleClose()
          event.preventDefault();
          this.setState({loadingImage: true})
          if (this.state.file) {
              let formData = new FormData();
              formData.append('file', this.state.file);
              this.props.actions.setCampaign(formData);
            } else {
              ModalResponse({
                title: 'Error!',
                text: 'No ha seleccionado la imagen',
                res: 404
              });
            }
          
        };
    handleOpen = () => this.setState({openModal : true});
    handleClose = () => this.setState({openModal : false});
    render() {
      let { error, imageurl , campaign, imageResponse} = this.props;
      let  imageHash =  Date.now();
      let imageurlCache = `${imageurl}?${imageHash}`  
      if (imageResponse && this.state.uploadtedLocal) {

        this.setState({uploadtedLocal : false, filePath: null, file : null, loadingImage: false})
        document.getElementById('btnUploadImage').innerHTML = this.state.btnSaveInputTitle;
        ModalResponse({
          title: 'Imagen Actualizada!',
          text: 'La imagen fue actualizada correctamente',
          res : 200
        });
        this.props.actions.clearCampaignState();
      }
      if (error) {
        this.setState({loadingImage: false})
        ModalResponse({
          title: 'Error!',
          text: 'Error al cargar la imagen, intente de nuevo',
          res: 400
        });
      }
        return (<>
           
        <Modal  open={this.state.openModal}  onClose={this.handleClose}>
        <Modal.Header>
          <Modal.Title>Confirmar imagen</Modal.Title>
        </Modal.Header>
              <Modal.Body>
                Al presionar 'Aceptar' enviará la imagen actual y sera actualizada en el login de Clientes <a href='https://siigonube.siigo.com/' target='_blank'>siigonube.siigo.com</a>
                Desea proceder ?
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.saveFile} appearance="primary">
                  Aceptar
                </Button>
                <Button onClick={this.handleClose} appearance="subtle">
                  Cancelar
                </Button>
              </Modal.Footer>
            </Modal>
          <div className='campaigngrid'>
            <div className='campaign-sidea' >
                <h3>Nueva Imagen de campaña:</h3>
                  <div
                    id="btnUploadImage"
                    className="divBtnAvatar"
                    onClick={this.getFile}
                  >
                    {this.state.btnSaveInputTitle}
                  </div>
                  <img src={this.state.filePath}></img>
                  <div>
                    <input
                      type="file"
                      className="btnAvatar"
                      id="upfile"
                      name="upfile"
                      onChange={this.sub} />
                  </div>
                  {this.state.uploadtedLocal && !this.state.loadingImage ? (
                  <button id="btnGuardar" onClick={this.saveFile}>
                    {this.state.btnSaveTitle}
                  </button>
                    ) : null}
                    {this.state.loadingImage ? (<h3>Cargando imagen ...</h3>): null}

            </div>  
            <div className='campaign-sideb'>
              <h3>Imagen de campaña actual:</h3>
              <p>Imagen de QA, Pre producción y Producción <a href='https://siigonube.siigo.com/' target='_blank'>siigonube.siigo.com</a></p>
              <img className='campaign-actual-image' src={imageurlCache}/>
            </div>
           
        </div>
        </>
          ); 
    }
}
CampaignComponent.propTypes = {
  actions: PropTypes.object.isRequired,
  error: PropTypes.object
};

const mapStateToProps = state => ({
  campaign: state.campaigns,
  isLoading: state.campaigns.isLoading,
  imageurl: state.campaigns.imageurl,
  image: state.campaigns.image,
  imageResponse: state.campaigns.imageResponse,
});


const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(CampaignActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(CampaignComponent);