import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../tools/loader';
import {
	getCompanies,
	getTask,
	setCompany,
} from '../../actions/market/marketAction';
import svgs from '../layout/icons';
import TableReport from '../tableReport';
import ModalResponse from '../layout/modals/modalResponse';
import Modal from './modalTarea/index';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';
import { NumEmployesByLicence } from './plandata';
import { getStateCompany, printState } from './state-company';
import CountryContext from '../../context/countries-context';
import { withTranslation } from 'react-i18next';
import config from '../../config';
import FormCompany from './formCompany';
import { COUNTRYS } from './utils/index';
import { uuidV4Generator } from "../../utils/randomizer";
import { StatusCodes } from 'http-status-codes';
import { useAuth } from '../../context/auth-context';

const columns = [
	{
		name: 'Nombre/ razón social',
		selector: 'CompanyFullName',
		sortable: true,
	},
	{
		name: 'Nit',
		selector: 'Nit',
		sortable: true,
	},
	{
		name: 'Serial',
		selector: 'Serial',
		sortable: true,
		filterDDL: true,
	},
	{
		name: 'Tipo',
		selector: 'PlanType',
		sortable: true,
		filterDDL: true,
	},
	{
		name: 'Estado',
		selector: 'Active',
		sortable: true,
		filterDDL: true,
	},
];

const Market = ({
	companyData,
	isLoadingCompany,
	task,
	isLoading,
	isLoadingTask,
	t,
	parentPath,
	companies,
	dispatch
}) => {
	const btnActions = {
		btnIngresar: '/company/',
		chMassiveActions: false,
	};
	const countryContext = useContext(CountryContext);
	const [showFormModal, setShowFormModal] = useState(false);
	const [companyInformation, setCompanyInformation] = useState({});
	const [show, setShow] = useState(false);
	const [previousTask, setPreviousTask] = useState({});
	const [errorMessageDisplayed, setErrorMessageDisplayed] = useState(false);
	const [marketTask, setMarketTask] = useState({});
	const [companyCreate, setCompanyCreate] = useState(false);
	const [gtask, setGtask] = useState(false);
	const [cCompany, setcCompany] = useState(false);
	const [informResponse, setInformResponse] = useState(true);
	const { checkAuth } = useAuth();
	
	useEffect(() => {
		if (localStorage.getItem('paramsCompany')) {
			let paramsCompany = JSON.parse(localStorage.getItem('paramsCompany'));

			if (
				localStorage.getItem('interCompany') &&
				localStorage.getItem('interCompany') !== 'false'
			) {
				setCompanyInformation(paramsCompany);
				setTimeout(
					function () {
						document.getElementById('btnSearchCompany').click();
					},
					100
				);

				localStorage.setItem('interCompany', false);
			}
		}
	}, [])

	useEffect(() => {
		if (companyData && companyCreate && !isLoadingCompany) {
			// Uncomment before Provisioning 2.0 publication.
			if (companyData?.data?.statusCode === StatusCodes.OK) {
				if (companyData?.data?.action === 'createCompany') {
					ModalResponse({
						title: 'Genial!',
						text: 'Empresa creada correctamente.',
						res: 200,
					});
				} else if (companyData?.data?.action === 'existCompany') {
					ModalResponse({
						title: 'Atención!',
						text:
							'La empresa ya cuenta con un aprovisionamiento basado en la tarea Nuevo Siigo Nube',
						res: 200,
					});
				} else {
					ModalResponse({
						title: 'Oops!',
						text: 'Ha ocurrido un error al crear la empresa.',
						res: 400,
					});
				}
				hideForm();
			} else {
				ModalResponse({
					title: 'Creación de empresa',
					text:
						'No fue posible crear la empresa, comuníquese con el administrador por favor.',
					res: 400,
				});

			}
			setCompanyCreate(false);
		}
	}, [companyData]);

	useEffect(() => {
		if (task && !isLoadingTask) {
			if (task?.status != null && task.status === StatusCodes.OK) {
				if (task?.data.length > 0) {
					setPreviousTask(mapTask(task?.data[0]));
					showForm();
				} else if (informResponse) {
					ModalResponse({
						title: 'Consultar tarea',
						text: 'No se encontro la tarea',
						res: 404,
					});
				}
				setInformResponse(false);
			} else {
				if (errorMessageDisplayed) {
					ModalResponse({
						title: 'Consultar tarea',
						text: 'Se presentó un problema con el servicio consultado, comuníquese con el administrador por favor.',
						res: 404,
					});
					setErrorMessageDisplayed(false);
				}
			}
		}
	}, [task, isLoadingTask])

	useEffect(() => {
		if (countryContext?.isCountryChanged()) {
			countryContext.setCountryChanged(false);
			dispatch(getCompanies({}));
		}
	}, [countryContext]);


	// TODO: Refactor this filters and validaions (Yup, etc) 💩
	const searchCompany = (e) => {
		e.preventDefault();
		if(!checkAuth()) return;
		let serial = e.target.serial.value;
		let companyName = e.target.companyFullName.value;
		let companyEmail = e.target.userEmail.value;
		let companyNit = e.target.nit.value;
		let workflowInstanceCode = e.target.workflowInstanceCode.value;
		let quoteNumber = e.target.quoteNumber.value;
		let cloudTenantCompanyKey = e.target.cloudTenantCompanyKey.value;

		const regex = /^[a-zA-Z0-9]*$/;
		if (cloudTenantCompanyKey !== '' && (cloudTenantCompanyKey.length < 2 || cloudTenantCompanyKey.length > 100 || !regex.test(cloudTenantCompanyKey))) {
			ModalResponse({
				title: t("market.search.title"),
				text: t("market.search.validationCompanyKey"),
				res: 404,
			});
			return;
		}

		if (
			(serial !== '' && serial.length < 5) ||
			(companyName !== '' && companyName.length < 5) ||
			(companyEmail !== '' && companyEmail.length < 5) ||
			(companyNit !== '' && companyNit.length < 5) ||
			(workflowInstanceCode !== '' && workflowInstanceCode.length < 1) ||
			(quoteNumber !== '' && quoteNumber.length < 1) ||
			(cloudTenantCompanyKey !== '' && cloudTenantCompanyKey.length < 2)
		) {
			ModalResponse({
				title: t("market.search.title"),
				text: t("market.search.validationCharacter"),
				res: 404,
			});
		} else if (
			serial === '' &&
			companyName === '' &&
			companyEmail === '' &&
			companyNit === '' &&
			workflowInstanceCode === '' &&
			quoteNumber === '' &&
			cloudTenantCompanyKey === ''
		) {
			ModalResponse({
				title: t("market.search.title"),
				text: t("market.search.validationEmpty"),
				res: 404,
			});
		} else {
			const params = {
				data: {
					serial: serial === '' ? undefined : serial,
					companyFullName: companyName === '' ? undefined : companyName,
					userEmail: companyEmail === '' ? undefined : companyEmail,
					nit: companyNit === '' ? undefined : companyNit,
					workflowInstanceCode: workflowInstanceCode === '' ? undefined : workflowInstanceCode,
					quoteNumber: quoteNumber === '' ? undefined : quoteNumber,
					cloudTenantCompanyKey: cloudTenantCompanyKey === '' ? undefined : cloudTenantCompanyKey,
				},
				meta: {
					country: countryContext?.country,
					requestId: uuidV4Generator(),
				}
			};

			dispatch(getCompanies(params));
		}
	};

	const getTasks = () => {
		if(!checkAuth()) return;
		let taskGet = document.getElementById('task').value.split('.').join('');
		const notAllowedCountries = [COUNTRYS.ECUADOR.CODE, COUNTRYS.PERU.CODE];
		const isCountryNotAllowed = notAllowedCountries.includes(countryContext.country.toLowerCase()) && taskGet === config.wifGenericCreateCompany;

		if ((isCountryNotAllowed) || (config.createCompanyWifGeneric?.toLowerCase() === "false" && taskGet === config.wifGenericCreateCompany)) {
			return ModalResponse({
				title: t("market.modal.response.title"),
				text: isCountryNotAllowed ? t("market.modal.response.text") : t("market.modal.response.tasknofound"),
				res: 404,
			});
		}
		setMarketTask(taskGet);
		const data = {
			task: taskGet,
			country: countryContext.country
		};
		setGtask(true);
		dispatch(getTask(data));
		setInformResponse(true);
	};

	const createCompany = (dataCompany, quotationLines) => {
		if(!checkAuth()) return;

		// Uncomment before Provisioning 2.0 publication.
		if (quotationLines.length > 0) {
			quotationLines.forEach(quote => {
				if (!quote.ItemAmount) quote.ItemAmount = 1
			});
		} else {
			ModalResponse({
				title: 'Crear empresas',
				text: 'No se encontraron productos en la cotización',
				res: 404,
			});
		}


		if ((quotationLines && quotationLines.length > 0) || dataCompany.license !== '-9') {
			const existQuoteId = task && task?.data?.length > 0 && task?.data[0]?.quoteId;
			const setQuoteId = existQuoteId ? task?.data[0]?.quoteId : marketTask;
			const company = {
				CompanyFullName: dataCompany.company,
				Email: dataCompany.emailCompany,
				Password: '',
				Mobile: dataCompany.phoneCompany,
				FullName: dataCompany.nameCompany,
				Nit: dataCompany.nit,
				CityName: countryContext.countries.marketModal.citysOptions[0].value,
				// CityName: 'Bogotá',
				WorkflowInstanceCode: marketTask,
				AgreementType: 0, // Uncomment before Provisioning 2.0 publication.
				serial: task?.data[0]?.serial,
				POSTerminal: dataCompany.pos ? dataCompany.pos : '0',
				Employees: dataCompany.employee,
				CloudLicense: String(dataCompany.license), // Uncomment before Provisioning 2.0 publication.
				Source: 'market',
				quoteId: setQuoteId,
				productCodeList: quotationLines[0].ItemCode ? quotationLines : [String(dataCompany.license)], // Uncomment before Provisioning 2.0 publication.
			};

			if (
				countryContext.country.toLowerCase() === COUNTRYS.MEXICO.CODE &&
				company.productCodeList.some((_product) => _product.ItemAmount > 1)
			)
				company.quantity = JSON.stringify(company.productCodeList.map(_product => ({
					code: _product.ItemCode,
					quantity: _product.ItemAmount
				})));

			const reqData = {
				company,
				meta: {
					country: countryContext.country,
					requestId: uuidV4Generator(),
				},
			};

			dispatch(setCompany(reqData));
			setCompanyCreate(true);
			setcCompany(true);
		}
	};

	const showModal = (e) => {
		setShow(!show);
		setShowFormModal(false);
	};

	const showForm = () => {
		if (gtask) {
			setShowFormModal(true);
			setGtask(false);
		}
	};

	const hideForm = () => {
		if (cCompany) {
			setShowFormModal(false);
			setcCompany(false);
		}
	};

	const focusInput = (e) => {
		e.preventDefault();
		e.currentTarget.placeholder = '';
		e.currentTarget.classList.add('inputFocus');
		e.currentTarget.previousSibling.classList.add('labelModal');
	};

	const blurInput = (e) => {
		e.preventDefault();
		if (e.currentTarget.value === '') {
			e.currentTarget.classList.add('inputError');
			e.currentTarget.previousSibling.classList.add('labelError');
		} else {
			e.currentTarget.classList.add('inputOk');
			e.currentTarget.previousSibling.classList.add('labelOk');
		}
	};

	const getData = (listData) => {
		let result = [];
		listData?.forEach(value => {
			let data = { ...value };
			data['Active'] = printState[getStateCompany(data.Active, data.State)];
			result.push(data);
		});
		return result;
	}

	const mapTask = (taskData) => {
		let newTask = {
			planType: taskData.productCodeList != null ? taskData.productCodeList : "",
			nit: taskData.Nit != null ? taskData.Nit : "",
			nameCompany: taskData.CompanyFullName != null ? taskData.CompanyFullName : "",
			emailCompany: taskData.Email != null ? taskData.Email : "",
			phoneCompany: taskData.Mobile != null ? taskData.Mobile : "",
			city: taskData.CityName != null ? taskData.CityName : "",
			pos: "0", //Parametro provisional mientras se valida la correcta informacion de la task
			identification: taskData.cuenta_contador_identificacion != null ? taskData.cuenta_contador_identificacion : "",
			nameCounter: taskData.cuenta_contador_nombres != null ? taskData.cuenta_contador_nombres : "",
			lastnameCounter: taskData.cuenta_contador_apellidos != null ? taskData.cuenta_contador_apellidos : "",
			emailCounter: taskData.cuenta_contador_email != null ? taskData.cuenta_contador_email : "",
			phoneCounter: taskData.cuenta_contador_contacto_telefono != null ? taskData.cuenta_contador_contacto_telefono : "",
			quotationLines: taskData.quoteProducts,
			showPlanTypeList: taskData.showPlanTypeList
		};
		newTask.license = newTask.planType != null ? newTask.planType.ItemCode : "";
		newTask.employee = NumEmployesByLicence[newTask.license] ? NumEmployesByLicence[newTask.license] : "1";
		if (taskData.CompanyFullName != null && taskData.CompanyFullName.length > 25) {
			const limitName = 25;
			const limitCharacters = taskData.CompanyFullName.length - limitName;
			const resultCompanyName = taskData.CompanyFullName.slice(0, -limitCharacters);
			newTask.company = resultCompanyName
		} else {
			newTask.company = taskData.CompanyFullName != null ? taskData.CompanyFullName : "";
		}
		return newTask;
	}

	// TODO: Refactor this form 💩 (Siigo DS, Formik, React Hook Form, etc)
	return (
		<div>
			{getAccess(parentPath, ACCESS.Create_Companies) ? (
				<a href="#" onClick={showModal} className="a-button green-fill">
					<img className="icon-img icon-mas" src={svgs['mas']} alt="" />
					{t("market.buttonCreateCompany")}

				</a>
			) : null}
			<div className="titleMarket">
				{t("market.head1")}
			</div>
			<div className="subtitleMarket">
				{t("market.headSmall")}
			</div>
			<form onSubmit={(event) => searchCompany(event)}>
				<div className="gridMarket">
					<input
						type="text"
						defaultValue={companyInformation.companyFullName}
						name="companyFullName"
						id="companyFullName"
						className="inputMarket"
						placeholder={t("market.form.placeHolderName")}
					/>
					<input
						type="text"
						defaultValue={companyInformation.userEmail}
						name="userEmail"
						id="userEmail"
						className="inputMarket"
						placeholder={t("market.form.placeHolderEmail")}
					/>
					<input
						type="text"
						defaultValue={companyInformation.nit}
						name="nit"
						id="nit"
						className="inputMarket"
						placeholder={t("market.form.placeHolderIdCompany")}
					/>
					<input
						type="text"
						defaultValue={companyInformation.serial}
						name="serial"
						id="serial"
						className="inputMarket"
						placeholder={t("market.form.placeHolderSerial")}
					/>
					<input
						type="text"
						defaultValue={companyInformation.workflowInstanceCode}
						name="workflowInstanceCode"
						id="workflowInstanceCode"
						className="inputMarket inputTask"
						placeholder={t("market.form.placeHolderTask")}
					/>
					<input
						type="text"
						defaultValue={companyInformation.quoteNumber}
						name="quoteNumber"
						id="quoteNumber"
						className="inputMarket inputTask"
						placeholder={t("market.form.placeHolderQuote")}
					/>
					<input
						type="text"
						defaultValue={companyInformation.cloudTenantCompanyKey}
						name="cloudTenantCompanyKey"
						id="cloudTenantCompanyKey"
						className="inputMarket inputTask"
						placeholder={t("market.form.placeHolderCompanyKey")}
					/>
				</div>
				<div className="center">
					<button id="btnSearchCompany" className="blue-fill btnbuscarm ">
						<span className="siicon icon-search searchMarket"></span>
						<span>{t("market.form.buttonSearch")}</span>
					</button>
				</div>
			</form>
			<Modal
				onClose={showModal}
				hideFrom={hideForm}
				show={show}
				showForm={showFormModal}
				title={t("market.modal.title")}
				onSubmit={getTasks}
			>
				{!showFormModal ? (
					<div className="input-container">
						<h3 className="h3Modal">{t("market.modal.task.title")}</h3>
						<label>{t("market.modal.task.input")}</label>
						<input
							type="text"
							placeholder={t("market.modal.task.input")}
							id="task"
							name="task"
							onFocus={(e) => focusInput(e)}
							onBlur={(e) => blurInput(e)}
						/>
					</div>
				) : null}

				{isLoadingTask ? <Loader /> : null}
				{showFormModal ? (
					<FormCompany
						country={countryContext}
						task={previousTask}
						isLoadingCompany={isLoadingCompany}
						onCancel={showModal}
						t={t}
						onSubmit={(_event) => createCompany(_event, previousTask.quotationLines)}
					/>
				) : <div className="actions">
					<button className="red" onClick={showModal}>
						Cancelar
					</button>
					<button className="green-fill ml20" onClick={getTasks}>
						Buscar
					</button>
				</div>}
			</Modal>

			{
				isLoading ? (
					<Loader />
				) : (
					<div>
						{companies ? (
							<div>
								<h3>Resultados</h3>
								<TableReport
									columns={columns} // Data columns to table
									tabs={false} //Enable tabs
									tabsData={[]} //Load data to tabs check option on tabsData const
									headerCounterTab={true} //Counter tab option
									btnActions={btnActions}
									actionButtons={true} //Enable buttons (edit, delete, copy, open)
									pageSize={10} // Enable page size
									pagination={true} //Enable pagination (true, false)
									paginationNav={'arrows'} //Nav options (arrow, dot)
									paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
									maxSizePagination={6} //Size pagination
									defaultPaginationNumber={1} //Current pagination when start app
									paginationGrouped={10} //Grouped by page
									detailRow={false} //Open column detail with left arrow
									data={getData(companies)} //Data for table
								/>
							</div>
						) : null}
					</div>
				)
			}
		</div >
	);
}

Market.propTypes = {
	companies:  PropTypes.array.isRequired,
	companyData: PropTypes.object,
	task: PropTypes.object,
};

const mapStateToProps = (state) => ({
	companyData: state.market.company,
	companies: state.market.companies,
	task: state.market.task,
	isLoading: state.market.isLoading,
	isLoadingTask: state.market.isLoadingTask,
	isLoadingCompany: state.market.isLoadingCompany,
});

export default connect(mapStateToProps)(withTranslation()(Market));