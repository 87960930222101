import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import config from '../config';
import API from '../services/api/indexGeneral';
import APIGeneral from '../services/api/indexGeneral';

export function* getContracts(action) {
  const api = API.create(config.urlMiddleware);
  try {
    api.setHeader('userdta', 'stark:iidkju===dk0');
    let auth = yield call(api.authJwt.auth);
    //showConsole('token ---->', auth);

    api.setHeader('token', auth.data);
    const { params } = action;
    let contracts = yield call(api.contracts.getInvoices, {
      types: JSON.stringify(params.types)
    });
    yield put({ type: types.INVOICES_RECEIVED, data: contracts });
  } catch (err) {
    yield put({ type: types.INVOICES_REQUEST_FAILED, err });
  }
}

export function* resendInvoiceEmail(action) {
  const api = API.create(config.urlMiddleware);
  try {
    api.setHeader('userdta', 'stark:iidkju===dk0');
    let auth = yield call(api.authJwt.auth);
    //showConsole('token ---->', auth);

    api.setHeader('token', auth.data);
    const { params } = action;
    let resendEmail = yield call(api.contracts.resendInvoiceEmail, params);
    if (resendEmail.status >= 400) {
      yield put({ type: types.RESEND_INVOICE_EMAIL_FAILED, error: resendEmail.status });
    } else {
      yield put({
        type: types.RESEND_INVOICE_EMAIL_RECEIVED,
        data: resendEmail.data,
      });
    }

  } catch (err) {
    yield put({ type: types.RESEND_INVOICE_EMAIL_FAILED, error: err });
  }
}


export function* sendExcelObject(action) {
  const api = APIGeneral.create(config.urlExcelApi);
  try {
    const { params } = action;
    let auth = yield call(api.accountant.authExcel, { username: 'admin', password: 'admin' });
    api.setHeader('authorization', auth.data.token);
    let excel = yield call(api.accountant.sendExcelObject, params);
    yield put({ type: types.SEND_EXCEL_OBJECT_RECEIVED, data: excel });
  } catch (err) {
    yield put({ type: types.SEND_EXCEL_OBJECT_FAILED, err });
  }
}