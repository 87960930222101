import axios from 'axios';
import Auth from './services/Auth';
import Plans from './services/Plans';
import Dependences from './services/Dependences';
import Components from './services/Components';
import Groups from './services/Groups';
import Customer from './services/Customers';
import AuroraConfigOriginsServices from './services/AuroraConfigOrigins';
import GeneralReport from './services/generalReport';
import User from './services/Users';
import Campaigns from './services/Campaign';
import Log from './services/Log';
import Countries from './services/Countries';
import config from '../../config.js';
import { getCookie } from '../../utils/cookies';
import ActivationManagement from './services/ActivationManagement';
import DataSource from './services/DataSource';

const create = (baseURL = config.url) => {
  const api = axios.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      Authorization: `bearer ${getCookie('token')}`
    },
    timeout: 120000
  });

  const auth = new Auth(api);
  const plans = new Plans(api);
  const customers = new Customer(api);
  const users = new User(api, getCookie('token'));
  const dependences = new Dependences(api);
  const components = new Components(api);
  const countries = new Countries(api);
  const groups = new Groups(api);
  const logs = new Log(api);
  const campaigns = new Campaigns(api);
  const activatedMenu = new ActivationManagement(api);
  const auroraConfigOrigins = new AuroraConfigOriginsServices(api);
  const generalReport = new GeneralReport(api);
  const dataSource = new DataSource(api);

  return {
    setAuthToken: (token) => { api.defaults.headers.common['Authorization'] = `Bearer ${token}`; },
    setAuth: (authValue) => { api.defaults.headers.common['auth'] = authValue; },
    removeAuthToken: () => { delete api.defaults.headers.common['Authorization']; },
    auth,
    plans,
    customers,
    users,
    dependences,
    components,
    groups,
    countries,
    logs,
    campaigns,
    auroraConfigOrigins,
    generalReport,
    dataSource,
    activatedMenu
  };
};

// Export the create function as the default
export default {
  create
};
