import React, { Component } from "react";
import TableReport from "../tableReport";
import PropTypes from "prop-types";
import * as auroraTokensActions from "../../actions/auroraTokens/auroraTokensActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../tools/loader";
import ModalResponse from "../layout/modals/modalResponse";
import Modal from "../layout/modals/detailSubscription/index";
import "./style.scss";
import SearchFilter from "./searchFilter";
import { getCookie } from "../../utils/cookies";
import config from "../../config";
import { TokensStates } from "./states";
import { AxiosUpdateSubscription } from "../auroraSubscription/serviceUpdate";
import { StatusCodes } from "http-status-codes";

const columns = [
  {
    name: "Fecha de Creación",
    selector: "date",
    sortable: true,
  },
  {
    name: "Identificación",
    selector: "payerDocument",
    sortable: true,
    filterDDL: true,
  },
  {
    name: "Correo",
    selector: "payerEmail",
    sortable: true,
  },
  {
    name: "Nombre",
    selector: "payerName",
    sortable: true,
  },
  {
    name: "Pais",
    selector: "payerCountry",
    sortable: true,
  },
  {
    name: "Metodo de pago",
    selector: "paymentMethodSelected",
    sortable: true,
  },
  {
    name: "Company ID",
    selector: "companyID",
    sortable: true,
  },
  {
    name: "Estado",
    selector: "isActive",
    sortable: true,
    subState: true,
  },
];

class AuroraTokens extends Component {
  constructor(props) {
    super(props);
    this.setFilterValue = this.setFilterValue.bind(this);
    this.filtrarTokens = this.filtrarTokens.bind(this);
    this.cleanFilter = this.cleanFilter.bind(this);
    this.queryCurrentDayTokens = this.queryCurrentDayTokens.bind(this);
    this.getTokensParams = this.getTokensParams.bind(this);

    this.state = {
      token: {},
      fromDate: undefined,
      toDate: undefined,
      datos: [],
      isLoading: false,
      filter: undefined,
    };
  }

  showModalDetail = (e) => {
    this.setState({ showDetail: !this.state.showDetail });
  };

  componentDidMount() {
    document.title = "Gestor de Tokens";
  }

  queryCurrentDayTokens = () => {
    let currentDateFrom = new Date();
    currentDateFrom.setHours(0);
    currentDateFrom.setMinutes(0);
    currentDateFrom.setSeconds(0);
    currentDateFrom.setMilliseconds(0);

    let currentDateTo = new Date();
    currentDateTo.setHours(23);
    currentDateTo.setMinutes(59);
    currentDateTo.setSeconds(59);
    currentDateTo.setMilliseconds(999);

    this.getTokensParams(currentDateFrom, currentDateTo, null);
  };

  getTokensParams(from, to, filter) {
    if (this.validItem(from) && this.validItem(to)) {
      let query = {
        data: {
          date: { $gt: from.toISOString(), $lt: to.toISOString() },
          isRecurrentPay: 0,
        },
        sort: { date: -1 },
      };
      if (this.validItem(filter)) {
        query.data["$or"] = this.getORquery(filter);
      }
      this.filtrarTokens();
    }
  }

  validItem(value) {
    return value !== "" && value !== null && value !== undefined;
  }

  getORquery(filter) {
    return [
      { companyID: { $regex: ".*" + filter + ".*", $options: "i" } },
      { payerName: { $regex: ".*" + filter + ".*", $options: "i" } },
      { payerDocument: { $regex: ".*" + filter + ".*", $options: "i" } },
      { payerEmail: { $regex: ".*" + filter + ".*", $options: "i" } },
      {
        paymentMethodSelected: { $regex: ".*" + filter + ".*", $options: "i" },
      },
    ];
  }

  async requestQueryTokenTC(query){
    await AxiosUpdateSubscription.getSuscription(query).then(
      resp => {
      this.setState({isLoading: false})
      if(resp.status == StatusCodes.CREATED && resp.data.subscriptionList){
        this.setState({datos: resp.data.subscriptionList, pagination: true})
      }
      else{
        ModalResponse({
          title: "¡Atención!",
          text:"Ocurrio un error al consultar los token TC, intenta de nuevo por favor",
          res: resp.status,
        });
      }
    }
    ).catch(error => {
      this.setState({isLoading: false})
      ModalResponse({
        title: "Ocurrió un error",
        text: "Por favor contacta a soporte técnico",
        res: 400
      });
    });
  }

  filtrarTokens = async (e = null) => { 
    if (e !== null) {
      e.preventDefault();
    }

    let query = {
      data: { isRecurrentPay: 0 },
      sort: { date: -1 },
    };

    if (
      this.validItem(this.state.filter) ||
      (this.validItem(this.state.toDate) && this.validItem(this.state.fromDate))
    ) {
      this.setState({isLoading: true})
      if (this.validItem(this.state.filter)) {
        query.data["$or"] = this.getORquery(this.state.filter);
      }
      if (this.validItem(this.state.fromDate)) {
        query.data.date = { ...query.data.date, $gt: this.state.fromDate };
      }
      if (this.validItem(this.state.toDate)) {
        query.data.date = { ...query.data.date, $lt: this.state.toDate };
      }

      await this.requestQueryTokenTC(query);

    } else {
      ModalResponse({
        title: "¡Hey!",
        text: "Debe ingresar al menos un filtro",
        res: 404,
      });
    }
  };

  cleanFilter = (e) => {
    e.preventDefault();
    this.setState({
      fromDate: undefined,
      toDate: undefined,
      filter: undefined,
      datos: [],
      pagination: false
    });
  };

  showDetail = (data) => {
    this.setState({ token: data, showDetail: true, openDetail: true });
  };

  handleDate = (data) => {
    let fromDate = undefined;
    let toDate = undefined;
    if (data.from !== undefined) {
      fromDate = new Date(data.from);
      fromDate.setHours(0);
      fromDate.setMinutes(0);
      fromDate.setSeconds(0);
      fromDate.setMilliseconds(0);
    }
    if (data.to !== undefined) {
      toDate = new Date(data.to);
      toDate.setHours(23);
      toDate.setMinutes(59);
      toDate.setSeconds(59);
      toDate.setMilliseconds(999);
    }
    this.setState({ fromDate, toDate }, function () {
      this.getTokensParams(
        this.state.fromDate,
        this.state.toDate,
        this.state.filter
      );
    });
    return { from: fromDate, to: toDate };
  };

  focusInput = (e) => {
    e.preventDefault();
    e.currentTarget.placeholder = "";
    e.currentTarget.classList.remove("inputError");
    e.currentTarget.classList.remove("inputOk");
    e.currentTarget.classList.add("inputFocus");
    if (e.currentTarget.previousSibling) {
      e.currentTarget.previousSibling.classList.remove("inputError");
      e.currentTarget.previousSibling.classList.remove("inputOk");
      e.currentTarget.previousSibling.classList.add("labelModal");
    }
  };

  blurInput = (e) => {
    e.preventDefault();
    if (e.currentTarget.value === "") {
      e.currentTarget.classList.remove("inputOk");
      e.currentTarget.classList.add("inputError");
      if (e.currentTarget.previousSibling) {
        e.currentTarget.previousSibling.classList.remove("labelOk");
        e.currentTarget.previousSibling.classList.add("labelError");
      }
    } else {
      e.currentTarget.classList.remove("inputError");
      e.currentTarget.classList.add("inputOk");
      if (e.currentTarget.previousSibling) {
        e.currentTarget.previousSibling.classList.remove("labelError");
        e.currentTarget.previousSibling.classList.add("labelOk");
      }
    }
  };

  renderModalDataBasic = (data) => {
    return (
      <div className="uxDetailContainer">
        <div>
          <b>
            <span className="labelUxDetail" style={{ margin: "15px" }}>
              Nombre del Cliente:{" "}
            </span>
          </b>{" "}
          <span id="uxDetailProfile">
            {data.payerName ? data.payerName : "N/A"}
          </span>
        </div>
        <div>
          <b>
            <span className="labelUxDetail" style={{ margin: "15px" }}>
              Identificación:{" "}
            </span>
          </b>{" "}
          <span id="uxDetailId">
            {data.payerDocument ? data.payerDocument : "N/A"}
          </span>
        </div>
        <div>
          <b>
            <span className="labelUxDetail" style={{ margin: "15px" }}>
              Correo Electrónico:{" "}
            </span>
          </b>{" "}
          <span id="uxDetailName">
            {data.payerEmail ? data.payerEmail : "N/A"}
          </span>
        </div>
        <div>
          <b>
            <span className="labelUxDetail" style={{ margin: "15px" }}>
              País:{" "}
            </span>
          </b>{" "}
          <span id="uxDetailCountry">
            {data.payerCountry ? data.payerCountry : "N/A"}
          </span>
        </div>
        <div>
          <b>
            <span className="labelUxDetail" style={{ margin: "15px" }}>
              Número de Tarjeta:{" "}
            </span>
          </b>{" "}
          <span id="uxDetailCelphone">
            {data.ccNumber ? data.ccNumber : "N/A"}
          </span>
        </div>
        <div>
          <b>
            <span className="labelUxDetail" style={{ margin: "15px" }}>
              NIT:{" "}
            </span>
          </b>{" "}
          <span id="uxDetailCelphone">
            {data.companyID ? data.companyID : "N/A"}
          </span>
        </div>
      </div>
    );
  };

  renderModalDataToken = (data) => {
    return (
      <div className="uxDetailContainer">
        <div>
          <b>
            <span class="labelUxDetail" style={{ margin: "15px" }}>
              Token ID:{" "}
            </span>
          </b>{" "}
          <span id="uxDetailProduct">
            {data.suscriptionID
              ? data.suscriptionID
              : data.subscriptionID
              ? data.subscriptionID
              : "N/A"}
          </span>
        </div>
        <div>
          <b>
            <span class="labelUxDetail" style={{ margin: "15px" }}>
              Fecha de Creación de Token:{" "}
            </span>
          </b>{" "}
          <span id="uxDetailSerial">{data.date ? data.date : "N/A"}</span>
        </div>
        <div>
          <b>
            <span class="labelUxDetail" style={{ margin: "15px" }}>
              Metodo de Pago:{" "}
            </span>
          </b>{" "}
          <span id="uxDetailRefer">
            {data.paymentMethodSelected ? data.paymentMethodSelected : "N/A"}
          </span>
        </div>
        <div>
          <b>
            <span class="labelUxDetail" style={{ margin: "15px" }}>
              Es pago Recurrente:{" "}
            </span>
          </b>{" "}
          <TokensStates
            subState={true}
            selector={"isRecurrentPay"}
            valueSelector={data.isRecurrentPay}
          />
        </div>
        <div>
          <b>
            <span class="labelUxDetail" style={{ margin: "15px" }}>
              Estado de Suscripción:{" "}
            </span>{" "}
          </b>{" "}
          <TokensStates
            subState={true}
            selector={"isActive"}
            valueSelector={data.isActive}
          />
        </div>
      </div>
    );
  };

  handleChangeInput(e) {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  setFilterValue(filter) {
    this.setState({ filter: filter });
  }

  render() {

    let { datos, isLoading, pagination } = this.state;

    const btnActions = {
      btnShowDetail: this.showDetail.bind(this),
    };

    return (
      <div>
        <div>
          <h3>Gestor de tokens</h3>
          <SearchFilter
            handleDate={this.handleDate}
            from={this.state.fromDate}
            to={this.state.toDate}
            filter={this.state.filter}
            datos={datos}
            focusInput={this.focusInput}
            blurInput={this.blurInput}
            filtrarTokens={this.filtrarTokens}
            setFilterValue={this.setFilterValue}
            cleanFilter={this.cleanFilter}
          />
        </div>
        {isLoading ? (
          <div style={{ padding: "10em" }}>
            <Loader />
          </div>
        ) : (
          <div>
            <div className="divTable coustomTab">
              <TableReport
                columns={columns} // Data columns to table
                tabs={false} //Enable tabs
                tabsData={[]} //Load data to tabs check option on tabsData const
                headerCounterTab={true} //Counter tab option
                btnActions={btnActions}
                actionButtons={true} //Enable buttons (edit, delete, copy, open)
                pageSize={50} // Enable page size
                pagination={pagination} //Enable pagination (true, false)
                paginationNav={"arrows"} //Nav options (arrow, dot)
                paginationPosition={"bottom"} //Pagination position (Bottom, top, both)
                maxSizePagination={6} //Size pagination
                defaultPaginationNumber={1} //Current pagination when start app
                paginationGrouped={10} //Grouped by page
                detailRow={false} //Open column detail with left arrow
                data={datos} //Data for table
              />
            </div>
            <Modal
              onClose={this.showModalDetail}
              show={this.state.showDetail}
              title="Detalles de tokenización"
              open={this.state.openDetail}
            >
              <div class="uxDetailData">
                <div className="uxDetailDataFilterContainer">
                  <div className="uxDetailDataFilter">
                    <div className="uxDetailDataFilterTitle">
                      <h3>Datos del Cliente</h3>
                    </div>
                    {this.renderModalDataBasic(this.state.token)}
                  </div>

                  <div class="uxDetailDataFilter">
                    <div class="uxDetailDataFilterTitle">
                      <h3>Datos de token</h3>
                    </div>
                    {this.renderModalDataToken(this.state.token)}
                  </div>
                </div>
              </div>
            </Modal>

          </div>
        )}
      </div>
    );
  }
}

AuroraTokens.propTypes = {
  actions: PropTypes.object.isRequired,
  tokens: PropTypes.object,
  tokensError: PropTypes.object,
};

const mapStateToProps = (state) => ({
  tokens: state.auroraTokens.tokens,
  tokensError: state.auroraTokens.tokensError,
  isLoading: state.auroraTokens.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(auroraTokensActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuroraTokens);
