import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as recurrentPaymentReportActions from '../../actions/recurrentPaymentReport/recurrentPaymentReportActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalResponse from '../layout/modals/modalResponse';
import DatePicker from '../tools/datePicker';
import Modal from '../layout/modals/detailSubscription/index';
import './style.scss';
import { PaymentStates, paymentStates } from './states';
import { normalize } from '../tools/format';
import { getCookie } from '../../utils/cookies';
import { ACCESS } from './access';
import { getAccess } from './../../utils/access';
import { StatusCodes } from 'http-status-codes';

const columns = [
    {
        name: 'Fecha Creación Pago',
        selector: 'date',
        sortable: true
    },
    {
        name: 'Fecha Suscripcion',
        selector: 'data.createdSubscription',
        sortable: true,
    },
    {
        name: 'Nombre Cliente',
        selector: 'data.payerName',
        sortable: true
    },
    {
        name: 'Correo Cliente',
        selector: 'data.payerEmail',
        sortable: true
    },
    {
        name: 'Identificación',
        selector: 'data.payerDocument',
        sortable: true,
        filterDDL: true
    },
    {
        name: 'Valor Pagado',
        selector: 'data.value',
        sortable: true
    },
    {
        name: 'ID de Registro Aurora',
        selector: '_id',
        sortable: true,
    },
    {
        name: 'ID de Transacción',
        selector: 'data.lastTransactionId',
        sortable: true,
    },
    {
        name: 'Estado Transacción',
        selector: 'data.auroraOrderStatus',
        sortable: true,
        paymentState: true,
    },
    {
        name: 'ID Transacción Primer Pago',
        selector: 'data.originalTransactionId',
        sortable: true,
    }
];

let downloadEnable = false;
let access = true;
let downloading = false;
class RecurrentPaymentReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: undefined,
            toDate: undefined,
            download: false,
            payment: {
                data: {}
            }

        };

    }

    showModalDetail = (e) => {
        this.setState({ showDetail: !this.state.showDetail });
    };

    componentDidMount() {
        document.title = "Reporte Pagos Recurrentes";
        access = getAccess(this.props.parentPath, ACCESS.download_excel);
        this.queryCurrentDayPayments();
    }

    queryCurrentDayPayments = () => {

        let currentDateFrom = new Date();
        currentDateFrom.setHours(0);
        currentDateFrom.setMinutes(0);
        currentDateFrom.setSeconds(0);
        currentDateFrom.setMilliseconds(0);

        let currentDateTo = new Date();
        currentDateTo.setHours(23);
        currentDateTo.setMinutes(59);
        currentDateTo.setSeconds(59);
        currentDateTo.setMilliseconds(999);

        const query = {
            data: {
                "data.isRecurrentPay": { "$exists": true, "$eq": "true" },
                date: { "$gt": currentDateFrom.toISOString(), "$lt": currentDateTo.toISOString() }
            },
            sort: { "date": -1 }
        };
        this.props.actions.getRecurrentPayments(query);
    }


    onHandleDownload = (datos) => {

        if (datos.length > 0) {
            downloadEnable = true;
            downloading = true;
            
            let extCols = [
                {
                    name: 'ID de Orden',
                    selector: 'data.orderId',
                },
                {
                    name: ' N° Cotizacion (ID Producto)',
                    selector: 'data.productId',
                },
                {
                    name: 'Fecha del Primer Pago',
                    selector: 'data.startDate',
                },
                {
                    name: 'Fecha del ultimo Cobro',
                    selector: 'data.lastChargeDate',
                },
                {
                    name: 'ID Aurora Primer Cobro',
                    selector: 'data.originalReferenceCode',
                }
            ]
            let copyColumns = [...columns, ...extCols];

            let columnsReq = copyColumns.map(function (column) {
                let columnAux = [];
                columnAux.title = column.name;
                columnAux.id = column.selector;
                return JSON.stringify(Object.assign({}, columnAux));
            }).join();

            let datosString = datos.map(function (dataItem) {

                var strReplace = (value, replace) => {
                    var r = (value === undefined || value === '' || value === null) ? replace : value;
                    return r;
                }

                let data = {};
                data = {
                    "_id": strReplace(dataItem._id, "N/A"),
                    "date": strReplace(dataItem.date, "N/A"),
                    "data.payerName": strReplace(dataItem.data.payerName, "N/A"),
                    "data.payerEmail": strReplace(dataItem.data.payerEmail, "N/A"),
                    "data.payerDocument": strReplace(dataItem.data.payerDocument, "N/A"),
                    "data.value": strReplace(dataItem.data.value, "N/A"),
                    "data.lastTransactionId": strReplace(dataItem.data.lastTransactionId, "N/A"),
                    "data.auroraOrderStatus": strReplace(paymentStates[dataItem.data.auroraOrderStatus], "N/A"),
                    "data.originalReferenceCode": strReplace(dataItem.data.originalReferenceCode, "N/A"),
                    "data.originalTransactionId": strReplace(dataItem.data.originalTransactionId, "N/A"),
                    "data.orderId": strReplace(dataItem.data.orderId, "N/A"),
                    "data.productId": strReplace(dataItem.data.productId, "N/A"),
                    "data.createdSubscription": strReplace(dataItem.data.createdSubscription, "N/A"),
                    "data.startDate": strReplace(dataItem.data.startDate, "N/A"),
                    "data.lastChargeDate": strReplace(dataItem.data.lastChargeDate, "N/A"),
                }


                return JSON.stringify(Object.assign({}, data));
            }).join();

            let reqExcel = {
                "columns": JSON.parse(`[${columnsReq}]`),
                "data": JSON.parse(`[${datosString}]`),
                "typeFile": "csv"
            };

            this.props.actions.sendExcelObject(reqExcel);

        } else {
            ModalResponse({
                title: 'Descargar excel',
                text: 'No hay datos para descargar',
                res: 404,
            });
        }
    };

    downloadExcel = (data) => {

        var blob = new Blob([atob(data)], { encoding: "UTF-8", type: 'text/plain;charset=UTF-8' });
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveOrOpenBlob(blob, 'exportData_RecurrentPayment' + new Date().toDateString() + '.csv');
        }
        else {
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(blob, { type: "text/plain;charset=UTF-8" });
            a.download = "exportData_RecurrentPayment" + new Date().toDateString() + ".csv";
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }


    filtrarPayments = e => {
        e.preventDefault();

        const payerDoc = e.target.payerDoc.value;
        //const email = e.target.email.value;
        // const payerName = e.target.payerName.value;
        const id = e.target.id.value;
        const inputDateFromValue = document.getElementsByClassName('inputDatePicker')[0].value;
        const inputDateToValue = document.getElementsByClassName('inputDatePicker')[1].value;
        const transactionId = e.target.transactionId.value;
        const originalTransactionId = e.target.originalTransactionId.value;
        const state = e.target.paymentState.value;

        let query = {
            data: {
                "data.isRecurrentPay": { "$exists": true, "$eq": "true" },
            },
            sort: { "date": -1 }
        }

        if (originalTransactionId != "" || state != "" || payerDoc != '' || id != '' || transactionId != "" || (inputDateFromValue != '' && inputDateToValue != '')) {
            if (payerDoc !== '' && payerDoc !== undefined && payerDoc !== null) {
                query.data['data.payerDocument'] = { "$regex": ".*" + payerDoc + ".*", "$options": "i" };
            }
            if (state !== '' && state !== undefined && state !== null) {
                query.data['data.auroraOrderStatus'] = state;
            }
            if (id !== '' && id !== undefined && id !== null) {
                query.objectID = id;
            }
            // if (email !== '' && email !== undefined && email !== null) {
            //     query.data["data.payerEmail"] = { "$regex": ".*" + email + ".*", "$options": "i" };
            // }
            if (transactionId !== '' && transactionId !== undefined && transactionId !== null) {
                query.data["data.lastTransactionId"] = { "$regex": ".*" + transactionId + ".*", "$options": "i" };
            }
            if (originalTransactionId !== '' && originalTransactionId !== undefined && originalTransactionId !== null) {
                query.data["data.originalTransactionId"] = { "$regex": ".*" + originalTransactionId + ".*", "$options": "i" };
            }
            // if (payerName !== '' && payerName !== undefined && payerName !== null) {
            //     query.data["data.payerName"] = { "$regex": ".*" + payerName + ".*", "$options": "i" };
            // }
            if (inputDateFromValue !== '' && inputDateFromValue !== undefined && inputDateFromValue !== null) {
                query.data.date = { ...query.data.date, $gt: this.state.fromDate };
            }
            if (inputDateToValue !== '' && inputDateToValue !== undefined && inputDateToValue !== null) {
                query.data.date = { ...query.data.date, $lt: this.state.toDate };
            }
            this.props.actions.getRecurrentPayments(query);
        } else {
            ModalResponse({
                title: '¡Hey!',
                text: 'Debe ingresar al menos un filtro',
                res: 404
            });
        }
    };

    cleanFilter = e => {
        e.preventDefault();
        var payerDoc = document.getElementById('payerDoc');
        payerDoc.value = '';
        payerDoc.classList.remove("inputError", "inputOk", "inputFocus");

        var id = document.getElementById('id');
        id.value = '';
        id.classList.remove("inputError", "inputOk", "inputFocus");

        var transactionId = document.getElementById('transactionId');
        transactionId.value = '';
        transactionId.classList.remove("inputError", "inputOk", "inputFocus");

        var paymentState = document.getElementById('paymentState');
        paymentState.value = '';
        paymentState.classList.remove("inputError", "inputOk", "inputFocus");

        var originalTransactionId = document.getElementById('originalTransactionId');
        originalTransactionId.value = '';
        originalTransactionId.classList.remove("inputError", "inputOk", "inputFocus");

        this.setState({ fromDate: undefined, toDate: undefined });
        this.queryCurrentDayPayments();
    };

    showDetail = data => {
        this.setState({ payment: data, showDetail: true, openDetail: true });
    };


    handleDate = (data) => {
        let fromDate = undefined;
        let toDate = undefined;
        if (data.from != undefined) {
            fromDate = new Date(data.from);
            fromDate.setHours(0);
            fromDate.setMinutes(0);
            fromDate.setSeconds(0);
            fromDate.setMilliseconds(0);
        }
        if (data.to != undefined) {
            toDate = new Date(data.to);
            toDate.setHours(23);
            toDate.setMinutes(59);
            toDate.setSeconds(59);
            toDate.setMilliseconds(999);
        }
        this.setState({ fromDate });
        this.setState({ toDate });
        return { from: fromDate, to: toDate };
    }



    focusInput = (e) => {
        e.preventDefault();
        e.currentTarget.placeholder = '';
        e.currentTarget.classList.remove("inputError");
        e.currentTarget.classList.remove("inputOk");
        e.currentTarget.classList.add('inputFocus');
        if (e.currentTarget.previousSibling) {
            e.currentTarget.previousSibling.classList.remove('inputError');
            e.currentTarget.previousSibling.classList.remove('inputOk');
            e.currentTarget.previousSibling.classList.add('labelModal');
        }
    };

    blurInput = (e) => {
        e.preventDefault();

        if (e.currentTarget.value == '') {
            e.currentTarget.classList.remove("inputOk");
            e.currentTarget.classList.add('inputError');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelOk');
                e.currentTarget.previousSibling.classList.add('labelError');
            }
        } else {
            e.currentTarget.classList.remove("inputError");
            e.currentTarget.classList.add('inputOk');
            if (e.currentTarget.previousSibling) {
                e.currentTarget.previousSibling.classList.remove('labelError');
                e.currentTarget.previousSibling.classList.add('labelOk');
            }
        }
    };



    renderModalDataBasic = (data) => {
        var blob = data.data;
        return (
            <div className="uxDetailContainer">
                <div>
                    <b><span className="labelUxDetail" style={{ margin: "15px" }}>Nombre del Cliente:	</span></b> <span id="uxDetailProfile">{blob.payerName ? blob.payerName : "N/A"}</span>
                </div>
                <div>
                    <b><span className="labelUxDetail" style={{ margin: "15px" }}>Identificación:	</span></b> <span id="uxDetailId">{blob.payerDocument ? blob.payerDocument : "N/A"}</span>
                </div>
                <div>
                    <b><span className="labelUxDetail" style={{ margin: "15px" }}>Correo Electrónico:	</span></b> <span id="uxDetailName">{blob.payerEmail ? blob.payerEmail : "N/A"}</span>
                </div>
                <div>
                    <b><span className="labelUxDetail" style={{ margin: "15px" }}>Telefono:	</span></b> <span id="uxDetailLastName">{blob.payerPhone ? blob.payerPhone : "N/A"}</span>
                </div>

                <div>
                    <b><span className="labelUxDetail" style={{ margin: "15px" }}>Ciudad:	</span></b> <span id="uxDetailCity">{blob.payerCity ? blob.payerCity : "N/A"}</span>
                </div>
                <div>
                    <b><span className="labelUxDetail" style={{ margin: "15px" }}>Dirección:	</span></b> <span id="uxDetailDepartment">{blob.payerAddress ? blob.payerAddress : "N/A"}</span>
                </div>
                <div>
                    <b><span className="labelUxDetail" style={{ margin: "15px" }}>País:	</span></b> <span id="uxDetailCountry">{blob.payerCountry ? blob.payerCountry : "N/A"}</span>
                </div>
                <div>
                    <b><span className="labelUxDetail" style={{ margin: "15px" }}>Número de Tarjeta:	</span></b> <span id="uxDetailCelphone">{blob.ccNumber ? blob.ccNumber : "N/A"}</span>
                </div>
            </div>
        );
    };

    renderModalDataPayment = (data) => {
        var blob = data.data;
        return (
            <div className="uxDetailContainer">
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>ID de Registro Aurora:	</span></b> <span id="uxDetailProduct">{data._id ? data._id : "N/A"}</span>
                </div>
                <div>
                    <b><span className="labelUxDetail" style={{ margin: "15px" }}>Fecha de Registro Aurora:	</span></b> <span id="uxDetailCelphone">{data.date ? data.date : "N/A"}</span>
                </div>
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>ID Transacción:	</span></b> <span id="uxDetailProduct">{blob.lastTransactionId ? blob.lastTransactionId : "N/A"}</span>
                </div>
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>ID Aurora Primer Pago:	</span></b> <span id="uxDetailProduct">{blob.originalReferenceCode ? blob.originalReferenceCode : "Es Primer Pago"}</span>
                </div>
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>ID Transacción Primer Pago:	</span></b> <span id="uxDetailProduct">{blob.originalTransactionId ? blob.originalTransactionId : "Es Primer Pago"}</span>
                </div>
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>Fecha de creación:	</span></b> <span id="uxDetailSerial">{blob.creationDate ? blob.creationDate : "N/A"}</span>
                </div>
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>ID de Orden:	</span></b> <span id="uxDetailRefer">{blob.orderId ? blob.orderId : "N/A"}</span>
                </div>
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>Estado Transacción:	</span> </b> <PaymentStates selector={"data.auroraOrderStatus"} paymentState={true} valueSelector={blob.auroraOrderStatus} />
                </div>
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}> N° Cotización (ID Producto):	</span></b> <span id="uxDetailSource">{blob.productId ? blob.productId : "N/A"}</span>
                </div>
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>Valor Pagado:	</span> </b><span id="uxDetailAlliesId">{blob.value ? blob.value : "N/A"}</span>
                </div>
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>Medio de Pago:	</span> </b><span id="uxDetailSeller">{blob.paymentMethod ? blob.paymentMethod : "N/A"}</span>
                </div>
            </div>
        );
    };

    renderModalDataPlataform(data) {
        var blob = data.data;
        return (
            <div className="uxDetailContainer">
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>Kushki Token:	</span></b> <span id="uxDetailSeller">{blob.kushkiToken ? blob.kushkiToken : "N/A"}</span>
                </div>
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>Kushki ID:	</span></b> <span id="uxDetailSeller">{blob.KushkiPaymentId ? blob.KushkiPaymentId : "N/A"}</span>
                </div>
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>Creación de Suscripción:	</span> </b> <span id="uxDetailSeller">{blob.createdSubscription ? blob.createdSubscription : "N/A"}</span>
                </div>
                <div>
                    <b><span class="labelUxDetail" style={{ margin: "15px" }}>Fecha del Primer Pago:	</span> </b> <span id="uxDetailSeller">{blob.startDate ? blob.startDate : "N/A"}</span>
                </div>
                <div>
                    <b>	<span class="labelUxDetail" style={{ margin: "15px" }}>Fecha del último Pago:	</span> </b> <span id="uxDetailSeller">{blob.lastChargeDate ? blob.lastChargeDate : "N/A"}</span>
                </div>
            </div>
        );
    }



    render() {
        let { error, isLoading, payments, excelData } = this.props;

        const btnActions = {

            btnShowDetail: this.showDetail.bind(this),
        }

        let datos = [];

        if (excelData) {
            if (excelData.status === StatusCodes.OK) {
                if (downloadEnable) {
                    this.downloadExcel(excelData.data);
                    this.setState({download: false});
                    downloadEnable = false;
                    downloading = false;
                }
             }
            else{
                if(downloading){
                    this.setState({download: false});
                    ModalResponse({
                        title: 'Error al Descarga Excel!',
                        text: 'La descarga del Excel ha fallado. Comuníquece con el Administrador.',
                        res: 404
                    });
                }
                downloading = false;
                downloadEnable = false;
            }
            downloading = false;
            downloadEnable = false;
        }

        if (payments) {

            if (payments.status == 200) {
                datos = payments.data;
                datos.map(function (payment) {
                    payment.data.originalReferenceCode = payment.data.originalReferenceCode === undefined ? "N/A" : payment.data.originalReferenceCode;
                    return payment;
                });

            }
        }


        return (
            <div>
                <div>
                    <h3>Reporte Pagos Recurrentes</h3>
                    <form onSubmit={this.filtrarPayments}>
                        <div className="row divFilter">
                            <DatePicker handleDate={this.handleDate} from={this.state.fromDate} to={this.state.toDate} />
                            <div className="column4">
                                <select className="slFilter" id="paymentState" name="paymentState">
                                    <option value="">Seleccione el estado</option>
                                    <option className="successState" value="RESPONSE_RECEIVED_APPROVED">{paymentStates["RESPONSE_RECEIVED_APPROVED"]}</option>
                                    <option className="warningState" value="RESPONSE_RECEIVED_DECLINED">{paymentStates['RESPONSE_RECEIVED_DECLINED']}</option>
                                    <option className="errorState" value="RESPONSE_RECEIVED_ERROR">{paymentStates['RESPONSE_RECEIVED_ERROR']}</option>
                                </select>
                            </div>
                            <div className="column4"></div>
                        </div>

                        <div className="row divFilter">



                            <div className="input-container-accountant column4">
                                <label>Identifiación Cliente</label>
                                <input
                                    className="inputFilter"
                                    id="payerDoc"
                                    name="payerDoc"
                                    type="text"
                                    placeholder=""
                                    onFocus={(e) => this.focusInput(e)}
                                    onBlur={(e) => this.blurInput(e)}
                                />
                            </div>
                            <div className="input-container-accountant column4">
                                <label>ID de Registro Aurora</label>
                                <input
                                    className="inputFilter"
                                    id="id"
                                    name="id"
                                    type="text"
                                    placeholder=""
                                    onFocus={(e) => this.focusInput(e)}
                                    onBlur={(e) => this.blurInput(e)}
                                />
                            </div>
                            {/* <div className="input-container-accountant column4">
                                <label>Correo Cliente</label>
                                <input
                                    className="inputFilter"
                                    id="email"
                                    name="email"
                                    type="text"
                                    placeholder=""
                                    onFocus={(e) => this.focusInput(e)}
                                    onBlur={(e) => this.blurInput(e)}
                                />
                            </div> */}
                            {/* <div className="input-container-accountant column4">
                                <label>Nombre Cliente</label>
                                <input
                                    className="inputFilter"
                                    id="payerName"
                                    name="payerName"
                                    type="text"
                                    placeholder=""
                                    onFocus={(e) => this.focusInput(e)}
                                    onBlur={(e) => this.blurInput(e)}
                                />
                            </div> */}
                            <div className="input-container-accountant column4">
                                <label>Id Transacción</label>
                                <input
                                    className="inputFilter"
                                    id="transactionId"
                                    name="transactionId"
                                    type="text"
                                    placeholder=""
                                    onFocus={(e) => this.focusInput(e)}
                                    onBlur={(e) => this.blurInput(e)}
                                />
                            </div>
                            <div className="input-container-accountant column4">
                                <label>Id Transacción Primer Pago</label>
                                <input
                                    className="inputFilter"
                                    id="originalTransactionId"
                                    name="originalTransactionId"
                                    type="text"
                                    placeholder=""
                                    onFocus={(e) => this.focusInput(e)}
                                    onBlur={(e) => this.blurInput(e)}
                                />
                            </div>
                            <div className="column4">
                                <div className="row">
                                    <div className="column2">
                                        <button className="green-fill btnBuscar" id="submitFilterButton" type="submit">
                                            <div className="row">
                                                <div className="column2">
                                                    <span className="siicon icon-search searchMarket"></span>
                                                </div>
                                                <div className="column2">
                                                    <span>Buscar</span>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className="column2">
                                        <button
                                            onClick={this.cleanFilter}
                                            className="orange-fill"
                                            type="button"
                                        >
                                            Limpiar
                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                {error && <h4>Error: {error.response.data}</h4>}
                {(isLoading) ? (
                    <Loader />
                ) : (
                        <div>

                            <div className="divTable">
                                <TableReport
                                    columns={columns} // Data columns to table
                                    tabs={false} //Enable tabs
                                    tabsData={[]} //Load data to tabs check option on tabsData const
                                    headerCounterTab={true} //Counter tab option
                                    btnActions={btnActions}
                                    actionButtons={true} //Enable buttons (edit, delete, copy, open)
                                    pageSize={50} // Enable page size
                                    pagination={true} //Enable pagination (true, false)
                                    paginationNav={'arrows'} //Nav options (arrow, dot)
                                    paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
                                    maxSizePagination={6} //Size pagination
                                    defaultPaginationNumber={1} //Current pagination when start app
                                    paginationGrouped={10} //Grouped by page
                                    detailRow={false} //Open column detail with left arrow
                                    data={datos} //Data for table
                                />
                            </div>
                            <Modal
                                onClose={this.showModalDetail}
                                show={this.state.showDetail}
                                title="Detalles de la Orden"
                                open={this.state.openDetail}
                            >
                                <div class="uxDetailData">

                                    <div className="uxDetailDataFilterContainer">
                                        <div className="uxDetailDataFilter">
                                            <div className="uxDetailDataFilterTitle">
                                                <h3>Datos del Cliente</h3>
                                            </div>
                                            {this.renderModalDataBasic(this.state.payment)}
                                        </div>

                                        <div class="uxDetailDataFilter">
                                            <div class="uxDetailDataFilterTitle">
                                                <h3>Datos de la Transacción</h3>
                                            </div>
                                            {this.renderModalDataPayment(this.state.payment)}
                                        </div>
                                        <div class="uxDetailDataFilter">
                                            <div class="uxDetailDataFilterTitle">
                                                <h3>Datos de Suscripción</h3>
                                            </div>
                                            {this.renderModalDataPlataform(this.state.payment)}
                                        </div>
                                    </div>
                                </div>


                            </Modal>

                            {access ? 
                            (<button className="button green-fill btnDownload" style={{opacity: this.state.download ? "0.9" : "1.0" }} 
                                disabled={this.state.download} onClick={e => {this.setState({download: true}); this.onHandleDownload(datos)}}>
                                
                                {this.state.download ? (<div  className="buttonload">

                                    <div style={{marginRight:"14px", marginBottom:"12px"}} className="loader btnLoadInter" >
                                        <div className="lds-ring btnLoadInter" >
                                            <div className="btnLoadInter divLoad" ></div>
                                            <div className="btnLoadInter divLoad" ></div>
                                            <div className="btnLoadInter divLoad" ></div>
                                            <div className="btnLoadInter divLoad" ></div>
                                        </div>
                                    </div>

                                    <div>Descargando...</div>
                                </div>):(<div>Descargar Excel</div>)}

                            </button>) : null}
                        </div>
                    )}
            </div>
        );
    }
}

RecurrentPaymentReport.propTypes = {
    actions: PropTypes.object.isRequired,
    payments: PropTypes.object,
    error: PropTypes.object,
    excelData: PropTypes.object,
};

const mapStateToProps = state => ({
    payments: state.recurrentPaymentReport.payments,
    error: state.recurrentPaymentReport.error,
    isLoading: state.recurrentPaymentReport.isLoading,
    excelData: state.synergy.excelData,

});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(recurrentPaymentReportActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(RecurrentPaymentReport);
