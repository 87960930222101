export default function (api) {
  return {
    getAccountant: params => api.get('getAccountant', { params }),
    modifyAccountant: body => api.post('modifyAccountant', body),
    resendMassiveEmails: body => api.post('resendMassiveEmail', body),
    sendEmail: params => api.get('resendAccountantEmail', { params }),
    sendExcelObject: body => api.post('convert/file', body),
    authExcel: body => api.post('jwtPostExcel', body)
  };
}
