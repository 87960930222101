import React, { Component } from 'react';
import Modal from './index';
import Loader from '../../tools/loader';

export default class ModalAddPackage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      packages: [],
      isLoadingPack: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    //showConsole('this.props PAYMENTS', this.props);

    if (nextProps.showModal !== this.state.showModal) {
      this.setState({ showModal: nextProps.showModal });
    }
    if (nextProps.packages.length !== this.state.packages.length) {
      this.setState({ packages: nextProps.packages });
    }

    if (nextProps.isLoadingPack !== this.state.isLoadingPack) {
      this.setState({ isLoadingPack: nextProps.isLoadingPack });
    }
  }

  onHandleInputChange (e) {
    const regexp = /[^\d]*/g;
    let inputOnlyNumber = e.target.value;
    document.querySelector(`input[name='${e.target.name}']`).value = inputOnlyNumber.toString().replace(regexp,'');
  }

  render() {
    return (
      <Modal
        onClose={this.props.hideModalPackage}
        addPackage={this.props.addPackage}
        title={"Adicionar paquetes de " + this.props.title}
        show={this.state.showModal}
        loading={this.state.isLoadingPack}
      >
        <div className="input-container__modal1">
          <label>Paquete</label>
          <select name="package" id="package">
            <option value="-9">Seleccione el paquete</option>
            {this.state.packages.filter(addPackages => !addPackages.Name.includes('Conversión')).map((val, id) => {
              return <option key={id} value={val._id}>{val.Name}</option>;
            })}
          </select>
        </div>
        <div className="input-container__modal1">
          <label> <span>*</span>Tarea de facturación o Novedad de Contrato</label>
            <input onChange={this.onHandleInputChange} pattern=".{8,10}" maxLength={10}  
            title="El input debe tener minimo 8 caracteres y maximo 10" type="text" name="observations" 
            id="observations" placeholder=" Relaciona aquí la tarea de facturación comercial."/>
        </div>
        {this.state.isLoadingPack ? <Loader /> : null}
      </Modal>
    );
  }
}
