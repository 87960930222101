function getEndPoint(name) {
  const endPoints = JSON.parse(localStorage.getItem("configInst"));
  for (let x = 0; x < endPoints.length; x++) {
    if (endPoints[x].name === name) {
      return endPoints[x].url;
    }
  }
}

export default function (api) {
  return {
    getUsers: (param) =>
      api.get(`${getEndPoint("provisioningHandler")}/${param}`),
    createUsers: (body) =>
      api.post(getEndPoint("provisioningHandler"), body),
    editUsers: (params) =>
      api.patch(`${getEndPoint("provisioningHandler")}/${params.userCode}`, params),
    deleteUsers: (params) =>
      api.delete(
        `${getEndPoint("provisioningHandler")}/${params.id}/${params.userCode}/${params.CloudTenantID}/${params.usersId}`
      ),
    userHasMovements: (params) => api.get(getEndPoint("userHasMovements"), { params })
  };
}
