import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../tools/loader';
import ModalResponse from '../layout/modals/modalResponse';
import config from '../../config.js';
import svgs from '../layout/icons';
import Avatar from '../../assets/icons/avatar.png';

import { getPerfil, setAvatar } from '../../actions/users/usersAction';
import { setCookie } from '../../utils/cookies';
import { formatoFecha } from '../tools/format';
import { StatusCodes } from 'http-status-codes';
//import Groups from './groups';
let update = false;
let actGroup = true;
class UserEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groupsUser: ['']
    };
  }

  onHandleEdit = event => {
    event.preventDefault();
    const formData = new FormData();

    if (update) {
      if (event.target.upfile.files[0]) {
        formData.append('avatar', event.target.upfile.files[0]);
        this.props.dispatch(setAvatar(formData, this.props.id));
        update = false;
      } else {
        ModalResponse({
          title: 'Error!',
          text: 'No ha seleccionado la imagen',
          res: 404
        });
      }
    } else {
      document.getElementById('btnGuardar').innerHTML = 'Guardar';
      document.getElementById('hperfil').innerHTML =
        '<img class="icon-cerrar" src=' + svgs.cerrar + ' />  Modificar Perfil';
      update = true;
    }
  };

  handleChange = e => {
    if (['group'].includes(e.target.name)) {
      let groupsUser = [...this.state.groupsUser];
      groupsUser[e.target.dataset.id] = e.target.value;
      this.setState({ groupsUser });
    }
  };

  actGroups = groupsUser => {
    if (actGroup) {
      this.setState({ groupsUser });
      actGroup = false;
    }
  };

  addGroups = e => {
    this.setState(prevState => ({
      groupsUser: [...prevState.groupsUser, '']
    }));
  };

  removeGroup = e => {
    let groupsUser = [...this.state.groupsUser];
    groupsUser.splice(e.target.dataset.id, 1);
    this.setState({ groupsUser });
  };

  getFile = () => {
    document.getElementById('upfile').click();
  };

  sub = e => {
    var file = document
      .getElementById('upfile')
      .value.split('\\')
      .pop();
    if (file !== '') document.getElementById('yourBtn').innerHTML = file;
  };

  componentDidMount() {
    document.title = 'Edit User';
    actGroup = true;
    this.props.dispatch(getPerfil(this.props.id));
  }

  render() {
    let firstName,
      lastName,
      email,
      cellphone,
      createdAt,
      identification,
      estado,
      avatar,
      phone = null;
    update = false;
    let groupsUser = [];
    let text = '';
    const { isLoading, user, userAvatar } = this.props;

    if (user) {
      if (user.data.error === 'Forbidden') {
        return <div>No tiene permiso para este componente</div>;
      }

      firstName = user.data.firstName;
      lastName = user.data.lastName;
      email = user.data.email;
      cellphone = user.data.cellphone;
      phone = user.data.phone;
      createdAt = user.data.createdAt;
      identification = user.data.identification;
      estado = user.data.estado;
      groupsUser = user.data.groupUser;
      if (user.data.avatar) avatar = user.data.avatar;
      if (actGroup && !isLoading) {
        this.actGroups(groupsUser);
      }

      if (user.config.method === 'put' && update && !isLoading) {
        if (user.status === StatusCodes.OK) {
          this.props.history.push('/users');
        } else if (update) {
          text = 'No fue posible guardar el Usuario';
        }
        update = false;
      }
      if (userAvatar) {
        setCookie('avatar', userAvatar.data.post.avatar, 1);
      }
    }

    return (
      <div>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            {text !== '' ? (
              <ModalResponse title={'Editar!'} text={text} res={404} />
            ) : null}
            <h3 id="hperfil">Perfil</h3>

            <form onSubmit={this.onHandleEdit} enctype="multipart/form-data">
              <button id="btnGuardar" className="button green-fill btnPerfil">
                Modificar perfil
              </button>
              <div className="gridperfil">
                <div className="perfil imgperfil">
                  <div className="imgRedonda">
                    {avatar ? (
                      <img
                        src={config.url + 'auth/avatar/' + avatar}
                        alt="avatar"
                      />
                    ) : (
                      <img src={Avatar} alt="avatar" />
                    )}
                  </div>

                  <div
                    id="yourBtn"
                    className="divBtnAvatar"
                    onClick={this.getFile}
                  >
                    Cambiar imagen
                  </div>
                  <div>
                    <input
                      type="file"
                      className="btnAvatar"
                      id="upfile"
                      name="upfile"
                      onChange={this.sub}
                    />
                  </div>
                </div>
                <div className="perfil">
                  <div className="grid2perfil">
                    <div>
                      <label>Nombre</label>
                      <p>{firstName}</p>
                    </div>
                    <div>
                      <label>Estado</label>
                      <p className="p-green">{estado}</p>
                    </div>
                  </div>
                  <div className="grid2perfil">
                    <div>
                      <label>Apellidos</label>
                      <p>{lastName}</p>
                    </div>
                    <div>
                      <label>Fecha de inicio</label>
                      <p>{formatoFecha(createdAt)}</p>
                    </div>
                  </div>
                  <div className="grid2perfil">
                    <div>
                      <label>Celular</label>
                      <p> {cellphone}</p>
                    </div>
                    <div>
                      <label>Telefono</label>
                      <p>{phone}</p>
                    </div>
                  </div>
                  <div className="grid2perfil">
                    <div>
                      <label>Correo electrónico</label>
                      <p>{email}</p>
                    </div>
                    <div>
                      <label>Identificación</label>
                      <p className="p-green">{identification}</p>
                    </div>
                  </div>
                </div>
              </div>

              <h3>Grupos</h3>
              <hr />
              <div>
                {this.state.groupsUser.map((val, idx) => (
                  <p className="p-green">{val.name}</p>
                ))}
              </div>
            </form>
          </div>
        )}
      </div>
    );
  }
}

UserEdit.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object,
  userAvatar: PropTypes.object,
  error: PropTypes.object
};

const mapStateToProps = state => ({
  user: state.users.user,
  error: state.users.err,
  userAvatar: state.users.userAvatar,
  isLoading: state.users.isLoading
});

/*const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(UserActions, dispatch)
});*/
export default connect(mapStateToProps)(withRouter(UserEdit));
