import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Complementos, optionState, PlanesNominaNube, COMPLEMENTS_TYPES_ENUM, ALLOWED_PLANT_TYPES_POS_WEB, POW_WEB_MODULES_TYPES_ENUM, PLANTYPE_FE, MODULE_ENUM, ALLOWED_PLANT_TYPES_FE_WHATSAPP} from "../plandata";
import {SiigoButtonAtom} from '@siigo/siigo-button-atom-react';
import {SiigoSelectAtom} from '@siigo/siigo-select-atom-react';
import { Cashiers } from './cashiers/cashiers';
import { VALID_POS_WEB_CASHIERS, cashiersLimitExceeded } from '../utils';
import ModalResponse from '../../layout/modals/modalResponse';


const getComplements = () => {
  return Array.isArray(Complementos) && Complementos;
}
const idNubeAddOns = 21;
const getPlanesComplements = (complementType) => {
  const planPermite = [PlanesNominaNube['Nuevo Siigo Nube'], PlanesNominaNube['Solo Nómina'], PlanesNominaNube['Siigo Nube Emprendedor'], PlanesNominaNube['Siigo Nube Independiente'], PlanesNominaNube['Siigo Nube Premium']];
  const companyInfo = JSON.parse(localStorage.getItem('Company'));

  if (complementType === COMPLEMENTS_TYPES_ENUM.POW_WEB) {
    const posWebComplements = Complementos.map((comp) => comp.planesComplemento)[1];
    if (companyInfo.PlanType === PLANTYPE_FE) return posWebComplements;
    return Complementos.map((comp) => comp.planesComplemento)[1].filter(comp => comp.value != MODULE_ENUM.POS_WEB_INITIAL);
  }

  if (!planPermite.includes(companyInfo.PlanType)) {
    return Complementos.map((comp) => comp.planesComplemento)[0].filter(comp => comp.value != idNubeAddOns);
  }
  return Array.isArray(Complementos) && Complementos.map((comp)=>comp.planesComplemento)[0];
}

const searchPlan = (value, complementType) =>{
  return Complementos.find(comp => comp.value === complementType).planesComplemento.find(plan => plan.name == value)
}

const getPlantsOptions = (actualComplemets) => {
  const companyInfo = JSON.parse(localStorage.getItem('Company'));
  let complements = Complementos

  if (!ALLOWED_PLANT_TYPES_POS_WEB.includes(companyInfo.PlanType)) {
    return complements.filter(comp => comp.value != COMPLEMENTS_TYPES_ENUM.POW_WEB);
  }

  if (!ALLOWED_PLANT_TYPES_FE_WHATSAPP.includes(companyInfo.PlanType)) {
    complements = complements.filter(comp => comp.value != COMPLEMENTS_TYPES_ENUM.FE_WHATSAPP);
  }

  if (actualComplemets.length) {
    return complements.filter(comp => !actualComplemets.some(actual => actual.type === comp.value));
  }

  return complements;
}

const SearchComplements = ({setDataComplement, complements, compPlanEdit}) => {
  const [text, setText] = useState(complements.length ? complements[0]?.namePlan : "");
  const [complement, setComplement] = useState("");
  const [planComplemento, setplanComplemento] = useState(compPlanEdit ? compPlanEdit : "");
  const [items, setitemsOptions] = useState(complement ? getPlanesComplements() : getPlantsOptions(complements));
  const [nominaNube, setNominaNube] = useState("");
  const [stateItems] = useState(optionState);
  const [selectState,setSelectState] = useState();
  const { t } = useTranslation();
  const [configAddon, setConfigAddon] = useState(false);
  const [isFormError, setIsFormError] = useState(false);
  const [form, setForm] = useState({
    additionalCashiers: 0,
    baseCashiers: 0,
    selectComplement: "",
  });

  useEffect(() => {
    resetSelectForm();
  }, []);

  const getComplement = (e) =>{
    e.preventDefault();

    if(text.value === COMPLEMENTS_TYPES_ENUM.FE_WHATSAPP) {
      setText("");
      return setDataComplement({
        name: text.name,
        namePlan: text.name,
        value: text.planesComplemento[0].value,
        valueParent: text.planesComplemento[0].valueParent,
        documentBase: text.planesComplemento[0].documentBase
      });
    }

    //valida complemento escrito con las opciones disponibles antes de crearlo
    if(getComplements().length && text.name === getComplements().filter(comp => comp.name === text.name)[0].name){
      setComplement(text);
      setText("");
      (text.value === COMPLEMENTS_TYPES_ENUM.POW_WEB) ? setConfigAddon(true) : setConfigAddon(false);
      setitemsOptions(getPlanesComplements(text.value))
      resetSelectForm();
      var elemento = document.querySelector(".modal-complements .content");
      elemento.style.height = "380px";
    }
  }

  const resetSelectForm =() =>{
    let select = document.querySelector('siigo-select-atom')
    if(select)  select.resetSelect()
  }

  const getPlan = (e) =>{
    e.preventDefault();
    if(searchPlan(text.name, text.valueParent)){
      setplanComplemento(text);
      var objectComplement = {
        name: complement.name,
        namePlan: text.name,
        value: text.valueSelected,
        valueParent: text.valueParent,
        documentBase: text.documentBase
      };

      if (text.posActiveCashiers) {
        objectComplement.posActiveCashiers = {
          baseCashiers: form.baseCashiers, 
          aditionalCashiers: parseInt(form.additionalCashiers)
        };
        objectComplement.planType = text.id;
      }

      if(objectComplement.valueParent === COMPLEMENTS_TYPES_ENUM.POW_WEB) {
        const dataLocal = JSON.parse(localStorage.getItem("Company"));
        const { exceeded, limit } = cashiersLimitExceeded(dataLocal.PlanType, objectComplement.value, objectComplement.posActiveCashiers.aditionalCashiers);
        if(exceeded) {
            ModalResponse({text: t("cashiersLimit", {limit: limit})})
            return;
        }
      }

      setText("");
      setDataComplement(objectComplement);
    }
    var elemento = document.querySelector(".modal-complements .content");
    elemento.style.height = "initial";
  }

  function getDataSelect(e){
    setForm({...form, selectComplement: e.target.value});
    setText(e.detail.selected)
    setNominaNube(e.detail.selected.id)
    const {valueParent, value, posActiveCashiers} = e.detail.selected; 
    if(valueParent === COMPLEMENTS_TYPES_ENUM.POW_WEB && POW_WEB_MODULES_TYPES_ENUM.includes(value)){
      setForm({
        ...form,
        selectComplement: e.target.value,
        additionalCashiers: posActiveCashiers.aditionalCashiers,
        baseCashiers: posActiveCashiers.baseCashiers
      });
    }
  }
  function setPayrollStatus(e){
    setSelectState(e.detail.selected)
  }

  const handleValuesChange = (e) => {
    const { name, value } = e.target;
    setIsFormError(!VALID_POS_WEB_CASHIERS.test(value));
    setForm({ ...form, [name]: value });
  };

  return (
      <div className="container-add-complements">
        <div>
          {!complement?
              <p>{t("serchComplementoToAdd")}</p>
              :
              (complements.length < 2)?
                  <p>{t("configComplementToAdd")}</p>
                  :
                  <p>{t("editComplement")}</p>
          }
          <div className="input-add-components">
            <SiigoSelectAtom
                errorMessages="Error"
                name="selectComplement"
                label={!complement ? "Complemento":"Plan"}
                onChangeData={(e) => getDataSelect(e)}
                options={items}
                value={form.selectComplement}
            />
            {nominaNube == idNubeAddOns &&
                <p style = {{color: "orange",marginBottom:"10px"}}>{t("banerEmployeeQuantity")}</p>


            }

          </div>
          {configAddon && 
            <Cashiers
              additionalCashiersValue={form.additionalCashiers}
              baseCashiersValue={form.baseCashiers}
              onValuesChange={handleValuesChange}
            />
          }
          {complements.length > 2 ?
              <>
                <p>{t("editStateComplement")}</p>

                <div className="input-add-components">
                  <SiigoSelectAtom
                      errorMessages="Error"
                      label="Estado"
                      onChangeData={(e) => setPayrollStatus(e)}
                      options={stateItems} />
                </div>
              </>
              :
              ''}

          {!complement ?
              !text ?
                  <div className="float-lf">
                    <SiigoButtonAtom
                        color="primary-blue"
                        href="#"
                        iconPosition="start"
                        name="configButton"
                        size="m"
                        disabled
                        text="Configurar"
                        type="button"
                    />
                  </div>
                  :
                  <div className="float-lf">
                    <SiigoButtonAtom
                        color="primary-blue"
                        href="#"
                        iconPosition="start"
                        name="configButton"
                        size="m"
                        text="Configurar"
                        type="button"
                        onClick={(e) => getComplement(e)}
                    />
                  </div>
              :
              !text || isFormError ?
                  <div className="float-lf">
                    <SiigoButtonAtom
                        color="primary-blue"
                        href="#"
                        iconPosition="start"
                        name="configButton"
                        size="m"
                        disabled
                        text={t("save")}
                        type="button"
                    />
                  </div>
                  :
                  <div className="float-lf">
                    <SiigoButtonAtom
                        color="primary-blue"
                        href="#"
                        iconPosition="start"
                        name="configButton"
                        size="m"
                        text={t("save")}
                        type="button"
                        onClick={(e) => getPlan(e)}
                    />
                  </div>
          }
        </div>
      </div>
  );
}


export default SearchComplements;