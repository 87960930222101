import React, { Component } from "react";
import PropTypes from "prop-types";
import * as accountantsActions from "../../actions/accountant/accountantsAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Loader from "../tools/loader";
import TableReport from "../tableReport";
import ModalResponse from "../layout/modals/modalResponse";
import Modal from "../layout/modals/detailAccountant/index";
import "./style.scss";
import { normalize } from "../tools/format";
import { statesClass, getState, humanStates, accountantStates } from "./states";
import { ACCESS } from "./access";
import { getAccess } from "./../../utils/access";
import DatePicker from "../tools/datePickerControl";
import { StatusCodes } from "http-status-codes";

const columns = [
  {
    name: "Fecha",
    selector: "createdTime",
    sortable: true,
  },
  {
    name: "Identificación",
    selector: "id",
    sortable: true,
    filterDDL: true,
  },
  {
    name: "Estado",
    selector: "state",
    selectorState: "state",
    sortable: true,
  },
  {
    name: "Perfil",
    selector: "profile",
    sortable: true,
  },
  {
    name: "Nombre",
    selector: "name",
    sortable: true,
  },
  {
    name: "Apellido",
    selector: "lastName",
    sortable: true,
  },
  {
    name: "Correo",
    selector: "email",
    sortable: true,
    filterDDL: true,
  },
];

const columnsAtrib = [
  {
    name: "Source",
    selector: "source",
  },
  {
    name: "Hits Source",
    selector: "hits",
  },
  {
    name: "Campaña",
    selector: "campaign",
  },
  {
    name: "Hits Campaña",
    selector: "campaignHits",
  },
  {
    name: "Term",
    selector: "campaignTerm",
  },
  {
    name: "Content",
    selector: "campaignContent",
  },
  {
    name: "Medio Virtual",
    selector: "campaignMedium",
  },
  {
    name: "Medio GA",
    selector: "campaignUtmMedium",
  },
  {
    name: "Convertido",
    selector: "campaignConvert",
  },
];
let sendEmail = false;
let downloadEnable = false;
let modifyAccountantFlag = false;
let massiveResendEmailFlag = false;
let renderDataExcelValid = 0;
let accountantsToActions = [];
class AccountantList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      detailAccount: [],
      sourceData: [],
      emailUser: [],
      modifyAccountant: {},
      massiveActions: false,
      fromDate: '',
      toDate: ''
    };

    this.datePickerRef = React.createRef();
  }

  showModal = (e) => {
    this.setState({ show: !this.state.show });
  };

  showModalModify = (e) => {
    this.setState({ showModify: !this.state.showModify });
  };

  getAccountant = () => {
    let pais = normalize(
      document.querySelector("#countryItemLabel span").innerHTML
    );
    pais = pais != "Pais" ? pais : "Colombia";

    const params = {
      pais: pais
    };

    this.props.actions.getAccountants(params);
  };

  componentDidMount() {
    this.queryCurrentDayAccountants();
  }

  queryCurrentDayAccountants = () => {
    let dates = { from: new Date(), toDate: new Date() };
    this.setTimeDate(dates.from, dates.toDate);
    const params = { from: dates.from, to: dates.toDate };
    if (!this.props.checkAuth()) return;
    this.props.actions.getAccountants(params);
  };

  filtrarAccountant = (e) => {
    e.preventDefault();

    const VALUE_STATE = [
      { name: "PENDING", value: 2 },
      { name: "CONFIRMED", value: 3 },
      { name: "PROVIDED", value: 4 },
      { name: "SERIALIZED", value: 5 },
      { name: "TASKED", value: 6 },
      { name: "MARKET_SERIALIZED", value: "7" },
      { name: "ERROR_PENDING", value: "E2" },
      { name: "ERROR_CONFIRMED", value: "E3" },
      { name: "ERROR_PROVIDED", value: "E4" },
      { name: "ERROR_SERIALIZED", value: "E5" },
      { name: "ERROR_TASKED", value: "E6" },
      { name: "ERROR_MARKET_SERIALIZED", value: "E7" },
    ]
    let state = e.target.state.value;
    if (state != "") {
      Object.keys(VALUE_STATE).forEach(function (key) {
        if (VALUE_STATE[key].name == state) {
          state = VALUE_STATE[key].value
        }
      })
    }
    const id = e.target.id.value;
    const email = e.target.email.value;
    const emailRefer = e.target.emailRefer.value;
    const pais = normalize(
      document.querySelector("#countryItemLabel span").innerHTML
    );

    const data = [
      { key: { state: state }, value: state },
      { key: { id: id }, value: id },
      { key: { email: email }, value: email },
      { key: { pais: pais }, value: pais },
      { key: { from: this.state.fromDate }, value: this.state?.fromDate?.toString() },
      { key: { to: this.state.toDate }, value: this.state?.toDate?.toString() },
      { key: { emailRefer: emailRefer }, value: emailRefer },
    ];

    let params = {};
    if (
      state != "" ||
      id != "" ||
      email != "" ||
      (this.state.fromDate != "" && this.state.toDate != "") ||
      emailRefer != ""
    ) {
      data.map((val, id) => {
        if (val.value != "") {
          Object.assign(params, val.key);
        }
      });

      if (emailRefer != "" && this.state.fromDate == "") {
        params.from = new Date()
      }
      if (!this.props.checkAuth()) return;
      this.props.actions.getAccountants(params);
    } else {
      ModalResponse({
        title: "¡Hey!",
        text: "Debe ingresar al menos un filtro",
        res: 404,
      });
    }
  };

  cleanFilter = (e) => {
    e.preventDefault();
    document.getElementById("state").value = "";
    document.getElementById("id").value = "";
    document.getElementById("email").value = "";
    document.getElementById("emailRefer").value = "";
    this.queryCurrentDayAccountants();
    if (this.datePickerRef?.current) this.datePickerRef.current.handleCleanImput()
    this.setState({ fromDate: '', toDate: '' });
  };

  showDetail = (data) => {
    let source = [];

    if (typeof data.source === "object" && data.source != null) {
      Object.keys(data.source).map(function (key) {
        source.push({ key: key, value: JSON.stringify(data.source[key]) });
      });
    } else {
      if (typeof data.source === "string" && data.source.includes("[{")) {
        try {
          source = JSON.parse(data.source);
          source.map(function (dataSource) {
            let resp = [];
            let dataSourceBk = dataSource;
            dataSourceBk.source = dataSource.source;
            dataSourceBk.hits = dataSource.hits;
            dataSource.campaigns.map((campaign) => {
              dataSourceBk.campaign = campaign.campaign;
              dataSourceBk.campaignHits = campaign.hits;
              dataSourceBk.campaignConvert = campaign.convert;
              dataSourceBk.campaignTime = campaign.time;
              dataSourceBk.campaignContent =
                campaign.content[campaign.content?.length - 1];
              dataSourceBk.campaignMedium =
                campaign.medium[campaign.medium?.length - 1];
              dataSourceBk.campaignTerm =
                campaign.term[campaign.term?.length - 1];
              dataSourceBk.campaignUtmMedium =
                campaign.medium[campaign.medium?.length - 1];
              resp = dataSourceBk;
              return resp;
            });
          });
        } catch (e) {
          console.error(e);
          source = data.source;
        }
      } else {
        source = data.source;
      }
    }

    this.setState({
      detailAccount: data,
      sourceData: source,
      show: true,
      open: true,
    });
  };

  btnResendEmail = (data) => {
    if (!sendEmail) {
      sendEmail = true;
      this.props.actions.sendEmail({ id: data.id, email: data.email });
    }
  };

  btnModifyAccountant = (data) => {
    if (getState(data.state) == accountantStates.PENDING) {
      this.setState({
        detailAccount: data,
        showModify: true,
        openModify: true,
        modifyAccountant: {
          _id: data._id,
          id: data.id,
          email: data.email,
          name: data.name,
          lastName: data.lastName,
          celphone: data.celphone,
        },
      });
    } else {
      ModalResponse({
        title: "Estado inválido",
        text: "El contador no se encuentra en un estado valido para modificar.",
        res: 404,
      });
    }
  };

  onHandleDownload = (datos) => {
    downloadEnable = true;
    let dataDownload = null;
    let itemString = "";
    if (datos?.length > 0) {
      if (!this.props.checkAuth()) return;
      let columnsReqClone = [
        ...columns,
        {
          name: "Celular",
          selector: "celphone",
        },
        {
          name: "Referenciador",
          selector: "referenciador",
        },
        {
          name: "IP",
          selector: "ip",
        },
        {
          name: "Serial",
          selector: "serial",
        },
        {
          name: "Seguimiento",
          selector: "task",
        },
        {
          name: "Log",
          selector: "msg",
        },
        {
          name: "Source",
          selector: "sourceGen",
        },
        {
          name: "Source hits",
          selector: "sourceHits",
        },
        {
          name: "Campana",
          selector: "campaign",
        },
        {
          name: "Campana hits",
          selector: "campaignHits",
        },
        {
          name: "Campana term",
          selector: "campaignTerm",
        },
        {
          name: "Campana medium",
          selector: "campaignMedium",
        },
        {
          name: "Campana content",
          selector: "campaignContent",
        },
      ];

      let columnsReq = columnsReqClone
        .map(function (column) {
          let columnAux = [];
          columnAux.title = column.name;
          columnAux.id = column.selector;
          return JSON.stringify(Object.assign({}, columnAux));
        })
        .join();

      let datosString = datos
        .map(function (dataItem) {
          return JSON.stringify(Object.assign({}, dataItem));
        })
        .join();

      let reqExcel = {
        columns: JSON.parse(`[${columnsReq}]`),
        data: JSON.parse(`[${datosString}]`),
        typeFile: "csv",
      };

      this.props.actions.sendExcelObject(reqExcel);
    } else {
      downloadEnable = false;
      ModalResponse({
        title: "Descargar excel",
        text: "No hay datos para descargar",
        res: 404,
      });
    }
  };

  handleDate = (data) => {
    let fromDate = data.from != undefined ? new Date(data.from) : undefined;
    let toDate = data.to != undefined ? new Date(data.to) : undefined;
    this.setState({ fromDate: !isNaN(fromDate.valueOf()) ? fromDate : '' });
    this.setState({ toDate: !isNaN(toDate.valueOf()) ? toDate : '' });
  }

  setTimeDate = (from, to) => {
    if (from) {
      from.setHours(0);
      from.setMinutes(0);
      from.setSeconds(0);
      from.setMilliseconds(0);
    }
    to.setHours(23);
    to.setMinutes(59);
    to.setSeconds(59);
    to.setMilliseconds(999);
  }
  downloadExcel = (data) => {
    var blob = new Blob([atob(data)], { type: "text/plain; charset=utf-8" });
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveOrOpenBlob(
        blob,
        "exportData" + new Date().toDateString() + ".csv"
      );
    } else {
      var a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob, {
        type: "text/plain; charset=utf-8",
      });
      a.download = "exportData" + new Date().toDateString() + ".csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  focusInput = (e) => {
    e.preventDefault();
    e.currentTarget.placeholder = "";
    e.currentTarget.classList.add("inputFocus");
    if (e.currentTarget.previousSibling)
      e.currentTarget.previousSibling.classList.add("labelModal");
  };

  blurInput = (e) => {
    e.preventDefault();
    if (e.currentTarget.value === "") {
      e.currentTarget.classList.add("inputError");
      if (e.currentTarget.previousSibling)
        e.currentTarget.previousSibling.classList.add("labelError");
    } else {
      e.currentTarget.classList.add("inputOk");
      if (e.currentTarget.previousSibling)
        e.currentTarget.previousSibling.classList.add("labelOk");
    }
  };

  changeModifyField = (e) => {
    const valueInput = e.target.value;
    const fieldacc = e.target.getAttribute("fieldacc");
    this.setState((prevState) => ({
      ...prevState,
      modifyAccountant: {
        ...prevState.modifyAccountant,
        [fieldacc]: valueInput,
      },
    }));
  };

  modifyAccountantSend = () => {
    if (!this.props.checkAuth()) return;
    this.props.actions.modifyAccountant(this.state.modifyAccountant);
    modifyAccountantFlag = true;
  };

  showMassiveActions = () => {
    this.setState({ massiveActions: !this.state.massiveActions });
    accountantsToActions = [];
    var clist = document.getElementsByClassName("chResendEmail");
    for (var i = 0; i < clist?.length; ++i) {
      clist[i].checked = false;
    }
  };

  massiveActions = (e, data) => {
    const deleteId = accountantsToActions.findIndex(
      (acc) => acc._id === data._id
    );
    if (deleteId != -1) {
      accountantsToActions.splice(deleteId, 1);
    } else {
      accountantsToActions.push(data);
    }
  };

  btnMassiveActions = () => {
    const actionSelect = document.getElementById("massiveActionsSelect");
    const actionValue = actionSelect.options[actionSelect.selectedIndex].value;
    if (actionValue == "resendEmails") {
      if (!this.props.checkAuth()) return;
      this.props.actions.resendMassiveEmails({ emails: accountantsToActions });
      accountantsToActions = [];
      var clist = document.getElementsByClassName("chResendEmail");
      for (var i = 0; i < clist?.length; ++i) {
        clist[i].checked = false;
      }
      massiveResendEmailFlag = true;
    } else {
      ModalResponse({
        title: "Acciones automáticas",
        text: "Debe seleccionar una acción de la lista",
        res: 404,
      });
    }
  };

  renderModalDataBasic = (data) => {
    return (
      <div className="uxDetailContainer">
        <div>
          <span className="labelUxDetail">Perfil: </span>{" "}
          <span id="uxDetailProfile">{data.profile}</span>
        </div>
        <div>
          <span className="labelUxDetail">Identificación: </span>{" "}
          <span id="uxDetailId">{data.id}</span>
        </div>
        <div>
          <span className="labelUxDetail">Nombre: </span>{" "}
          <span id="uxDetailName">{data.name}</span>
        </div>
        <div>
          <span className="labelUxDetail">Apellido: </span>{" "}
          <span id="uxDetailLastName">{data.lastName}</span>
        </div>
        <div>
          <span className="labelUxDetail">Correo: </span>{" "}
          <span id="uxDetailEmail">{data.email}</span>
        </div>
        <div>
          <span className="labelUxDetail">Ciudad: </span>{" "}
          <span id="uxDetailCity">{data.city}</span>
        </div>
        <div>
          <span className="labelUxDetail">Departamento: </span>{" "}
          <span id="uxDetailDepartment">{data.departament}</span>
        </div>
        <div>
          <span className="labelUxDetail">País: </span>{" "}
          <span id="uxDetailCountry">{data.country}</span>
        </div>
        <div>
          <span className="labelUxDetail">Teléfono: </span>{" "}
          <span id="uxDetailCelphone">{data.celphone}</span>
        </div>
        <div>
          <span className="labelUxDetail">Teléfono fijo: </span>{" "}
          <span id="uxDetailPhone">{data.phone}</span>
        </div>
        <div>
          <span className="labelUxDetail">Dirección: </span>{" "}
          <span id="uxDetailAddress">{data.address}</span>
        </div>
      </div>
    );
  };

  renderModalDataSiigo = (data) => {
    return (
      <div className="uxDetailContainer">
        <div>
          <span class="labelUxDetail">Producto: </span>{" "}
          <span id="uxDetailProduct">{data.product}</span>
        </div>
        <div>
          <span class="labelUxDetail">Serial: </span>{" "}
          <span id="uxDetailSerial">{data.serial}</span>
        </div>
        <div>
          <span class="labelUxDetail">Referido: </span>{" "}
          <span id="uxDetailRefer">{data.referenciador}</span>
        </div>
        <div>
          <span class="labelUxDetail">Source: </span>{" "}
          <span id="uxDetailSource">{data.source}</span>
        </div>
        <div>
          <span class="labelUxDetail">Tarea Seguimiento: </span>{" "}
          <span id="uxDetailAlliesId">{data.task}</span>
        </div>
        <div>
          <span class="labelUxDetail">Comercial: </span>{" "}
          <span id="uxDetailSeller">{data.seller}</span>
        </div>
      </div>
    );
  };

  renderModalDescription = (data) => {
    return (
      <div className="uxDetailContainer">
        <h3>
          Estado:{" "}
          <span
            id="uxDetailState"
            className={statesClass[getState(data.state)]}
          >
            {humanStates[getState(data.state)]}
          </span>
        </h3>
      </div>
    );
  };

  renderModalLogs = (data) => {
    if (typeof data.msg === "string") {
      return (
        <div className="uxDetailContainer">
          <p id="uxDetailLogs">{data.msg}</p>
        </div>
      );
    } else {
      return (
        <div className="uxDetailContainer">
          <p id="uxDetailLogs">-</p>
        </div>
      );
    }
  };

  renderModalModify = (data) => {
    return (
      <div>
        <div className="input-container-accountant">
          <label>Id del contador</label>
          <input
            className="inputFilter"
            id="idModify"
            name="idModify"
            type="text"
            fieldacc="id"
            onFocus={(e) => this.focusInput(e)}
            onBlur={(e) => this.blurInput(e)}
            value={this.state.modifyAccountant.id}
            onChange={this.changeModifyField.bind(this)}
          />
        </div>
        <div className="input-container-accountant">
          <label>Nombre(s)</label>
          <input
            className="inputFilter"
            id="nameModify"
            name="nameModify"
            type="text"
            fieldacc="name"
            onFocus={(e) => this.focusInput(e)}
            onBlur={(e) => this.blurInput(e)}
            value={this.state.modifyAccountant.name}
            onChange={this.changeModifyField.bind(this)}
          />
        </div>
        <div className="input-container-accountant">
          <label>Apellido(s)</label>
          <input
            className="inputFilter"
            id="lastNameModify"
            name="lastNameModify"
            type="text"
            placeholder=""
            fieldacc="lastName"
            onFocus={(e) => this.focusInput(e)}
            onBlur={(e) => this.blurInput(e)}
            value={this.state.modifyAccountant.lastName}
            onChange={this.changeModifyField.bind(this)}
          />
        </div>
        <div className="input-container-accountant">
          <label>Correo electrónico</label>
          <input
            className="inputFilter"
            id="emailModify"
            name="emailModify"
            type="text"
            fieldacc="email"
            value={this.state.modifyAccountant.email}
            onFocus={(e) => this.focusInput(e)}
            onBlur={(e) => this.blurInput(e)}
            onChange={this.changeModifyField.bind(this)}
          />
        </div>
        <div className="input-container-accountant">
          <label>Celular</label>
          <input
            className="inputFilter"
            id="celphoneModify"
            name="celphoneModify"
            type="text"
            fieldacc="celphone"
            value={this.state.modifyAccountant.celphone}
            onFocus={(e) => this.focusInput(e)}
            onBlur={(e) => this.blurInput(e)}
            onChange={this.changeModifyField.bind(this)}
          />
        </div>
        <button
          className="orange-fill"
          type="button"
          onClick={this.modifyAccountantSend}
        >
          Modificar
        </button>
      </div>
    );
  };

  render() {
    let {
      error,
      isLoading,
      accountants,
      email,
      isLoadingEmail,
      excelData,
      modifyAccountant,
      massiveResendEmail,
      isLoadingMassive,
    } = this.props;
    const btnActions = {
      btnShowDetail: this.showDetail,
      btnResendEmail: getAccess(this.props.parentPath, ACCESS.Resend_Email)
        ? this.btnResendEmail
        : null,
      btnModifyAccountant: getAccess(
        this.props.parentPath,
        ACCESS.Modify_Accountant
      )
        ? this.btnModifyAccountant
        : null,
      chMassiveActions:
        this.state.massiveActions &&
          getAccess(this.props.parentPath, ACCESS.Resend_Email)
          ? this.massiveActions
          : null,
    };
    let datos = [];

    if (accountants) {
      if (accountants.status == 200) {
        datos = accountants.data;
        datos.map(function (dataAccountant) {
          dataAccountant.profile =
            dataAccountant.profile == "0"
              ? "Sin definir"
              : dataAccountant.profile == "1"
                ? "Empresario"
                : dataAccountant.profile == "2" ||
                  dataAccountant.profile == "4" ||
                  dataAccountant.profile == "5" ||
                  dataAccountant.profile == "6"
                  ? "Contador"
                  : dataAccountant.profile == "3"
                    ? "Estudiante"
                    : dataAccountant.profile;
          if (dataAccountant.createdTime?.length > 20) {
            dataAccountant.createdTime = new Date(dataAccountant.createdTime)
              .toLocaleString()
              .replace(/^(\d{4})-(\d{2})-(\d{2})$/g, "$12/$2/$3");
          }
          if (
            dataAccountant.source != null &&
            dataAccountant.source.includes("source")
          ) {
            if (dataAccountant.source.indexOf("[{") !== -1) {
              try {
                let source = JSON.parse(dataAccountant.source);
                source.map(function (dataSource) {
                  dataSource.campaigns.map((campaign) => {
                    if (campaign.convert) {
                      dataAccountant.sourceGen = dataSource.source;
                      dataAccountant.sourceHits = dataSource.hits;
                      dataAccountant.campaign = campaign.campaign;
                      dataAccountant.campaignHits = campaign.hits;
                      dataAccountant.campaignTerm =
                        campaign.term[campaign.term?.length - 1];
                      dataAccountant.campaignMedium =
                        campaign.medium[campaign.medium?.length - 1];
                      dataAccountant.campaignContent =
                        campaign.content[campaign.content?.length - 1];
                    }
                  });
                });
              } catch (e) {
                console.error(e);
                dataAccountant.sourceGen = dataAccountant.source;
                dataAccountant.sourceHits = "No especificado";
                dataAccountant.campaign = "No especificado";
                dataAccountant.campaignHits = "No especificado";
                dataAccountant.campaignTerm = "No especificado";
                dataAccountant.campaignMedium = "No especificado";
                dataAccountant.campaignContent = "No especificado";
              }
            } else {
              dataAccountant.sourceGen = dataAccountant.source;
              dataAccountant.sourceHits = "No especificado";
              dataAccountant.campaign = "No especificado";
              dataAccountant.campaignHits = "No especificado";
              dataAccountant.campaignTerm = "No especificado";
              dataAccountant.campaignMedium = "No especificado";
              dataAccountant.campaignContent = "No especificado";
            }
          }

          return dataAccountant;
        });
      }
    }

    if (email && sendEmail && !isLoadingEmail) {
      ModalResponse({
        title: "Envío de correo",
        text: email.data.msg,
        res: email.data.status == false ? 404 : 200,
      });

      sendEmail = false;
    }

    if (excelData) {
      if (excelData.status === StatusCodes.OK && downloadEnable) {
        renderDataExcelValid = renderDataExcelValid + 1
        if (renderDataExcelValid == 2) {  // renderDataExcelValid with value 2, get the value excelData of the current query
          this.downloadExcel(excelData.data);
          downloadEnable = false;
          renderDataExcelValid = 0;
        }
      }
    }

    if (modifyAccountantFlag) {
      if (modifyAccountant) {
        if (modifyAccountant.status === StatusCodes.OK) {
          ModalResponse({
            title: "Modificar contador",
            text: `${modifyAccountant.data.msg}`,
            res: modifyAccountant.data.status == false ? 404 : 200,
          });

          const findModifyAcc = datos.find(
            (item) => item._id === this.state.modifyAccountant._id
          );
          findModifyAcc.id = this.state.modifyAccountant.id;
          findModifyAcc.name = this.state.modifyAccountant.name;
          findModifyAcc.lastName = this.state.modifyAccountant.lastName;
          findModifyAcc.email = this.state.modifyAccountant.email;
          findModifyAcc.celphone = this.state.modifyAccountant.celphone;
        } else {
          ModalResponse({
            title: "Modificar contador",
            text: "Ha ocurrido un problema, inténtelo más tarde",
            res: 404,
          });
        }

        modifyAccountantFlag = false;
        this.showModalModify();
      }
    }

    if (massiveResendEmailFlag) {
      if (massiveResendEmail) {
        if (massiveResendEmail.status === StatusCodes.OK) {
          ModalResponse({
            title: "Re envio de correos",
            text: `${massiveResendEmail.data.msg} </br>Exitosos: <span style="color:green">${massiveResendEmail.data.data.success}</span></br>Fallidos: <span style="color:red">${massiveResendEmail.data.data.failed}</span></br>En otros estados: <span style="color:orange">${massiveResendEmail.data.data.anotherState}</span>`,
            res: massiveResendEmail.data.status == false ? 404 : 200,
          });
        } else {
          ModalResponse({
            title: "Re envio de correos",
            text: "Ha ocurrido un problema, inténtelo más tarde",
            res: 404,
          });
        }
      }

      massiveResendEmailFlag = false;
    }

    return (
      <div>
        <div>
          <h3>Portal Contador</h3>
          <form onSubmit={this.filtrarAccountant}>
            <div className="row divFilter">
              <DatePicker id="datePickerControl" ref={this.datePickerRef} handleDate={this.handleDate} />
              <div className="massiveActionsContainer">
                {this.props.rolAccess == "admin" ? (
                  <div>
                    <input
                      className="massiveResendEmail"
                      type="checkbox"
                      name="massiveResendEmail"
                      onClick={(e) => this.showMassiveActions()}
                    ></input>
                    <label name="massiveResendEmail">
                      Acciones automáticas
                    </label>
                  </div>
                ) : null}
              </div>
              {this.state.massiveActions ? (
                <div className="massiveActions">
                  <div className="input-container-accountant column4">
                    <label>Acción</label>
                    <select
                      className="slFilter"
                      id="massiveActionsSelect"
                      name="massiveActionsSelect"
                    >
                      <option value="">Seleccione una acción</option>
                      <option className="" value="resendEmails">
                        Re envio de email
                      </option>
                    </select>
                  </div>
                  <button
                    className="green-fill submitMassiveActions"
                    id="submitMassiveActions"
                    type="button"
                    onClick={this.btnMassiveActions}
                  >
                    <div className="row">
                      <div className="column2">
                        <span className="siicon icon-siigo-construccion-cono"></span>
                      </div>
                      <div className="column2">
                        <span>Ejecutar</span>
                      </div>
                    </div>
                  </button>
                </div>
              ) : null}
            </div>

            <div className="row divFilter">
              <div className="input-container-accountant column4">
                <label>Estado</label>
                <select className="slFilter" id="state" name="state">
                  <option value="">Seleccione el estado</option>
                  <option className="successState" value="PENDING">{`${getState(
                    "PENDING"
                  )}0 - Pendiente`}</option>
                  <option
                    className="warningState"
                    value="CONFIRMED"
                  >{`${getState("CONFIRMED")}0 - Confirmado`}</option>
                  <option
                    className="warningState"
                    value="PROVIDED"
                  >{`${getState("PROVIDED")}0 - Aprovisionado`}</option>
                  <option
                    className="warningState"
                    value="SERIALIZED"
                  >{`${getState("SERIALIZED")}0 - Serializado`}</option>
                  <option className="warningState" value="TASKED">{`${getState(
                    "TASKED"
                  )}0 - Creado seguimiento`}</option>
                  <option
                    className="successState"
                    value="MARKET_SERIALIZED"
                  >{`${getState(
                    "MARKET_SERIALIZED"
                  )}0 - Market Serializado`}</option>
                  <option className="errorState" value="ERROR_PENDING">
                    {`${getState(
                      "ERROR_PENDING"
                    )}0 - Error en proceso pendiente`}
                  </option>
                  <option className="errorState" value="ERROR_CONFIRMED">
                    {`${getState(
                      "ERROR_CONFIRMED"
                    )}0 - Error en proceso confirmado`}
                  </option>
                  <option className="errorState" value="ERROR_PROVIDED">
                    {`${getState(
                      "ERROR_PROVIDED"
                    )}0 - Error en proceso aprovisionado`}
                  </option>
                  <option className="errorState" value="ERROR_SERIALIZED">
                    {`${getState(
                      "ERROR_SERIALIZED"
                    )}0 - Error en proceso serializado`}
                  </option>
                  <option className="errorState" value="ERROR_TASKED">
                    {`${getState("ERROR_TASKED")}0 - Error creando seguimiento`}
                  </option>
                  <option
                    className="errorState"
                    value="ERROR_MARKET_SERIALIZED"
                  >
                    {`${getState(
                      "ERROR_MARKET_SERIALIZED"
                    )}0 - Error actualizando serial en Market`}
                  </option>
                </select>
              </div>
              <div className="input-container-accountant column4">
                <label>Id del contador</label>
                <input
                  className="inputFilter"
                  id="id"
                  name="id"
                  type="text"
                  placeholder=""
                  onFocus={(e) => this.focusInput(e)}
                  onBlur={(e) => this.blurInput(e)}
                />
              </div>
              <div className="input-container-accountant column4">
                <label>Correo electrónico</label>
                <input
                  className="inputFilter"
                  id="email"
                  name="email"
                  type="text"
                  placeholder=""
                  onFocus={(e) => this.focusInput(e)}
                  onBlur={(e) => this.blurInput(e)}
                />
              </div>
              <div className="input-container-accountant column4">
                <label>Correo referenciador</label>
                <input
                  className="inputFilter"
                  id="emailRefer"
                  name="emailRefer"
                  type="text"
                  placeholder=""
                  onFocus={(e) => this.focusInput(e)}
                  onBlur={(e) => this.blurInput(e)}
                />
              </div>
              <div className="column4">
                <div className="row">
                  <div className="column2">
                    <button
                      className="green-fill btnBuscar"
                      id="submitFilterButton"
                      type="submit"
                    >
                      <div className="row">
                        <div className="column2">
                          <span className="siicon icon-search searchMarket"></span>
                        </div>
                        <div className="column2">
                          <span>Buscar</span>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div className="column2">
                    <button
                      onClick={this.cleanFilter}
                      className="orange-fill"
                      type="button"
                    >
                      Limpiar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {error && <h4>Error: {error.response.data}</h4>}
        {isLoading || isLoadingMassive ? (
          <Loader />
        ) : (
          <div>
            <div className="divTable">
              <TableReport
                columns={columns} // Data columns to table
                tabs={false} //Enable tabs
                tabsData={[]} //Load data to tabs check option on tabsData const
                headerCounterTab={true} //Counter tab option
                btnActions={btnActions}
                actionButtons={true} //Enable buttons (edit, delete, copy, open)
                pageSize={50} // Enable page size
                pagination={true} //Enable pagination (true, false)
                paginationNav={"arrows"} //Nav options (arrow, dot)
                paginationPosition={"bottom"} //Pagination position (Bottom, top, both)
                maxSizePagination={6} //Size pagination
                defaultPaginationNumber={1} //Current pagination when start app
                paginationGrouped={10} //Grouped by page
                detailRow={false} //Open column detail with left arrow
                data={datos} //Data for table
              />
            </div>
            <Modal
              onClose={this.showModal}
              show={this.state.show}
              title="Detalles del Usuario"
              open={this.state.open}
            >
              <div class="uxDetailData">
                {this.renderModalDescription(this.state.detailAccount)}
                <div className="uxDetailDataFilterContainer">
                  <div className="uxDetailDataFilter">
                    <div className="uxDetailDataFilterTitle">
                      <h3>Datos básicos</h3>
                    </div>
                    {this.renderModalDataBasic(this.state.detailAccount)}
                  </div>

                  <div class="uxDetailDataFilter">
                    <div class="uxDetailDataFilterTitle">
                      <h3>Datos Siigo</h3>
                    </div>
                    {this.renderModalDataSiigo(this.state.detailAccount)}
                  </div>
                </div>
              </div>
              <hr />
              <div>
                <div class="uxDetailsLogsTitle">
                  <h3>Logs</h3>
                </div>
                {this.renderModalLogs(this.state.detailAccount)}
              </div>
              <hr />
              <h3 className="h3Modal">Tabla de atribución</h3>
              {typeof this.state.sourceData === "object" ? (
                <div className="divTableModal">
                  <TableReport
                    columns={columnsAtrib} // Data columns to table
                    tabs={false} //Enable tabs
                    tabsData={[]} //Load data to tabs check option on tabsData const
                    headerCounterTab={true} //Counter tab option
                    btnActions={[]}
                    actionButtons={false} //Enable buttons (edit, delete, copy, open)
                    pageSize={10} // Enable page size
                    pagination={false} //Enable pagination (true, false)
                    paginationNav={"arrows"} //Nav options (arrow, dot)
                    paginationPosition={"bottom"} //Pagination position (Bottom, top, both)
                    maxSizePagination={6} //Size pagination
                    defaultPaginationNumber={1} //Current pagination when start app
                    paginationGrouped={10} //Grouped by page
                    detailRow={false} //Open column detail with left arrow
                    data={this.state.sourceData} //Data for table
                  />
                </div>
              ) : (
                <div>{this.state.sourceData}</div>
              )}
            </Modal>
            <Modal
              onClose={this.showModalModify}
              show={this.state.showModify}
              title="Modificar usuario"
              open={this.state.openModify}
            >
              <div className="uxDetailDataFilterTitle">
                <h3>Modificar contador</h3>
              </div>
              {this.renderModalModify(this.state.detailAccount)}
            </Modal>
            {getAccess(this.props.parentPath, ACCESS.Download_Excel) ? (
              <button
                className="button green-fill btnDownload"
                onClick={(e) => this.onHandleDownload(datos)}
              >
                {" "}
                Descargar excel
              </button>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

AccountantList.propTypes = {
  actions: PropTypes.object.isRequired,
  accountants: PropTypes.object,
  excelData: PropTypes.object,
  error: PropTypes.object,
  modifyAccountant: PropTypes.object,
  massiveResendEmail: PropTypes.object,
};

const mapStateToProps = (state) => ({
  accountants: state.synergy.accountants,
  email: state.synergy.email,
  error: state.synergy.err,
  excelData: state.synergy.excelData,
  isLoading: state.synergy.isLoading,
  isLoadingEmail: state.synergy.isLoadingEmail,
  modifyAccountant: state.synergy.modifyAccountant,
  massiveResendEmail: state.synergy.massiveResendEmail,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(accountantsActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountantList);
