import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalResponse from './../layout/modals/modalResponse';
import Modal from './modalTarea/indexProcessNsn';
import ModalProcess from './modalTarea/modalProcessNsn';
import * as ProcessNSNActions from '../../actions/processNSN/processNSNaction';
import Loader from '../tools/loader';
import './style.scss';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

import { ACCESS } from './access';
import { getAccess } from './../../utils/access';
import { getCookie } from '../../utils/cookies';

const formatFileColumns = [
    {
        "Tipo": ""
    },
    {
        "Creado": ""
    },
    {
        "Acción": ""
    },
    {
        "Cuenta ": ""
    },
    {
        "Descripción": ""
    },
    {
        "Modificado": ""
    },
    {
        "Modificado por": ""
    },
    {
        "Recurso": ""
    },
    {
        "Producto 1": ""
    },
    {
        "ID": ""
    },
    {
        "Cuenta: Nit tiene Sucursal ? ": ""
    },
    {
        "Cuenta": ""
    },
    {
        "Cuenta: Tipo Identificacion ": ""
    },
    {
        "Libre: GUID 3": ""
    },
    {
        "Nuestrareferencia": ""
    },
    {
        "Produc prin ": ""
    },
    {
        "Producto": ""
    },
    {
        "Obsequi": ""
    },
    {
        "Obsequio": ""
    },
    {
        "": ""
    },
    {
        " ": ""
    },
    {
        "Numerousuarios": ""
    }


];
class ProcessNSN extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            processFile: false,
            uploadFile: false,
            fileToProcess: '',
            dataToProcess: '',
            busyModal: true,
            count: 1
        }
    }

    componentDidMount() {
        this.props.actions.processRequest({
            state: "VALIDATE"
        });
    }

    downLoadExcel = () => {

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const fileExtension = ".xlsx";
        const fileName = "template"
        const ws = XLSX.utils.json_to_sheet(formatFileColumns);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const exportExcel = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(exportExcel, fileName + fileExtension);

    }

    sendToProcessData = () => {
        this.setState({
            show: !this.state.show,
        });
        if (this.state.show === true) {
            this.setState({
                processFile: false,
            })
        }

        if (this.state.processFile) {
            this.props.actions.processRequest({
                state: "PROCESS",
                userName: getCookie("name"),
                userEmail: getCookie("email"),
                data: this.state.dataToProcess
            });
        }
    }

    counter = () => {
        if (this.state.count === 0) {
            this.setState({ uploadFile: !this.state.uploadFile, })
        } else {
            this.setState({ count: 0 })
        }
    }

    loadExcel = (file) => {
        this.counter()
        if (this.state.count === 1) {
            const { files } = file.target;
            if (files[0].name.indexOf('.xlsx') != -1 & files[0].name.indexOf('.xls') != -1) {
                const fileReader = new FileReader();
                fileReader.onload = event => {
                    try {
                        const { result } = event.target;
                        const workbook = XLSX.read(result, { type: 'array' });
                        let data = [];
                        for (const sheet in workbook.Sheets) {
                            if (workbook.Sheets.hasOwnProperty(sheet)) {
                                data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
                            }
                        };

                        let dataProcess = []
                
                        for (let item = 0; item < data.length; item++) {                                        
                            if(data[item].ID && data[item].Numerousuarios && data[item].Obsequio && data[item].Producto){                
                                if (isNaN(data[item].Producto) || isNaN(data[item].Obsequio) || isNaN(data[item].Numerousuarios)) {
                                    throw new Error("Columnas inválidas.");
                                }
    
                                let dataWithItem = {
                                    'processed': "false",
                                    'workflowInstance': String(data[item].ID.replace(/[^\d]*/g, "")),
                                    'serial': data[item].Cuenta_1,
                                    'usersQuantity': Number(data[item].Numerousuarios),
                                    'packQuantity': Number(data[item].Obsequio),
                                    'invoiceQuantity': Number(data[item].Producto)
                                }

                                dataProcess.push(dataWithItem)
    
                            }
                            
                        }
                       
                        this.setState({
                            processFile: true,
                            fileToProcess: files[0].name,
                            dataToProcess: dataProcess
                        })
                    } catch (e) {
                        ModalResponse({
                            title: 'Archivo cargado',
                            text: `No tiene el formato de columnas correcto o valores inválidos, 
                                    por favor descargue el archivo y vuelva a cargarlo`,
                            res: 400,
                        });
                        document.getElementById("loadExcel").value = "";
                        this.setState({
                            count: 1
                        });
                    }
                };
                fileReader.readAsArrayBuffer(files[0])
            } else {
                ModalResponse({
                    title: 'Archivo cargado',
                    text: 'Solamente se pueden cargar archivos con extension .xlsx o .xls',
                    res: 400,
                });
            }
        } else {
            this.counter()
        }
    }

    deleteFile = () => {
        this.setState({ processFile: false })
    }

    closeCurrentModal = () => {
        this.setState({ show: !this.state.show });
        this.setState({ processFile: !this.state.processFile });
    }

    closeBusyModal = () => {
        this.setState({ busyModal: !this.state.busyModal });
    }

    render() {
        let fileExcel = this.state.fileToProcess;

        return (
            <div>
                { getAccess(this.props.parentPath, ACCESS.Bulk_load) ? (
                    <div>
                        { !this.state.processFile ? (
                            <div>
                                <h2 className="bulk-load">Carga masiva de obsequios</h2>
                                <div className="rows divInformation">
                                    <div className="column">
                                        <a onClick={this.downLoadExcel} className="downloadExcelContainer">
                                            <i className="siicon-process icon-excel" />
                                            <span className="description-green"><u>Descargar formato de excel</u></span>
                                        </a>

                                    </div>
                                    {
                                        this.state.busyModal && !this.props?.processNSN?.statusProcess ? (
                                            <div className="column">
                                                <i className="siicon-process icon-load">
                                                    <input className="input-process"
                                                        type='file'
                                                        id="loadExcel"
                                                        accept='.xlsx, .xls'
                                                        onChange={this.loadExcel} />
                                                </i>
                                            </div>
                                        ) : null
                                    }
                                </div>

                                {
                                    this.props?.processNSN?.status ? (
                                        <ModalProcess
                                            onClose={this.closeBusyModal}
                                            uploadFile={this.props?.processNSN?.status && this.state.busyModal}
                                            title="!Atencion!"
                                        >
                                            <div className="input-containers">
                                                <h3 className="hModal">
                                                    Actualmente se esta ejecutando el proceso con otro <br />
                            usuario.
                        </h3>
                                                <br />
                                                <h3 className="hModal">
                                                    Para cargar un nuevo formato por favor espere hasta que <br />
                            termine el proceso en curso.
                        </h3>
                                            </div>
                                        </ModalProcess>
                                    ) : null
                                }

                            </div>
                        ) : null}
                        {
                            this.state.processFile ? (
                                <div >
                                    <h2 className="bulk-load">Carga masiva de obsequios</h2>
                                    <button className="buttons green-process"
                                        onClick={this.sendToProcessData}
                                    >Procesar</button>
                                    <div className="rownd divInformation">
                                        <div className="column">
                                            <label className="title">Formato subido</label>
                                            <div className="column-description">
                                                <i className="siicon-process icon-deleteX"
                                                    onClick={this.deleteFile}
                                                />
                                                <label className="load-excel">{fileExcel}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <Modal
                                            show={this.state.show}
                                            onClose={this.closeCurrentModal}
                                        >
                                            <div className="input-containers">
                                                <h3 className="hModal">
                                                    Estamos procesando la información, una vez termine se <br />
                                        enviara un mail con el resultado.
                                    </h3>
                                                <br />
                                                <h3 className="hModal">
                                                    Para cargar un nuevo formato por favor espere hasta que <br />
                                        termine el proceso en curso.
                                    </h3>
                                            </div>
                                        </Modal>
                                    </div>
                                </div>

                            ) : null}
                    </div>
                ) :
                    <p>
                        No cuenta con los permisos para acceder al componente{' '}
                        <b>/nsn</b>, por favor comuníquese con el
                administrador.
                </p>
                }
            </div>
        )
    }
}

ProcessNSN.propTypes = {
    actions: PropTypes.object.isRequired,
    processNSN: PropTypes.object,
    error: PropTypes.object,
    excelData: PropTypes.object
};

const mapStateToProps = state => ({
    processNSN: state.processNSN.data,
    error: state.leads.err,
    isLoading: state.leads.isLoading,
    excelData: state.synergy.excelData
});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(ProcessNSNActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ProcessNSN);
