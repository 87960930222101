import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as UserActions from '../../actions/users/usersAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../tools/loader';
import TableReport from '../tableReport';
import ModalDelete from '../layout/modals/modalDelete';
import ModalResponse from '../layout/modals/modalResponse';
import { StatusCodes } from 'http-status-codes';

const columns = [
  {
    name: 'Nombre del usuario',
    selector: 'firstName',
    sortable: true
  },
  {
    name: 'Apellido',
    selector: 'lastName',
    sortable: true
  },
  {
    name: 'Correo Electrónico',
    selector: 'email',
    sortable: true,
    filterDDL: true
  },
  {
    name: 'Grupos',
    selector: 'groupUser',
    sortable: true,
    filterDDL: true
  }
];

let deleteDep = false;
class UserList extends Component {
  componentDidMount() {
    this.props.actions.getUsers();
  }

  checkDelete = id => {
    ModalDelete({
      actionDelete: this.props.actions.deleteUser,
      id: id
    });
    deleteDep = true;
  };

  render() {
    let { error, isLoading, isLoadingDel, userList, resDelete } = this.props;
    const btnActions = {
      btnEdit: '/user/edit/',
      btnDelete: this.checkDelete
    };
    let datos = [];
    let status = 0;
    let text = '';

    if (userList) {
      if (userList.data.error === 'Forbidden') {
        return <div>No tiene permiso para este componente</div>;
      }
      datos = userList.data;
    }
    if (resDelete && deleteDep && !isLoadingDel) {
      if (resDelete.status === StatusCodes.OK) {
        text = 'EL usuario ha sido eliminado';
      } else if (resDelete.status === StatusCodes.NOT_FOUND) {
        text = 'No fue posible eliminar el usuario';
      }
      deleteDep = false;
      status = resDelete.status;
    }
    return (
      <div>
        {!isLoadingDel && status > 0 && text !== '' ? (
          <ModalResponse title={'Eliminar Usuario!'} text={text} res={status} />
        ) : null}
        {error && <h4>Error: {error.response.data}</h4>}
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <h3>Usuarios</h3>
              <div>
                  <TableReport
                    filter={true}
                    columns={columns} // Data columns to table
                    tabs={false} //Enable tabs
                    tabsData={[]} //Load data to tabs check option on tabsData const
                    headerCounterTab={true} //Counter tab option
                    btnActions={btnActions}
                    actionButtons={true} //Enable buttons (edit, delete, copy, open)
                    pageSize={10} // Enable page size
                    pagination={true} //Enable pagination (true, false)
                    paginationNav={'arrows'} //Nav options (arrow, dot)
                    paginationPosition={'bottom'} //Pagination position (Bottom, top, both)
                    maxSizePagination={6} //Size pagination
                    defaultPaginationNumber={1} //Current pagination when start app
                    paginationGrouped={10} //Grouped by page
                    detailRow={false} //Open column detail with left arrow
                    data={datos} //Data for table
                  />
            </div>
          </div>
        )}
      </div>
    );
  }
}

UserList.propTypes = {
  actions: PropTypes.object.isRequired,
  userList: PropTypes.object,
  error: PropTypes.object
};

const mapStateToProps = state => ({
  userList: state.users.users,
  resDelete: state.users.user,
  error: state.users.err,
  isLoading: state.users.isLoading,
  isLoadingDel: state.users.isLoadingDel
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(UserActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserList);
