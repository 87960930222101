import React, { Component } from 'react';

import { SiigoSelectAtom } from '@siigo/siigo-select-atom-react'
import { SiigoNumberButtonMolecule } from '@siigo/siigo-number-button-molecule-react'

import { tipoDeCamaOptions, tipoAcomodacionOptions } from '../data/data'

class Sperfilanfitrionespacio extends Component {
    constructor(props) {
	    super(props);           
        let place = this.props.siiger.host.place[0];

        //tipo de acomodacion
        let _titems = tipoAcomodacionOptions;
        let objIndex = tipoAcomodacionOptions.findIndex((obj => obj.id == place.placetype ));
        _titems[objIndex].selected = true;

        //tipo de cama
        let _bomItems = tipoDeCamaOptions
        objIndex = tipoDeCamaOptions.findIndex((obj => obj.id == this.props.siiger.host.place[0].bedtype ));
        _bomItems[objIndex].selected = true;        

        this.state = {
            itemAcomodacion: _titems,
            itemTipoCama: _bomItems, 
            pCount: place.peoplecount,
            tAcomo: place.placetype,
            tBed: place.bedtype,
            siiger: this.props.siiger
        }
    }

    onChangeTipoDeAcomodacion(e){
        let taitems = tipoAcomodacionOptions;
        let objIndex = tipoAcomodacionOptions.findIndex((obj => obj.id == e.target.value));
        taitems[objIndex].selected = true;

        this.setState({ 
                    itemAcomodacion: taitems,
                    itemTipoCama: this.state.itemTipoCama,
                    pCount: this.state.pCount,
                    tAcomo: e.target.value,
                    tBed: this.state.tBed
                },() => {});      
    }

    onChangeTipoDeCama(e){
        let tbitems = tipoDeCamaOptions;
        let objIndex = tipoDeCamaOptions.findIndex((obj => obj.id == e.target.value));
        tbitems[objIndex].selected = true;

        this.setState({ 
                    itemAcomodacion: this.state.itemAcomodacion,
                    itemTipoCama: tbitems,
                    pCount: this.state.pCount,
                    tAcomo: this.state.tAcomo,
                    tBed: e.target.value
                },
            () => {});         
    }

    onChangeSpotPeopleNumber(e){
        this.setState({ 
                itemAcomodacion: this.state.itemAcomodacion,
                itemTipoCama: this.state.itemTipoCama,
                pCount: e.target.value,
                tAcomo: this.state.tAcomo,
                tBed: this.state.tBed    
            },() => {});
    }

    render(){
        return(
            <>
                <div className='scard alojamiento-espacio width-100'>
                    <div className=''>
                        <div className='col width-100'>
                            <h5>ESPACIO 1</h5>
                            
                            <SiigoSelectAtom 
                                label='Tipo de acomodación'
                                options={ this.state.itemAcomodacion }
                                onChangeData={ (e, val) => {this.onChangeTipoDeAcomodacion(e)} }
                            >
                            </SiigoSelectAtom>
                        
                            <hr className='siiger' />

                            <SiigoSelectAtom 
                                label='Tipo de cama'
                                options={ this.state.itemTipoCama }
                                onChangeData={ (e, val) => {this.onChangeTipoDeCama(e)} }
                            >              
                            </SiigoSelectAtom>
                        </div>
                    </div>

                    <div className="row align-center">
                        <SiigoNumberButtonMolecule
                            label="Capacidad del espacio (Nro. de huéspedes)"
                            maxValue={50}
                            minValue={1}
                            name="name"                                  
                            value={ this.state.pCount }
                            onChangedNumber={(e, val) => {this.onChangeSpotPeopleNumber(e)} }
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default Sperfilanfitrionespacio
