import React from 'react';
import { useAuth } from '../../context/auth-context';
import LeadsList from './index';

const LeadsListWrapper = (props) => {
    const { checkAuth } = useAuth();
    return <LeadsList {...props} checkAuth={checkAuth} />;
};

export default LeadsListWrapper;
