import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api';


export function* getCampaignUrl(action) {
  const api = API.create();
  try {
    let imageUrl = yield call(api.campaigns.getCampaignImage);

    yield put({ type: types.GET_CAMPAIGN_RECEIVED, data: imageUrl.data });
  } catch (err) {
    yield put({ type: types.SAVE_CAMPAIGN_FAILED, err });
  }
}

export function* setCampaign(action) {
  const api = API.create();
  try {
    const { image } = action;
    let campaign = yield call(api.campaigns.uploadCampaignImage, image);
    debugger
    yield put({ type: types.SAVE_CAMPAIGN_RECEIVED, data: campaign.data });
  } catch (err) {
    yield put({ type: types.SAVE_CAMPAIGN_FAILED, err });
  }
}