import * as types from "../index";

export const getCompanies = (params) => {
  return { type: types.GET_COMPANIES, params };
};

export const setPlan = (params) => {
  return { type: types.SET_PLAN, params };
};

export const setLog = (params) => {
  return { type: types.SET_LOG, params };
};

export const getTask = (params) => {
  return { type: types.GET_TASK, params };
};

export const setCompany = (params) => {
  return { type: types.SET_COMPANY, params };
};

export const modifyCompany = (params) => {
  return { type: types.MODIFY_COMPANY, params };
};

export const getCompanyLogs = (params) => {
  return { type: types.GET_COMPANY_LOGS, params };
};

export const changeCompanyAddonState = (params) => {
  return { type: types.CHANGE_COMPANY_ADDON_STATE, params };
}

export const getPackage = () => {
  return { type: types.GET_PACKAGE };
};

export const addPackage = (params) => {
  return { type: types.ADD_PACKAGE, params };
};

export const getAddOnsNE = (params) => {
  return { type: types.GET_ADDONS, params };
};

export const deleteBalance = (params) => {
  return { type: types.DELETE_BALANCE, params };
};

export const getBalancePack = (params) => {
  return { type: types.GET_BALANCE_PACK, params };
};

export const getPackageDetail = (params) => {
  return { type: types.GET_PACKAGE_DETAIL, params };
};

export const getUserHasMovements = (params) => {
  return { type: types.GET_USER_HAS_MOVEMENTS, params };
};

export const getUsersCompanyMarket = (params) => {
  return { type: types.GET_USERS_COMPANY_MARKET, params };
};

export const editUsersCompanyMarket = (params) => {
  return { type: types.EDIT_USER_COMPANY_MARKET, params };
};

export const deleteUsersCompanyMarket = (params) => {
  return { type: types.DELETE_USER_COMPANY_MARKET, params };
};

export const createUsersCompanyMarket = (params) => {
  return { type: types.CREATE_USER_COMPANY_MARKET, params };
};

export const getPayrollPackBalance = (params) => {
  return { type: types.GET_PAYROLL_PACKAGE_BALANCE, params };
};

export const addPayrollPackage = (params) => {
  return { type: types.ADD_PAYROLL_PACKAGE, params };
};

export const getPayrollDetail = (params) => {
  return { type: types.GET_PAYROLL_PACKAGE_DETAIL, params };
};

export const deletePayrollPackage = (params) => {
  return { type: types.DELETE_PAYROLL_PACKAGE, params };
}
export const emailGetCompanies = (params) => {
  return { type: types.EMAIL_GET_COMPANIES, params };
};

export const verifyNitEdition = (params) => {
  return { type: types.VERIFY_NIT_EDITION, params }
}