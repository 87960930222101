export const ACCESS = {
    Create_Companies: 1,
    Convert_Companies: 2,
    Modify_Users_With_Access: 3,
    Modify_POS: 4,
    Modify_Payroll_Employees: 5,
    Adding_Invoice_Packages: 6,
    Modify_Serial: 7,
    Change_Name: 8,
    Modify_Billing_Task: 9,
    Modify_Status: 10,
    Modify_Plan_Type: 11,
    Delete_Companies: 12,
    Bulk_load: 13,
    Add_complement:14,
    Modify_Quotation: 15,
    Modify_Billing_Task_Force: 16,
    Users_Management: 17,    
    Modify_Nit: 18,
    Users_Management_edit: 19,
    Users_Management_delete: 20,
    Users_Management_create: 21,
    Show_complement_NE: 22,
    Add_complement_NE: 23,
    Remove_complement_NE: 24,
    Edit_complement_NE: 25,
    Show_details_FE: 26,
    Remove_packs_FE:27,
    Add_pack_FE:28,
    Show_details_NE:29,
    Remove_packs_NE:30,
    Add_pack_NE: 31,
    Send_mail_company:32,
    Modify_Nit_Special: 34,
    Modify_Mail_Special:35
}