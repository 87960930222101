import { put, call } from 'redux-saga/effects';
import * as types from '../actions';
import API from '../services/api/indexGeneral';
import config from '../config.js';

export function* getTokens(action) {
    const api = API.create(config.urlDataHandler);
    try {
        const { params } = action;
        api.setHeader('token', "stark:iidkju===dk0");
        let subs = yield call(api.auroraTokens.getTokens, params);
        yield put({ type: types.GET_TOKENS_RECEIVED, data: subs });
    } catch (error) {
        yield put({ type: types.GET_TOKENS_FAILED, error });
    }
}