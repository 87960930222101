const countryMx = 'mx';
const planCountryMX = localStorage.getItem("i18nextLng") === countryMx;

export const PlanesNominaNube = {
  "Nuevo Siigo Nube": 5,
  "Solo Nómina": 11,
  "Siigo Nube Independiente": 12,
  "Siigo Nube Emprendedor": 13,
  "Siigo Nube Premium": 14
}

export const planes = {
  "5": [{ id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }],
  "6": [{ id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }],
  "7": [{ id: 724, name: 'Activación FE Documentos Electrónicos Nube (24Doc)' }, { id: 726, name: 'Activación FE Documentos Electrónicos Nube (60Doc)' }, { id: 78, name: 'Activación FE Documentos Electrónicos Nube (80Doc)' }, { id: 712, name: 'Activación FE Documentos Electrónicos Nube (120Doc)' }, { id: 72, name: 'Activación FE Documentos Electrónicos Nube (260Doc)' }, { id: 730, name: 'Activación FE Documentos Electrónicos Nube (300Doc)' }, { id: 715, name: 'Activación FE Documentos Electrónicos Nube (1500Doc)' }, { id: 15, name: 'Siigo FE PRO' }, { id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }, { id: 705, name: 'Facturación Electronica Gratis' }],
  "705": [{ id: 724, name: 'Activación FE Documentos Electrónicos Nube (24Doc)' }, { id: 726, name: 'Activación FE Documentos Electrónicos Nube (60Doc)' }, { id: 78, name: 'Activación FE Documentos Electrónicos Nube (80Doc)' }, { id: 712, name: 'Activación FE Documentos Electrónicos Nube (120Doc)' }, { id: 72, name: 'Activación FE Documentos Electrónicos Nube (260Doc)' }, { id: 730, name: 'Activación FE Documentos Electrónicos Nube (300Doc)' }, { id: 715, name: 'Activación FE Documentos Electrónicos Nube (1500Doc)' }, { id: 15, name: 'Siigo FE PRO' }, { id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }],
  "11": [{ id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }, { id: 75, name: 'FE Gratis' }, { id: 724, name: 'Activación FE Documentos Electrónicos Nube (24Doc)' }, { id: 726, name: 'Activación FE Documentos Electrónicos Nube (60Doc)' }, { id: 78, name: 'Activación FE Documentos Electrónicos Nube (80Doc)' }, { id: 712, name: 'Activación FE Documentos Electrónicos Nube (120Doc)' }, { id: 72, name: 'Activación FE Documentos Electrónicos Nube (260Doc)' }, { id: 730, name: 'Activación FE Documentos Electrónicos Nube (300Doc)' }, { id: 715, name: 'Activación FE Documentos Electrónicos Nube (1500Doc)' }, { id: 15, name: 'Siigo FE PRO' }],
  "12": [{ id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }],
  "13": [{ id: 14, name: 'Siigo Nube Premium' }],
  "78": [{ id: 724, name: 'Activación FE Documentos Electrónicos Nube (24Doc)' }, { id: 726, name: 'Activación FE Documentos Electrónicos Nube (60Doc)' }, { id: 712, name: 'Activación FE Documentos Electrónicos Nube (120Doc)' }, { id: 72, name: 'Activación FE Documentos Electrónicos Nube (260Doc)' }, { id: 730, name: 'Activación FE Documentos Electrónicos Nube (300Doc)' }, { id: 715, name: 'Activación FE Documentos Electrónicos Nube (1500Doc)' }, { id: 15, name: 'Siigo FE PRO' }, { id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }, { id: 705, name: 'Facturación Electronica Gratis' }],
  "72": [{ id: 724, name: 'Activación FE Documentos Electrónicos Nube (24Doc)' }, { id: 726, name: 'Activación FE Documentos Electrónicos Nube (60Doc)' }, { id: 78, name: 'Activación FE Documentos Electrónicos Nube (80Doc)' }, { id: 712, name: 'Activación FE Documentos Electrónicos Nube (120Doc)' }, { id: 730, name: 'Activación FE Documentos Electrónicos Nube (300Doc)' }, { id: 715, name: 'Activación FE Documentos Electrónicos Nube (1500Doc)' }, { id: 15, name: 'Siigo FE PRO' }, { id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }, { id: 705, name: 'Facturación Electronica Gratis' }],
  "75": [{ id: 724, name: 'Activación FE Documentos Electrónicos Nube (24Doc)' }, { id: 726, name: 'Activación FE Documentos Electrónicos Nube (60Doc)' }, { id: 78, name: 'Activación FE Documentos Electrónicos Nube (80Doc)' }, { id: 712, name: 'Activación FE Documentos Electrónicos Nube (120Doc)' }, { id: 72, name: 'Activación FE Documentos Electrónicos Nube (260Doc)' }, { id: 730, name: 'Activación FE Documentos Electrónicos Nube (300Doc)' }, { id: 715, name: 'Activación FE Documentos Electrónicos Nube (1500Doc)' }, { id: 15, name: 'Siigo FE PRO' }, { id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }, { id: 705, name: 'Facturación Electronica Gratis' }],
  "724": [{ id: 726, name: 'Activación FE Documentos Electrónicos Nube (60Doc)' }, { id: 78, name: 'Activación FE Documentos Electrónicos Nube (80Doc)' }, { id: 712, name: 'Activación FE Documentos Electrónicos Nube (120Doc)' }, { id: 72, name: 'Activación FE Documentos Electrónicos Nube (260Doc)' }, { id: 730, name: 'Activación FE Documentos Electrónicos Nube (300Doc)' }, { id: 715, name: 'Activación FE Documentos Electrónicos Nube (1500Doc)' }, { id: 15, name: 'Siigo FE PRO' }, { id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }, { id: 705, name: 'Facturación Electronica Gratis' }],
  "726": [{ id: 724, name: 'Activación FE Documentos Electrónicos Nube (24Doc)' }, { id: 78, name: 'Activación FE Documentos Electrónicos Nube (80Doc)' }, { id: 712, name: 'Activación FE Documentos Electrónicos Nube (120Doc)' }, { id: 72, name: 'Activación FE Documentos Electrónicos Nube (260Doc)' }, { id: 730, name: 'Activación FE Documentos Electrónicos Nube (300Doc)' }, { id: 715, name: 'Activación FE Documentos Electrónicos Nube (1500Doc)' }, { id: 15, name: 'Siigo FE PRO' }, { id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }, { id: 705, name: 'Facturación Electronica Gratis' }],
  "715": [{ id: 724, name: 'Activación FE Documentos Electrónicos Nube (24Doc)' }, { id: 726, name: 'Activación FE Documentos Electrónicos Nube (60Doc)' }, { id: 78, name: 'Activación FE Documentos Electrónicos Nube (80Doc)' }, { id: 712, name: 'Activación FE Documentos Electrónicos Nube (120Doc)' }, { id: 72, name: 'Activación FE Documentos Electrónicos Nube (260Doc)' }, { id: 730, name: 'Activación FE Documentos Electrónicos Nube (300Doc)' }, { id: 15, name: 'Siigo FE PRO' }, { id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }, { id: 705, name: 'Facturación Electronica Gratis' }],
  "730": [{ id: 724, name: 'Activación FE Documentos Electrónicos Nube (24Doc)' }, { id: 726, name: 'Activación FE Documentos Electrónicos Nube (60Doc)' }, { id: 78, name: 'Activación FE Documentos Electrónicos Nube (80Doc)' }, { id: 712, name: 'Activación FE Documentos Electrónicos Nube (120Doc)' }, { id: 72, name: 'Activación FE Documentos Electrónicos Nube (260Doc)' }, { id: 715, name: 'Activación FE Documentos Electrónicos Nube (1500Doc)' }, { id: 15, name: 'Siigo FE PRO' }, { id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }, { id: 705, name: 'Facturación Electronica Gratis' }],
  "712": [{ id: 724, name: 'Activación FE Documentos Electrónicos Nube (24Doc)' }, { id: 726, name: 'Activación FE Documentos Electrónicos Nube (60Doc)' }, { id: 78, name: 'Activación FE Documentos Electrónicos Nube (80Doc)' }, { id: 72, name: 'Activación FE Documentos Electrónicos Nube (260Doc)' }, { id: 730, name: 'Activación FE Documentos Electrónicos Nube (300Doc)' }, { id: 715, name: 'Activación FE Documentos Electrónicos Nube (1500Doc)' }, { id: 15, name: 'Siigo FE PRO' }, { id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }, { id: 705, name: 'Facturación Electronica Gratis' }],
  "15": [{ id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }],
  "16": [{ id: 724, name: 'Activación FE Documentos Electrónicos Nube (24Doc)' }, { id: 726, name: 'Activación FE Documentos Electrónicos Nube (60Doc)' }, { id: 712, name: 'Activación FE Documentos Electrónicos Nube (120Doc)' }, { id: 730, name: 'Activación FE Documentos Electrónicos Nube (300Doc)' }, { id: 15, name: 'Siigo FE PRO' }, { id: 715, name: 'Activación FE Documentos Electrónicos Nube (1500Doc)' }, { id: 12, name: 'Siigo Nube Independiente' }, { id: 13, name: 'Siigo Nube Emprendedor' }, { id: 14, name: 'Siigo Nube Premium' }]
}

export const planesConversionMx = {
  "20": [{ id: 21, name: 'Siigo Nube Gestión Inicio' }, { id: 22, name: 'Siigo Nube Gestión Avanzado' }, { id: 17, name: 'Siigo Nube Gestión Premium' }],
  "25": [{ id: 21, name: 'Siigo Nube Gestión Inicio' }, { id: 22, name: 'Siigo Nube Gestión Avanzado' }, { id: 17, name: 'Siigo Nube Gestión Premium' }],
  "21": [{ id: 22, name: 'Siigo Nube Gestión Avanzado' }, { id: 17, name: 'Siigo Nube Gestión Premium' }],
  "22": [{ id: 17, name: 'Siigo Nube Gestión Premium' }]
}

export const rolUsersCompany = {
  0: "Con acceso",
  1: "Con acceso lite",
  2: "Con acceso contador",
  3: "Gestor nómina",
  100: "Administrador"
}

export const rolUsersCompanyMap = [
  { id: 0, name: 'Con acceso' },
  { id: 1, name: 'Con acceso lite' },
  { id: 2, name: 'Con acceso contador' },
  { id: 3, name: 'Gestor nómina' },
]

export const rolUsersCompanyEnum = {
  "WITH_ACCESS": 0,
  "WITH_ACCESS_LITE": 1,
  "WITH_ACCESS_ACCOUNTANT": 2,
  "PAYROLL_MANAGER": 3,
}

export const ALLOWED_ACCOUNTANT_USERS = 1;

export const productListMex = [
  {
    code: '9510003000003',
    name: 'Siigo Nube Total Premium'
  },
  {
    code: '9510003000001',
    name: 'Siigo Nube Gestión Inicio'
  },
  {
    code: '9510003000002',
    name: 'Siigo Nube Gestión Avanzado'
  },
  {
    code: '9510002000001',
    name: 'Siigo Nube Gestión Premium'
  },
  {
    code: '9510003000006',
    name: 'Siigo Nube Facturación'
  },
  {
    code: '9510003000007',
    name: 'Siigo Nube Facturación Duo'
  },
  {
    code: '9510003000008',
    name: 'Siigo Nube Contabilidad Inicio'
  },
  {
    code: '9510003000009',
    name: 'Siigo Nube Contabilidad Avanzado'
  }
]

export const productListCol = [
  {
    code: '9410001000106',
    name: 'Siigo Nube Independiente'
  },
  {
    code: '9410001000107',
    name: 'Siigo Nube Emprendedor'
  },
  {
    code: '9410001000108',
    name: 'Siigo Nube Premium'
  },
  {
    code: '9380001000001',
    name: 'Solo Facturación'
  },
  {
    code: '9380002000046',
    name: 'Activación Facturación E (24Doc)'
  },
  {
    code: '9380002000104',
    name: 'Activación Facturación E (60Doc)'
  },
  {
    code: '9380002000013',
    name: 'Activación Facturación E (80Doc)'
  },
  {
    code: '9380002000047',
    name: 'Activación Facturación E PRO (120Doc)'
  },
  {
    code: '9380002000012',
    name: 'Activación Facturación E PRO (260Doc)'
  },
  {
    code: '9380002000048',
    name: 'Activación Facturación E PRO (300Doc)'
  },
  {
    code: '9380002000034',
    name: 'Activación Facturación E (1500Doc)'
  },
  {
    code: '9380002000045',
    name: 'Activación Siigo FE PRO'
  },
  {
    code: '9460001000003',
    name: 'Nómina Lite Paquete 2 Empleados (24 Doc) Modulo'
  },
  {
    code: '9460001000002',
    name: 'Nómina Lite Paquete 10 Empleados (120 Doc) Modulo'
  },
  {
    code: '9460001000001',
    name: 'Nómina Lite Paquete 25 Empleados (300 Doc) Modulo'
  },
  {
    code: '9460001000005',
    name: 'Nómina Nube Plus Modulo'
  },
  {
    code: '9460001000004',
    name: 'Nómina Nube Pro Modulo'
  },
  {
    code: '9490001000054',
    name: 'POS Inicio'
  },
  {
    code: '9490001000055',
    name: 'POS Avanzado'
  }
]
export const optionState = [
  {
    "name": "Activo",
    "label": "Activo",
    "selected": false,
    "valueSelected": 0,
    "value": 1,
  },
  {
    "name": "Inactivo",
    "label": "Inactivo",
    "selected": false,
    "valueSelected": 0,
    "value": 0,
  },
  {
    "name": "Solo consulta",
    "label": "Solo consulta",
    "selected": false,
    "valueSelected": 0,
    "value": 2,
  }
]
export const Complementos = [
  {
    "name": "Nómina Electrónica",
    "value": 0,
    "label": "Nómina Electrónica",
    "selected": false,
    "planesComplemento": [
      {
        "name": "Lite Gratis",
        "label": "Lite Gratis",
        "selected": false,
        "valueSelected": 0,
        "value": 0,
        "valueParent": 0,
        "id": 724,
        "documentBase": 5,
        "code": "9460001000021"
      },
      {
        "name": "Lite (24 documentos)",
        "label": "Lite (24 documentos)",
        "selected": false,
        "valueSelected": 0,
        "value": 1,
        "valueParent": 0,
        "id": 724,
        "documentBase": 24,
        "code": "9460001000008"
      },
      {
        "name": "Lite (120 documentos)",
        "label": "Lite (120 documentos)",
        "selected": false,
        "valueSelected": 0,
        "value": 2,
        "valueParent": 0,
        "id": 724,
        "documentBase": 120,
        "code": "9460001000007"
      },
      {
        "name": "Lite (300 documentos)",
        "label": "Lite (300 documentos)",
        "selected": false,
        "value": 3,
        "valueSelected": 0,
        "valueParent": 0,
        "id": 724,
        "documentBase": 300,
        "code": "9460001000006"
      },
      {
        "name": "Plus (documentos ilimitados)",
        "label": "Plus (documentos ilimitados)",
        "selected": false,
        "value": 4,
        "valueSelected": 1,
        "valueParent": 0,
        "id": 730,
        "documentBase": 0,
        "code": "9460001000010"
      },
      {
        "name": "Pro (documentos ilimitados)",
        "selected": false,
        "label": "Pro (documentos ilimitados)",
        "value": 5,
        "valueSelected": 2,
        "valueParent": 0,
        "id": 15,
        "documentBase": 0,
        "code": "9460001000009"
      },
      {
        "name": "Nómina nube",
        "selected": false,
        "label": "Nómina nube",
        "value": 6,
        "valueSelected": 3,
        "valueParent": 0,
        "id": 21,
        "documentBase": 0,
        "code": "9460001000004"

      }
    ]
  },
  {
    "name": "POS Web",
    "value": 1,
    "label": "POS Web",
    "selected": false,
    "planesComplemento": [
      {
        "name": "POS Inicio",
        "label": "POS Inicio",
        "selected": false,
        "valueSelected": 4,
        "value": 4,
        "valueParent": 1,
        "id": 7,
        "documentBase": 0,
        "code": "POSBASIC",
        "posActiveCashiers": {
          "baseCashiers": 1,
          "aditionalCashiers": 0,
        }
      },
      {
        "name": "POS Avanzado",
        "label": "POS Avanzado",
        "selected": false,
        "valueSelected": 5,
        "value": 5,
        "valueParent": 1,
        "id": 15,
        "documentBase": 0,
        "code": "POSADVANCED",
        "posActiveCashiers": {
          "baseCashiers": 1,
          "aditionalCashiers": 0,
        }
      }
    ]
  },
  {
    "name": "FE WhatsApp",
    "value": 2,
    "label": "FE WhatsApp",
    "selected": false,
    "planesComplemento": [
      {
        "name": "FE WhatsApp",
        "label": "FE WhatsApp",
        "selected": false,
        "valueSelected": 6,
        "value": 6,
        "valueParent": 2,
        "id": 7,
        "documentBase": 0,
        "code": "FEWHATSAPP"
      }
    ]
  }
]

export const ALLOWED_NUMBER_OF_CASHIERS_FE = {
  POS_WEB_INITIAL: 0,
  POS_WEB_ADVANCE: 2,
}

export const ALLOWED_NUMBER_OF_CASHIERS = 998;

export const MODULE_ENUM = {
  PAYROLL_LITE: 0,
  PAYROLL_PLUS: 1,
  PAYROLL_PRO: 2,
  PAYROLL_CLOUD: 3,
  POS_WEB_INITIAL: 4,
  POS_WEB_ADVANCE: 5,
  FE_WHATSAPP: 6
}

export const COMPLEMENTS_TYPES = {
  0: "Nómina Electrónica",
  1: "POS Web",
  2: "FE WhatsApp"
}

export const COMPLEMENTS_TYPES_ENUM = {
  NOMINA: 0,
  POW_WEB: 1,
  FE_WHATSAPP: 2
}

export const DEFAULT_POS_WEB_NAMES = {
  4: "Inicio",
  5: "Avanzado",
}

export const POW_WEB_MODULES_TYPES_ENUM = [4, 5]

export const POS_WEB_FE_DOCUMENTS = [300, 1500]

export const PLANTYPE_FE = 7;

export const ALLOWED_PLANT_TYPES_POS_WEB = [7, 15, 13, 14, 12];
export const ALLOWED_PLANT_TYPES_FE_WHATSAPP = [7];

export const ALLOWED_COMPLEMENTS_BY_PLAN_TYPE = {
  7: 3,
}

export const NumEmployesByLicence = {
  "9410001000106": 1,
  "9410001000107": 3,
  "9410001000108": 5,
  "9380001000001": 1,
  "9380002000009": 1,
  "9380002000013": 1,
  "9380002000012": 1,
  "9380002000033": 1,
  "9380002000034": 1,
  "9380002000048": 1,
  "9380002000047": 1,
  "9380002000045": 1,
  "9510003000003": 3,
  "9410001000130": 1,
  "9510003000001": 2,
  "9510003000002": 2,
  "9510002000001": 3,
  "9510003000006": 1,
  "9510003000007": 2,
  "9510003000008": 2,
  "9510003000009": 2,
  "9490001000054": 1,
  "9490001000055": 1
}

/* Son  los valores de las licencias que se utilizan para los PlanType */
export const LICENCE_TYPE = {
  "MUNDO_ANTERIOR": { value: 1, name: 'Mundo anterior' },
  "SIIGO_NUBE": { value: 5, name: 'Siigo Nube' },
  "CONTADOR": { value: 6, name: 'Contador' },
  "SOLO_FACTURACION": { value: 7, name: 'Solo facturación' },
  "NOMINA": { value: 10, name: 'Nomina' },
  "SOLO_NOMINA": { value: 11, name: 'Solo Nomina' },
  "SIIGO_NUBE_INDEPENDIENTE": { value: 12, name: 'Siigo Nube Independiente' },
  "SIIGO_NUBE_EMPRENDEDOR": { value: 13, name: 'Siigo Nube Emprendedor' },
  "SIIGO_NUBE_PREMIUM": planCountryMX ? { value: 14, name: 'Siigo Nube Total Premium' } : { value: 14, name: 'Siigo Nube Premium' },
  "SIIGO_FE_PRO": { value: 15, name: 'Siigo FE PRO' },
  "SIIGO_POS": { value: 16, name: 'Siigo POS' },
  "SIIGO_NUBE_GESTION_PREMIUM": { value: 17, name: 'Siigo Nube Gestión Premium' },
  "SIIGO_NUBE_FACTURACION": { value: 20, name: 'Siigo Nube Facturación' },
  "SIIGO_NUBE_FACTURACION_DUO": { value: 25, name: 'Siigo Nube Facturación Duo' },
  "SIIGO_NUBE_GESTION_INICIO": { value: 21, name: 'Siigo Nube Gestión Inicio' },
  "SIIGO_NUBE_GESTION_INICIO_AVANZADO": { value: 22, name: 'Siigo Nube Gestión Avanzado' },
  "SIIGO_NUBE_CONTABILIDAD_INICIO": { value: 24, name: 'Siigo Nube Contabilidad Inicio' },
  "SIIGO_NUBE_CONTABILIDAD_AVANZADO": { value: 23, name: 'Siigo Nube Contabilidad Avanzado' },
  "SOLO_FACTURACION_(260)": { value: 72, name: 'Solo facturación (260)' },
  "SOLO_FACTURACION_(5)": { value: 75, name: 'Solo facturación (5)' },
  "SOLO_FACTURACION_(80)": { value: 78, name: 'Solo facturación (80)' },
  "SOLO_FACTURACION_(120)": { value: 712, name: 'Solo facturación (120)' },
  "SOLO_FACTURACION_(1500)": { value: 715, name: 'Solo facturación (1500)' },
  "SOLO_FACTURACION_(24)": { value: 724, name: 'Solo facturación (24)' },
  "SOLO_FACTURACION_(60)": { value: 726, name: 'Solo facturación (60)' },
  "SOLO_FACTURACION_(300)": { value: 730, name: 'Solo facturación (300)' },
  "FE_GRATIS": { value: 705, name: 'Solo facturación (Gratis)' },
}

export const PlanType = {
  12: 'Siigo Nube Independiente',
  13: 'Siigo Nube Emprendedor',
  14: planCountryMX ? 'Siigo Nube Total Premium' : 'Siigo Nube Premium',
  7: 'Solo facturación',
  78: 'Solo facturación (80)',
  72: 'Solo facturación (260)',
  75: 'Solo facturación (5)',
  724: 'Solo facturación (24)',
  726: 'Solo facturación (60)',
  715: 'Solo facturación (1500)',
  6: 'Contador',
  1: 'Mundo anterior',
  11: 'Nómina',
  5: 'Siigo Nube',
  730: 'Solo facturación (300)',
  712: 'Solo facturación (120)',
  15: 'Siigo FE PRO',
  705: 'Solo facturación (Fe Gratis)',
  16: 'Siigo POS',
  17: 'Siigo Nube Gestión Premium',
  20: 'Siigo Nube Facturación',
  21: 'Siigo Nube Gestión Inicio',
  22: 'Siigo Nube Gestión Avanzado',
  23: 'Siigo Nube Contabilidad Avanzado',
  24: 'Siigo Nube Contabilidad Inicio',
  25: 'Siigo Nube Facturación Duo'
};

export const planSubType = {
  0: 'N/A',
  null: 'N/A',
  1: 'Mensual',
  2: 'Gratis',
  3: 'Anual',
  4: 'Convenio'
};

export const AllowedInvoices = {
  '78': '80',
  '72': '260',
  '75': '5',
  '724': '24',
  '726': '60',
  '715': '1500',
  '730': '300',
  '712': '120',
  '15': '500',
  '705': '5'
}
export const invoicesPlan = {
  '80': '78',
  '260': '72',
  '5': '75',
  '24': '724',
  '60': '726',
  '1500': '715',
  '300': '730',
  '120': '712',
  '500': '15'
}

export const EVENTS_LOGS = {
  CREATE_ADDON: "created-addon",
  UPDATED_ADDON: "updated-addon",
  DELETED_ADDON: "deleted-addon",
  CREATED_COMPANY: "created-company",
  UPDATED_COMPANY_INFO: "updated-company-info",
  UPGRADED_COMPANY: "upgraded-company",
  RESENT_MAIL: "resent-mail",
  ADD_FE_DOCUMENTS:"added-fe-documents",
  ADD_NE_DOCUMENTS:"added-ne-documents",
  REMOVE_FE_DOCUMENTS:"removed-fe-documents",
  REMOVE_NE_DOCUMENTS:"removed-ne-documents"
}
