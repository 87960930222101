import { gql } from 'graphql-request';

export const updateUserMutation = gql`
        mutation User(
            $userLogin: String!
            $source: String!
            $companies: [Users_Input_CompanyUpdate!]!
            $enableZeroValues: [String]
        ) {
            UpdateUser (
                userLogin: $userLogin
                source: $source
                enableZeroValues: $enableZeroValues
                companies: $companies
            ) {
                userId
            }
        }
    `;

export const getUpdateUserVariables = (data) => {
    const company = JSON.parse(localStorage.getItem("Company"));
    return {
        userLogin: data.email,
        source: "Backoffice",
        enableZeroValues: ["isActive", "isAdministrator", "userLicenseType"],
        companies: [
            {
                tenantId: company.CloudTenantConnectionUser.split("_")[1],
                userId: 1,
                isActive: data.status,
                basicInformation: {
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    fullName: data.firstName + " " + data.lastName,
                    identification: data.identification,
                    phone: data.cellPhone,
                    idType: 18, // TODO: Change this to a dynamic value
                },
                systemParameters: {
                    userLicenseType: data.role,
                    isAdministrator: data.isAdmin,
                    licenseType: 0,
                },
            }
        ]
    }
}