import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ModalResponse from '../layout/modals/modalResponse';
import { withRouter } from 'react-router-dom';
import './style.scss';
import * as NotificationsActions from '../../actions/notificationAS/notificationAction';

let update = false;
class NotificationEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      textEditor: ''
    };
  }
  onHandleEdit = event => {
    event.preventDefault();

    let id = this.props.id;
    let criterion = event.target.criterion.value;
    let description = event.target.description.value;
    let state = event.target.state.value;
    let subject = event.target.subject.value;
    let message = this.state.textEditor;
    const data = {
      id,
      criterion,
      description,
      state,
      subject,
      message
    };
    this.props.actions.editNotification(data);
    update = true;
  };

  componentDidMount() {
    document.title = 'Edit Notification';
  }

  render() {
    let notification = null;
    let criterion,
      description,
      state,
      subject,
      message = '';
    const { notifications, notificationEdit, isLoadingEdit } = this.props;
    if (localStorage.getItem('notification')) {
      notification = JSON.parse(localStorage.getItem('notification'));
    }
    if (notifications) {
      if (notification) {
        if (notification._id != this.props.id) {
          notification = notifications.data.find(
            element => element._id == this.props.id
          );
        }
      } else {
        notification = notifications.data.find(
          element => element._id == this.props.id
        );
      }
      localStorage.setItem('notification', JSON.stringify(notification));
    }
    if (notificationEdit && update) {
      if (notificationEdit.data.status) {
        notification = JSON.parse(localStorage.getItem('notification'));
        notification.criterion = document.getElementById('criterion').value;
        notification.description = document.getElementById('description').value;
        notification.state = document.getElementById('state').value;
        notification.subject = document.getElementById('subject').value;
        notification.message = this.state.textEditor;
        localStorage.setItem('notification', JSON.stringify(notification));
        ModalResponse({
          title: 'Editar Notificación',
          text: 'Se edito la notificación correctamente',
          res: 200
        });
        this.props.history.push('/notificationas');
      } else if (!isLoadingEdit) {
        ModalResponse({
          title: 'Editar Notificación',
          text: 'No fue posible editar la notificación',
          res: 404
        });
      }
      update = false;
    }
    if (notification) {
      criterion = notification.criterion;
      description = notification.description;
      state = notification.state;
      subject = notification.subject;
      message = notification.message;
    }

    return (
      <div>
        <form onSubmit={this.onHandleEdit}>
          <h3 className="h3Noti">Editar la notificación</h3>
          <div className="input-container">
            <input
              type="text"
              name="criterion"
              id="criterion"
              placeholder="Criterio"
              defaultValue={criterion}
            />
          </div>
          <div className="input-container">
            <input
              type="text"
              name="description"
              id="description"
              placeholder="Descripcion"
              defaultValue={description}
            />
          </div>
          <div className="input-container">
            <select id="state" name="state" DefaultValue={state}>
              <option value="1">Activo</option>
              <option value="0">Inactivo</option>
            </select>
          </div>

          <h3 className="h3Noti">Contenido del correo</h3>
          <div className="input-container">
            <input
              type="text"
              name="subject"
              id="subject"
              placeholder="Asunto"
              defaultValue={subject}
            />
          </div>
          <div className="input-container">
            <CKEditor
              editor={ClassicEditor}
              data={message}
              id="editorNoti"
              onInit={editor => {
                // You can store the "editor" and use when it is needed.
                this.setState({ textEditor: message });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                this.setState({ textEditor: data });
              }}
            />
          </div>
          <div>
            <button type="submit" className="button green-fill">
              {' '}
              Guardar
            </button>
          </div>
        </form>
      </div>
    );
  }
}

NotificationEdit.propTypes = {
  actions: PropTypes.object.isRequired,
  notifications: PropTypes.object,
  error: PropTypes.object,
  notificationEdit: PropTypes.object
};

const mapStateToProps = state => ({
  notifications: state.synergy.notifications,
  error: state.synergy.err,
  isLoading: state.synergy.isLoading,
  isLoadingEdit: state.synergy.isLoadingEdit,
  notificationEdit: state.synergy.notificationEdit
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(NotificationsActions, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationEdit));
